// This file is for holding logic that must happen when the front-end application loads.

import Translate from 'i18n-react';

import {supportedLanguages} from './constants/SupportedLanguages';
import {MultiLocaleRouter} from './services';
import multiLocaleRouterConfigSlice from './entities/routing/multiLocaleRouter/multiLocaleRouterConfigSlice';

export const initializeLocalization = (pageLang) => {
  // i18n
  const texts = {};
  const localeModuleFiles = [
    'common',
    'cart',
    'checkout',
    'designs',
    'errors',
    'fabric',
    'fabricShop',
    'footer',
    'header',
    'invoice',
    'navigation',
    'orderItems',
    'pagination',
    'payment',
    'search',
    'socialMedia',
    'tags',
    'welcome',
    'testimonies',
    'backToTop',
    'totalPrice',
    'vatLegalese',
    'fillayard',
    'collections',
    'cartPop',
    'measurements',
    'userPreferences',
    'titles',
    'business',
    'help',
    'designGrant',
    'reviews',
    'collections',
    'businessGrant',
    'meta',
    'homeGood',
    'homeGoodFabrics',
    'wallpaper',
    'stockItems',
    'subscribeNewsletter',
    'afterpay',
    'solidFabrics',
    'solidColors',
    'cardBlock',
    'wallpaperCalculator',
    'promos',
    'privacy',
    'requestForInformation',
    'accountDeletion',
  ];

  for (const langCode of supportedLanguages) {
    texts[langCode] = [];

    for (const localeModule of localeModuleFiles) {
      try {
        texts[langCode][localeModule] = require(`./locales/${langCode}/${localeModule}.yml`);
      } catch (e) {
        // Not all languages have all modular locale files
      }
    }
  }
  texts.xurls = require('./locales/xurls.yml');
  texts.fabricMeasurements = require('./locales/fabricMeasurements.yml');
  Translate.setTexts(texts);

  MultiLocaleRouter.setPreferredLocale(pageLang);
};

export const initializeMultilocaleRouter = (lang) => {
  MultiLocaleRouter.setPreferredLocale(lang);
  MultiLocaleRouter.initRoutesTranslated(multiLocaleRouterConfigSlice);
};

// Some tests require locale configurations that missing in multiLocaleRouterConfigSlice.
export const initializeMultilocaleRouterWithTestData = (lang) => {
  MultiLocaleRouter.setPreferredLocale(lang);
  MultiLocaleRouter.setRoutesTranslated({
    ...multiLocaleRouterConfigSlice,
    'solid.page': {
      'en': '/en/solid-fabric',
      'de': '/de/uni-stoffe',
      'es': '/es/tela-lisa',
      'fr': '/fr/tissus-unis'
    },
  });
};
