module.exports = {
	"headerTitle": "Checkout",
	"progressShipping": "Shipping",
	"progressDelivery": "Delivery",
	"progressPayment": "Payment",
	"progressReview": "Review",
	"progressConfirm": "Confirm",
	"progressNoShipping": "No Shipping",
	"afterpay": "Afterpay",
	"shipping": {
		"pageTitleLong": "Shipping & Contact Information",
		"pageTitle": "Shipping Information",
		"loading": "Loading Checkout Step 1 of 5 - Shipping Form",
		"shippedFromNC": "Your order will be shipping from Durham, North Carolina.",
		"noShipping": "Your order does not require a shipping address.",
		"checklist": {
			"factoryBerlin": "Spoonflower has a factory in Berlin!",
			"internationalCheckoutUse": "Using our new International checkout means that you:",
			"shipFromBerlin": "will have your order shipped from Berlin.",
			"avoidCustoms": "avoid customs fees and trips to the customs office.",
			"getItFaster": "get your order faster!"
		},
		"form": {
			"titleContact": "Contact Information",
			"titleShipping": "Shipping Address",
			"email": "Email address",
			"emailHelper": "To check out as a guest, an email address is required.",
			"retypeEmail": "Retype email address",
			"country": "Country",
			"selectCountry": "Please select a country",
			"fullName": "Full name",
			"address1": "Address line 1",
			"address1Helper": "Street address, P.O. box, company name, c/o",
			"address2": "Address line 2",
			"address2Helper": "Apartment, suite, unit, building, floor",
			"city": "City",
			"state": "State",
			"selectState": "Please select a state",
			"province": "Province",
			"selectProvince": "Please select a province",
			"optional": "Optional",
			"zipcode": "Zipcode",
			"phoneNumber": "Phone number",
			"phoneNumberHelper": "Include your phone number to assist with your delivery.",
			"back": "Back",
			"backTitle": "Back to your Shopping Cart",
			"next": "Next",
			"nextTitle": "Choose your delivery options next",
			"newsletter": "Send me Spoonflower updates",
			"newsletterDescription": "When you sign up for our email list, you’ll be the first to hear about deals, exciting new products and much more!"
		}
	},
	"delivery": {
		"pageTitle": "Delivery Options",
		"loading": "Loading Checkout Step 2 of 5 - Delivery Options",
		"germany": "Germany",
		"theUsa": "the USA",
		"shipsFrom": "Ships from",
		"giftOptions": "Gift Options",
		"removePrices": "Remove prices from packing slip",
		"textareaPlaceholder": "Write a message for the recipient here",
		"textareaLabel": "Message for the recipient",
		"shippingHelpLink": "Learn more about different services here",
		"shippingHelpText": "Shipping prices are calculated by adding up the weight of your order. ",
		"homeTextilesDeliveryInfo": "Orders containing home decor items are not eligible for Guaranteed or Rush delivery.",
		"homeTextilesArrivalTimeInfo": "The time below is for your home decor item(s). Fabric and wallpaper will arrive sooner.",
		"homeTextilesSplittingInfo": "For different options, please split your order into two orders.",
		"deliveryLimitation": "(fabric and wallpaper only)",
		"deliverySpeed": "Delivery Speed",
		"noShippingRequired": "Your order does not require shipping!",
		"shipping": "Shipping:",
		"backShipping": "Back to Shipping Address",
		"nextPayment": "Enter your payment information next",
		"noticeStandardDelivery": "Please note: Some of the items in your cart are only available with Standard delivery. Please contact help@spoonflower.com if you have questions.",
		"deliveryStandard": "Standard",
		"deliveryGuaranteed": "Guaranteed",
		"deliveryRush": "Rush",
		"shipsInRange": "(ships in {ships_in_min} to {ships_in_max} days)",
		"shipsOnDate": "(ships on or before {date})",
		"getItBy": "(get it {date})",
		"turnaroundTitle": "Made to order in:",
		"turnaroundDays": "{turnaround_days} business days",
		"turnaroundRange": "{turnaround_min}-{turnaround_max} business days",
		"SHIPPING_MESSAGE_1_heading": "COVID-19 shipping notice",
		"SHIPPING_MESSAGE_1": "The Standard Delivery provider for your destination country is not currently delivering packages. Orders placed with Standard Delivery will be held in our queue until these services become available again.",
		"SHIPPING_MESSAGE_2_heading": "COVID-19 shipping notice",
		"SHIPPING_MESSAGE_2": "The Rush & Guaranteed Delivery provider for your destination country is not currently delivering packages. To ensure delivery please select Standard Delivery method.",
		"SHIPPING_MESSAGE_3_heading": "COVID-19 shipping notice",
		"SHIPPING_MESSAGE_3": "Shipping providers are not currently delivering packages to your destination country. Orders placed will be held in our queue until these services become available again.",
		"SHIPPING_MESSAGE_4_heading": "COVID-19 fulfillment notice",
		"SHIPPING_MESSAGE_4": "As of ____, our factory in ______ is temporarily closed. Your order will be processed as soon as we’re back in business, but please note that your order will be delayed. We will not be able to provide shipping estimates for these orders – we will update customers as soon as we are back up and running.",
		"SHIPPING_MESSAGE_5_heading": "COVID-19 shipping notice",
		"SHIPPING_MESSAGE_5": "Due to social distancing measures for COVID-19, we are prioritizing the safety of our associates and making changes to our operations. We are currently unable to support expedited shipping for many locations at this time. Many shipping carriers are changing policies as well which make it difficult for us to guarantee delivery dates. If expedited shipping is available for your order, you will see this option below. Please keep in mind that an order cannot be edited or changed once it has been placed. We appreciate your understanding!",
		"SHIPPING_MESSAGE_6": "Due to unforeseen circumstances, we are temporarily unable to offer Guaranteed Delivery or Rush at this time. We will continue to work hard printing and processing orders quickly on our end. Delivery services across the USA are experiencing delays in processing and delivery due to weather and Covid safety precautions. Please refer to your tracking information for updates on your shipment."
	},
	"payment": {
		"pageTitle": "Payment Information",
		"loading": "Loading Checkout Step 3 of 5 - Payment",
		"minimumPriceGbp": "{amount} pence",
		"minimumPriceOther": "{amount} cents",
		"noFurtherPayments": "Your order requires no further payment!",
		"freeOrder": "Your order is free!",
		"abn": "ABN",
		"nzbn": "NZBN",
		"auBusinessNr": "Australian Business Number",
		"nzBusinessNr": "New Zealand Business Number",
		"vatNr": "VAT Number",
		"vatNrCorrect": "Correct VAT number",
		"vatRegisterGst": "Please click to confirm you are registered for GST",
		"vatRegisteredGst": "You are registered for GST",
		"vatEnter": "Enter",
		"vatApply": "Apply",
		"vatApplyTax": "Apply {taxType}",
		"sectionTitle": "Payment",
		"giftCertificate": "Gift Certificate",
		"giftCertificateRedeem": "Redeem your Gift Certificate",
		"giftCertificateRedeemHere": "Redeem it here",
		"spoonDollarApply": "Apply Spoondollars to Your Order",
		"spoonDollarRemove": "Remove All Spoondollars from Your Order",
		"spoonDollarAnother": "another ",
		"spoonDollarApplyMore": "Apply {another}{displayMaxAvailableSpoondollarsToApply} Spoondollars",
		"spoonDollarSave": "Save Spoondollars for Later",
		"spoonDollarApplied": "{displayAppliedSpoondollars} ({currency}) applied",
		"backTitle": "Back to Delivery Options",
		"next": "Review Order",
		"nextTitle": "Review your order next",
		"headings": {
			"useANewCard": "Use a new card",
			"yourCards": "Your Cards",
			"expires": "Expires",
			"billingAddress": "Billing Address",
			"paymentInfo": "Payment Info"
		},
		"checkboxes": {
			"sameAsShipping": "Same as shipping address",
			"savePayment": "Save Payment Information"
		},
		"buttons": {
			"deleteYourSavedCard": "Delete your saved card"
		},
		"creditCardsImgAlt": "We accept the following cards: Visa, MasterCard, American Express, JCB, Discover, and Diners Club",
		"nameOnCard": "Name on card",
		"cardNumber": "Card number",
		"cardNumberPlaceholder": "•••• •••• •••• ••••",
		"expirationDate": "Expiration date",
		"month": "Month",
		"year": "Year",
		"cvc": "CVC",
		"cvcHelperQ": "Why does Spoonflower need my CVC?",
		"cvcHelperA": "Providing your CVC number to an online merchant proves that you actually have the physical credit or debit card - and helps to keep you safe while reducing fraud. Your CVC number is not longer than 4 digits.",
		"payPalInfoHeading": "Additional Information",
		"payPalInfoSupportCurrency": "For PayPal payments we do not support {currency} at the moment. Please continue with US Dollars or use a credit card.",
		"payPalLoginSuccess": "You logged in to PayPal successfully",
		"payPalLogin": "Log in to",
		"payPalPayWith": "Pay with PayPal",
		"payPalLaunch": "Launch Paypal",
		"payPalClickAuthorize": "Click the PayPal button to authorize your purchase.",
		"australianDollars": "Australian Dollars",
		"canadianDollars": "Canadian Dollars"
	},
	"review": {
		"pageTitle": "Review your order",
		"loading": "Loading Checkout Step 4 of 5 - Review your order",
		"creditCard": "Credit card",
		"card": "Card",
		"payPal": "PayPal",
		"afterpay": "Afterpay",
		"shippingAddress": "Shipping address",
		"change": "change",
		"paymentMethod": "Payment method",
		"spoondollars": "Spoondollars",
		"spoondollarsApplied": "{spoondollarsApplied} applied",
		"deliveryOption": "Delivery Option",
		"giftOptions": "Gift Options",
		"giftOptionsPrices": "Prices will be removed from packing slip",
		"giftOptionsMessage": "Message for the recipient:",
		"placeOrder": "Place your order",
		"vatLegalSentence": "Customer to account for any VAT arising on this Pro Membership in accordance with Article 196, Council Directive 2006/112/EC."
	},
	"confirmation": {
		"pageTitle": "Confirmation of your order",
		"loading": "Loading Checkout Step 5 of 5 - Order Confirmation",
		"emailAddress": "Email Address:",
		"orderNumber": "Order Number:",
		"total": "Total:",
		"shippingTo": "Shipping To:",
		"thanksOrder": "Thanks for your order!",
		"receiveEmail": "You will receive a confirmation email shortly.",
		"returnStudio": "Return to My Studio",
		"goToStudio": "Go to My Studio page",
		"continueShopping": "Continue Shopping",
		"gotoShopPage": "Go to Shop page"
	}
};