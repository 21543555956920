import {History} from 'history';
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {Router} from 'react-router';

import routes from '../../constants/Routes';
import {setPageName} from '../../entities/pageSetup/actions/pageName';

import getPageName from './getPageName';


const App: FC<{ history: History }> = ({history}) => {
  const dispatch = useDispatch();

  const onRouterUpdate = () => {
    if (!process.env.REACT_APP_IS_SERVER && !window.location.href.includes('#')) {
      // start new page on top
      window.scrollTo(0, 0);

      const pageName = getPageName(window.location.pathname);

      dispatch(setPageName(pageName));
    }
  };

  return <Router history={history} routes={routes} onUpdate={ onRouterUpdate } />;
};

export default App;
