import * as types from '../../../constants/ActionTypes';


type pageNameActionType = {
  type: string;
  payload: {
    pageName: string;
  };
};

export const setPageName = (pageName: string): pageNameActionType => ({
  type: types.PAGE_UPDATE,
  payload: {
    pageName
  }
});
