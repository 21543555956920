import {
  HOSTNAME_DEVELOPMENT,
  HOSTNAME_INTERNAL,
  HOSTNAME_NODEJS_TESTS, HOSTNAME_PREPRODUCTION, HOSTNAME_PRODUCTION,
  HOSTNAME_SHORT_PRODUCTION, HOSTNAME_STAGING, HOSTNAME_TEST1
} from '../../hostname/constants';


export enum RuntimeEnv {
  Development = 'Development',
  Staging = 'Staging',
  Production = 'Production',
}

export default function getRuntimeEnvFromHostname(hostname: string): RuntimeEnv {
  let env = RuntimeEnv.Development;

  switch (hostname) {
    case HOSTNAME_SHORT_PRODUCTION:
    case HOSTNAME_PRODUCTION:
    case HOSTNAME_PREPRODUCTION:
    case HOSTNAME_NODEJS_TESTS:
      env = RuntimeEnv.Production;
      break;
    case HOSTNAME_STAGING:
    case HOSTNAME_TEST1:
      env = RuntimeEnv.Staging;
      break;
    case HOSTNAME_DEVELOPMENT:
      env = RuntimeEnv.Development;
      break;
    default: {
      if (!hostname.includes(HOSTNAME_INTERNAL)) {
        console.warn(`Failed to infer RuntimeEnv for hostname ${hostname}`);
      }
    }
  }

  return env;
}
