import {
  ORDER_ITEM_TYPE_FABRIC_ITEM, ORDER_ITEM_TYPE_HOME_GOOD_ITEM, ORDER_ITEM_TYPE_KIT_ITEM,
  ORDER_ITEM_TYPE_SERVICE_ITEM, ORDER_ITEM_TYPE_SOLID_ITEM, ORDER_ITEM_TYPE_STOCK_PRODUCT,
  ORDER_ITEM_TYPE_UNPRINTED_ITEM, ORDER_ITEM_TYPE_WALLPAPER_ITEM,
} from '../constants/Codes';

import {MerchandiseDesign, OrderItemDesign} from './design';
import {OrderItemFabricBasic, OrderItemFabricSized} from './fabrics';
import {OrderItemBasicPricing, OrderItemPricePerFoot, OrderItemPricePerUnit, OrderItemPricePerYard} from './pricing';
import {TurnaroundTime} from './turnaroundTime';
import {WallpaperFabricCodes} from './wallpaper';


interface EmptyOrderItem {
  isFetching: boolean;
}

// Unused data structures being passed has been included for future reference
interface BasicOrderItem extends EmptyOrderItem {
  // favorite: {
  //   favoriteable: boolean;
  //   favorited: boolean;
  // };
  id: number;
  promo?: {
    id: number;
    name: string;
    raw_amount_saved: number;
    // designer_discount: boolean;
    // quantity_discounted: number;
    // raw_amount_saved_usd: number;
  };
  quantity: {
    changeable: boolean;
    value: number;
  };
  saved: boolean;
  type: string;
  url: string;
  fabric?: {
    code: string;
    id: number;
    type: string;
  };
}

export interface FabricOrderItem extends BasicOrderItem { // Fill-a-yard is treated as a Fabric Order item
  analytics: {
    parent_sku: string;
    sku: string;
  };
  design: OrderItemDesign;
  fabric: OrderItemFabricSized;
  chunk_size?: number;
  pricing: OrderItemPricePerUnit | OrderItemPricePerFoot;
  turnarounds: TurnaroundTime;
  type: typeof ORDER_ITEM_TYPE_FABRIC_ITEM;
}

export interface SolidOrderItem extends BasicOrderItem {
  analytics: {
    parent_sku: string;
    sku: string;
  };
  merchandise: {
    solid_item: {
      solid: {
        code: string;
        color_code: string;
        fabric_code: string;
        id: number;
        thumbnail_path: string;
        // color: string;
        // fabric: string;
        // priority: number;
      };
      width: string;
      length: number;
    };
    // type: string;
  };
  pricing: OrderItemPricePerUnit;
  turnarounds: TurnaroundTime;
  type: typeof ORDER_ITEM_TYPE_SOLID_ITEM;
}

export interface WallpaperOrderItem extends BasicOrderItem {
  analytics: {
    parent_sku: string;
    sku: string;
  };
  design: OrderItemDesign;
  fabric: OrderItemFabricSized;
  merchandise: {
    wallpaper_item: {
      fabric: {
        code: WallpaperFabricCodes;
        // name: string;
      };
    // design: MerchandiseDesign;
    // dpi: number;
    // repeat: boolean;
    // repeat_type: number;
    };
    // type: string;
  };
  pricing: OrderItemPricePerFoot;
  turnarounds: TurnaroundTime;
  type: typeof ORDER_ITEM_TYPE_WALLPAPER_ITEM;
}

export function isHomeGoodOrderItem(input: OrderItemTypes): input is HomeGoodOrderItem {
  return (input as HomeGoodOrderItem).type === ORDER_ITEM_TYPE_HOME_GOOD_ITEM;
}

export interface HomeGoodOrderItem extends BasicOrderItem {
  analytics: {
    parent_sku: string;
    sku: string;
  };
  design: OrderItemDesign;
  fabric: OrderItemFabricBasic;
  merchandise: {
    home_good_item: {
      design: MerchandiseDesign;
      home_good: {
        code: string;
        home_good_type: {
          code: string;
        };
        home_goods_additions?: HomeGoodOrderItemAdditions[];
        // weight: string;
      };
      home_goods_additions?: HomeGoodOrderItemAdditions[];
    };
    // type: string;
  };
  pricing: OrderItemBasicPricing;
  turnarounds: TurnaroundTime;
  type: typeof ORDER_ITEM_TYPE_HOME_GOOD_ITEM;
}

export type HomeGoodOrderItemAdditions = {
  code: string;
  name: string;
  // Unused data points
  // weight: string;
  // description: string;
  // home_good_code: string;
}

export interface ServiceOrderItem extends BasicOrderItem {
  analytics: {
    sku: string;
  };
  service_item: {
    title: string;
    title_code: string;
  };
  pricing: OrderItemBasicPricing;
  sub_type: string;
  type: typeof ORDER_ITEM_TYPE_SERVICE_ITEM;
}

export interface KitOrderItem extends BasicOrderItem {
  analytics: {
    sku: string;
  };
  fabric: OrderItemFabricBasic;
  kit_item: {
    collection_name: string;
    designs: OrderItemDesign[];
  };
  pricing: OrderItemBasicPricing;
  sub_type: string;
  turnarounds: TurnaroundTime;
  type: typeof ORDER_ITEM_TYPE_KIT_ITEM;
}

export interface StockOrderItem extends BasicOrderItem {
  analytics: {
    sku: string;
  };
  pricing: OrderItemBasicPricing;
  sub_type: string;
  type: typeof ORDER_ITEM_TYPE_STOCK_PRODUCT;
  // turnarounds: TurnaroundTime;
  // merchandise: {
    // created_at: Date;
    // id: number;
    // product_id: number;
    // products_pricing_id: number;
    // updated_at: Date;
  // };
}

export interface UnprintedOrderItem extends BasicOrderItem {
  analytics: {
    sku: string;
  };
  fabric: OrderItemFabricBasic;
  pricing: OrderItemPricePerYard;
  sub_type: string;
  type: typeof ORDER_ITEM_TYPE_UNPRINTED_ITEM;
}

export interface SavedOrder {
  id: number;
  user_id: number;
  status: string;
  hide_prices: boolean;
  factory: string;
  isFetching: boolean;
  order_items?: OrderItemTypes[];
}

export type OrderItemTypes = FabricOrderItem | WallpaperOrderItem | HomeGoodOrderItem | ServiceOrderItem | KitOrderItem | StockOrderItem | SolidOrderItem | UnprintedOrderItem;

export type OrderItemObject = EmptyOrderItem | OrderItemTypes;

export function isOrderItemType(object: unknown): object is OrderItemTypes {
  return (object as OrderItemTypes)?.id !== undefined;
}

export function isFabricOrderItem(object: unknown): object is FabricOrderItem {
  return isOrderItemType(object) && object.type === ORDER_ITEM_TYPE_FABRIC_ITEM;
}

export function isWallpaperOrderItem(object: unknown): object is WallpaperOrderItem {
  return isOrderItemType(object) && object.type === ORDER_ITEM_TYPE_WALLPAPER_ITEM;
}
