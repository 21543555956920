export const IMPERIAL = 'IMPERIAL';
export const METRIC = 'METRIC';
export const MEASUREMENTS = [METRIC, IMPERIAL] as const;
export const YARD = 'YARD';
export const METER = 'METER';
export const FOOT = 'FOOT';
export const ROLL = 'ROLL';
export const PANEL = 'PANEL';

export const measurementSystemToUnit: Record<MeasurementSystem, string> = Object.freeze({
  [IMPERIAL]: YARD,
  [METRIC]: METER
});

export type MeasurementSystem = typeof MEASUREMENTS[number];

export function measurementUnitSpecification(measurementSystem: MeasurementSystem): string {
  return measurementSystem === METRIC ? ' cm' : '"';
}

export const сmInOneInch = 2.54;
export const mmInOneCM = 10;

export const CM_TO_MM_MULTIPLIER = 10;
export const FT_TO_IN_MULTIPLIER = 12;
