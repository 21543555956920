import loadable from '@loadable/component';
import React from 'react';

import Loading from '../../components/Reusable/Loading/Loading';


const FabricShopContainerLazy = loadable(() => import('./FabricShopContainer'), {
  fallback: <Loading message='' />,
});

export default FabricShopContainerLazy;
