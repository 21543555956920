import * as types from '../constants/ActionTypes';

function tabSets(state = {}, action) {
  switch (action.type) {
    case types.TAB_SET_ACTIVE_TAB_NAME: {
      const newState = Object.assign({}, state);

      if (newState[action.tabSetName] === undefined) {
        newState[action.tabSetName] = {};
      }
      newState[action.tabSetName].activeTabName = action.activeTabName;

      return newState;
    }

    default:
      return state;
  }
}

export default tabSets;
