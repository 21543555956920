module.exports = {
	"home": "/studio",
	"login": "/login",
	"logout": "/logout",
	"collections": "/collections",
	"collection": "/collections/{collectionId}",
	"followDesigner": "/connections?screen_name={userName}&trans_type=Follow+user&user_id={designerId}",
	"userProfile": "/profiles/{userName}",
	"editCollection": "/collections/{slug}",
	"fay": "/templates?collection_id={id}",
	"collectionSample": "/designs?view={id}",
	"orderDetailsPage": "/orders/{paidAtDate}-{orderNumber}",
	"guestOrderDetailsPage": "/orders/{paidAtDate}-{orderNumber}?token={orderUuid}",
	"userPreferencesLearnMoreLink": "https://support.spoonflower.com/hc/en-us/articles/215118066-Payment-Methods",
	"accessibility": "/en/accessibility-statement",
	"similarDesigns": "/en/designs/{id}/similar{queryString}",
	"sisters": {
		"roostery": "https://www.roostery.com"
	},
	"shop": {
		"fabric": "/en/shop?on=fabric",
		"wallpaper": "/en/shop?on=wallpaper",
		"giftCertificates": "/gift_certificates",
		"fabricPage": "/fabric/{slug}",
		"wallpaperPage": "/wallpaper/{slug}",
		"spelunksPage": "/spelunks"
	},
	"design": {
		"edit": "/designs/{id}",
		"upload": "/designs/new"
	},
	"mainNav": {
		"create": "/create"
	},
	"welcome": {
		"sell": "/sell",
		"getStarted": "/get_started",
		"interiorDesigner": "/interior-design-fabric-and-wallpaper",
		"discounts": "/discounts",
		"pro": "/pro"
	},
	"header": {
		"myStudio": {
			"studioHome": "/studio",
			"editProfile": "/profiles/{userName}/edit",
			"designLibrary": "/designs",
			"account": "/account/{userId}?sub_action=settings",
			"orders": "/account/{userId}?sub_action=orders",
			"privacy": "/account/{userId}?sub_action=privacy",
			"notifications": "/userhome/{userId}",
			"favorites": "/favorites/{userName}",
			"collections": "/profiles/{userName}/collections?filter=designed",
			"connections": "/connections",
			"myDesignChallenges": "/design-challenge/me/voting",
			"uploadYourDesign": "/designs/new",
			"messages": "/messages",
			"alerts": "/userhome/{userId}?filter=me",
			"analytics": "/analytics",
			"businessTools": "https://www.spoonflower.com/biz_tools",
			"promotionalTools": "/promote"
		},
		"join": "/register/new"
	},
	"about": {
		"overview": {
			"en": "/en/about",
			"de": "/de/ueber",
			"fr": "/fr/apropos",
			"es": "/es/sobre"
		},
		"fabrics": "/spoonflower_fabrics",
		"howItWorks": {
			"en": "/en/how-it-works",
			"de": "/de/wie-es-funktioniert",
			"es": "/es/como-funciona",
			"fr": "/fr/comment-ca-marche"
		},
		"plus": "/en/plus-membership",
		"careers": "https://jobs.spoonflower.com/work-at-spoonflower",
		"tradeProgram": "/en/trade",
		"sellerAgreement": "/seller_agreement",
		"termsOfService": "/terms",
		"proTermsOfService": "/pro_terms",
		"privacy": {
			"en": "/en/privacy",
			"de": "/de/datenschutz",
			"es": "/es/privacidad",
			"fr": "/fr/confidentialite"
		},
		"cookies": "/cookies",
		"sellOrShare": "",
		"returnsAndExchanges": "https://support.spoonflower.com/hc/en-us/articles/204444690-Can-I-return-or-exchange-what-I-ordered",
		"returnsAndExchangesDe": "https://support.spoonflower.com/hc/de/articles/204444690"
	},
	"business": {
		"wholesale": "/wholesale"
	},
	"happinessGuarantee": {
		"en": "/en/happiness-guarantee",
		"de": "/de/zufriedenheits-garantie",
		"es": "/es/satisfaccion-garantizada",
		"fr": "/fr/satisfaction-garantie"
	},
	"idMe": {
		"whatIs": "https://help.id.me/hc/en-us/articles/201904874-What-is-ID-me-",
		"groups": "https://groups.id.me/",
		"de": "placeholder_for_url_de"
	},
	"afterpay": {
		"home": "https://www.afterpay.com/",
		"installment": {
			"US": "https://www.afterpay.com/en-US/installment-agreement",
			"AU": "https://www.afterpay.com/en-AU/terms-of-service"
		},
		"terms": {
			"US": "https://www.afterpay.com/en-US/terms-of-service",
			"AU": "https://www.afterpay.com/en-AU/terms-of-service"
		}
	},
	"solids": {
		"faq": "https://support.spoonflower.com/hc/en-us/articles/360061069892-Petal-Signature-Cotton-Solids-Fabric-FAQ"
	},
	"accountInfo": {
		"en": "/en/request-for-information",
		"de": "/de/request-for-information",
		"es": "/es/request-for-information",
		"fr": "/fr/request-for-information"
	},
	"newsletter": {
		"subscribe": "/en/subscribe",
		"preferences": "/en/preferences",
		"unsubscribe": "/en/unsubscribe"
	},
	"sitemap": {
		"index": "https://www.spoonflower.com/catalog/pt/index"
	}
};