import React from 'react';
import './_loading.scss';


const Loading = ({message}: {message: string}): JSX.Element => (
  <div className='b-loading'>
    <p className='message'>{message}</p>
  </div>);

export default Loading;
