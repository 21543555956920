import debugLib from 'debug';


const debug =
  process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'test' &&
  !process.env.REACT_APP_IS_SERVER;

if (debug) {
  debugLib.enable('SPOONFLOWER:*');
} else {
  debugLib.disable();
}

const getDebugger = (debugLabel: string): debugLib.Debugger => debugLib(`SPOONFLOWER:${debugLabel}`);

export default getDebugger;
