import * as types from '../constants/ActionTypes';

// For add to collections modal
export function collectionsFetching() {
  return {
    type: types.USER_COLLECTIONS_FETCHING,
  };
}

export function collectionsReceived(payload) {
  return {
    type: types.USER_COLLECTIONS_RECEIVED,
    payload
  };
}

export function collectionsReceivedError(error) {
  return {
    type: types.USER_COLLECTIONS_ERROR,
    payload: error
  };
}

export function collectionsCreated(payload) {
  return {
    type: types.USER_COLLECTION_CREATED,
    payload
  };
}

export function collectionsCreatedError(error) {
  return {
    type: types.USER_COLLECTION_CREATED_ERROR,
    payload: error
  };
}

export function addedToCollection(payload) {
  return {
    type: types.USER_COLLECTION_DESIGN_ADDED,
    payload
  };
}

export function removedFromCollection(payload) {
  return {
    type: types.USER_COLLECTION_DESIGN_REMOVED,
    payload
  };
}

export function addedToCollectionError(error) {
  return {
    type: types.USER_COLLECTION_DESIGN_ADDED_ERROR,
    payload: error
  };
}

export function removedFromCollectionError(error) {
  return {
    type: types.USER_COLLECTION_DESIGN_REMOVED_ERROR,
    payload: error
  };
}

export function favoriteCollectionsFetching() {
  return {
    type: types.USER_FAVORITE_COLLECTIONS + types.FETCHING
  };
}

export function favoriteCollectionsReceived(payload) {
  return {
    type: types.USER_FAVORITE_COLLECTIONS_RECEIVED,
    payload
  };
}

export function favoriteCollectionsReceivedError() {
  return {
    type: types.USER_FAVORITE_COLLECTIONS + types.FAIL
  };
}

export function addedToFavoriteCollections(payload) {
  return {
    type: types.USER_FAVORITE_COLLECTION_ADDED,
    payload
  };
}

export function removedFromFavoriteCollections(payload) {
  return {
    type: types.USER_FAVORITE_COLLECTION_REMOVED,
    payload
  };
}

export function addedToFavoriteCollectionsError(error) {
  return {
    type: types.USER_FAVORITE_COLLECTION_ADDED_ERROR,
    payload: error
  };
}

export function removedFromFavoriteCollectionsError(error) {
  return {
    type: types.USER_FAVORITE_COLLECTION_REMOVED_ERROR,
    payload: error
  };
}

// for Fill-A-Yard
export function fayCollectionReceived(payload) {
  return {
    type: types.FAY_COLLECTION_RECEIVED,
    payload
  };
}

export function fayCollectionReceivedError(error) {
  return {
    type: types.FAY_OPTIONS_PAGE_ERROR,
    payload: error
  };
}
