import * as types from '../constants/ActionTypes';

/**
 * PAGE_SETUP clears all non-sticky data from the previous use of the action, by design. It's meant to be used when the
 * router changes.
 */
export function pageSetup(payload) {
  return {
    type: types.PAGE_SETUP,
    payload
  };
}

export function checkoutFunnelPageSetup(payload) {
  return pageSetup(Object.assign({
    displayHeader: false,
    displayFooter: false,
    displayNavigation: false
  }, payload));
}

export function pageUpdate(payload) {
  return {
    type: types.PAGE_UPDATE,
    payload
  };
}

export function pageKeySetup(payload) {
  return {
    type: types.PAGE_KEY_SETUP,
    payload
  };
}

export function modalClose() {
  return {
    type: types.MODAL_CLOSE
  };
}

export function modalOpen(payload) {
  return {
    type: types.MODAL_OPEN,
    payload
  };
}

export function productPageReset() {
  return {
    type: types.PRODUCT_PAGE_RESET
  };
}

export function saveScrollPosition(payload) {
  return {
    type: types.SAVE_SCROLL_POSITION,
    payload
  };
}
