/* global Collection */
import type {AppLocalities, CountriesCollection} from '../entities/pageSetup/countries/types';


export const countriesByCodeAndName = (countries: CountriesCollection, locale: AppLocalities): Collection<string, string> =>
  Object.keys(countries).reduce((results, current) => {
    results[current] = countries[current].localized_names[locale];

    return results;
  }, {} as Collection<string, string>);

export const COUNTRY_CODE_US = 'US';
export const COUNTRY_CODE_AU = 'AU';
export const COUNTRY_CODE_CA = 'CA';
export const COUNTRY_CODE_DE = 'DE';
