export const STRIPE = 'stripe';
export const PAYPAL = 'paypal';
export const AFTERPAY = 'afterpay';
export const FREE = 'free_order';
export const SAVED_CARD = 'saved_card';
export const NEW_CARD = 'new_card';

export const TOKEN_TOTAL_PRICE_ZERO = 'total_price_0';

export const AUD = 'AUD';
export const CAD = 'CAD';
export const EUR = 'EUR';
export const GBP = 'GBP';
export const USD = 'USD';
export const CURRENCIES = [USD, GBP, EUR, CAD, AUD];
export const CURRENCIES_AS_SELECT_OPTIONS = {
  USD, GBP, EUR, CAD, AUD
};

export const SPOONFLOWER_VAT_NUMBER = 'DE308977292';
export const SPOONFLOWER_ABN_NUMBER = '3000 1358 9361';
export const SPOONFLOWER_EIN_NUMBER = '26-2509623';

export const currencyCodeWithSign = {
  [USD]: 'US$', [AUD]: 'AU$', [CAD]: 'CA$', [EUR]: '€', [GBP]: '£'
};

// https://braintree.github.io/braintree-web/3.39.0/PayPalCheckout.html#createPayment
export const PAYPAL_SUPPORTED_LOCALES = Object.freeze([
  'da_DK', 'de_DE', 'en_AU', 'en_GB', 'en_US', 'es_ES', 'fr_CA', 'fr_FR', 'id_ID', 'it_IT', 'ja_JP', 'ko_KR', 'nl_NL',
  'no_NO', 'pl_PL', 'pt_BR', 'pt_PT', 'ru_RU', 'sv_SE', 'th_TH', 'zh_CN', 'zh_HK', 'zh_TW'
]);

type InstallmentConst = readonly [string, string, string, string];

/**
 * The translation keys for each Afterpay installment in order, suitable for
 * indexing into (i.e. the zeroth element is the translation for the first
 * installment, etc.)
 */
export const INSTALLMENT_TRANSLATIONS: InstallmentConst = [
  'afterpay.installmentDetails.firstInstallment',
  'afterpay.installmentDetails.secondInstallment',
  'afterpay.installmentDetails.thirdInstallment',
  'afterpay.installmentDetails.fourthInstallment',
] as const;

/**
 * The image URLs for each Afterpay installment in order, suitable for indexing
 * into (i.e. the zeroth element is the image URL for the first installment,
 * etc.)
 */
export const INSTALLMENT_IMAGE_URLS: InstallmentConst = [
  'afterpay_installment_1of4.png',
  'afterpay_installment_2of4.png',
  'afterpay_installment_3of4.png',
  'afterpay_installment_4of4.png',
] as const;

/**
 * The mapping between payment methods and the translation key describing that
 * payment method in order review.
 */
export const PAYMENT_TRANSLATION_MAPPING = {
  [STRIPE]: 'checkout.review.creditCard',
  [PAYPAL]: 'checkout.review.payPal',
  [AFTERPAY]: 'checkout.review.afterpay',
};

export const IDME_GROUPS = 'teacher,student,military,responder,nurse';
export const IDME_POPUP_FEATURES = 'width=700,height=800,scrollbars=no,resizable=no';
