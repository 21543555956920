import merge from 'deepmerge';
import initialState from '../store/initialState';
import * as types from '../constants/ActionTypes';

function userStats(state = initialState.userStats, action) {
  switch (action.type) {
    case types.USER_STATS_PAGE_TYPE + types.SUCCESS:
      return merge(state, {
        data_layer: {
          pageType: action.payload
        }
      });

    case types.USER_STATS_GET + types.SUCCESS:
      return merge(state, action.payload.data);

    case types.USER_STATS_SET_INITIALS:
      return merge(state, action.payload);

    default:
      return state;
  }
}
export default userStats;
