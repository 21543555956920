import * as types from '../constants/ActionTypes';

export function orderCountReceived(payload) {
  return {
    type: types.COUNT_GET + types.SUCCESS,
    payload
  };
}

export function orderCountFailed() {
  return {
    type: types.COUNT_GET + types.FAIL
  };
}

export function orderCountFetching() {
  return {
    type: types.COUNT_GET + types.FETCHING,
  };
}

export function orderPendingFetching() {
  return {
    type: types.PENDING + types.ORDER_GET + types.FETCHING
  };
}

export function orderPendingReceived(payload) {
  return {
    type: types.PENDING + types.ORDER_GET + types.SUCCESS,
    payload
  };
}

export function orderPendingFail() {
  return {
    type: types.PENDING + types.ORDER_GET + types.FAIL
  };
}

export function orderSavedFetching() {
  return {
    type: types.SAVED + types.ORDER_GET + types.FETCHING
  };
}

export function orderSavedReceived(payload) {
  return {
    type: types.SAVED + types.ORDER_GET + types.SUCCESS,
    payload
  };
}

export function orderLastPaidReceived(payload) {
  return {
    type: types.LAST_PAID + types.ORDER_GET + types.SUCCESS,
    payload
  };
}

export function orderDeliveryOptionsReceived(payload) {
  return {
    type: types.ORDER_DELIVERY_OPTIONS_GET + types.SUCCESS,
    payload
  };
}

export function braintreePublicClientTokenReceived(payload) {
  return {
    type: types.BRAINTREE_PUBLIC_CLIENT_TOKEN_GET + types.SUCCESS,
    payload
  };
}

export function afterpayPublicClientTokenReceived(payload) {
  return {
    type: types.AFTERPAY_PUBLIC_CLIENT_TOKEN_GET + types.SUCCESS,
    payload
  };
}

export function idMeReceived(payload) {
  return {
    type: types.IDME + types.SUCCESS,
    payload
  };
}

export function idMeFail() {
  return {
    type: types.IDME + types.FAIL
  };
}
