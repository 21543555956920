import classNames from 'classnames';
import React, {useEffect} from 'react';

import './modal.scss';
import {translate} from '../../../services';
import {onClickPreventPropagation} from '../../../utils/events';
import {isFunction} from '../../../utils/validation';
import Icon from '../Icon/Icon';


export interface ModalProps {
  contents: JSX.Element | string;
  displayModal: boolean;
  slideInModal?: boolean;
  modalClose: (arg1: string, arg2?: boolean, arg3?: boolean) => void;
  modalWithButton: boolean;
  centeredModal?: boolean;
  modalExtraWide?: boolean;
  modalExtraExtraWide?: boolean;
  displayFabricsModal?: boolean;
  displayQuickLookModal?: boolean;
  displayColorModal?: boolean;
  initialLocale: string;
  modalWithReset?: boolean;
  showFiltersComponent?: (() => void) | boolean;
  modalWithTwoContents?: boolean;
  clearErrors?: boolean;
}

const Modal = ({
  contents, displayModal, slideInModal, modalClose, modalWithButton, centeredModal, modalExtraWide, modalExtraExtraWide,
  displayFabricsModal, displayQuickLookModal, initialLocale, modalWithReset, showFiltersComponent, displayColorModal,
  modalWithTwoContents, clearErrors
}: ModalProps): JSX.Element => {
  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('keyup', handleKeyPress);
    };
  });

  const handleKeyPress = (event: { key: string }): void => {
    if (event.key === 'Escape') {
      modalClose(initialLocale);
    }
  };

  const modalClasses = classNames('b-modal', {
    'x-active': displayModal && !centeredModal,
    'x-active-slide-in': slideInModal,
    'x-centered': centeredModal,
    'x-two-contents': modalWithTwoContents
  });
  const modalContentClasses = classNames('modal-content', {
    'modal-with-button': modalWithButton,
    'modal-extra-wide': modalExtraWide,
    'modal-extra-extra-wide': modalExtraExtraWide,
    'touchscroll': displayFabricsModal || displayQuickLookModal
  });
  const closeModalOnClick = () => {
    modalClose(initialLocale, modalWithReset, clearErrors);
  };

  return (
    <article className={modalClasses} tabIndex={0} onClick={closeModalOnClick} onKeyUp={handleKeyPress}>
      <div className={modalContentClasses} onClick={onClickPreventPropagation}>
        {contents}
      </div>
      {displayFabricsModal && isFunction(showFiltersComponent) &&
      <div className='button-group-wrapper x-secondary'>
        <button
          type='button'
          className='btn white-button big-button'
          onClick={closeModalOnClick}>
          {translate('common.forms.close')}
        </button>
        <button className='btn blue-button big-button' onClick={showFiltersComponent}>
          {translate('fabricShop.filterFabrics')}
        </button>
      </div>}
      {displayColorModal &&
        <div className='button-group-wrapper x-secondary'>
          <button
            type='button'
            className='btn black-button big-button'
            onClick={closeModalOnClick}
          >
            {translate('common.forms.close')}
          </button>
        </div>
      }
      <button
        className='btn btn-close'
        type='button'
        onClick={closeModalOnClick}>
        <Icon iconName='close' iconTitle={translate('cart.addedToCart.close')}/>
      </button>
    </article>
  );
};

export default Modal;
