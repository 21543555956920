import update from 'immutability-helper';
import * as types from '../constants/ActionTypes';

function wallpapers(state = {}, action) {
  switch (action.type) {
    case types.WALLPAPERS_GET + types.SUCCESS:
      return update(
        action.payload.data,
        {
          $merge: {
            isFetching: false
          }
        }
      );
    case types.WALLPAPERS_GET + types.FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetching: true
          }
        }
      );
    case types.WALLPAPERS_GET + types.FAIL:
      return update(
        state,
        {
          $merge: {
            isFetching: false,
            failed: true
          }
        }
      );
    case types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.FAIL:
      return Object.assign({}, state,{
        measurementsPosted: true,
        isPostingMeasurements: false
      });
    case types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.FETCHING:
      return Object.assign({}, state,{
        isPostingMeasurements: true,
        measurementsPosted: false,
      });
    case types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        measurementsPosted: true,
        isPostingMeasurements: false
      });
    case types.CALCULATOR_LANDING_RESET:
      return Object.assign({}, state,{
        error: '',
        wallpapers: {}
      });

    default:
      return state;
  }
}

export default wallpapers;
