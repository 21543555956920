module.exports = {
	"myStudio": {
		"linkTitle": "My Studio",
		"myDashboard": "My Dashboard",
		"designLibrary": "Design Library",
		"myShop": "View My Shop",
		"uploadYourDesign": "Upload Your Design",
		"account": "Account",
		"notifications": "Notifications",
		"favorites": "Favorites",
		"collections": "Collections",
		"connections": "Connections",
		"myDesignChallenges": "My Design Challenges",
		"messages": "Messages",
		"alerts": "Alerts",
		"analytics": "Analytics",
		"businessTools": "Business Tools",
		"promotionalTools": "Promotional Tools"
	},
	"subheader": {
		"uploadButton": {
			"href": "/designs/new",
			"text": "Upload a Design"
		},
		"designChallenge": {
			"href": "/design-challenge",
			"text": "Design Challenges"
		}
	},
	"afterSignupAlert": {
		"complete": "Your account setup is complete!",
		"click": "Click here",
		"login": "to Login"
	},
	"accessibility": {
		"href": "/en/accessibility-statement",
		"text": "Accessibility Statement"
	}
};