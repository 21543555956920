module.exports = {
	"accounts": {
		"instagram": {
			"linkTitle": "Go to Spoonflower's profile on Instagram"
		},
		"pinterest": {
			"linkTitle": "Go to Spoonflower's profile on Pinterest"
		},
		"facebook": {
			"linkTitle": "Go to Spoonflower's profile on Facebook"
		},
		"twitter": {
			"linkTitle": "Go to Spoonflower's profile on Twitter"
		},
		"youtube": {
			"linkTitle": "Go to Spoonflower's profile on YouTube"
		},
		"flickr": {
			"linkTitle": "Go to the Spoonflower group on Flickr"
		},
		"tiktok": {
			"linkTitle": "Go to the Spoonflower group on TikTok"
		}
	}
};