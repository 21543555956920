import React from 'react';
import Translate from 'i18n-react';
import './_subscribe.scss';
import {translate} from '../../../services';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';

const Subscribe = () => (
  <section className='b-subscribe'>
    <LinkWrapper
      // TODO SP-7621: Update method to pass DE subscribe link to Subscribe.js
      hrefValue={Translate.translate('xurls.newsletter.subscribe')}
      className='btn white-button subscribe-link'
      contents={translate('footer.subscribe.joinLink')}
    />
    <p className='join-info'>{translate('footer.subscribe.joinInfo')}</p>
  </section>
);

export default Subscribe;
