import * as types from '../constants/ActionTypes';
import initialState from '../store/initialState';

function crossSell(state = {}, action) {
  switch (action.type) {
    case types.CROSS_SELL_GET + types.SUCCESS:
      return Object.assign({}, {
        isFetching: false,
        designs: action.payload.data,
        fetchingIsReady: true
      });
    case types.CROSS_SELL_CLEAR:
      return Object.assign({}, {
        isFetching: false,
        designs: [],
      });
    case types.CROSS_SELL_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.CROSS_SELL_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        fetchingIsReady: true
      });
    case types.PRODUCT_PAGE_RESET:
      return initialState.crossSell;
    case types.SOLID_CROSS_SELL_GET + types.SUCCESS:
      return Object.assign({}, {
        isFetching: false,
        designs: action.payload.data,
        fetchingIsReady: true
      });
    case types.SOLID_CROSS_SELL_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.SOLID_CROSS_SELL_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        fetchingIsReady: true
      });
    default:
      return state;
  }
}

export default crossSell;
