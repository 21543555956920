import Translate from 'i18n-react';
import {supportedLanguages} from '../constants/SupportedLanguages';

class TranslateLocale {
  constructor(initialLangCode) {
    this.langCode = initialLangCode;
    this.supportedLanguages = supportedLanguages;
  }

  setLangCode(code) {
    if (this.supportedLanguages.includes(code.toLowerCase())) {
      this.langCode = code;
    } else {
      console.warn(`Attempting to set to unsupported language: ${code}`);
    }
  }

  translate(keyToTranslate, options) {
    const locale = options && options['langCode'] ? options['langCode'] : this.langCode;

    return Translate.translate(`${locale}.${keyToTranslate}`, options);
  }
}

export default TranslateLocale;
