import {AxiosResponse} from 'axios';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import renderNotFound from '../../../actions/shouldRenderErrorPage';
import request from '../../../application/api/request';
import {supportedLanguages} from '../../../constants/SupportedLanguages';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {AppLocalities} from '../../../entities/pageSetup/countries/types';
import {selectPageLang, selectPageName, selectPageKey} from '../../../entities/pageSetup/selectors';
import {selectLocation} from '../../../entities/routing/selectors';
import {MultiLocaleRouterConfig} from '../../../server/bootstrap/loadMultiLocaleRouterConfig/MultiLocaleRouterConfigTypes';
import {getLanguageFromUrl} from '../../../server/getServer/middlewares/utils/getLanguageFromUrl';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';

import {CMSContentResponse} from './CmsContentResponse';
import {CmsQueryParams} from './fetchCmsContent';


export default function fetchCmsSlugs(): ThunkAction<Promise<MultiLocaleRouterConfig | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();

    const pageKey = selectPageKey(state);
    const pageLang = selectPageLang(state);

    const params: CmsQueryParams = {
      name: selectPageName(state),
      lang: pageLang,
    };

    const location = selectLocation(state);
    const urlLang = getLanguageFromUrl(location.pathname);

    if (location.search === '?purge=1') {
      params['purge'] = 1;
    }

    return request<CMSContentResponse>(state, {
      url: `${selectAlpenroseHost(state)}/content`,
      params,
    })
      .then((res: AxiosResponse<CMSContentResponse>) => {
        if (res.status === 204 || isEmpty(res.data)) {
          const otherLangs = xor(supportedLanguages, [urlLang]) as AppLocalities[];
          const result = otherLangs.reduce((acc: MultiLocaleRouterConfig, lang: AppLocalities) => {
            acc[pageKey][lang] = location.pathname.replace(urlLang, lang);

            return acc;
          }, {
            [pageKey]: {}
          });

          result[pageKey][urlLang] = location.pathname;
          dispatch(renderNotFound());

          return result;
        }
        const {data: dataObj} = res;
        const {contents: {langSlugs, categorySlugs, subcategorySlugs}} = dataObj.data;

        return Object.entries(langSlugs).reduce((acc: MultiLocaleRouterConfig, [lang, slug]: [string, string]) => {
          let url = `/${lang}`;

          if (categorySlugs[lang as AppLocalities]) url += `/${categorySlugs[lang as AppLocalities]}`;
          if (subcategorySlugs[lang as AppLocalities]) url += `/${subcategorySlugs[lang as AppLocalities]}`;

          acc[pageKey][lang as AppLocalities] = `${url}/${slug}`;

          return acc;
        }, {
          [pageKey]: {}
        });
      })
      .catch((e: RequestError) => {
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(e));
        } else {
          console.log('Error caught in the `fetchCmsSlugs` function', e); // eslint-disable-line
        }
      });
  };
}
