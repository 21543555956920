import * as types from '../constants/ActionTypes';
import {LOCATION_CHANGE} from 'react-router-redux';

function flashMessage(state = {}, action) {
  switch (action.type) {
    case types.FLASH_MESSAGE_SET_MESSAGE:
      return {
        severity: action.severity,
        message: action.message,
        hops: action.hops,
        targetComponent: action.targetComponent,
      };

    case LOCATION_CHANGE:
      if (state.hops === 0 || state.hops === undefined) {
        return {};
      }

      return Object.assign(state, {
        hops: state.hops - 1
      });

    default:
      return state;
  }
}

export default flashMessage;
