import * as types from '../constants/ActionTypes';
import {fetchUserConsents, fetchMiddleware} from '../utils/fetch';
import {selectUserId} from '../entities/user/selectors';
import request from '../application/api/request';
import selectAlpenroseHost from '../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {collectionsFetching, collectionsReceived, collectionsReceivedError} from './userCollections';

export const currentUserFetching = () => ({
  type: types.CURRENT_USER_GET + types.FETCHING
});

export const currentUserFail = () => ({
  type: types.CURRENT_USER_GET + types.FAIL
});

export function currentUserReceived(payload) {
  return {
    type: types.CURRENT_USER_GET + types.SUCCESS,
    payload
  };
}

export function registerAbnConsent(userId) {
  return function(dispatch) {
    const fetchOptions = {
      method: 'POST',
      body: {
        user_id: userId,
        name: 'au_gst'
      }
    };

    const callback = (data) => dispatch({
      type: types.SET_ABN_CONSENT + types.SUCCESS,
      payload: data
    });

    fetchUserConsents(fetchOptions, callback, fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash());
  };
}

export function getAbnConsent(userId) {
  return function(dispatch) {
    const fetchOptions = {
      method: 'GET',
    };
    const queryString = `?user_id=${userId}&name=au_gst`;
    const callback = (payload) => {
      const consents = payload.data;

      if (consents !== undefined && consents.length !== 0) {
        dispatch({
          type: types.SET_ABN_CONSENT + types.SUCCESS,
          payload
        });
      } else {
        dispatch({
          type: types.SET_ABN_CONSENT + types.FAIL
        });
      }
    };
    const failCallback = () => {};

    fetchUserConsents(fetchOptions, callback, failCallback, queryString);
  };
}

export const fetchUserCollections = () => (dispatch, getState) => {
  dispatch(collectionsFetching());

  const store = getState();
  const userId = selectUserId(store);

  return request(store,{
    url: `${selectAlpenroseHost(store)}/collections/user/${userId}`
  })
    .then((response) => {
      dispatch(collectionsReceived(response.data));

      return response.data;
    })
    .catch((error) => {
      dispatch(collectionsReceivedError(error));

      return Promise.reject(error);
    });
};
