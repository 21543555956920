import {FABRIC, HOME_GOOD, WALLPAPER} from './Codes';

/**
 * Datalayer Constants
 */
export const DATA_FABRIC = 'Fabric';
export const DATA_SOLID = 'Solid';
export const DATA_WALLPAPER = 'Wallpaper';
export const DATA_HOME_GOOD = 'Home Good';
export const DATA_COLORWAYS_CATEGORIES = {
  [FABRIC]: DATA_FABRIC,
  [WALLPAPER]: DATA_WALLPAPER,
  [HOME_GOOD]: DATA_HOME_GOOD
};
export const DATA_LIVING = 'Living';
export const DATA_DINING = 'Dining';
export const DATA_BEDDING = 'Bedding';
export const DATA_SERVICE_ITEM = 'Service Item';
export const DATA_PRODUCT_ITEM = 'Product Item';
export const DATA_STOCK_ITEM = 'Stock Item';
export const DATA_CROSS_SELL_LIST_TYPE = 'Cross Sell';
export const DATA_PRODUCTS_OVERVIEW_LIST_TYPE = 'Products Overview';
export const DATA_SHOP_PAGE_LIST_TYPE = 'Shop Page';
export const DATA_TAGS_PAGE_LIST_TYPE = 'Tags Page';
export const DATA_SHOP_COLORWAYS_LIST_TYPE = 'shop_colorways';
export const DATA_COLORWAYS_LIST_TYPE = 'colorways';
export const DATA_ERROR_NO_SKU = 'MISSINGSKU';
export const DATA_FABRIC_SHOP = 'Fabric Shop Page';
export const DATA_FABRIC_DETAIL = 'Fabric Detail Page';
export const DATA_DESIGN_GRANT = 'Student Project Grant';
export const DATA_BUSINESS_GRANT = 'Business Grant';
export const DATA_BUSINESS_OWNER = 'Business Owner';
export const DATA_BUSINESS_STARTER = 'Business Starter';
export const DATA_BUSINESS_CONFIRMATION = 'Business Confirmation';
export const DATA_CMS = 'CMS';
export const DATA_ALL_PRODUCTS_LIST_TYPE = 'Also Available As';
