import {PageName} from '../../entities/pageSetup/types';

/**
 * Url templates to parse pageName
 * - '*lang'
 * - '*lang/:page_name'
 * - '*lang/*category/*page_name'
 * - '*lang/*category/*subcategory/*page_name'
 * @see https://github.com/Spoonflower/baerlauch/blob/staging/config/routes.rb#L790
 */
const getPageName = (pathName: string): PageName => {
  if (!pathName) {
    return 'home';
  }

  const chunks = pathName.charAt(0) === '/' ?
    // relative
    pathName.split('/').filter((c) => !!c) :
    new URL(pathName).pathname.split('/').filter((c) => !!c);

  switch (chunks.length) {
    // Default "en".
    case 0:
    // Only one element always is going to be the language.
    // eslint-disable-next-line no-fallthrough
    case 1: {
      return 'home';
    }
    case 2: {
      const lang = chunks[0];

      if (chunks[1] === 'home' || chunks[1] === `home-${lang}`) {
        return 'home';
      }

      return chunks[1] as PageName;
    }
    default: {
      return chunks[chunks.length-1] as PageName;
    }
  }
};

export default getPageName;
