/* global dataLayer */
import get from 'lodash/get';
import {FABRIC, HOME_GOOD, SOLID, STOCK_ITEM, WALLPAPER, UNPRINTED_FABRIC} from '../constants/Codes';
import {
  FABRIC_FETCH_URL,
  HOME_GOOD_FETCH_URL,
  SOLIDS_FETCH_URL,
  STOCK_ITEMS_FETCH_URL,
  WALLPAPER_FETCH_URL,
  UNPRINTED_FABRIC_FETCH_URL
} from '../constants/Fetch';
import {ADULT_CONTENT, ADULT_CONTENT_OFF, COUNTRY, CURRENCY, LOCALE, MEASUREMENT_SYSTEM} from '../constants/Parameters';
import {fetchCartCount} from '../entities/cart/api/fetchCartCount';
import {fetchCartOrders} from '../entities/cart/api/fetchCartOrders';
import {addToCartEvent} from './dataLayer';
import {fetchAddToCart, fetchCurrentUser, fetchOrderItemChangeQuantity, fetchOrderItemRemove} from './fetch';
import {isUndefined} from './validation';
import {addToCTCartDispatch} from '../entities/cart/api/addToCart';
import {whoAmIRequest} from '../actions/auth';
import {updateUserViaAlpAPI} from '../entities/user/api/updateUserViaAlpAPI';

const addToCartFetchUrls = {
  [FABRIC]: FABRIC_FETCH_URL,
  [WALLPAPER]: WALLPAPER_FETCH_URL,
  [HOME_GOOD]: HOME_GOOD_FETCH_URL,
  [STOCK_ITEM]: STOCK_ITEMS_FETCH_URL,
  [SOLID]: SOLIDS_FETCH_URL,
  [UNPRINTED_FABRIC]: UNPRINTED_FABRIC_FETCH_URL,
};

class CartOperations {
  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }
  setState(state) {
    this.state = state;
  }

  async addToCart(productTypeAbbreviated, requestBody, userId, stayLegacy, country, measurementSystem, currency, locale, isGuest) {
    /**
     * This functionality existed in a lower function, but I moved it up here
     * so that we can decide on the user cart much earlier in the process.
     */
    if (isUndefined(userId)) {
      await this.handleGuestUserNeeded(currency, country, locale, measurementSystem);

      try {
        await whoAmIRequest(this.dispatch, this.state);
      } catch (error) {
        // ignore the error this time
      }
    }

    if (stayLegacy) {
      const fetchUrl = addToCartFetchUrls[productTypeAbbreviated];
      const fetchOptions = {
        method: 'POST',
        body: requestBody
      };

      return this.dispatchAddToCart(fetchOptions, fetchUrl, isGuest)
        .then((data) => {
          const newOrderItem = data.data;

          if (newOrderItem.order_id) {
            this.dispatch(fetchCartCount());
            this.dispatchCartOrders(newOrderItem.order_id);
          }

          dataLayer.push(addToCartEvent(newOrderItem, userId));

          return newOrderItem;
        });
    } else {
      return this.dispatch(addToCTCartDispatch(productTypeAbbreviated, requestBody, userId, isGuest));
    }
  }

  handleGuestUserNeeded(currency, country, locale, measurementSystem) {
    const userPreferences = {
      [CURRENCY]: currency,
      [COUNTRY]: country,
      [LOCALE]: locale,
      [MEASUREMENT_SYSTEM]: measurementSystem,
      [ADULT_CONTENT]: ADULT_CONTENT_OFF
    };
    const updatedPreferences = {
      preferences: {
        ...userPreferences,
      },
    };

    return updateUserViaAlpAPI(this.state, updatedPreferences);
  }

  updateCartItem(
    productTypeAbbreviated,
    quantity,
    selectedSubstrate,
    productSize,
    chunkSize,
    params,
    orderItem,
    userId,
    country,
    measurementSystem,
    currency,
    locale,
    addToCartRequestBody
  ) {
    // If only the quantity has changed, PATCH the quantity
    if (
      this.patchVersusReplace(
        productTypeAbbreviated,
        orderItem,
        productSize,
        selectedSubstrate,
        params,
        get(addToCartRequestBody, 'home_good_addition_codes[0]'),
        get(addToCartRequestBody, 'solid_code')
      )
    ) {
      return this.dispatchOrderItemChangeQuantity(orderItem.id, quantity, chunkSize).then((data) => {
        const newOrderItem = data.payload.data;

        return this.dispatchCartOrders() // todo should we remove this function when we will get rid of update functionality?
          .then(() => newOrderItem);
      });
    }

    // Otherwise, delete the current order item and POST a new one
    return this.dispatchOrderItemRemove(orderItem.id).then(() => {
      addToCartRequestBody.promo_id = get(orderItem, 'promo.id');

      return this.addToCart(
        productTypeAbbreviated,
        addToCartRequestBody,
        userId,
        country,
        measurementSystem,
        currency,
        locale
      );
    });
  }

  orderItemSize(productTypeAbbreviated, orderItem) {
    switch (productTypeAbbreviated) {
      case FABRIC:
      case WALLPAPER:
        return orderItem.fabric.size;
      case HOME_GOOD:
        return orderItem.merchandise.home_good_item.home_good.code;
      default:
    }
  }

  patchVersusReplace(productTypeAbbreviated, orderItem, productSize, selectedSubstrate, params, addition, solidColor) {
    const orderItemFabricCode = orderItem?.fabric?.code || orderItem?.fabric?.type;

    // check for solid changes
    if (productTypeAbbreviated === SOLID) {
      return solidColor === orderItem.merchandise?.solid_item.solid.code;
    } else {
      // make sure all changing options on pdp are accounted for
      return (
        this.orderItemSize(productTypeAbbreviated, orderItem) === productSize &&
        orderItemFabricCode === selectedSubstrate &&
        parseInt(params.designId, 10) === orderItem.design.id &&
        addition === get(orderItem.merchandise, 'home_good_item.home_goods_additions[0]code')
      );
    }
  }

  dispatchAddToCart(fetchOptions, fetchUrl, isGuest) {
    return fetchAddToCart(this.dispatch, fetchOptions, fetchUrl, isGuest);
  }

  dispatchCartOrders() {
    return this.dispatch(fetchCartOrders());
  }

  dispatchOrderItemRemove(orderItemId) {
    return fetchOrderItemRemove(this.dispatch, orderItemId);
  }

  dispatchOrderItemChangeQuantity(orderItemId, quantity, chunkSize) {
    return fetchOrderItemChangeQuantity(this.dispatch, orderItemId, quantity, chunkSize);
  }
}

const CartOperationsSingleton = new CartOperations();

export default CartOperationsSingleton;
