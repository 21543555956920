import * as types from '../constants/ActionTypes';
import initialState from '../store/initialState';

function reviews(state = {}, action) {
  let reviewIndex = 0;
  let newState = {};

  switch (action.type) {
    case types.REVIEWS_GET + types.SUCCESS:
      return Object.assign({}, state, {
        reviews: action.payload.data.reviews.reviews,
        summary: action.payload.data.summary,
        isFetched: true,
        isFetching: false
      });

    case types.REVIEWS_GET + types.FAIL:
      return Object.assign({}, state,{
        isFetched: true,
        isFetching: false
      });

    case types.REVIEWS_GET + types.FETCHING:
      return Object.assign({}, state,{
        isFetching: true
      });

    case types.REVIEWS_POST + types.SUCCESS:
      return Object.assign({}, state, {
        review_submitted: true
      });

    case types.REVIEWS_VOTE_PATCH + types.SUCCESS:
      for (const review of Object.keys(state.reviews)) {
        if (state.reviews[review].id === action.payload.data.id) {
          reviewIndex = review;
        }
      }

      newState = Object.assign({}, state);
      newState.reviews[reviewIndex] = action.payload.data;

      return newState;

    case types.REVIEWS_FLAG_PATCH + types.SUCCESS:
      for (const review of Object.keys(state)) {
        if (state[review].id === action.payload.data.id) {
          reviewIndex = review;
        }
      }

      newState = Object.assign({}, state);
      newState.reviews[reviewIndex] = action.payload.data;

      return newState;

    case types.REVIEWS_ELIGIBILITY_GET + types.SUCCESS:
      return Object.assign({}, state, {
        eligibility: true
      });

    case types.PRODUCT_PAGE_RESET:
      return initialState.reviews;
    default:
      return state;
  }
}

export default reviews;
