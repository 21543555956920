module.exports = {
	"title": "Update your preferences",
	"country": "Country",
	"currency": "Currency",
	"language": "Language",
	"measurement": "Measurement",
	"shipTo": "Ship to",
	"changeCountryPreferences": "Click to change your shipping country",
	"YARD": "in, ft, yd",
	"METER": "cm, m",
	"blocked": "blocked",
	"allowed": "allowed",
	"boxMessageHeader": "Where did Country, Language and Currency options go?",
	"boxMessageMainText": "Going forward, Spoonflower’s website will only be offered in English. Pricing will only be displayed in US Dollars. ",
	"boxMessageLearnMore": "Learn more"
};