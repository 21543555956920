module.exports = {
	"metaTitle": "Privacy Notice | Spoonflower",
	"metaDescription": "Spoonflower Online Privacy And Security",
	"title": "Spoonflower Privacy Notice",
	"subtitle": "Spoonflower Online Privacy and Security",
	"lastModified": "Last Modified: June 27, 2024",
	"intro": {
		"p1": "Spoonflower is a community of creative, passionate people who trust one another and who also trust Spoonflower as a company. At Spoonflower, our purpose is to connect makers and consumers with artists worldwide, and we are committed to respecting your privacy.",
		"p2": "This Privacy Notice (“Notice”) explains how Spoonflower collects and uses personal information when you visit and use the Spoonflower services at Spoonflower.com and/or other Spoonflower online properties and apps (collectively our “Sites”).  This Notice also addresses information we collect from you when you contact one of our customer service representatives (via chat or submitting a request on our Sites), or when you respond to a survey or enter a contest or sweepstakes that we administer.",
		"p3": "Our Sites may be linked to other websites and other websites may have links to the Spoonflower Sites. This Privacy Notice only applies to the Spoonflower Sites, and we are not responsible for the privacy practices, security standards, or content of other websites. You should check the privacy policies of those websites before providing your personal information to them.",
		"p4": "We encourage you to read this Privacy Notice in its entirety before using our services. By creating an online account, using our services, or making a purchase, you acknowledge that you have read and agree to this Privacy Notice. Your use of our Services is also subject to our Terms and Conditions."
	},
	"collect": {
		"title": "What personal information do we collect? How do we use that information?",
		"p1": "We collect various types of personal information when you register with us as a customer, we collect various types of personal information when you create a Spoonflower account, purchase any products or services from us; register as a Verified Seller of designs in the Spoonflower marketplace (“Seller”), contact one of our customer service representatives, sign up for notifications or customer rewards programs, or complete a survey or enter a contest or sweepstakes that we sponsor. This information may be collected directly from you (for example, when you complete our forms or enter your information on our websites to make a purchase) or indirectly through your device or browser when you visit our Sites and Apps.",
		"p2": "We use your information to fulfill product orders, to customize and improve the advertising and content you see, to deliver marketing communications and promotional materials that you may be interested in, to contact you and provide customer service, to protect the security or integrity of our databases or websites, to detect and prevent fraud or illegal activity, to take precautions against liability, to detect and remediate violations of our Terms of Use or other misuse of our Sites and Apps, to develop and improve our products and services, and for internal operational purposes. The following chart describes what categories of personal information we may collect, how we collect that information from you, the business purposes for which we generally use such information, and which types of trusted third-party service providers we may share that information with:",
		"sub-headline1": "Messaging and Other Interactive Services",
		"p3": "Our Sites may include messaging services or other interactive areas or services, including blogs, design challenges, or other areas or services in which you or third parties create and post content, messages, comments, designs or other materials on the sites (\"Interactive Areas\"). If you use an Interactive Area, you should be aware that these areas are open to the public and any personal data you post or provide at registration may be viewable by others. Spoonflower may enable approved third-party websites and software to interact with the site and upload Content, view publicly available Content and/or order products utilizing Content that is made available for sale through the site. Any information or Content that you make publicly available on the site may be accessed and used by these third-party sites. We are not responsible for public disclosure of personal data you submit in connection with the Interactive Areas, nor are we responsible for how others might use that information, including to send you unsolicited messages.",
		"sub-headline2": "User Profiles",
		"p4": "You may opt to provide additional information, such as your photograph, bio and favorite links, and to make your profile available to the public through our Sites. As indicated below, in the section titled \"Sharing Your Information,\" you can control how your information is displayed and used within the Spoonflower website.",
		"sub-headline3": "Cookies",
		"p5": {
			"a": "We use cookies and other tracking technologies to collect your information when you are using our Services. We use such information to analyze trends, remember user settings, administer our Services, track user movements, and market our products and services. We may obtain reports based on the use of these technologies on an individual and/or aggregated basis. You can control cookies through your browser setting and through tools we make available through our Services. For more information about cookies and other tracking technologies we use, including how to disable them, please go to ",
			"url1-text": "Manage Cookies",
			"b": " linked in the footers of our pages."
		},
		"sub-headline4": "Employment",
		"p6": "When you apply for a job with us, we may collect information from you, including information you provide in connection with your application, information you make available on your social media accounts, information about you that is available publicly, and information you authorize us to collect via third parties, including former employers or references.",
		"p7": "In certain circumstances, you may submit your application for employment through a third-party service that displays our job posting. We do not control the privacy practices of these third-party services. Please review their privacy policies carefully prior to submitting your application materials."
	},
	"table": {
		"th1": "Information Type",
		"th2": "Source",
		"th3": "Purpose",
		"th4": "Service Provider",
		"r1": {
			"td1": {
				"a": "Personal Identifiers",
				"b": "(Customer Name, Postal Address, Phone Number, E-mail Address, Social Media or Website Handle, Screen Name, Seller Profile Image)"
			},
			"td2": {
				"a": "Provided directly by customer or Sellers",
				"b": "Social networks"
			},
			"td3": {
				"li1": "Maintaining and servicing your account",
				"li2": "Providing customer service",
				"li3": "Processing and fulfilling your orders",
				"li4": "Processing payments on your orders",
				"li5": "Advertising and marketing our products to you",
				"li6": "Detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity"
			},
			"td4": {
				"li1": "Product creation and order fulfillment vendors",
				"li2": "Customer service providers",
				"li3": "Marketing and advertising services",
				"li4": "Surveys, sweepstakes, and contests",
				"li5": "Payment and chargeback processors",
				"li6": "Mailing and shipping services"
			}
		},
		"r2": {
			"td1": {
				"a": "Financial information",
				"b": "(credit card information, banking account information, billing and shipping addresses)"
			},
			"td2": "Provided directly by customer",
			"td3": {
				"li1": "Processing payments on your orders",
				"li2": "For designers, remitting earnings based on product sales incorporating your designs",
				"li3": "Detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity"
			},
			"td4": {
				"li1": "Customer service providers",
				"li2": "Payment and chargeback processors",
				"li3": "Fraud prevention and site security"
			}
		},
		"r3": {
			"td1": {
				"a": "Commercial Information",
				"b": "(purchase date, purchase totals, product types)"
			},
			"td2": "Provided directly by customer",
			"td3": {
				"li1": "Providing customer service",
				"li2": "Processing and fulfilling your orders",
				"li3": "Processing payments on your orders",
				"li4": "Analytics to improve our products and services",
				"li5": "Advertising and marketing our products to you"
			},
			"td4": {
				"li1": "Product creation and order fulfillment vendors",
				"li2": "Customer service providers",
				"li3": "Payment and chargeback processors"
			}
		},
		"r4": {
			"td1": {
				"a": "Tax Information",
				"b": "(Business Legal Name, VAT Number, US Tax Documents)"
			},
			"td2": "Provided directly by Sellers",
			"td3": {
				"li1": "Providing customer service",
				"li2": "Processing and fulfilling your sales",
				"li3": "Compliance with mandatory disclosures to tax authorities"
			}
		},
		"r5": {
			"td1": {
				"a": "Internet or Electronic Network Activity Information and Geolocation Information",
				"b": "(IP Address, Cookies, Website Browsing and Interaction Activity, Other Device or Browser Identifiers)"
			},
			"td2": "Collected indirectly from customer’s browser or device",
			"td3": {
				"li1": "Enhancing your user experience",
				"li2": "Analytics to improve our products and services",
				"li3": "Advertising and marketing our products to you",
				"li4": "Detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity"
			},
			"td4": {
				"li1": "Marketing and advertising service providers",
				"li2": "Website performance and analytics vendors",
				"li3": "Fraud prevention and site security"
			}
		},
		"r6": {
			"td1": "Website login credentials",
			"td2": "Provided directly by customer",
			"td3": {
				"li1": "Maintaining and servicing your account",
				"li2": "Providing customer service",
				"li3": "Processing and fulfilling your orders",
				"li4": "Detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity"
			},
			"td4": {
				"li1": "None"
			}
		},
		"r7": {
			"td1": {
				"a": "Content of communications",
				"b": "(Contents of phone calls, emails, or text messages; content of social media interactions; content of posts on discussion forums)"
			},
			"td2": {
				"a": "Provided directly by customer",
				"b": "Social networks"
			},
			"td3": {
				"li1": "Maintaining and servicing your account",
				"li2": "Providing customer service",
				"li3": "Processing and fulfilling your orders",
				"li4": "Processing payments on your orders",
				"li5": "Advertising and marketing our products to you",
				"li6": "Detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity",
				"li7": "Enabling you to provide feedback, respond to a survey, or participate in community discussions"
			},
			"td4": {
				"li1": "Product creation and order fulfillment vendors",
				"li2": "Customer service providers",
				"li3": "Marketing and advertising services",
				"li4": "Surveys, sweepstakes, and contests",
				"li5": "Payment and chargeback processors",
				"li6": "Mailing and shipping services"
			}
		},
		"r8": {
			"td1": "Inferences about customer preferences and characteristics",
			"td2": "Inferred from information provided by customer, information collected indirectly from customer’s browser or device",
			"td3": {
				"li1": "Enhancing your user experience",
				"li2": "Analytics to improve our products and services",
				"li3": "Advertising and marketing our products to you"
			},
			"td4": {
				"li1": "Marketing and advertising service providers",
				"li2": "Website performance and analytics vendors"
			}
		}
	},
	"how": {
		"title": "How do we share your information with third parties?",
		"p1": {
			"a": "We do not sell, license or share the personal information we collect with unaffiliated third parties for their marketing purposes. ",
			"b": "We may share personal information in the following situations."
		},
		"sub-sub-headline1": "Service Providers",
		"p2": "We engage vendors to perform specific business functions on our behalf, and they may receive information about you from us or collect it directly. These vendors are obligated by contract to use information that we share only for the purpose of providing these business functions, which include:",
		"list": {
			"item1": {
				"a": "Supporting Service functionality",
				"b": ", such as vendors that support customer service and customer relationship management, application development, and communications (email, fax)."
			},
			"item2": {
				"a": "Auditing and accounting firms",
				"b": ", such as firms that assist us in the creation of our financial records."
			},
			"item3": {
				"a": "Professional services consultants",
				"b": ", such as firms that perform analytics, assist with improving our business, provide legal services, or supply project-based resources and assistance."
			},
			"item4": {
				"a": "Analytics and marketing services",
				"b": ", including entities that analyze traffic on our online properties and assist with identifying and communicating with potential customers."
			},
			"item5": {
				"a": "Security vendors",
				"b": ", such as entities that assist with security incident verification and response, service notifications, and fraud prevention."
			},
			"item6": {
				"a": "Information technology vendors",
				"b": ", such as entities that assist with website design, hosting and maintenance, data and software storage, and network operation."
			},
			"item7": {
				"a": "Marketing vendors",
				"b": ", such as entities that support distribution of marketing emails."
			},
			"item8": {
				"a": "Online advertising partners",
				"b": ", including partners that use cookies and online tracking technologies to collect information to personalize, retarget, and measure the effectiveness of advertising."
			}
		},
		"sub-sub-headline2": "Partners and Affiliates",
		"p3": {
			"a": "Spoonflower is part of the ",
			"url": "https://www.shutterflyinc.com/brands/",
			"url-text": "Shutterfly family",
			"b": " of companies and brands. We may share your personal data within our corporate group, including with our subsidiaries, affiliates, and divisions, all of whom may use your data for the purposes described in this Notice."
		},
		"p4": {
			"url": "https://www.shutterflyinc.com/our-brands/",
			"url-text": "Spoonflower may from time to time engage with distribution partners to offer Spoonflower products on third party platforms and marketplaces.  If you are a Seller, you may have opportunities from time to time to offer your designs through such distribution partners.",
			"a": " Participation in such programs is voluntary; if you choose to participate, Spoonflower may share your personal information with such partners to facilitate the sale and marketing of products incorporating your designs."
		},
		"sub-sub-headline3": "Law Enforcement and Compliance",
		"p5": "We may be required to share your personal information in other, limited circumstances. For example, we may be required to share certain information to respond to a subpoena or similar judicial process, to comply with state, federal or local laws, to protect and secure our Services, assets, network, and business operations, and to detect, investigate, and prevent activities that may violate our policies or be fraudulent or illegal, to protect the security or integrity of our databases or Sites, to take precautions against liability,  or, to the extent required by law, to provide information to law enforcement agencies. Additionally, if you are a Seller, Spoonflower will release earnings based on sale of products incorporating your designs and certain tax information to the United States Internal Revenue Service and/or other applicable tax authorities.",
		"sub-headline1": "Social media platforms",
		"p6": "If we offer you the option to log into your account with any of our brands through a social media site, we will also have access to certain information from that site, such as your name, account information and profile photo, in accordance with the site’s account verification procedures. Also, if you interact with content on our social media profiles (for example, if you “like” or share a post from one of our brand’s Facebook, Twitter, or Instagram accounts), those social media sites may share information about you with us, including your public profile, e-mail address and friend list. If you choose to “like” or share content, information about you may be publicly displayed on the social network, depending on your privacy settings. You should review the Privacy Notice of that social media site and check your privacy settings through that site. If you post information on a social media or another third-party service that references our brands or our products and services (e.g., via a Twitter hashtag), we may publish your post on our social media accounts or websites.",
		"sub-headline2": "Other businesses in the context of a commercial transaction",
		"p7": "We may change our ownership or corporate organization while providing the Services. We may transfer to another entity or its affiliates or service providers some or all information about you in connection with, or during negotiations of, any merger, acquisition, sale of assets or any line of business, change in ownership control, or financing transaction. We cannot promise that an acquiring party or the merged entity will have the same privacy practices or treat your information as described in this Notice."
	},
	"children": {
		"title": "Does our website collect information from children?",
		"p1": {
			"a": "Our Sites are intended for use by general audiences and are not targeted directly at children. We will not knowingly collect personally identifiable information from children. If we become aware that a child under the age of 13 has provided personal information through our websites or apps, we will remove their personally identifiable information from our files. If a parent or guardian becomes aware that their child has provided such information through our websites or apps, the parent or guardian should contact us in writing at ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": " so that we may respond appropriately."
		}
	},
	"options": {
		"title": "What options do you have to limit our collection and use of your personal information?",
		"p1": {
			"a": "You have choices about how your information is collected and used. You can notify us of your preferences when you create an account with us, and if you would like to review, correct or delete your account information on our Services, you can do so by logging in to your account or by contacting us directly at ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": " or visiting the Privacy tab in your profile."
		},
		"sub-headline1": "Opting out of Marketing Communications",
		"p2": "If you do not wish to receive marketing information from us or wish to opt out of future email promotions from us, please visit Account Settings and My Email Settings and/or instructions contained in the email communication. Please note that all promotional email messages you receive from us will include an option to opt out of future email communications.",
		"p3": "We reserve the right to send certain communications such as system emails, order confirmation emails, and legal notices.",
		"sub-headline2": "Cookie Options",
		"p4": "You have options to limit the information that we and our partners collect for online advertising purposes.",
		"list": {
			"item1": "You may disable cookies in your browser or mobile device using their settings menus. Your mobile device may give you the option to disable advertising functionality. Because we use cookies to support Service functionality, disabling cookies may also disable some elements of our online properties.",
			"item2": {
				"a": "The following industry organizations offer opt-out choices for companies that participate in them: the ",
				"url1": "http://www.networkadvertising.org/choices/",
				"url1-text": "Network Advertising Initiative",
				"b": ", the ",
				"url2": "http://www.aboutads.info/choices",
				"url2-text": "Digital Advertising Alliance",
				"c": ", and the ",
				"url3": "http://www.youronlinechoices.eu/",
				"url3-text": "European Interactive Digital Advertising Initiative",
				"d": "."
			},
			"item3": {
				"a": "You can opt out of Google cookies by installing ",
				"url1": "https://tools.google.com/dlpage/gaoptout",
				"url1-text": "Google Analytics’ opt-out browser add-on",
				"b": ", and/or the ",
				"url2": "https://tools.google.com/dlpage/optoutplugin/eula.html",
				"url2-text": "Google Advertising Cookie opt-out browser add-on",
				"c": "."
			},
			"item4": {
				"a": "You may ",
				"url1-text": "Manage Cookies",
				"b": " (link also found at bottom of our pages)."
			},
			"item5": "You may contact us directly."
		},
		"p5": "If you exercise these options, please be aware that you may still see advertising, but it will not be personalized. Nor will exercising these options prevent other companies from displaying personalized ads to you.",
		"p6": "If you delete your cookies, you may also delete your opt-out preferences, in which case you will need to opt out again where you want your preferences to apply. “Do Not Track” is a preference you can set in your web browser to let the sites you visit know that you do not want them collecting information about you. Our sites do not currently respond to all “Do Not Track” settings. However, we treat Global Privacy Control signals as requests to opt out of the “sale or sharing” of personal information pursuant to the California Privacy Rights Act."
	},
	"jurisdiction": {
		"title": "Jurisdiction-specific rights",
		"p1": "You may have certain rights with respect to your personal information depending on your location or residency. Please see “Privacy Disclosures for Specific Jurisdictions” below."
	},
	"secure": {
		"title": "How do we keep your information secure?",
		"p1": "Although no website, application, database or system is completely secure or “hacker proof,” we have safeguards in place to protect against unauthorized access and disclosures of the personal information we control, including technical, physical and administrative safeguards and security measures. Despite these measures, however, we cannot guarantee that our information security safeguards will never be compromised."
	},
	"updates": {
		"title": "How do we make updates to this Notice?",
		"p1": "As we implement new technology and introduce new services or otherwise change our privacy practices, or in response to changes in applicable laws or regulations, we may modify this Notice and provide notice to you by posting updates on this page. Please check back periodically to view any updates. Changes to our Notice will become effective when posted. If we change this Privacy Notice in a material way, we will endeavor to promptly notify our customers. Your continued use of our websites, and/or other services following such notice acknowledges your acceptance of such updated terms."
	},
	"contact": {
		"title": "How can you contact us with questions about your privacy or this Notice?",
		"p1": "For further information regarding this website privacy notice, please contact:",
		"p2": "Spoonflower, Inc.\n2810 Meridian Parkway Suite 132\nDurham, NC 27713\nUSA\nPhone: +1-919-975-4862\nEmail: privacy@spoonflower.com\n"
	},
	"other": {
		"title": "Other Important Information",
		"sub-headline1": "Data retention",
		"p1": "Spoonflower will retain your information only for as long as is necessary for the purposes set out in this Notice, for as long as your account is active (i.e., for the lifetime of your user account), or as needed to provide the Services to you. If you no longer want Spoonflower to use your information to provide the Services to you, you may close your account. Spoonflower will retain and use your information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your information to comply with applicable tax laws), resolve disputes, perform and enforce our agreements, and as otherwise described in this Notice.",
		"sub-headline2": "Cross-border data transfer",
		"p2": "We may collect, process, and store your information in the United States and other countries. The laws in the United States regarding information may be different from the laws of your country. Any such transfers will comply with safeguards as required by relevant law, including the Standard Contractual Clauses adopted by the European Commission or the UK Information Commissioner’s Office."
	},
	"specific": {
		"title": "Privacy Disclosures for Specific Jurisdictions",
		"title2": "Supplemental Notice to Residents of the European Economic Area, United Kingdom, and Switzerland",
		"p1": "We process “personal data,” as that term is defined in the European Union’s General Data Protection Regulation (“GDPR”). The data controller for your personal information is Spoonflower, Inc., 2810 Meridian Parkway Suite 132, Durham, NC 27713, USA",
		"p2": "Your rights under the GDPR and UK-GDPR",
		"p3": "European Economic Area (“EEA”), U.K., or Swiss residents have the following rights:",
		"list": {
			"item": {
				"li1": "Access and Portability: Request access to personal data we hold about you or request transmission of your data to a third party.",
				"li2": "Correction: Request that we rectify inaccurate or incomplete personal data we store about you.",
				"li3": "Erasure: Request that we erase personal data when such data is no longer necessary for the purpose for which it was collected, when you withdraw consent and no other legal basis for processing exists, or when you believe that your fundamental rights to data privacy and protection outweigh our legitimate interest in continuing the processing.",
				"li4": "Restriction of processing: Request that we restrict our processing of personal data if there is a dispute about the accuracy of the data; if the processing is unlawful; if the processing is no longer necessary for the purposes for which it was collected but is needed by you for the establishment, exercise or defense of legal claims; or if your request to object to processing is pending evaluation.",
				"li5": "Objection to processing: Object to processing of your personal data based on our legitimate interests or for direct marketing (including profiling). We will no longer process the data unless there are compelling legitimate grounds for our processing that override your interests, rights, and freedoms, or for the purpose of asserting, exercising, or defending legal claims.",
				"li6": "Transfers: Obtain information about and a copy of the safeguards we use to transfer personal data across borders."
			}
		},
		"p4": {
			"a": "You may access the personal data we hold about you and edit, cancel, or delete your Spoonflower account information at any time by using the self-service tools available under Privacy Settings in ",
			"url1": "https://support.spoonflower.com/hc/en-us/articles/204924304-Adjusting-Account-Settings#access",
			"url1-text": "Account Settings",
			"b": " (you will be required to log in), or by emailing us at ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"c": ". ",
			"d": "These tools are the most efficient way to take these actions. ",
			"e": "If you request your account to be cancelled or deleted, we will satisfy your request within 30 days. If we require more time to process your request, we will notify you about the delay."
		},
		"p5": {
			"a": "For any other request please contact us at ",
			"mailto1": "mailto:privacy@spoonflower.com",
			"mailto1-text": "privacy@spoonflower.com",
			"b": " or contact our Data Representative at the address below."
		},
		"p6": "Information necessary for the purposes of maintaining business records regarding a deleted or cancelled account, including records of credit transactions and account ownership, will be retained in accordance with applicable law. We may decline to process requests that are frivolous/vexatious, would jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law. We may also decline aspects of deletion or access requests if we believe doing so would undermine our legitimate use of data for anti-fraud and security purposes.",
		"p7": "Users who are in the EEA, U.K. or Switzerland have the right to lodge a complaint about our data collection and processing actions with the supervisory authority concerned. That supervisory authority is Data Protection Commission, 21 Fitzwilliam Square South, Dublin 2, D02 RD28, Ireland.",
		"p8": {
			"a": "Notice to Supervisory Authority",
			"b": "Supervisory authorities can 1) email Spoonflower directly at ",
			"mailto1": "mailto:privacy@spoonflower.com",
			"mailto1-text": "privacy@spoonflower.com",
			"c": ", or 2) contact our Data Representative: ",
			"d": "DataRep, BPM 335368, 372 Old Street, EC1V 9AU, London, United Kingdom"
		}
	},
	"ca-co-ct-ut-va": {
		"title": "Supplemental Notice to Residents of CA, CO, CT, MT, OR, TX, UT and VA",
		"p1": "Depending on where you reside, you may also have additional legal rights with respect to your information. While some of these rights apply generally, certain rights apply in limited cases. Consistent with applicable laws, this Supplemental Notice provides a way to exercise such rights for residents of California, Colorado, Connecticut, Montana, Oregon, Texas, Utah and Virginia.",
		"p2": {
			"a": "For information about what categories of personal information we may collect from our customers, the sources of that information, the purposes for collecting and using that information, and what types of service providers we may share that information with, please see above our sections titled ",
			"anchor-link": "#collect",
			"anchor-link-text": "What personal information do we collect? How do we use that information?"
		},
		"p3": "The laws of California, Colorado, Connecticut, Montana, Oregon, Texas, Utah and Virginia grant some or all of the following rights to consumers who reside in those states:",
		"list": {
			"item": {
				"li1": "The right to request information about personal information that we have collected about that customer in the 12 months preceding the customer’s request – including the categories of information collected, the source of that information, the business purpose of that collection, the categories of third parties with whom that information is shared, and the specific personal information collected about that particular customer;",
				"li2": "The right to receive requested information in a readily-usable format if provided electronically;",
				"li3": "The right to request that we delete any personal information about the consumer that we have collected (although we may be entitled to retain some information for certain purposes);",
				"li4": "The right to opt-out of “sales” of personal information to third parties, the sharing of personal information with third parties for targeted advertising purposes, and/or the processing of personal information for targeted advertising purposes;",
				"li5": "The right to update or correct any personal information which is out of date or incorrect; and",
				"li6": "The right to be free from discrimination based on your exercise of your privacy rights."
			}
		},
		"p4": {
			"a": "You may access the personal data we hold about you and edit, cancel, or delete your Spoonflower account information at any time by using the self-service tools available under My Settings or under Privacy Settings in ",
			"url1": "https://support.spoonflower.com/hc/en-us/articles/204924304-Adjusting-Account-Settings#access",
			"url1-text": "Account Settings",
			"b": " (you will be required to log in). Non-account holders can click on the Do Not Sell or Share My Personal Information link in the footer of the site, or email us at ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"c": " to make requests regarding personal data. ",
			"d": "The tools are the most efficient way to make your request and have it fulfilled."
		},
		"p5": "We do not “sell” personal information in the ordinary sense of that term. That is, we don’t provide your personally identifiable information to third parties in exchange for money. But under California law, sharing information with third parties for targeted advertising purposes may be considered a “sale” of personal information. We offer residents from these states the right to opt out of such sharing.",
		"p6": "We cannot respond to your request to access or delete information unless we are able to both verify your identity or authority to make the request and confirm the personal information relates to you and/or your household. If you have an account on any of our Sites and Apps, we will verify your identity by having you log in to your account. If you do not have an account on our Sites and Apps, we will attempt to verify your request by asking you to provide identifying information that we will match to information in our files. We will only use information you provide in your request for the purpose of verifying your identity or authority to make the request.",
		"p7": {
			"a": "You can also designate an authorized agent to make a request on your behalf. Your agent will need to provide a Power of Attorney authorizing the agent to act on your behalf, or will need to complete the same verification procedures that would be required for a request submitted by you directly, and provide information that allows us to verify your authorization. If you would like to appeal a decision related to a request to exercise your rights under this Supplemental Notice, please contact us at ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": "."
		},
		"p8": "We may deny deletion requests in whole or in part as permitted or required by applicable law. We do not charge a different price or rate or provide a different level or quality of goods or services based on your exercising these privacy rights."
	},
	"california": {
		"title": "Supplemental Notice to Residents of California",
		"p1": {
			"a": "If you are a California resident under the age of 18, and a registered user of any site where this Notice is posted, you may request and obtain removal of content or information you have publicly posted on such site. To make such a request, please send an email with the subject line “California Minor Content Removal Request” to ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": ". Please specify in your request which website or application your request relates to and the specific information or content that you would like removed. Please note that your request does not ensure complete or comprehensive removal of the content or information, because, for example, some of your content may have been reposted by another visitor to our websites."
		},
		"p2": "We may offer various financial incentives and benefits (such as free shipping, discounts, and other special offers) to customers who interact with us in our Sites and provide personal information. We offer such financial incentives and benefits for business purposes such as increasing engagement and sales or raising brand awareness, and participation is voluntary. We do not assign monetary or other value to personal information, but the value of such personal information is reasonably related to the estimated value of the financial incentive offered when the information was collected. Spoonflower reserves the right to withdraw or modify the financial incentives we offer at any time.",
		"p3": {
			"a": "If you are a current or former Spoonflower employee and would like to exercise your rights with respect to your personal data, please send an email with the subject line “California Employee Data Request” to ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": "."
		},
		"p4": "In 2023, Spoonflower received and processed 885 verified delete requests and 148 verified access requests from residents of California, Colorado, Connecticut and Virginia. Spoonflower received and completed 192 requests to opt-out of sale/sharing from residents of these same states. Spoonflower received 0 requests to correct as customers can correct their data within their accounts. Spoonflower received 0 requests to limit use and disclosure of sensitive personal information because we do not collect sensitive personal information. Spoonflower did not deny any verified requests. Spoonflower responded to data subject rights requests within 7 days on average."
	},
	"nevada": {
		"title": "Supplemental Notice to Residents of Nevada",
		"p1": {
			"a": "Nevada law gives Nevada consumers the right to request that a company not sell their personal information for monetary consideration to certain other parties. This right applies even if their personal information is not currently being sold. If you are a Nevada consumer and wish to exercise this right, please send an email with the subject line “Nevada Resident Do Not Sell Request” to ",
			"mailto": "mailto:privacy@spoonflower.com",
			"mailto-text": "privacy@spoonflower.com",
			"b": "."
		}
	},
	"privacy": "Your Privacy",
	"content": "When you visit our site, cookies are stored on your browser to provide you advertising more relevant to your preferences.",
	"clickableContent": "Manage My Cookies.",
	"residents": "CA, CO, CT, MT, OR, TX, UT, VA Residents",
	"residentText": "If you are a resident of one of these states and want more data options,",
	"clickableResident": "click here."
};