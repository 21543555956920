// TODO SP-6816: Remove once all files are using interfaces
// constants
export const REGISTERED = 'registered';
export const GUEST = 'guest';
export const UNAUTHENTICATED = 'unAuthenticated';

// userType helper functions
export const unAuthOrGuestUser = (user: string): boolean => user === UNAUTHENTICATED || user === GUEST;
export const registeredUser = (user: string): boolean => user === REGISTERED;
export const guestUser = (user: string): boolean => user === GUEST;
