import update from 'immutability-helper';
import * as types from '../constants/ActionTypes';

function stockItems(state = {}, action) {
  switch (action.type) {
    case types.STOCK_ITEMS_GET + types.SUCCESS:
      return update(
        action.payload.data,
        {
          $merge: {
            isFetching: false
          }
        }
      );
    case types.STOCK_ITEMS_GET + types.FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetching: true
          }
        }
      );

    default:
      return state;
  }
}

export default stockItems;
