import merge from 'deepmerge';

import {
  SolidColorDataFailActionType,
  SolidColorDataFetchingActionType,
  SolidColorDataReceivedActionType,
  SolidColorsFailActionType, SolidColorsFetchingActionType, SolidColorsReceivedActionType, SolidImagesFailActionType,
  SolidImagesFetchingActionType, SolidImagesReceivedActionType, SolidPricingFailActionType,
  SolidPricingFetchingActionType, SolidPricingReceivedActionType, SolidResetType, SolidsAction,
} from '../actions/solids';
import {State, initialState} from '../store/initialState';


type SolidsState = State['solids']

function solids(state = {} as SolidsState, action: SolidsAction): SolidsState {
  switch (action.type) {
    case SolidImagesReceivedActionType:
      return {
        ...state,
        images: {
          images: action.payload,
          isFetching: false
        }
      };
    case SolidImagesFetchingActionType:
      return merge(state, {
        images: {
          isFetching: true
        }
      });
    case SolidImagesFailActionType:
      return {
        ...state,
        images: {
          isFetching: false
        }
      };

    case SolidPricingReceivedActionType:
      return Object.assign({}, state, action.payload, {
        isFetching: false
      });
    case SolidPricingFetchingActionType:
      return Object.assign({}, state, {
        isFetching: true
      });
    case SolidPricingFailActionType:
      return Object.assign({}, state, {
        isFetching: false
      });

    case SolidColorsReceivedActionType:
      return {
        ...state,
        availableColors: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          colors: action.payload,
          isFetching: false
        }
      };
    case SolidColorsFetchingActionType:
      return merge(state, {
        availableColors: {
          isFetching: true
        }
      });
    case SolidColorsFailActionType:
      return merge(state, {
        availableColors: {
          isFetching: false
        }
      });

    case SolidColorDataReceivedActionType:
      return {
        ...state,
        availableColors: {
          colors: action.payload,
          isFetching: false
        }
      };
    case SolidColorDataFetchingActionType:
      return merge(state, {
        availableColors: {
          isFetching: true
        }
      });
    case SolidColorDataFailActionType:
      return merge(state, {
        availableColors: {
          isFetching: false
        }
      });
    case SolidResetType:
      return initialState.solids;
    default: {
      // https://stackoverflow.com/a/58009992
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustivenessCheck: never = action;

      return state;
    }
  }
}

export default solids;
