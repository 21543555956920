/* global newrelic */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../services';
import {errorsToIgnoreList} from '../../../constants/Errors';
import FlashMessage from '../Content/FlashMessage/FlashMessage';

import './_error_boundary.scss';

export class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  componentDidCatch(error) {
    const shouldIgnoreError = (error) => (errorsToIgnoreList.includes(error.message));

    if (typeof newrelic !== 'undefined' && newrelic !== null) {
      newrelic.setErrorHandler(function(error) {
        return shouldIgnoreError(error);
      });
      newrelic.noticeError(error);
    } else if (!shouldIgnoreError) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (<FlashMessage
        message={translate('errors.ERROR_BOUNDARY_TRIGGER')}
        severity={'info'}
        specialClass='space-bottom' />);
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};
