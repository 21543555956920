import {errorsToIgnoreList} from '../constants/Errors';


export const newrelicError = (): void => {
  // See http://bluebirdjs.com/docs/api/error-management-configuration.html#global-rejection-events

  window.addEventListener('unhandledrejection', (e) => {
    // Note that newrelic is not installed via webpack, but via a <script/> tag. In combination with our dependency on
    // the `window` object, this is the second direct coupling to the browser, for this operation.

    // eslint-disable-next-line no-console
    console.log('Confirmed unhandledrejection listener for this browser.');

    if (typeof window.newrelic !== 'undefined' && window.newrelic !== null) {
      const exception = e.reason;

      // eslint-disable-next-line no-console
      console.log('Detected the following exception in unhandledrejection listener', exception);

      const shouldIgnoreError = (e: Error) => errorsToIgnoreList.includes(e.message);

      window.newrelic.setErrorHandler(shouldIgnoreError);

      if (typeof exception !== 'undefined') {
        e.preventDefault();
        window.newrelic.noticeError(exception);
      }
    }
  });
};
