import {isNotUndefined} from './validation';
import pick from 'lodash/pick';

export const commonErrorProps = [
  'config.url',
  'config.params',
  'config.headers',
  'config.data',
  'response.status',
  'response.statusText',
  'response.headers',
];
// Wrap errors caught in fetch requests to contain useful data.
export class RequestError extends Error {
  constructor(fetchError, url, fetchOptions, state) {
    const requestBody = fetchOptions.body || fetchOptions.data;

    const {fetchResult, status, body: fetchErrorBody, message, response} = fetchError;

    const {user} = state;
    const extendedData = {
      request: {
        method: fetchOptions.method,
        // Prioritize exact URL used in fetch middleware. Fallback to url provided in call. If URL is modified in middleware, we want that reported.
        url: isNotUndefined(fetchResult) && isNotUndefined(fetchResult.url) ? fetchResult.url : url,
        headers: fetchOptions.headers,
        body: isNotUndefined(requestBody) ?
          typeof requestBody === 'string' ?
            JSON.parse(requestBody) :
            requestBody :
          undefined, // TODO update and check request body
      },
      response: {
        status: status || response?.status,
        body: fetchErrorBody,
      },
      user: {
        id: user?.id,
        order_id_pending: user?.order_id_pending,
      },
    };

    super(`${message} : ${JSON.stringify(extendedData)}`);
    let errorToCopy = fetchError;

    if (fetchError.isAxiosError) {
      errorToCopy = pick(fetchError, commonErrorProps);
      this.message = `${this.message}\n${this.stack.split('\n').splice(1)
        .join('\n')}`;
      this.stack = fetchError.stack;
      this.body = response?.data;
    }
    Object.keys(errorToCopy).forEach((key) => {
      this[key] = errorToCopy[key];
    });
    // So that error is not reported simply as the Error constructor. Cannot use this.constructor.name due to minification
    // causing error types to be reported as simply 'i' for example.
    this.name = 'RequestError';
    this.status = status || response?.status;
  }
}
