import * as types from '../constants/ActionTypes';

export function designReceived(payload) {
  return {
    type: types.DESIGN_GET + types.SUCCESS,
    payload
  };
}

export function designFetching() {
  return {
    type: types.DESIGN_GET + types.FETCHING
  };
}

export function designFailed(payload) {
  return {
    type: types.DESIGN_GET + types.FAIL,
    payload
  };
}

export function validImageReceived() {
  return {
    type: types.VALID_IMAGE_HEAD + types.SUCCESS
  };
}

export function noValidImageReceived() {
  return {
    type: types.VALID_IMAGE_HEAD + types.FAIL
  };
}

export function validImageFetching() {
  return {
    type: types.VALID_IMAGE_HEAD + types.FETCHING
  };
}

export function favoriteDesign(favoriteCountChange, designId) {
  return {
    type: types.DESIGN_FAVORITED + types.SUCCESS,
    favoriteCountChange,
    designId
  };
}

export function favoriteDesignFetching() {
  return {
    type: types.DESIGN_FAVORITED + types.FETCHING
  };
}

export function cleanDesignState() {
  // initial state
  return {
    type: types.DESIGN_GET + types.SUCCESS,
    payload: {
      designer: {},
      isFetching: false,
      isFetchingValidImage: false,
      isFetchingFavoriteDesign: false,
      error: false
    }
  };
}
