import * as types from '../constants/ActionTypes';

function invoice(state = {}, action) {
  switch (action.type) {
    case types.INVOICE + types.ORDER_GET + types.SUCCESS: {
      const newOrderState = Object.assign({}, state);

      newOrderState.order = action.payload.data;

      return newOrderState;
    }

    case types.INVOICE_GATEWAY_METADATA_GET + types.SUCCESS: {
      const newGatewayMetadataState = Object.assign({}, state);

      newGatewayMetadataState.gateway_metadata = action.payload.data;

      return newGatewayMetadataState;
    }

    case types.INVOICE_TOGGLE_LOADING: {
      const newLoadingState = Object.assign({}, state);

      newLoadingState.loading = action.loading;

      return newLoadingState;
    }

    case types.INVOICE_METADATA_TOGGLE_LOADING: {
      const newMetadataLoadingState = Object.assign({}, {
        metadataLoading: action.loading ?? false
      }, state);

      return newMetadataLoadingState;
    }

    default:
      return state;
  }
}

export default invoice;
