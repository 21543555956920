module.exports = {
	"headlines": {
		"color": "Color",
		"size": "Size",
		"modal": "Select a Color"
	},
	"changeColor": "Change Color",
	"selectText": "Select",
	"SOLID_COLOR_WHITE": {
		"name": "White",
		"description": "Simple and endlessly useful, our Cotton Solid in White is an essential staple for every fabric stash. This classic neutral will be perfect as the accent on a bold, graphic heirloom quit, or as the focal point in a crisp white shirt. If you are looking for something one-of-a-kind, our solid white cotton is also the perfect canvas for tie-dye, shibori, and other colorful DIY experiments!"
	},
	"SOLID_COLOR_NATURAL": {
		"name": "Natural",
		"description": "Warm and inviting, our Cotton Solid in Natural, is a classic alternative to pure white. There is nothing beige about this subtle neutral! If you are looking to complete your serene coastal or cottage-chic inspired look or provide beautiful balance to a splashy mix of bold color, our Natural cotton is a versatile staple for any DIY project."
	},
	"SOLID_COLOR_BLACK": {
		"name": "Black",
		"description": "While chromatic purists like to say black is the absence of light and thus the absence of color, we have a different point of view. Black is beautiful, and our Cotton Solid in Black is a great choice for any project where you to provide a sharp contrast, relief, or just want to let your inner darkness show."
	},
	"SOLID_COLOR_GRAPHITE": {
		"name": "Graphite",
		"description": "No pencil pouch is complete without a perfectly sharpened pencil and no fabric stash is complete without our Cotton Solid in Graphite. This cool deep grey cotton is perfect for apparel, dress-up accessories, and lining everyday tote bags for books."
	},
	"SOLID_COLOR_PEWTER": {
		"name": "Pewter",
		"description": "Our Cotton Solid in Pewter is a chamelon-like silver grey that brings to mind hazy city skylines. Perfect for your little one's knight in shining armor costume, backing a Fill-A-Yard™ cheater quilt, or a jumpsuit that pairs well with every color in the rainbow."
	},
	"SOLID_COLOR_BARK": {
		"name": "Bark",
		"description": "What did the dog say to the tree? Our Cotton Solid in Bark is a muted mid-tone brown. Like its name suggests, this color evokes a feeling of rustic earthiness and is an excellent option for projects looking to mimic natural textures or simply as a coordinate for rustic and woodland themed prints."
	},
	"SOLID_COLOR_MUSHROOM": {
		"name": "Mushroom",
		"description": "No need to forage for your new favorite neutral! Our Cotton Solid in Mushroom is a light brown with muted tan hue. It pairs well with a wide range of colors and styles, making it the perfect option for timeless fashions and whimsical crafts alike."
	},
	"SOLID_COLOR_MOCHA": {
		"name": "Mocha",
		"description": "Like it's namesake beverage, our Cotton Solid in Mocha is comforting yet energizing. This warm, mid-tone brown is widely found in nature and serves as an ideal coordinate for animal prints or as a featured color in a rustic quilt."
	},
	"SOLID_COLOR_CINNAMON": {
		"name": "Cinnamon",
		"description": "Our Cotton Solid in Cinnamon is a rich, warm brown that combines a grounded earthiness with exciting tints of red. Whether you are look to create a classic teddy bear, or a colorful quilt with a bold contrast, this color is sure to spice up your next project."
	},
	"SOLID_COLOR_MOSS": {
		"name": "Moss",
		"description": "Surround yourself with our comforting Cotton Solid in Moss. This natural medium cool-toned green will create a peaceful harmony for all of your projects that involve earthy, woodland and rustic aesthetics. It’s also a lovely accent color to add a bit of elegance to your space with quilts with coordinating patterns, zipper pouches, or even seasonal napkins."
	},
	"SOLID_COLOR_MUSTARD": {
		"name": "Mustard",
		"description": "Fall into autumn with our golden Cotton Solid in Mustard. Add positive energy into your fall crafts with this warm comforting tone. Make a lightweight sack bag to carry all your favorite books to your most relaxing outdoor space and soak up the autumn vibes. This color is also perfect for a seasonal outdoor quilt or embroidery hoop art."
	},
	"SOLID_COLOR_HONEY": {
		"name": "Honey",
		"description": "Our Cotton Solid in Honey looks like we broke off a chunk of dripping honeycomb and ran it through a fabric press. A perfect accent for our Buttercup color, but can stand on its own for projects that need a grounded backing."
	},
	"SOLID_COLOR_BUTTERCUP": {
		"name": "Buttercup",
		"description": "Build me up, Buttercup! Our Cotton Solid in Buttercup is like a breath of fresh air. Reminscent of blooms in the Springtime, this fabric would be perfectly suited for a pinnafore dress, a baby blanket, or a dapper bowtie."
	},
	"SOLID_COLOR_LEMON_LIME": {
		"name": "Lemon Lime",
		"description": "When life gives you lemons... add a splash of lime! Our Cotton Solid in Lemon Lime is the perfect mix of a bold yellow and a bright green. All the summer citrus vibes you need blended into one delicious margarit... we mean, color. Perfect for summertime quilts, gender-neutral decor, and kid's clothing."
	},
	"SOLID_COLOR_MARIGOLD": {
		"name": "Marigold",
		"description": "Fun fact: some species of marigold flowers are actually quite tasty in a salad! Our Cotton Solid in Marigold is not edible, but it looks good enough to eat. If you need a saturated yellow-orange fabric for your quilting or crafting projects, you can't go wrong here."
	},
	"SOLID_COLOR_CARROT": {
		"name": "Carrot",
		"description": "Our Cotton Solid in Carrot is a deep orange fabric that brings about feelings of energy, warmth, and happiness. Whether you're sewing a seasonal autumn quilt, making stuffed veggie toys for your favorite little chef, or creating a fierce tiger costume, this color is sure to please."
	},
	"SOLID_COLOR_DESERT_SUN": {
		"name": "Desert Sun",
		"description": "It is not a mirage, our Cotton Solid in Desert Sun is as warm as the desert sun itself. This mid-tone, earthy orange-brown fabric is mature and inviting. A perfect pick for a wrap skirt accent or cuddly plush."
	},
	"SOLID_COLOR_WINE": {
		"name": "Wine",
		"description": "Rich and deep, our Cotton Solid in Wine is a beautiful red-purple. It is a warm and highly sophisticated hue, which is perfect for headbands, seasonal crafts, or coasters to camouflage your spilled Bordeaux."
	},
	"SOLID_COLOR_POPPY_RED": {
		"name": "Poppy Red",
		"description": "A bouquet of Poppies always makes a statement, as will our Cotton Solid in Poppy Red fabric. This is brilliant shade of red that is both elegant and exciting. Whether you are making a bright summer dress, pillows for your porch, or a trendy scrunchy, you’re sure to turn heads."
	},
	"SOLID_COLOR_BUBBLE_GUM": {
		"name": "Bubble Gum",
		"description": "Youthful and bright, our Cotton Solid in Bubble Gum takes you right back to those adolescent years. The cheerful pink hue is uplifting, and playful which makes it a great choice for children's dresses and zipper pouches to throw in your beach bag."
	},
	"SOLID_COLOR_BERRY": {
		"name": "Berry",
		"description": "Historically, precious gemstones have been used to signify wealth, status and royalty. Why not add a little glam to your life with our richly saturated Cotton Solid in Berry? This jewel-toned fabric will enliven your wardrobe or provide a pop of color to your decor. Pair with neutrals like Honey and Natural or keep things moody with Navy and Emerald."
	},
	"SOLID_COLOR_PEONY": {
		"name": "Peony",
		"description": "Our Cotton Solid in Peony is a showstopper pink full of saturated summer joy. Whether you're sewing a summer skirt, making a cosmetic pouch, or crafting a fabric bouquet, this color is sure to please."
	},
	"SOLID_COLOR_COTTON_CANDY": {
		"name": "Cotton Candy",
		"description": "A visual sugar-rush, our Cotton Solid in Cotton Candy is a light, bright pink as fun as the sunny carnival atmosphere it evokes. This is the perfect shade for plush toys and pastel springtime apparel."
	},
	"SOLID_COLOR_BLUSH": {
		"name": "Blush",
		"description": "Is there anything more lovely than a dusty pink? Our Cotton Solid in Blush calls to mind romatic florals, broken-in ballet slippers, and wind-kissed cheeks. This fabric would be appropriate for a flirty blouse, a throw pillow in a young child's room, or lining for a formal evening coat."
	},
	"SOLID_COLOR_WATERMELON": {
		"name": "Watermelon",
		"description": "A color as sweet as the fruit it's named after! Our Cotton Solid in watermelon is an muted pink tone that is sure to bring sweet summer vibes all year long. From fabric flowers to baby shower gifts, this melon won't disappoint."
	},
	"SOLID_COLOR_CORAL": {
		"name": "Coral",
		"description": "Dive in and warm up with our vibrant Cotton Solid in Coral. With a hint of red and orange, this color is sure to evoke thoughts of breezy tropical getaways. Make fun unique coasters for your space, add a pop for the lining of a tote bag, or create insulated beverage holders to keep your drinks cool and frosty!"
	},
	"SOLID_COLOR_PEACH": {
		"name": "Peach",
		"description": "Summertime calls for a fresh juicy peach! Our pinkish pale yellow Cotton Solid in Peach is sweet to pair with vibrant shades like Coral and Bubble Gum. This color adds playful vibes to all sorts of summer apparel projects from culottes or a pop of color on a shirt placket."
	},
	"SOLID_COLOR_LILAC": {
		"name": "Lilac",
		"description": "A shade so synonymous with Springtime blooms, you can practically smell it! Our Cotton Solid in Lilac is light and sweet, perfect for lining a handbag, a sweet smocked dress, or napkins for your picnic basket!"
	},
	"SOLID_COLOR_ORCHID": {
		"name": "Orchid",
		"description": "A mid-toned purple that is equal parts summer sunset and fragrant flower, our Cotton Solid in Orchid makes a pleasant addition to any boquet of fabrics in your creations. Need a flowing and majestic wizard robe for your costuming project or beautiful blooms for a scrapbook? Look no further."
	},
	"SOLID_COLOR_GRAPE": {
		"name": "Grape",
		"description": "Long associated with royalty and wealth, our Cotton Solid in Grape is a true purple, as rich as the emperors who wore it. Pair this vibrant hue with Marigold or Lemon Lime for game-day accessories and apparel, or with our Cotton Candy to create a magical plush unicorn."
	},
	"SOLID_COLOR_PLUM": {
		"name": "Plum",
		"description": "Our dark, mysterious and moody Cotton Solid in Plum can easily add a bit of luxury or sophistication to any project like sunglass cases, seasonal holiday crafts (soft Halloween toys, reusable treat bags!), or unique table runners for your favorite board game."
	},
	"SOLID_COLOR_NAVY": {
		"name": "Navy",
		"description": "Truly a classic. Our Cotton Solid in Navy evokes feelings of strength and importance. Commonly used within nautical aesthetics, this color would be perfect for a little sailor's bedroom, classic beach home decor, or a striped sailing top. No matter the creation, this color will never go out of style."
	},
	"SOLID_COLOR_COBALT": {
		"name": "Cobalt",
		"description": "Number 27 on the periodic table, but number 1 in our hearts, our Cotton Solid in Cobalt is a royal blue that rings true to the mineral it was named after. Perfect for beach bags, apparel accents and quilting projects that need a classic touch."
	},
	"SOLID_COLOR_BLUEBELL": {
		"name": "Bluebell",
		"description": "Our Cotton Solid in Bluebell is a medium-toned, striking blue fabric. The hue was named for Bluebell flowers, which symbolize gratitude and humility. This joyful blue is perfect for quilts, baby blankets, or tote bags."
	},
	"SOLID_COLOR_SKY_BLUE": {
		"name": "Sky Blue",
		"description": "Evoking feelings of relaxation and happiness, our Cotton Solid in Sky Blue is a perfect addition to any project. Whether you are sewing summer wedding accessories or a simple reusable lunch bag for camp, this gentle blue hue is sure to please."
	},
	"SOLID_COLOR_FOG": {
		"name": "Fog",
		"description": "Put on a sweatshirt! This misty blue is reminiscent of a cold morning. Our Cotton Solid in Fog can be a soothing coordinate to a busy quilted pattern, playful as the lining of a zipper pouch, or a soft pillowcase."
	},
	"SOLID_COLOR_SLATE": {
		"name": "Slate",
		"description": "Watch out for falling rock! Our Cotton Solid in Slate is a cool tone named after the rock. As a darker blue-green hybrid that emulates a rock face after a fierce rain, slate plays wonderfully with almost every other hue."
	},
	"SOLID_COLOR_PINE": {
		"name": "Pine",
		"description": "Calling all nature lovers: Our Cotton Solid in Pine is perfect for your landscape-inspired project. This cool green is a grounded shade for fall apparel or quilting."
	},
	"SOLID_COLOR_EMERALD": {
		"name": "Emerald",
		"description": "Bling bling! Our Cotton Solid in Emerald is named for the precious gemstone. This rich color is a fabulous jewel-tone that coordinates well with neutrals as well as other shades of green. Emerald is a classic color that can symbolize refinement, wealth and royalty."
	},
	"SOLID_COLOR_KELLY_GREEN": {
		"name": "Kelly Green",
		"description": "Our Cotton Solid in Kelly Green is a vibrant and youthful shade. The true middle green that Kelly Green is evokes a lush Irish landscape and brings about feelings of renewal. This color is sure to add a bright punch to any project."
	},
	"SOLID_COLOR_GRASS": {
		"name": "Grass",
		"description": "Although you might be tempted, there is no need to water our Cotton Solid in Grass. This green can help balance or promote a sense of calm and clarity, and is an excellent choice for quilting and craft projects."
	},
	"SOLID_COLOR_LIME": {
		"name": "Lime",
		"description": "Our Cotton Solid in Lime is so citrusy, you can almost smell its namesake fruit. This vibrant shade is an excellent choice for projects that need an injection of color, gender neutral baby toys, and toddler shirts that stand out on a playground. Lime is a favorite for kids and kids-at-heart alike."
	},
	"SOLID_COLOR_HONEYDEW": {
		"name": "Honeydew",
		"description": "Our Cotton Solid in Honeydew is the perfect alternative to the bright pinks, oranges, and yellows traditionally associated with the summertime. For a fun twist, try Honeydew paired with a muted pastel like Buttercup or Lilac."
	},
	"SOLID_COLOR_SAGE": {
		"name": "Sage",
		"description": "Create a cleansing feeling of spa-like serenity with our Cotton Solid in Sage. Pairse well with earthy tones like Desert Sun, Pine, or Slate for a perfect pair of paperboy shorts, home decor accent, or quilted throw for your guest room."
	},
	"SOLID_COLOR_JADE": {
		"name": "Jade",
		"description": "Like the mineral it's named after, our Cotton Solid in Jade is a soothing and regal green that can bring a balance to your creations. Whether as a backing for a spring quilt, or in combination with our Cotton Solid in Marigold or Lemon Lime, this Jade has to be seen to be believed."
	},
	"SOLID_COLOR_PEACOCK": {
		"name": "Peacock",
		"description": "Just like its namesake bird, our Cotton Solid in Peacock is sure to make any project stand out! This deep jewel-toned blue will pop alongside warmer colors like Coral and Peach, but can also be calming when paired with other blues like Caribbean and Lagoon."
	},
	"SOLID_COLOR_CARIBBEAN": {
		"name": "Caribbean",
		"description": "Mimicking the crystal blue waters of its namesake sea, our Cotton Solid in Caribbean is energetic and bold, while still cool and refreshing. Add this contemporary color to your home with accent pillows for a beach vacation feel year-round."
	},
	"SOLID_COLOR_LAGOON": {
		"name": "Lagoon",
		"description": "The cool aqua of our Cotton Solid in Lagoon will add a bold splash of color to your home decor and craft projects. Like the bodies of water it is named after, Lagoon can be both cheerful and soothing. When used in an accent pillow or a quilt, it excels at being an expressive complement to more neutral surroundings."
	},
	"SOLID_COLOR_POOL": {
		"name": "Pool",
		"description": "You’ll always feel like you’re poolside if you surround yourself with our Cotton Solid in Pool! The cool sky blue color is sure to complement the rays from the hot summer sun as a custom beach bag or bucket hat."
	},
	"SOLID_COLOR_SEA_GLASS": {
		"name": "Sea Glass",
		"description": "A soft blue with a slight hint of green, our Cotton Solid in Sea Glass will transport you right to the ocean! Inspired by the highly coveted pieces of weathered glass collected by beach combers, this fabric is perfect for home decor to add a tranquil, calm to any space."
	}
};