import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import Cookies from 'js-cookie';

import {authCookieName, JWTExpiredCode, JWTInvalidCode, jwtCookieName} from '../../../constants/APIGateway';
import selectApiGatewayHost from '../../../entities/pageSetup/apiHosts/selectors/selectApiGatewayHost';
import {selectPageLang, selectPageSetup} from '../../../entities/pageSetup/selectors';
import {selectUserCookies} from '../../../entities/user/selectors';
import logger from '../../../logger';
import {cookieStringify} from '../../../server/cookies/cookieStringify';
import {httpAgent, httpsAgent} from '../../../server/network/agents/keepalive';
import {translate} from '../../../services';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';


interface ServiceRequestOptions
  extends Pick<
      AxiosRequestConfig,
      | 'method'
      | 'timeout'
      | 'params'
      | 'headers'
      | 'httpAgent'
      | 'httpsAgent'
      | 'validateStatus'
      | 'withCredentials'
      | 'data'
    >,
    Required<Pick<AxiosRequestConfig, 'url'>> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ServiceRequestOptionsWithoutMethod extends Omit<ServiceRequestOptions, 'method'> {}

export enum HttpMethod {
  get = 'get',
  delete = 'delete',
  post = 'post',
  put = 'put',
  patch = 'patch',
}

type PostDataMethod = HttpMethod.post | HttpMethod.put | HttpMethod.patch;
type NoPostDateMethod = HttpMethod.get | HttpMethod.delete;

export type RequestOptions<RequestPostData> =
  | ServiceRequestOptions
  | (ServiceRequestOptionsWithoutMethod & { method: NoPostDateMethod })
  | (ServiceRequestOptionsWithoutMethod & { method: PostDataMethod } & { data: RequestPostData });

function request<ResponseData, RequestPostData = undefined>(
  state: State,
  options: RequestOptions<RequestPostData>,
  sendUuidHeader?: boolean,
  skipCache?: boolean,
): Promise<AxiosResponse<ResponseData>> {
  // eslint-disable-next-line no-async-promise-executor, complexity
  return new Promise(async(resolve, reject) => {
    const startTime = Date.now();
    const userCookies = selectUserCookies(state);

    options.headers = options.headers ?? {};
    options.method = options.method ?? 'GET';
    sendUuidHeader && (options.headers['X-Spoonflower-Window-UUID'] = selectPageSetup(state).uuid);
    options.params = {
      ...(!options.url?.match(/page_locale=/) && {
        page_locale: selectPageLang(state)
      }),
      ...options.params,
    };

    if (process.env.REACT_APP_IS_SERVER) {
      options.headers.Cookie = cookieStringify(userCookies);
      options.httpAgent = httpAgent;
      options.httpsAgent = httpsAgent;
      logger.trace('Request. Fetching', options.url, options.params);
    }

    if (options.url.includes(selectApiGatewayHost(state))) {
      if (process.env.REACT_APP_IS_SERVER) {
        options.headers.Cookie = cookieStringify({
          ...(userCookies[jwtCookieName] && {
            [jwtCookieName]: userCookies[jwtCookieName]
          }),
          ...(userCookies[authCookieName] && {
            [authCookieName]: userCookies[authCookieName]
          })
        });
      } else {
        // Check if the environment is development to conditionally comment out SSR-specific logic
        const isDevelopment = process.env.NODE_ENV !== 'production';

        if (isDevelopment) {
          console.warn('In CSR development mode.');
          // Uncomment the line below for SSR mode during development if needed
          // options.url = options.url.replace(selectApiGatewayHost(state), '/api-gateway');
        } else {
          options.url = options.url.replace(selectApiGatewayHost(state), '/api-gateway');
        }
      }
    }

    const handleError = (error: unknown): RequestError => {
      let requestError: RequestError;

      if (error instanceof RequestError) {
        requestError = error;
      } else {
        requestError = new RequestError(error, options.url, options, state);
      }
      logger.error(requestError);

      return requestError;
    };

    const {
      server: {apiResponses},
      apiCalled,
    } = state;

    // todo SP-10618 revisit update of the state using dispatch
    try {
      let response: AxiosResponse<ResponseData>;

      if (process.env.REACT_APP_IS_SERVER && !skipCache) {
        if (apiResponses[options.url]) {
          return resolve(apiResponses[options.url]);
        }
      }
      // eslint-disable-next-line prefer-const
      response = await axios.request<ResponseData>({
        withCredentials: true,
        ...options,
      });
      if (process.env.REACT_APP_IS_SERVER && !skipCache) {
        apiResponses[options.url] = response;
        apiCalled.push({
          url: options.url,
          params: options.params,
          time: Date.now() - startTime,
          code: response.status
        });
      }

      return resolve(response);
    } catch (error) {
      const {status, data} = error.response ?? {};

      if (status === 400 && data?.gatewayError === JWTInvalidCode) {
        if (process.env.REACT_APP_IS_SERVER) {
          state.flashMessage = {
            ...state.flashMessage,
            severity: 'error',
            message: translate('errors.EXPIRED_SESSION'),
            hops: 1,
          };
        } else {
          window.location.href = '/login';
        }
      }

      if (process.env.REACT_APP_IS_SERVER && status === 401 && data?.gatewayError === JWTExpiredCode) {
        return reject(new RequireRedirectError(error, '/login', 'JWT expired'));
      }

      if (!process.env.REACT_APP_IS_SERVER && status === 401 && data?.gatewayError === JWTExpiredCode) {
        // This is to ensure that each endpoint does not trigger a token refresh more than once
        window.refreshTokenAttempted = window.refreshTokenAttempted || {};
        if (window.refreshTokenAttempted[error.config.url]) {
          return reject(handleError(error));
        }
        window.refreshTokenAttempted[error.config.url] = true;

        return axios
          .request({
            ...options,
            params: {},
            method: 'GET',
            withCredentials: true,
            url: `${selectApiGatewayHost(state)}/refresh_token`,
          })
          .then(() =>
            axios.request<ResponseData>({
              withCredentials: true,
              ...options,
            })
          )
          .then((result) => {
            delete window.refreshTokenAttempted[error.config.url];

            return resolve(result);
          })
          .catch((error: AxiosError<{ redirect_url: string }>) => {
            if (error.response?.status === 401 && error.response?.data?.redirect_url) {
              logger.debug(`Refresh token request redirected to: ${error.response.data.redirect_url}`);
              window.location.href = error.response.data.redirect_url;
            }

            logger.debug(`Refresh token request failed with ${error}`);

            return reject(handleError(error));
          });
      }

      return reject(handleError(error));
    }
  });
}

export default request;
