import {FeatureFlagsAction} from '../actions/featureFlags';
import * as types from '../constants/ActionTypes';
import {FeatureFlagsState} from '../shapes/featureFlags';


function featureFlags(state = {} as FeatureFlagsState, action: FeatureFlagsAction): FeatureFlagsState {
  switch (action.type) {
    case types.FEATURE_FLAGS + types.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default featureFlags;
