// Shared functions for mapping dispatch to common props for containers.

import {modalOpen, modalClose} from '../actions/pageSetup';

export const openModalDispatcher = (dispatch) => (
  (name) => (
    dispatch(modalOpen({
      modal: {
        displayModal: true,
        modalName: name
      }
    }))
  )
);

export const closeModalDispatcher = (dispatch) => (
  () => (
    dispatch(modalClose({
      modal: {
        displayModal: false,
        modalName: ''
      }
    }))
  )
);
