module.exports = {
	"YARD": {
		"singular": "Yard",
		"plural": "Yards"
	},
	"METER": {
		"singular": "Meter",
		"plural": "Meters"
	},
	"FOOT": {
		"singular": "foot"
	},
	"ROLL": {
		"singular": "roll"
	},
	"priceInfo": "{price}/{unit}",
	"PRICE_PER_UNIT": {
		"yardMeasurement": "{price} / yard",
		"footMeasurement": "{price} / foot",
		"rollMeasurement": "{price} / roll",
		"meterMeasurement": "{price} / meter"
	}
};