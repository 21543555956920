/* eslint-disable max-lines */
import React from 'react';
import fetchWrap from 'fetch-wrap';
import middleware from 'fetch-wrap/middleware';
import update from 'immutability-helper';
import Cookies from 'js-cookie';
import fetchPonyfillLoader from 'fetch-ponyfill';
import Translate from 'i18n-react';
import {
  orderPendingReceived,
  orderSavedFetching,
  orderSavedReceived,
  orderPendingFetching,
  idMeReceived,
  idMeFail,
} from '../../actions/orders';
import {currentUserFail, currentUserFetching, currentUserReceived, fetchUserCollections} from '../../actions/user';
import {addToCartFetching, addToCartFail, addToCartReceived} from '../../actions/addToCart';
import {userStatsReceived} from '../../actions/userStats';
import {showFlashMessage} from '../../actions/flashMessage';
import {vote, flagAsInappropriate, reviewPosted, reviewEligibility} from '../../actions/reviews';
import {favoriteDesign, favoriteDesignFetching} from '../../actions/design';
import {colorCrossSellFailed, colorCrossSellFetching, colorCrossSellReceived} from '../../actions/crossSell';
import {
  wallpaperRecommendationPosted,
  wallpaperLandingRecommendationPosted,
  wallpaperRecommendationFetching,
  wallpaperRecommendationFail,
  wallpaperLandingRecommendationFail,
  wallpaperLandingRecommendationFetching,
} from '../../actions/wallpaperRecommendation';
import {orderItemFetching, orderItemReceived, orderItemFetchFail, orderItemDelete} from '../../actions/orderItems';
import {
  fayCollectionReceived,
  fayCollectionReceivedError,
  collectionsCreated,
  collectionsCreatedError,
  addedToCollection,
  addedToCollectionError,
  removedFromCollection,
  removedFromCollectionError,
  removedFromFavoriteCollections,
  removedFromFavoriteCollectionsError,
  addedToFavoriteCollections,
  addedToFavoriteCollectionsError,
  collectionsFetching,
} from '../../actions/userCollections';
import {MultiLocaleRouter, translate} from '../../services';
import {isUndefined, isNotUndefined, isEmpty, objectIsEmpty} from '../validation';
import {RequestError} from '../errors';
import {reloadPageWithoutShoppingParams} from '../url';
import {SOLID} from '../../constants/Codes';
import * as db from '../../constants/Database';
import {POSITIVE_FAVORITE_COUNT} from '../../constants/Design';
import * as fm from '../../constants/FlashMessages';
import {baseApiUrl, JWTExpiredCode, cookiePartialDomain, authCookieName} from '../../constants/APIGateway';
import {ORDER_ITEMS_FOR_ORDER_AT_LIMIT} from '../../constants/AlpenroseErrorCodes';
import browserHistoryWrapper from '../../components/Reusable/LinkWrapper/browserHistoryWrapper';
import LinkWrapper from '../../components/Reusable/LinkWrapper/LinkWrapper';
import type {UserResponse} from '../../entities/user/types';

const {fetch: fetchPonyfill} = fetchPonyfillLoader();

class FetchMiddleware {
  constructor() {
    this.refreshTokenAttempted = {};
  }

  get defaultOptions() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        page_locale: 'en', // window.pageLang
      },
    };
  }

  credentialedOptions(options) {
    return update(options, {
      $merge: {
        credentials: 'include',
        headers: {
          'X-Spoonflower-Window-UUID': window.uuid,
        },
      },
    });
  }

  updatePageLocale(endpoint) {
    const pageLocale = window.location.pathname.substring(1, 3);

    return endpoint.replace(/(page_locale=)(\w{2})/, `$1${pageLocale}`);
  }

  setStore(store) {
    this.store = store;
  }

  static statusCodeIs200Level(statusCode) {
    return statusCode >= 200 && statusCode < 300;
  }

  static promiseRejectionErrorFromSuccessResponse(response, message) {
    const error = new Error(message);

    error.body = response;
    error.status = 200;

    return error;
  }

  // decided not to migrate to our new axios instance
  handleResponseHeaders() {
    return (endpoint, options, originalFetch) =>
      originalFetch(endpoint, options).then((result) => {
        if (result.headers.map && result.headers.map['x-spoonflower-user-state-changed']) {
          // really strange to catch up to response header in tests
          checkoutFetchApplicationBootstrapData() // returned value is not handled, but inner fetches dispatch some data
            .then(() => {
              window.dataLayer.push(fetchMiddleware.store.getState().userStats.data_layer);
            });
        }

        return result;
      });
  }

  apiGateway() {
    return (endpoint, options, originalFetch) => {
      // Update the locale parameter
      endpoint = fetchMiddleware.updatePageLocale(endpoint);

      // Point requests to the API Gateway. If it is to refresh the JWT, use a different endpoint of the gateway to
      // bypass the authorizer lambda. If this function is called with a full URL, do not alter it.
      if (endpoint.match(/^https?:\/\//)) {
        return originalFetch(endpoint, options);
      }

      // TODO EN-2255 Non-hack `fetch` fix for alternate "resource" paths on the API Gateway
      let url;
      const endpointPrefixedWithScuppernong = endpoint.match(/^\/scuppernong/);

      if (endpointPrefixedWithScuppernong) {
        url = `/api-gateway${endpoint}`;
      } else if (endpoint.match(/^\/store-api/)) {
        url = endpoint;
      } else {
        url = `/api-gateway/alpenrose${endpoint}`;
      }
      const modifiedOptions = fetchMiddleware.credentialedOptions(options);

      return originalFetch(url, modifiedOptions);
    };
  }

  handleApiGatewayErrors() {
    return (url, options, wrappedFetch) =>
      wrappedFetch(url, options).catch((error) => {
        if (error.status === 401 && error.body.gatewayError === JWTExpiredCode) {
          // This is to ensure that each endpoint does not trigger a token refresh more than once
          if (this.refreshTokenAttempted[url]) {
            return Promise.reject(error);
          }
          this.refreshTokenAttempted[url] = true;

          // dead code because of old cookie unused for this codebase...
          const auth_cookie = Cookies.get(authCookieName);

          if (!isEmpty(auth_cookie)) {
            Cookies.set(authCookieName, auth_cookie, {
              domain: cookiePartialDomain,
              secure: true,
            });
          }
          // end of dead code

          return wrappedFetch(
            `${baseApiUrl}/refresh_token`,
            fetchMiddleware.credentialedOptions(fetchMiddleware.defaultOptions)
          )
            .then(() => wrappedFetch(url, options))
            .catch((error) => {
              if (error.status === 401 && error.body.errors.redirect_url) {
                window.location.href = error.body.errors.redirect_url;
              }

              return Promise.reject(error);
            });
        }

        return Promise.reject(error);
      });
  }

  provideResponseErrorData() {
    return (url, options, wrappedFetch) =>
      wrappedFetch(url, options).catch((error) =>
        // only works while running tests (due to this.store), when getting an error in real use, it just rejects with initialState
        Promise.reject(new RequestError(error, url, options, this.store ? this.store.getState() : {}))
      );
  }

  rebootstrapRedirectAndReject(url, error, flashMessage, targetComponent) {
    return checkoutFetchApplicationBootstrapData().then(() => {
      const action = showFlashMessage(fm.MESSAGE_SEVERITY_INFO, flashMessage, 1, targetComponent);

      this.store.dispatch(action);
      browserHistoryWrapper.push(url);
      error.breakChain = true;

      return Promise.reject(error);
    });
  }

  // During the checkout process, handle errors that have descriptive error codes. Determine any messages to show to
  // user and any redirects. Errors with no useful data should simply be rejected to be passed through to the next catch function.
  // TODO EN-813 be specific about the error (e.g. no items!,) one day
  catchCheckoutFetchRequestFailure() {
    return (error) => {
      if ((error.status === 405 || error.status === 500) && isNotUndefined(error.body.errors)) {
        let redirectUrl = MultiLocaleRouter.localePathname('cart');
        const errorCode = error.body.errors[0].code[0];

        switch (errorCode) {
          case 'EXCHANGE_RATE_CHANGED':
          case 'VAT_RATE_CHANGED':
          case 'STRIPE_CUSTOMER_REQUEST_BY_GUEST_USER_NOT_ALLOWED':
          case 'CANNOT_APPLY_SPOONDOLLARS_CONTAINS_SUBSCRIPTION_ITEM':
          case 'CANNOT_PAY_WITH_PAYPAL_CONTAINS_SUBSCRIPTION_ITEM':
          case 'CANNOT_PAY_WITH_UNSAVED_CREDIT_CARD_CONTAINS_SUBSCRIPTION_ITEM':
          case 'STRIPE_CUSTOMER_ACCOUNT_ALREADY_EXISTS':
          case 'STRIPE_CUSTOMER_TECHNICAL_FAILURE':
            // TODO: EN-1177 - want to set errorCode to be PAYMENT_TECHNICAL_FAILURE for STRIPE_CUSTOMER_ERRORS
            redirectUrl = MultiLocaleRouter.localePathname('checkout.payment');
            break;
          default:
            // TODO: EN-1177 - shouldn't be redirecting on default
            redirectUrl = MultiLocaleRouter.localePathname('cart');
        }

        return this.rebootstrapRedirectAndReject(redirectUrl, error, translate(`errors.${errorCode}`));
      }

      return Promise.reject(error);
    };
  }

  // todo: fix this it is use in axios
  catchUnhandledFetchRequestFailureAndShowFlash() {
    return (error) => {
      if (!FetchMiddleware.statusCodeIs200Level(error.status)) {
        if (error.status === 400 && isNotUndefined(error.body.errors)) {
          this.store.dispatch(
            showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate(`errors.${error.body.errors[0].code}`))
          );
        } else if (!error.breakChain) {
          this.store &&
            this.store.dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate('errors.SERVER_ERROR')));
        }
      }

      return Promise.reject(error);
    };
  }
}

export const fetchMiddleware = new FetchMiddleware();

// fetchWrap applies middleware sequentially for preparing a request, and in reverse order for handling a request.
// Not sure at the time of writing this if it is intended behavior. This could present bugs or unexpected results in
// future development, since error handling and appending of URLs, options, etc. happen in the other direction.
// Issue registered with package's repo:
// https://github.com/benjamine/fetch-wrap/issues/6
const requestPreparation = [
  middleware.optionsByUrlPattern([
    {
      for: '*',
      options: fetchMiddleware.defaultOptions,
    },
  ]),
  middleware.urlParams(),
  fetchMiddleware.apiGateway(),
  middleware.sendJSON(),
];
const responseHandling = [
  fetchMiddleware.handleResponseHeaders(),
  middleware.receiveJSON(),
  fetchMiddleware.handleApiGatewayErrors(),
  fetchMiddleware.provideResponseErrorData(),
];

const fetch = fetchWrap(fetchPonyfill, [...requestPreparation, ...responseHandling.reverse()]);

export default fetch;

/**
 * Fetchers
 */

export function checkoutFetchApplicationBootstrapData() {
  const store = fetchMiddleware.store;

  return fetchCurrentUser(store.dispatch).then(function(payload) {
    // Does the user lack a cookie identity, is unauthenticated?
    // this logic is now broken, because backend never responds with `undefined`, it is at least { message: 'text of the error' }
    if (isUndefined(payload) || Object.keys(payload).length === 0) {
      return;
    }
    const userId = payload.data.id;

    return Promise.all([
      // these two functions dispatch some data to store, but their results are not handled in any way
      fetchUserStats(store.dispatch, userId),
      fetchCartOrders(store.dispatch, payload.data.order_id_pending),
      fetchUserCollections(store.dispatch, userId),
    ]);
  });
}

export function fetchCartOrder(dispatch, orderId, orderDispatchFunction) {
  // NOTE: No checkoutFetch usage / no cart cleanup middleware because this is used by base application bootstrapping.
  return fetch(`/order/${orderId}`).then(function(data) {
    dispatch(orderDispatchFunction(data));

    return data;
  });
}

function fetchCartOrders(dispatch, pendingOrderId, savedOrderId) {
  dispatch(orderPendingFetching());
  const pendingFetch = isUndefined(pendingOrderId) ?
    Promise.resolve() :
    fetchCartOrder(dispatch, pendingOrderId, orderPendingReceived);

  if (savedOrderId) {
    fetchSavedOrder(dispatch, savedOrderId);
  }

  return pendingFetch;
}

export function fetchCartOrderWithIdMePromoApplied(dispatch, orderId, idMeToken) {
  const fetchOptions = {
    method: 'PATCH',
  };

  return fetch(`/order/${orderId}/id_me/${idMeToken}`, fetchOptions)
    .then((data) => {
      dispatch(idMeReceived(data));
    })
    .catch(() => {
      dispatch(idMeFail());
      dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate('errors.ID_ME_VERIFICATION_FAILED')));
    });
}

export function fetchSavedOrder(dispatch, orderId) {
  dispatch(orderSavedFetching());
  fetchCartOrder(dispatch, orderId, orderSavedReceived).catch(() => {
    dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate('errors.ORDER_SAVED_TIMEOUT_ERROR')));
  });
}

export function fetchCurrentUser(dispatch, updatedPreferences, reloadPage = false) {
  dispatch(currentUserFetching());

  const fetchOptions = !objectIsEmpty(updatedPreferences) ?
    {
      method: 'PATCH',
      body: updatedPreferences,
    } :
    {};

  return fetch('/user/me', fetchOptions)
    .then((data: { data: UserResponse }) => {
      dispatch(currentUserReceived(data));

      if (reloadPage) {
        reloadPageWithoutShoppingParams();

        return data;
      }

      return data;
    })
    .catch(fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash())
    .catch((error) => {
      dispatch(currentUserFail());

      return Promise.reject(error);
    });
}

// This is only used to create a new order item when one does not exist.
export function fetchAddToCart(
  dispatch,
  fetchOptions = {},
  fetchUrl,
  isGuest = false
) {
  dispatch(addToCartFetching());

  fetchOptions = {
    ...fetchOptions,
    credentials: 'include'
  };

  const fetchOrderItem = () =>
    fetch(fetchUrl, fetchOptions)
      .then((data) => {
        dispatch(addToCartReceived(data));
        dispatch(orderItemReceived(data));

        return data;
      })
      .catch(catchAddToCartFetchFailure(dispatch, isGuest))
      .catch((error) => {
        dispatch(addToCartFail());

        return Promise.reject(error);
      });

  return fetchOrderItem();
}

function catchAddToCartFetchFailure(dispatch, isGuest) {
  return (error) => {
    // eslint-disable-next-line no-console
    console.log(error);
    if (isNotUndefined(error.body) && isNotUndefined(error.body.errors)) {
      const orderItemLimitError = error.body.errors.find((errorObj) =>
        errorObj.code.includes(ORDER_ITEMS_FOR_ORDER_AT_LIMIT)
      );

      if (isNotUndefined(orderItemLimitError)) {
        const getFlashMessage = () => (
          <>
            {translate(`errors.${ORDER_ITEMS_FOR_ORDER_AT_LIMIT}`)}{' '}
            {isGuest ? (
              <>
                {translate('help.orderItemLimit.guest')}{' '}
                <LinkWrapper
                  hrefValue={Translate.translate('xurls.header.join')}
                  className='link-button x-dark'
                  contents={translate('help.orderItemLimit.guestJoin')}
                />
                .
              </>
            ) : (
              translate('help.orderItemLimit.user')
            )}
          </>
        );

        return dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, getFlashMessage()));
      }
    }

    return fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash()(error);
  };
}

function catchOrderItemFetchFailure(dispatch, orderItemId) {
  return (error) => {
    dispatch(orderItemFetchFail(orderItemId));

    if (isNotUndefined(error.body) && isNotUndefined(error.body.errors)) {
      const savedOrderFullError = error.body.errors.find((errorObj) =>
        errorObj.code.includes(ORDER_ITEMS_FOR_ORDER_AT_LIMIT)
      );

      if (isNotUndefined(savedOrderFullError)) {
        return dispatch(
          showFlashMessage(fm.MESSAGE_SEVERITY_INFO, translate(`errors.${ORDER_ITEMS_FOR_ORDER_AT_LIMIT}`))
        );
      }
    }

    return fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash()(error);
  };
}

export function fetchOrderItemRemove(dispatch, orderItemId, orderId = null, orderStatus = db.ORDER_STATUS_SAVED) {
  const fetchOptions = {
    method: 'DELETE',
  };

  return fetchOrderItemUpdate(dispatch, orderItemId, orderId, orderStatus, fetchOptions);
}

export function fetchOrderItemChangeQuantity(
  dispatch,
  orderItemId,
  quantity,
  chunk_size,
  orderId = null,
  orderStatus = db.ORDER_STATUS_SAVED
) {
  const fetchOptions = {
    method: 'PATCH',
    body: {
      quantity,
      chunk_size,
    },
  };

  return fetchOrderItemUpdate(dispatch, orderItemId, orderId, orderStatus, fetchOptions);
}

function fetchOrderItemUpdate(dispatch, orderItemId, orderId, orderStatus, fetchOptions) {
  dispatch(orderItemFetching(orderItemId));

  const orderDispatchFunction = orderStatus === db.ORDER_STATUS_SAVED ? orderSavedReceived : orderPendingReceived;

  return fetch(`/order_item/${orderItemId}`, fetchOptions)
    .then(function(data) {
      if (fetchOptions.method === 'DELETE') {
        data = orderItemDelete(orderItemId);
      } else {
        dispatch(orderItemReceived(data));
      }

      return isEmpty(orderId) ? dispatch(data) : fetchCartOrder(dispatch, orderId, orderDispatchFunction);
    })
    .catch(catchOrderItemFetchFailure(dispatch, orderItemId));
}

export function fetchOrderItemToggleSavedState(dispatch, orderItem) {
  dispatch(orderItemFetching(orderItem.id));

  const fetchOptions = {
    method: 'PATCH',
    body: {
      saved: !orderItem.saved,
    },
  };

  fetch(`/order_item/${orderItem.id}`, fetchOptions)
    .then(function(data) {
      dispatch(orderItemDelete(data.data.id));
    })
    .catch(catchOrderItemFetchFailure(dispatch, orderItem.id));
}

export function fetchUserStats(dispatch, userId) {
  let url = `/user/${userId}/user_stats`;

  // eslint-disable-next-line no-useless-escape
  const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)s\s*\=\s*([^;]*).*$)|^.*$/, '$1');

  if (typeof cookieValue !== 'undefined') {
    url = `${url}/${cookieValue}`;
  }

  return fetch(url).then(function(data) {
    dispatch(userStatsReceived(data));

    return data;
  });
}

export function fetchUserConsents(fetchOptions, callback, failCallback, queryString = '') {
  return fetch(`/consent${queryString}`, fetchOptions)
    .then((data) => {
      callback(data);

      return data;
    })
    .catch((error) => {
      failCallback(error);

      return Promise.reject(error);
    });
}

export function fetchFavoriteState(dispatch, designId, favoriteCountChange) {
  const fetchMethod = favoriteCountChange === POSITIVE_FAVORITE_COUNT ? 'POST' : 'DELETE';
  const fetchOptions = {
    method: fetchMethod,
    body: {
      favoriteCountChange,
    },
  };

  dispatch(favoriteDesignFetching());

  return fetch(`/design/${designId}/favorite`, fetchOptions).then(() => {
    dispatch(favoriteDesign(favoriteCountChange, designId)); // dispatch an additional action that would push/pop id userFavoriteIds
  });
}

export function fetchPostedWallpaperRecommendation(
  dispatch,
  queryParams,
  wallHeight,
  wallWidths,
  isCalculatorModal,
  wallpaperType
) {
  isCalculatorModal ? dispatch(wallpaperRecommendationFetching()) : dispatch(wallpaperLandingRecommendationFetching());

  const fetchOptions = {
    method: 'POST',
    body: {
      height: wallHeight,
      widths: wallWidths,
    },
  };

  const wallpaperSpecificQuery = `/wallpapers/prices/calculate/${wallpaperType}?${queryParams}`;
  const nonSpecificQuery = `/wallpapers/prices/calculate?${queryParams}`;
  const query = isNotUndefined(wallpaperType) ? wallpaperSpecificQuery : nonSpecificQuery;

  return fetch(query, fetchOptions)
    .then((data) => {
      isCalculatorModal ?
        dispatch(wallpaperRecommendationPosted(data)) :
        dispatch(wallpaperLandingRecommendationPosted(data));

      return data;
    })
    .catch(fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash())
    .catch((error) => {
      isCalculatorModal ? dispatch(wallpaperRecommendationFail()) : dispatch(wallpaperLandingRecommendationFail());

      return Promise.reject(error);
    });
}

export function fetchVotes(dispatch, designId, reviewId, voteDirection) {
  return fetchReviewsPatch(dispatch, designId, reviewId, voteDirection, vote);
}

export function fetchFlag(dispatch, designId, reviewId) {
  return fetchReviewsPatch(dispatch, designId, reviewId, 'flag', flagAsInappropriate);
}

function fetchReviewsPatch(dispatch, designId, reviewId, action, callback) {
  const fetchOptions = {
    method: 'PATCH',
    body: {
      review_id: reviewId,
      review_action: action,
    },
  };

  return fetch(`/reviews/design/${designId}`, fetchOptions).then((data) => {
    dispatch(callback(data));

    return data;
  });
}

export function fetchPostedReview(dispatch, designId, review) {
  const photos = isNotUndefined(review.photos) ? review.photos.filter((p) => p !== null) : {};
  const reviewAnswers = [
    review.material_received,
    review.material_rating,
    review.print_rating,
    review.project_selection,
    review.recommendation,
  ];
  const reviewDimensions = [].concat.apply([], reviewAnswers);
  const fetchOptions = {
    method: 'POST',
    body: {
      title: review.title,
      text: review.text,
      rating: review.rating,
      dimensions: reviewDimensions,
      photos,
    },
  };

  return fetch(`/reviews/design/${designId}`, fetchOptions)
    .then((data) => {
      dispatch(reviewPosted(data));

      return data;
    })
    .catch(fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash());
}

export function fetchReviewsEligibility(dispatch, designId) {
  const fetchOptions = {
    method: 'GET',
  };

  return fetch(`/reviews/design/${designId}/product`, fetchOptions)
    .then((data) => {
      dispatch(reviewEligibility(data));
    })
    .catch((error) => {
      if (error.status === 400 || error.status === 401) {
        const flashMessage = translate('reviews.reviews.reviewAllowanceInfo');

        dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_INFO, flashMessage));
      }

      return fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash();
    });
}

export const fetchColorCrossSellDesigns = (colorParam, fabricParam) => (dispatch, getState) => {
  dispatch(colorCrossSellFetching());

  const query = `/solids/${fabricParam}/${colorParam}/cross_sells`;

  return fetch(query)
    .then(function(data) {
      const state = getState();

      if (state.routingData.routesProps.productType === SOLID && state.searchParams.color === colorParam) {
        dispatch(colorCrossSellReceived(data));
      }

      return data;
    })
    .catch((error) => {
      dispatch(colorCrossSellFailed());

      return Promise.reject(error);
    });
};

export function createCollection(dispatch, userId, designId, collectionName, collectionDescription, showCollection) {
  const stringBool = showCollection.toString(); // TODO SP-8547: True booleans fail.
  const fetchOptions = {
    method: 'POST',
    body: {
      name: collectionName,
      show_collection: stringBool,
      description: collectionDescription,
      designer_only: 'false', // TODO SP-8547: True booleans fail. This value is corrected and returned by the backend.
      designs: [designId],
    },
  };

  return fetch('/collection', fetchOptions)
    .then((data) => {
      dispatch(collectionsCreated(data));
      dispatch(fetchUserCollections());

      return data;
    })
    .catch((error) => {
      dispatch(collectionsCreatedError(error));

      return Promise.reject(error);
    });
}

export function addToOrRemoveFromCollection(dispatch, collectionId, designId, removeFromCollection) {
  dispatch(collectionsFetching());

  if (removeFromCollection) {
    const fetchOptions = {
      method: 'DELETE',
      body: {
        designs: [designId],
      },
    };

    return fetch(`/collection/${collectionId}`, fetchOptions)
      .then((data) => {
        dispatch(removedFromCollection(data));
      })
      .catch((error) => {
        dispatch(removedFromCollectionError(error));

        return Promise.reject(error);
      });
  }

  const fetchOptions = {
    method: 'PATCH',
    body: {
      designs: [designId],
    },
  };

  return fetch(`/collection/${collectionId}`, fetchOptions)
    .then((data) => {
      dispatch(addedToCollection(data));
    })
    .catch((error) => {
      dispatch(addedToCollectionError(error));

      return Promise.reject(error);
    });
}

export function addToOrRemoveFromFavoriteCollections(dispatch, collectionId, removeFromFavoriteCollections) {
  if (removeFromFavoriteCollections) {
    const fetchOptions = {
      method: 'DELETE',
    };

    return fetch(`/collection/${collectionId}/favorite`, fetchOptions)
      .then((data) => {
        dispatch(removedFromFavoriteCollections(data));
      })
      .catch((error) => {
        dispatch(removedFromFavoriteCollectionsError(error));

        return Promise.reject(error);
      });
  }

  const fetchOptions = {
    method: 'POST',
  };

  return fetch(`/collection/${collectionId}/favorite`, fetchOptions)
    .then((data) => {
      dispatch(addedToFavoriteCollections(data));
    })
    .catch((error) => {
      dispatch(addedToFavoriteCollectionsError(error));

      return Promise.reject(error);
    });
}

// for Fill-A-Yard
export function fetchFayCollection(collectionId) {
  return function(dispatch) {
    const fetchOptions = {
      method: 'GET',
    };

    fetch(`/scuppernong/collections/${collectionId}`, fetchOptions)
      .then((data) => {
        dispatch(fayCollectionReceived(data));

        return data;
      })
      .catch((error) => {
        dispatch(fayCollectionReceivedError(error));

        return Promise.reject(error);
      });
  };
}
