// Cookies
export const FABRIC_CODE_COOKIE_NAME = 'fabricCodeCookie';
export const WALLPAPER_CODE_COOKIE_NAME = 'wallpaperCodeCookie';

export const TEST_COLOR_UI_COOKIE_NAME = 'color_test';
export const TEST_COLOR_UI_GROUP_B = 'color_test_b';

export const ONE_TRUST_REF_COOKIE_NAME = 'exitintel_ref';

export const ATTENTIVE_REF_COOKIE_NAME = '__attentive_ss_referrer';
export const ATTENTIVE_REF_MAX_LENGTH = 150;

export const NEWSLETTER_SUBSCRIPTION_UID = 'newsletterSubscriptionUid';
