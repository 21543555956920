/* eslint-disable max-lines */

import Translate from 'i18n-react';

import {keyColorModifier} from '../../constants/Navigation';
import {translate} from '../../services';
import {isEmpty} from '../validation';


interface MenuItemOld {
  text: string;
  href: string;
  apiValue: string;
}

interface TreeBranchOld {
  [key: string]: string | TreeBranchOld | MenuItemOld;
}

interface NavigationOld {
  [key: string]: {
    [key: string]: unknown;
  };
}

export const navigationAccount = (userName: string, userId: number, userDesignOwner: boolean, isMobileMenu = false): NavigationOld => {
  const designLibraryShop = userDesignOwner ? {
    designLibrary: {
      text: translate('header.myStudio.designLibrary'),
      href: Translate.translate('xurls.header.myStudio.designLibrary')
    },
    myShop: {
      text: translate('header.myStudio.myShop'),
      href: Translate.translate('xurls.userProfile',
        {
          userName
        })
    }
  } : null;

  const removeUploadBtnIfMobile = !isMobileMenu ? {
    uploadYourDesign: {
      text: translate('header.myStudio.uploadYourDesign'),
      href: Translate.translate('xurls.header.myStudio.uploadYourDesign')
    }
  } : null;

  return {
    myStudio: {
      text: 'myStudio',
      children: {
        studioHome: {
          text: translate('header.myStudio.myDashboard'),
          href: Translate.translate('xurls.header.myStudio.studioHome')
        },
        orders: {
          text: 'Orders',
          href: Translate.translate('xurls.header.myStudio.orders',
            {
              userId
            })
        },
        account: {
          text: translate('navigation.myAccount.text'),
          href: Translate.translate('xurls.header.myStudio.account',
            {
              userId
            })
        },
        favorites: {
          text: translate('header.myStudio.favorites'),
          href: Translate.translate('xurls.header.myStudio.favorites',
            {
              userName
            })
        },
        collections: {
          text: translate('header.myStudio.collections'),
          href: Translate.translate('xurls.header.myStudio.collections',
            {
              userName
            })
        },
        ...designLibraryShop,
        ...removeUploadBtnIfMobile,
        notifications: {
          text: translate('header.myStudio.notifications'),
          href: Translate.translate('xurls.header.myStudio.notifications',
            {
              userId
            })
        },
        messages: {
          text: translate('header.myStudio.messages'),
          href: Translate.translate('xurls.header.myStudio.messages')
        },
        MyDesignChallenges: {
          text: translate('header.myStudio.myDesignChallenges'),
          href: Translate.translate('xurls.header.myStudio.myDesignChallenges',
            {
              userId
            })
        },
        logOut: {
          text: translate('navigation.logOut'),
          href: Translate.translate('xurls.logout')
        }
      }
    }
  };
};

export const translatingMenuItemOld = (translationKey: string): MenuItemOld => ({
  text: translate(`navigation.${keyColorModifier(translationKey)}.text`),
  href: translate(`navigation.${translationKey}.href`),
  apiValue: keyColorModifier(translationKey)
});

export const translatingMenuItemArrayOld = (arrayOfTextKeys: string[]): { [key: string]: MenuItemOld } => {
  const translatedMenu: { [key: string]: MenuItemOld } = {};

  for (const translationKey of arrayOfTextKeys) {
    if (!isEmpty(translationKey)) {
      translatedMenu[translationKey] = translatingMenuItemOld(translationKey);
    }
  }

  return translatedMenu;
};

export const navigationByCategories = (): TreeBranchOld => (
  {
    text: translate('navigation.byCategoriesMenu.text'),
    children: {
      topAndTrendingMenu: {
        text: translate('navigation.topAndTrending.text'),
        children: translatingMenuItemArrayOld(['allTrending', 'artNouveau', 'babyBoy', 'beach', 'bicycle', 'birds', 'blackAndWhite', 'border', 'butterfly', 'cat', 'chevron',
          'damask', 'deer', 'dog', 'elephant', 'feathers', 'fish', 'floral', 'forest', 'fox', 'fruit', 'geek', 'geometric', 'horse', 'summerIceCream', 'ikat', 'japanese',
          'kawaii', 'kitchen', 'map', 'mapleLeaf', 'mermaid', 'mudcloth', 'nautical', 'ocean', 'owl', 'paisley', 'peacock', 'rainbow', 'retro', 'science', 'skull',
          'space', 'stars', 'steampunk', 'stripes', 'teaTowel', 'tiki', 'toile', 'victorian', 'watercolor', 'allWoodland', 'yellow'])
      },
      styleMenu: {
        text: translate('navigation.style.text'),
        children: {
          modernMenu: {
            text: translate('navigation.modern.text'),
            children: translatingMenuItemArrayOld(['allModern', 'abstract', 'arrows', 'artDeco', 'chevron', 'geometric', 'hipster', 'midCenturyModern', 'ikat', 'mod', 'ombre'])
          },
          traditionalMenu: {
            text: translate('navigation.traditional.text'),
            children: translatingMenuItemArrayOld(['allTraditional', 'check', 'chinoiserie', 'damask', 'dots', 'gingham', 'herringbone', 'houndstooth', 'paisley', 'plaid',
              'preppy', 'shabbyChic', 'stripes', 'toile', 'vintage'])
          },
          historicalMenu: {
            text: translate('navigation.historical.text'),
            children: translatingMenuItemArrayOld(['allHistorical', 'artDeco', 'artNouveau', 'gothic', 'medieval', 'midCenturyModern', 'popArt', 'renaissance', 'rococo', 'retro',
              'steampunk', 'victorian', 'vintage', '60s', '70s', '80s', '90s'])
          },
          nauticalMenu: {
            text: translate('navigation.nautical.text'),
            children: translatingMenuItemArrayOld(['allNautical', 'anchors', 'boats', 'buoys', 'knots', 'lighthouse', 'lobster', 'map', 'mermaid', 'octopus', 'shells', 'ship'])
          },
          artisticMenu: {
            text: translate('navigation.artistic.text'),
            children: translatingMenuItemArrayOld(['allArtistic', 'abstract', 'brushStroke', 'handDrawn', 'photography', 'sketches', 'splatterPaint', 'watercolor'])
          },
          shapesMenu: {
            text: translate('navigation.shapes.text'),
            children: translatingMenuItemArrayOld(['allShapes', 'arrows', 'circle', 'cross', 'dots', 'fleurDeLis', 'geometric', 'grid', 'diamond', 'hearts', 'hexagon',
              'honeycombPattern', 'pixel', 'quatrefoil', 'stars', 'trellis', 'triangles'])
          },
          textureMenu: {
            text: translate('navigation.texture.text'),
            children: translatingMenuItemArrayOld(['allTexture', 'birchBark', 'lace', 'linen', 'marble', 'shibori', 'stainedGlass', 'wood', 'woodgrain'])
          },
          geographyMenu: {
            text: translate('navigation.geography.text'),
            children: translatingMenuItemArrayOld(['allGeography', 'asian', 'chinese', 'scandinavian', 'mexican', 'paris', 'chinoiserie', 'indian', 'kawaii', 'african',
              'london', 'hawaiian', 'french', 'tropical', 'japanese', 'turkish'])
          },
          worldlyMenu: {
            text: translate('navigation.worldly.text'),
            children: translatingMenuItemArrayOld(['allWorldly', 'aztec', 'bohemian', 'celtic', 'ikat', 'mandala', 'mapleLeaf',
              'moroccan', 'mudcloth', 'nativeAmerican', 'persian', 'tribal'])
          }
        }
      },
      animalsMenu: {
        text: translate('navigation.animals.text'),
        children: {
          allAnimals: {
            text: translate('navigation.allAnimals.text'),
            href: translate('navigation.allAnimals.href')
          },
          woodlandMenu: {
            text: translate('navigation.woodlandAnimals.text'),
            children: translatingMenuItemArrayOld(['allWoodlandAnimals', 'bat', 'bear', 'deer', 'fox', 'hedgehog', 'moose', 'owl', 'rabbit', 'squirrel', 'stag', 'wolf'])
          },
          farmMenu: {
            text: translate('navigation.farm.text'),
            children: translatingMenuItemArrayOld(['allFarm', 'chicken', 'cows', 'donkey', 'ducks', 'equestrian', 'goat', 'hen', 'horse', 'llama', 'pig', 'sheep'])
          },
          petsMenu: {
            text: translate('navigation.pets.text'),
            children: translatingMenuItemArrayOld(['allPets', 'bird', 'cat', 'dog', 'fish', 'hamster', 'parrot', 'snake', 'spider'])
          },
          oceanMenu: {
            text: translate('navigation.ocean.text'),
            children: translatingMenuItemArrayOld(['allOcean', 'crab', 'dolphin', 'fish', 'jellyfish', 'koi', 'lobster',
              'octopus', 'penguin', 'seahorse', 'shark', 'starfish', 'whale'])
          },
          insectsMenu: {
            text: translate('navigation.insects.text'),
            children: translatingMenuItemArrayOld(['allInsects', 'bee', 'beetle', 'bug', 'butterfly', 'caterpillar', 'dragonfly', 'firefly', 'spider'])
          },
          birdsMenu: {
            text: translate('navigation.birds.text'),
            children: translatingMenuItemArrayOld(['allBirds', 'flamingo', 'hummingbird', 'owl', 'parrot', 'peacock', 'robin', 'sparrow', 'toucan'])
          },
          zooMenu: {
            text: translate('navigation.zoo.text'),
            children: translatingMenuItemArrayOld(['allZoo', 'bears', 'giraffe', 'hippo', 'llama', 'lions', 'monkey', 'panda', 'rhino', 'tigers', 'zebra'])
          },
          safariMenu: {
            text: translate('navigation.safari.text'),
            children: translatingMenuItemArrayOld(['allSafari', 'cheetah', 'elephant', 'giraffe', 'hippo', 'leopard', 'lions', 'monkey', 'rhino', 'sloth', 'tigers', 'zebra'])
          },
          dogBreedsMenu: {
            text: translate('navigation.dogBreeds.text'),
            children: translatingMenuItemArrayOld(['allDogBreeds', 'bulldog', 'corgi', 'dachshund', 'dalmatian', 'goldenRetriever', 'greyhound', 'poodle', 'pug', 'yorkie'])
          }
        }
      },
      natureMenu: {
        text: translate('navigation.nature.text'),
        children: {
          allNature: {
            text: translate('navigation.allNature.text'),
            href: translate('navigation.allNature.href')
          },
          botanicalMenu: {
            text: translate('navigation.botanical.text'),
            children: translatingMenuItemArrayOld(['allBotanical', 'cactus', 'dandelion', 'fauna', 'feathers', 'flora', 'flowers', 'fruit', 'leaves', 'mushroom', 'palm',
              'peony', 'poppy', 'rose', 'seeds', 'succulents', 'sunflower', 'thistles', 'trees'])
          },
          spaceMenu: {
            text: translate('navigation.space.text'),
            children: translatingMenuItemArrayOld(['allSpace', 'asteroids', 'astronaut', 'constellations', 'galaxy', 'moon', 'nebula', 'planets', 'sky', 'stars', 'sun', 'zodiac'])
          },
          weatherMenu: {
            text: translate('navigation.weather.text'),
            children: translatingMenuItemArrayOld(['allWeather', 'clouds', 'ice', 'lightning', 'rain', 'rainbow', 'snow', 'storm', 'sun', 'tropical', 'umbrella'])
          },
          birdsMenu: {
            text: translate('navigation.birds.text'),
            children: translatingMenuItemArrayOld(['allBirds', 'feathers', 'flamingo', 'hummingbird', 'nest', 'parrot', 'robin', 'sparrow', 'toucan'])
          },
          woodlandMenu: {
            text: translate('navigation.woodlandNature.text'),
            children: translatingMenuItemArrayOld(['allWoodlandNature', 'antler', 'bat', 'bear', 'birchBark', 'deer', 'fox', 'hedgehog', 'moose', 'mushroom', 'owl',
              'rabbit', 'squirrel', 'stag', 'trees', 'wolf'])
          },
          seaMenu: {
            text: translate('navigation.sea.text'),
            children: translatingMenuItemArrayOld(['allSea', 'coral', 'island', 'ocean', 'sand', 'seaweed', 'starfish', 'surf', 'waves'])
          },
          outdoorsMenu: {
            text: translate('navigation.outdoors.text'),
            children: translatingMenuItemArrayOld(['allOutdoors', 'birch', 'campfire', 'camping', 'desert', 'forest',
              'jungle', 'lake', 'mountain', 'smores', 'teepee', 'tents', 'trees'])
          }
        }
      },
      noveltyMenu: {
        text: translate('navigation.novelty.text'),
        children: {
          allNovelty: {
            text: translate('navigation.allNovelty.text'),
            href: translate('navigation.allNovelty.href')
          },
          foodAndBeverageMenu: {
            text: translate('navigation.foodAndBeverage.text'),
            children: translatingMenuItemArrayOld(['allFood', 'banana', 'candy', 'coffee', 'cookies', 'donuts', 'fruit', 'iceCream', 'lemon', 'pineapple', 'pizza', 'popcorn',
              'popsicle', 'sprinkles', 'strawberry', 'sushi', 'watermelon'])
          },
          musicMenu: {
            text: translate('navigation.music.text'),
            children: translatingMenuItemArrayOld(['allMusic', 'drums', 'guitar', 'jazz', 'musicNotes', 'piano', 'radio', 'rockAndRoll', 'symphony', 'trumpets', 'violin'])
          },
          academiaMenu: {
            text: translate('navigation.academia.text'),
            children: translatingMenuItemArrayOld(['allAcademia', 'atomic', 'book', 'geek', 'math', 'school', 'science', 'teacher'])
          },
          sewingNotionsMenu: {
            text: translate('navigation.sewingNotions.text'),
            children: translatingMenuItemArrayOld(['allSewingNotions', 'knitting', 'sewingMachines', 'scissors', 'tapeMeasure', 'thimbles', 'thread'])
          },
          sportsMenu: {
            text: translate('navigation.sports.text'),
            children: translatingMenuItemArrayOld(['allSports', 'ballet', 'baseball', 'basketball', 'bicycle', 'equestrian',
              'fishing', 'football', 'golf', 'gymnastics', 'soccer', 'surfing', 'tennis'])
          },
          scaryMenu: {
            text: translate('navigation.scary.text'),
            children: translatingMenuItemArrayOld(['allScary', 'bat', 'ghost', 'gothic', 'monster', 'skull', 'steampunk', 'wolf', 'zombie'])
          },
          transportationMenu: {
            text: translate('navigation.transportation.text'),
            children: translatingMenuItemArrayOld(['allTransportation', 'airplanes', 'bicycles', 'boats', 'cars', 'construction', 'helicopters', 'hotAirBalloon', 'motorcycles',
              'retroCampers', 'rocketShip', 'spaceShip', 'ships', 'tractors', 'trucks'])
          },
        }
      },
      occasionMenu: {
        text: translate('navigation.occasion.text'),
        children: {
          babyAndKidsMenu: {
            text: translate('navigation.babyAndKids.text'),
            children: translatingMenuItemArrayOld(['allChildren', 'alphabet', 'babyBoy', 'babyGirl', 'ballerina', 'butterfly', 'cars', 'circus',
              'dinosaur', 'doll', 'dragon', 'fairy', 'nursery', 'pirate', 'princess', 'robot', 'tractors', 'trucks', 'unicorn'])
          },
          birthdayMenu: {
            text: translate('navigation.birthday.text'),
            children: translatingMenuItemArrayOld(['allBirthday', 'balloons', 'cake', 'confetti', 'cupcake', 'gifts', 'presents', 'picnic', 'wedding', 'graduation'])
          },
          picnic: translatingMenuItemOld('picnic'),
          wedding: translatingMenuItemOld('wedding'),
          graduation: translatingMenuItemOld('graduation')
        }
      },
      seasonalMenu: {
        text: translate('navigation.seasonal.text'),
        children: {
          allSeasonal: {
            text: translate('navigation.allSeasonal.text'),
            href: translate('navigation.allSeasonal.href')
          },
          winterMenu: {
            text: translate('navigation.winter.text'),
            children: translatingMenuItemArrayOld(['allWinter', 'antler', 'chanukah', 'christmas', 'holiday', 'holly', 'iceSkates', 'penguins', 'presents',
              'polarBears', 'santa', 'skiing', 'snow', 'stockings']),
          },
          springMenu: {
            text: translate('navigation.spring.text'),
            children: translatingMenuItemArrayOld(['allSpring', 'birds', 'bunny', 'crocus', 'daffodil', 'floral', 'flowers', 'rain', 'tulips', 'umbrella']),
          },
          summerMenu: {
            text: translate('navigation.summer.text'),
            children: translatingMenuItemArrayOld(['allSummer', 'beach', 'camping', 'floral', 'july4th', 'summerIceCream', 'lemonade', 'mermaid', 'popsicle', 'tiki', 'tropical']),
          },
          fallMenu: {
            text: translate('navigation.fall.text'),
            children: translatingMenuItemArrayOld(['allFall', 'apple', 'autumn', 'backpack', 'camping', 'cider', 'halloween',
              'leaves', 'pumpkins', 'school', 'skull', 'teacher', 'trees', 'woodlandFall']),
          },
          seasonalHolidaysMenu: {
            text: translate('navigation.seasonalHolidays.text'),
            children: translatingMenuItemArrayOld(['allSeasonal', 'hearts', 'menorah', 'pumpkins', 'santaClaus', 'skull',
              'spider', 'starsAndStripes', 'supernatural', 'witches', 'zombies']),
          }
        }
      },
      holidaysMenu: {
        text: translate('navigation.holidays.text'),
        children: {
          allHolidays: {
            text: translate('navigation.allHolidays.text'),
            href: translate('navigation.allHolidays.href')
          },
          christmasMenu: {
            text: translate('navigation.christmas.text'),
            children: translatingMenuItemArrayOld(['allChristmas', 'christmasTree', 'holly', 'pinecones', 'reindeer',
              'retroChristmas', 'santa', 'sled', 'sleigh', 'snow', 'snowmen', 'stocking']),
          },
          july4thMenu: {
            text: translate('navigation.july4th.text'),
            children: translatingMenuItemArrayOld(['allJuly4th', 'americanFlag', 'cookout', 'fireworks', 'hamburgers',
              'hotdogs', 'independenceDay', 'patriotic', 'picnic', 'redWhiteAndBlue', 'stars', 'stripes']),
          },
          valentinesDayMenu: {
            text: translate('navigation.valentinesDay.text'),
            children: translatingMenuItemArrayOld(['allValentinesDay', 'arrows', 'candyHearts', 'cupid', 'hearts', 'love']),
          },
          halloweenMenu: {
            text: translate('navigation.halloween.text'),
            children: translatingMenuItemArrayOld(['allHalloween', 'bat', 'blackCats', 'candy', 'ghost', 'jack-o-lantern',
              'pumpkin', 'scarecrow', 'skull', 'spiderWeb', 'sugarSkulls', 'vampire', 'witch', 'zombie']),
          }
        },
      },
      projectsMenu: {
        text: translate('navigation.projects.text'),
        children: translatingMenuItemArrayOld(['allProjects', 'bags', 'border', 'bunting', 'calendarTeaTowels', 'cheaterQuilts',
          'cutAndSew', 'pencilPouch', 'plushies', 'quilt', 'quiltLabels', 'stockings', 'teaTowels', 'zipperPouch']),
      }
    }
  }
);

export const navigationByColor = (): TreeBranchOld => (
  {
    text: translate('navigation.byColors.text'),
    children: {
      whiteMenu: {
        text: translate('navigation.whites.text'),
        children: translatingMenuItemArrayOld(['white', 'beige', 'cornsilk', 'ecru', 'eggshell', 'flax', 'ivory', 'linen', 'vanilla', 'whiteSmoke'])
      },
      greyMenu: {
        text: translate('navigation.greys.text'),
        children: translatingMenuItemArrayOld(['gray', 'grey', 'ashGray', 'battleshipGray', 'blueGray', 'charcoal', 'gunmetal', 'platinum', 'silver', 'slate', 'taupe'])
      },
      pinkMenu: {
        text: translate('navigation.pinks.text'),
        children: translatingMenuItemArrayOld(['pink', 'carnation', 'cerise', 'coral', 'cyclamen', 'fandango', 'fuschsia', 'hotPink', 'magenta', 'mauve', 'puce',
          'raspberry', 'rosePink', 'roseQuartz', 'ruby', 'salmonPink', 'shockingPink'])
      },
      redMenu: {
        text: translate('navigation.reds.text'),
        children: translatingMenuItemArrayOld(['red', 'auburn', 'burgundy', 'cardinal', 'claret', 'crimson', 'mahogany', 'maroon', 'marsala', 'oxblood', 'raspberry',
          'redwood', 'rosewood', 'ruby', 'rust', 'scarlet', 'terraCotta', 'vermilion'])
      },
      brownMenu: {
        text: translate('navigation.browns.text'),
        children: translatingMenuItemArrayOld(['brown', 'beige', 'bronze', 'buff', 'burntSienna', 'burntUmber', 'copper', 'desertSand', 'ecru', 'khaki', 'mahogany',
          'ochre', 'russet', 'rust', 'sand', 'sepia', 'sienna', 'tan', 'taupe', 'wheat'])
      },
      orangeMenu: {
        text: translate('navigation.oranges.text'),
        children: translatingMenuItemArrayOld(['orange', 'amber', 'apricot', 'coral', 'marigold', 'orangeRed', 'peach', 'salmon', 'tangerine'])
      },
      yellowMenu: {
        text: translate('navigation.yellows.text'),
        children: translatingMenuItemArrayOld(['yellow', 'citrine', 'gold', 'goldenrod', 'honey', 'jonquil', 'mustard', 'saffron'])
      },
      greenMenu: {
        text: translate('navigation.greens.text'),
        children: translatingMenuItemArrayOld(['green', 'artichoke', 'cladon', 'chartreuse', 'emerald', 'jade', 'kelly', 'mint'])
      },
      cyanMenu: {
        text: translate('navigation.cyans.text'),
        children: translatingMenuItemArrayOld(['cyan', 'aqua', 'aquamarine', 'cerulean', 'teal', 'turquoise', 'verdigris'])
      },
      blueMenu: {
        text: translate('navigation.blues.text'),
        children: translatingMenuItemArrayOld(['blue', 'azure', 'babyBlue', 'cobalt', 'cornflower', 'denim', 'indigo', 'midnight', 'navy', 'periwinkle',
          'sapphire', 'serenity', 'ultramarine'])
      },
      violetMenu: {
        text: translate('navigation.violets.text'),
        children: translatingMenuItemArrayOld(['violet', 'amethyst', 'aubergine', 'cerise', 'eggplant', 'fuchsia', 'indigo', 'lavender', 'lilac', 'magenta',
          'orchid', 'periwinkle', 'plum', 'purple', 'radiantOrchid'])
      },
      blackMenu: {
        text: translate('navigation.blacks.text'),
        children: translatingMenuItemArrayOld(['black', 'charcoal', 'ebony', 'midnight', 'noir', 'onyx'])
      }
    }
  }
);

export const navigationItems = (
  hideCategoriesColors: boolean,
  localeIsEnglish: boolean,
  isMobileNavigation: boolean,
  userName: string,
  userId: number,
  userDesignOwner: boolean,
  recentlyViewedUrl: string,
): NavigationOld => ({
  'inspiration-menu': {
    'text': 'Inspiration',
    'href': '/en/inspiration',
    'children': {
      'inspirationInspiration': {
        'text': 'Inspiration',
        'href': '/en/inspiration',
      },
      'byCategoriesMenu': {
        'text': 'By Categories',
        'children': {
          'topAndTrending': {
            'text': 'Top & Trending',
            'children': {
              'allTrending': {
                'text': 'All Trending',
                'href': '/en/shop?main=new&on=fabric&view=main',
              },
              'artNouveau': {
                'text': 'Art Nouveau',
                'href': '/en/shop/art-nouveau',
              },
              'babyBoy': {
                'text': 'Baby Boy',
                'href': '/en/shop/baby-boy',
              },
              'beach': {
                'text': 'Beach',
                'href': '/en/shop/beach',
              },
              'bicycle': {
                'text': 'Bicycle',
                'href': '/en/shop/bicycle',
              },
              'birds': {
                'text': 'Birds',
                'href': '/en/shop/birds',
              },
              'blackAndWhite': {
                'text': 'Black and White',
                'href': '/en/shop/black-and-white',
              },
              'border': {
                'text': 'Border',
                'href': '/en/shop/border',
              },
              'butterfly': {
                'text': 'Butterfly',
                'href': '/en/shop/butterfly',
              },
              'cat': {
                'text': 'Cat',
                'href': '/en/shop/cat',
              },
              'chevron': {
                'text': 'Chevron',
                'href': '/en/shop/chevron',
              },
              'damask': {
                'text': 'Damask',
                'href': '/en/shop/damask',
              },
              'deer': {
                'text': 'Deer',
                'href': '/en/shop/deer',
              },
              'dog': {
                'text': 'Dog',
                'href': '/en/shop/dog',
              },
              'elephant': {
                'text': 'Elephant',
                'href': '/en/shop/elephant',
              },
              'feathers': {
                'text': 'Feathers',
                'href': '/en/shop/feathers',
              },
              'fish': {
                'text': 'Fish',
                'href': '/en/shop/fish',
              },
              'floral': {
                'text': 'Floral',
                'href': '/en/shop/floral',
              },
              'forest': {
                'text': 'Forest',
                'href': '/en/shop/forest',
              },
              'fox': {
                'text': 'Fox',
                'href': '/en/shop/fox',
              },
              'fruit': {
                'text': 'Fruit',
                'href': '/en/shop/fruit',
              },
              'geek': {
                'text': 'Geek',
                'href': '/en/shop/geek',
              },
              'geometric': {
                'text': 'Geometric',
                'href': '/en/shop/geometric',
              },
              'horse': {
                'text': 'Horse',
                'href': '/en/shop/horse',
              },
              'iceCream': {
                'text': 'Ice Cream',
                'href': '/en/shop/icecream',
              },
              'ikat': {
                'text': 'Ikat',
                'href': '/en/shop/ikat',
              },
              'japanese': {
                'text': 'Japanese',
                'href': '/en/shop/japanese',
              },
              'kawaii': {
                'text': 'Kawaii',
                'href': '/en/shop/kawaii',
              },
              'kitchen': {
                'text': 'Kitchen',
                'href': '/en/shop/kitchen',
              },
              'map': {
                'text': 'Map',
                'href': '/en/shop/map',
              },
              'mapleLeaf': {
                'text': 'Maple Leaf',
                'href': '/en/shop/maple-leaf',
              },
              'mermaid': {
                'text': 'Mermaid',
                'href': '/en/shop/mermaid',
              },
              'mudcloth': {
                'text': 'Mudcloth',
                'href': '/en/shop/mudcloth',
              },
              'nautical': {
                'text': 'Nautical',
                'href': '/en/shop/nautical',
              },
              'ocean': {
                'text': 'Ocean',
                'href': '/en/shop/ocean',
              },
              'owl': {
                'text': 'Owl',
                'href': '/en/shop/owl',
              },
              'paisley': {
                'text': 'Paisley',
                'href': '/en/shop/paisley',
              },
              'peacock': {
                'text': 'Peacock',
                'href': '/en/shop/peacock',
              },
              'rainbow': {
                'text': 'Rainbow',
                'href': '/en/shop/rainbow',
              },
              'retro': {
                'text': 'Retro',
                'href': '/en/shop/retro',
              },
              'science': {
                'text': 'Science',
                'href': '/en/shop/science',
              },
              'skull': {
                'text': 'Skull',
                'href': '/en/shop/skull',
              },
              'space': {
                'text': 'Space',
                'href': '/en/shop/space',
              },
              'stars': {
                'text': 'Stars',
                'href': '/en/shop/stars',
              },
              'steampunk': {
                'text': 'Steampunk',
                'href': '/en/shop/steampunk',
              },
              'stripes': {
                'text': 'Stripes',
                'href': '/en/shop/stripes',
              },
              'teaTowel': {
                'text': 'Tea Towel',
                'href': '/en/shop/tea-towel',
              },
              'tiki': {
                'text': 'Tiki',
                'href': '/en/shop/tiki',
              },
              'toile': {
                'text': 'Toile',
                'href': '/en/shop/toile',
              },
              'victorian': {
                'text': 'Victorian',
                'href': '/en/shop/victorian',
              },
              'watercolor': {
                'text': 'Watercolor',
                'href': '/en/shop/watercolor',
              },
              'allWoodland': {
                'text': 'Woodland',
                'href': '/en/shop/woodland',
              },
              'yellow': {
                'text': 'Yellow',
                'href': '/en/shop/yellow',
              }
            }
          },
          'style': {
            'text': 'Style',
            'children': {
              'modern': {
                'text': 'Modern',
                'children': {
                  'allModern': {
                    'text': 'All Modern',
                    'href': '/en/shop/modern',
                  },
                  'abstract': {
                    'text': 'Abstract',
                    'href': '/en/shop/abstract',
                  },
                  'arrows': {
                    'text': 'Arrows',
                    'href': '/en/shop/arrows',
                  },
                  'artDeco': {
                    'text': 'Art Deco',
                    'href': '/en/shop/artdeco',
                  },
                  'chevron': {
                    'text': 'Chevron',
                    'href': '/en/shop/chevron',
                  },
                  'geometric': {
                    'text': 'Geometric',
                    'href': '/en/shop/geometric',
                  },
                  'hipster': {
                    'text': 'Hipster',
                    'href': '/en/shop/hipster',
                  },
                  'midCenturyModern': {
                    'text': 'Mid Century Modern',
                    'href': '/en/shop/midcentury-modern',
                  },
                  'ikat': {
                    'text': 'Ikat',
                    'href': '/en/shop/ikat',
                  },
                  'mod': {
                    'text': 'Mod',
                    'href': '/en/shop/mod',
                  },
                  'ombre': {
                    'text': 'Ombre',
                    'href': '/en/shop/ombre',
                  }
                }
              },
              'traditional': {
                'text': 'Traditional',
                'children': {
                  'allTraditional': {
                    'text': 'All Traditional',
                    'href': '/en/shop/traditional',
                  },
                  'check': {
                    'text': 'Check',
                    'href': '/en/shop/check',
                  },
                  'chinoiserie': {
                    'text': 'Chinoiserie',
                    'href': '/en/shop/chinoiserie',
                  },
                  'damask': {
                    'text': 'Damask',
                    'href': '/en/shop/damask',
                  },
                  'dots': {
                    'text': 'Dots',
                    'href': '/en/shop/dots',
                  },
                  'gingham': {
                    'text': 'Gingham',
                    'href': '/en/shop/gingham',
                  },
                  'herringbone': {
                    'text': 'Herringbone',
                    'href': '/en/shop/herringbone',
                  },
                  'houndstooth': {
                    'text': 'Houndstooth',
                    'href': '/en/shop/houndstooth',
                  },
                  'paisley': {
                    'text': 'Paisley',
                    'href': '/en/shop/paisley',
                  },
                  'plaid': {
                    'text': 'Plaid',
                    'href': '/en/shop/plaid',
                  },
                  'preppy': {
                    'text': 'Preppy',
                    'href': '/en/shop/preppy',
                  },
                  'shabbyChic': {
                    'text': 'Shabby Chic',
                    'href': '/en/shop/shabby-chic',
                  },
                  'stripes': {
                    'text': 'Stripes',
                    'href': '/en/shop/stripes',
                  },
                  'toile': {
                    'text': 'Toile',
                    'href': '/en/shop/toile',
                  },
                  'vintage': {
                    'text': 'Vintage',
                    'href': '/en/shop/vintage',
                  }
                }
              },
              'historical': {
                'text': 'Historical',
                'children': {
                  'allHistorical': {
                    'text': 'All Historical',
                    'href': '/en/shop/historical',
                  },
                  'artDeco': {
                    'text': 'Art Deco',
                    'href': '/en/shop/artdeco',
                  },
                  'artNouveau': {
                    'text': 'Art Nouveau',
                    'href': '/en/shop/art-nouveau',
                  },
                  'gothic': {
                    'text': 'Gothic',
                    'href': '/en/shop/gothic',
                  },
                  'medieval': {
                    'text': 'Medieval',
                    'href': '/en/shop/medieval',
                  },
                  'midCenturyModern': {
                    'text': 'Mid Century Modern',
                    'href': '/en/shop/midcentury-modern',
                  },
                  'popArt': {
                    'text': 'Pop Art',
                    'href': '/en/shop/pop',
                  },
                  'renaissance': {
                    'text': 'Renaissance',
                    'href': '/en/shop/renaissance',
                  },
                  'rococo': {
                    'text': 'Rococo',
                    'href': '/en/shop/rococo',
                  },
                  'retro': {
                    'text': 'Retro',
                    'href': '/en/shop/retro',
                  },
                  'steampunk': {
                    'text': 'Steampunk',
                    'href': '/en/shop/steampunk',
                  },
                  'victorian': {
                    'text': 'Victorian',
                    'href': '/en/shop/victorian',
                  },
                  'vintage': {
                    'text': 'Vintage',
                    'href': '/en/shop/vintage',
                  },
                  '60s': {
                    'text': '60s',
                    'href': '/en/shop/60s',
                  },
                  '70s': {
                    'text': '70s',
                    'href': '/en/shop/70s',
                  },
                  '80s': {
                    'text': '80s',
                    'href': '/en/shop/80s',
                  },
                  '90s': {
                    'text': '90s',
                    'href': '/en/shop/90s',
                  }
                }
              },
              'nautical': {
                'text': 'Nautical',
                'href': '/en/shop/nautical',
                'children': {
                  'allNautical': {
                    'text': 'All Nautical',
                    'href': '/en/shop/nautical',
                  },
                  'anchors': {
                    'text': 'Anchors',
                    'href': '/en/shop/anchors',
                  },
                  'boats': {
                    'text': 'Boats',
                    'href': '/en/shop/boats',
                  },
                  'buoys': {
                    'text': 'Buoys',
                    'href': '/en/shop/buoys',
                  },
                  'knots': {
                    'text': 'Knots',
                    'href': '/en/shop/knots',
                  },
                  'lighthouse': {
                    'text': 'Lighthouse',
                    'href': '/en/shop/lighthouse',
                  },
                  'lobster': {
                    'text': 'Lobster',
                    'href': '/en/shop/lobster',
                  },
                  'map': {
                    'text': 'Map',
                    'href': '/en/shop/map',
                  },
                  'mermaid': {
                    'text': 'Mermaid',
                    'href': '/en/shop/mermaid',
                  },
                  'octopus': {
                    'text': 'Octopus',
                    'href': '/en/shop/octopus',
                  },
                  'shells': {
                    'text': 'Shells',
                    'href': '/en/shop/shells',
                  },
                  'ship': {
                    'text': 'Ship',
                    'href': '/en/shop/ship',
                  }
                }
              },
              'artistic': {
                'text': 'Artistic',
                'children': {
                  'allArtistic': {
                    'text': 'All Artistic',
                    'href': '/en/shop/artistic',
                  },
                  'abstract': {
                    'text': 'Abstract',
                    'href': '/en/shop/abstract',
                  },
                  'brushStroke': {
                    'text': 'Brush Stroke',
                    'href': '/en/shop/brush-stroke',
                  },
                  'handDrawn': {
                    'text': 'Hand Drawn',
                    'href': '/en/shop/hand-drawn',
                  },
                  'photography': {
                    'text': 'Photography',
                    'href': '/en/shop/photography',
                  },
                  'sketches': {
                    'text': 'Sketches',
                    'href': '/en/shop/sketches',
                  },
                  'splatterPaint': {
                    'text': 'Splatter Paint',
                    'href': '/en/shop/splatter-paint',
                  },
                  'watercolor': {
                    'text': 'Watercolor',
                    'href': '/en/shop/watercolor',
                  }
                }
              },
              'shapes': {
                'text': 'Shapes',
                'children': {
                  'allShapes': {
                    'text': 'All Shapes',
                    'href': '/en/shop/shapes',
                  },
                  'arrows': {
                    'text': 'Arrows',
                    'href': '/en/shop/arrows',
                  },
                  'circle': {
                    'text': 'Circle',
                    'href': '/en/shop/circle',
                  },
                  'cross': {
                    'text': 'Cross',
                    'href': '/en/shop/cross',
                  },
                  'dots': {
                    'text': 'Dots',
                    'href': '/en/shop/dots',
                  },
                  'fleurDeLis': {
                    'text': 'Fleur de Lis',
                    'href': '/en/shop/fleur-de-lis',
                  },
                  'geometric': {
                    'text': 'Geometric',
                    'href': '/en/shop/geometric',
                  },
                  'grid': {
                    'text': 'Grid',
                    'href': '/en/shop/grid',
                  },
                  'diamond': {
                    'text': 'Diamond',
                    'href': '/en/shop/harlequin-diamonds',
                  },
                  'hearts': {
                    'text': 'Hearts',
                    'href': '/en/shop/hearts',
                  },
                  'hexagon': {
                    'text': 'Hexagon',
                    'href': '/en/shop/hexagon',
                  },
                  'honeycombPattern': {
                    'text': 'Honeycomb Pattern',
                    'href': '/en/shop/honeycomb-pattern',
                  },
                  'pixel': {
                    'text': 'Pixel',
                    'href': '/en/shop/pixel',
                  },
                  'quatrefoil': {
                    'text': 'Quatrefoil',
                    'href': '/en/shop/quatrefoil',
                  },
                  'stars': {
                    'text': 'Stars',
                    'href': '/en/shop/stars',
                  },
                  'trellis': {
                    'text': 'Trellis',
                    'href': '/en/shop/trellis',
                  },
                  'triangles': {
                    'text': 'Triangles',
                    'href': '/en/shop/triangles',
                  }
                }
              },
              'texture': {
                'text': 'Texture',
                'children': {
                  'allTexture': {
                    'text': 'All Texture',
                    'href': '/en/shop/texture',
                  },
                  'birchBark': {
                    'text': 'Birch Bark',
                    'href': '/en/shop/birch-bark',
                  },
                  'lace': {
                    'text': 'Lace',
                    'href': '/en/shop/lace',
                  },
                  'linen': {
                    'text': 'Linen',
                    'href': '/en/shop/linen',
                  },
                  'marble': {
                    'text': 'Marble',
                    'href': '/en/shop/marble',
                  },
                  'shibori': {
                    'text': 'Shibori',
                    'href': '/en/shop/shibori',
                  },
                  'stainedGlass': {
                    'text': 'Stained Glass',
                    'href': '/en/shop/stained-glass',
                  },
                  'wood': {
                    'text': 'Wood',
                    'href': '/en/shop/wood',
                  },
                  'woodgrain': {
                    'text': 'Woodgrain',
                    'href': '/en/shop/woodgrain',
                  }
                }
              },
              'geography': {
                'text': 'Geography',
                'children': {
                  'allGeography': {
                    'text': 'All Geography',
                    'href': '/en/shop/geography',
                  },
                  'asian': {
                    'text': 'Asian',
                    'href': '/en/shop/asian',
                  },
                  'chinese': {
                    'text': 'Chinese',
                    'href': '/en/shop/chinese',
                  },
                  'scandinavian': {
                    'text': 'Scandinavian',
                    'href': '/en/shop/scandinavian',
                  },
                  'mexican': {
                    'text': 'Mexican',
                    'href': '/en/shop/mexican',
                  },
                  'paris': {
                    'text': 'Paris',
                    'href': '/en/shop/paris',
                  },
                  'chinoiserie': {
                    'text': 'Chinoiserie',
                    'href': '/en/shop/chinoiserie',
                  },
                  'indian': {
                    'text': 'Indian',
                    'href': '/en/shop/indian',
                  },
                  'kawaii': {
                    'text': 'Kawaii',
                    'href': '/en/shop/kawaii',
                  },
                  'african': {
                    'text': 'African',
                    'href': '/en/shop/african',
                  },
                  'london': {
                    'text': 'London',
                    'href': '/en/shop/london',
                  },
                  'hawaiian': {
                    'text': 'Hawaiian',
                    'href': '/en/shop/hawaiian',
                  },
                  'french': {
                    'text': 'French',
                    'href': '/en/shop/french',
                  },
                  'tropical': {
                    'text': 'Tropical',
                    'href': '/en/shop/tropical',
                  },
                  'japanese': {
                    'text': 'Japanese',
                    'href': '/en/shop/japanese',
                  },
                  'turkish': {
                    'text': 'Turkish',
                    'href': '/en/shop/turkish',
                  }
                }
              },
              'worldly': {
                'text': 'Worldly',
                'children': {
                  'allWorldly': {
                    'text': 'All Worldly',
                    'href': '/en/shop/worldly',
                  },
                  'aztec': {
                    'text': 'Aztec',
                    'href': '/en/shop/aztec',
                  },
                  'bohemian': {
                    'text': 'Bohemian',
                    'href': '/en/shop/bohemian',
                  },
                  'celtic': {
                    'text': 'Celtic',
                    'href': '/en/shop/celtic',
                  },
                  'ikat': {
                    'text': 'Ikat',
                    'href': '/en/shop/ikat',
                  },
                  'mandala': {
                    'text': 'Mandala',
                    'href': '/en/shop/mandala',
                  },
                  'mapleLeaf': {
                    'text': 'Maple Leaf',
                    'href': '/en/shop/maple-leaf',
                  },
                  'moroccan': {
                    'text': 'Moroccan',
                    'href': '/en/shop/moroccan',
                  },
                  'mudcloth': {
                    'text': 'Mudcloth',
                    'href': '/en/shop/mudcloth',
                  },
                  'nativeAmerican': {
                    'text': 'Native American',
                    'href': '/en/shop/native-american',
                  },
                  'persian': {
                    'text': 'Persian',
                    'href': '/en/shop/persian',
                  },
                  'tribal': {
                    'text': 'Tribal',
                    'href': '/en/shop/tribal',
                  }
                }
              }
            }
          },
          'animals': {
            'text': 'Animals',
            'children': {
              'allAnimals': {
                'text': 'All Animals',
                'href': '/en/shop/animals',
              },
              'woodlandAnimals': {
                'text': 'Woodland Animals',
                'children': {
                  'allWoodlandAnimals': {
                    'text': 'All Woodland Animals',
                    'href': '/en/shop/woodland',
                  },
                  'bat': {
                    'text': 'Bat',
                    'href': '/en/shop/bat',
                  },
                  'bear': {
                    'text': 'Bear',
                    'href': '/en/shop/bear',
                  },
                  'deer': {
                    'text': 'Deer',
                    'href': '/en/shop/deer',
                  },
                  'fox': {
                    'text': 'Fox',
                    'href': '/en/shop/fox',
                  },
                  'hedgehog': {
                    'text': 'Hedgehog',
                    'href': '/en/shop/hedgehog',
                  },
                  'moose': {
                    'text': 'Moose',
                    'href': '/en/shop/moose',
                  },
                  'owl': {
                    'text': 'Owl',
                    'href': '/en/shop/owl',
                  },
                  'rabbit': {
                    'text': 'Rabbit',
                    'href': '/en/shop/rabbit',
                  },
                  'squirrel': {
                    'text': 'Squirrel',
                    'href': '/en/shop/squirrel',
                  },
                  'stag': {
                    'text': 'Stag',
                    'href': '/en/shop/stag',
                  },
                  'wolf': {
                    'text': 'Wolf',
                    'href': '/en/shop/wolf',
                  }
                }
              },
              'farm': {
                'text': 'Farm',
                'children': {
                  'allFarm': {
                    'text': 'All Farm',
                    'href': '/en/shop/farm',
                  },
                  'chicken': {
                    'text': 'Chicken',
                    'href': '/en/shop/chicken',
                  },
                  'cows': {
                    'text': 'Cows',
                    'href': '/en/shop/cows',
                  },
                  'donkey': {
                    'text': 'Donkey',
                    'href': '/en/shop/donkey',
                  },
                  'ducks': {
                    'text': 'Ducks',
                    'href': '/en/shop/ducks',
                  },
                  'equestrian': {
                    'text': 'Equestrian',
                    'href': '/en/shop/equestrian',
                  },
                  'goat': {
                    'text': 'Goat',
                    'href': '/en/shop/goat',
                  },
                  'hen': {
                    'text': 'Hen',
                    'href': '/en/shop/hen',
                  },
                  'horse': {
                    'text': 'Horse',
                    'href': '/en/shop/horse',
                  },
                  'llama': {
                    'text': 'Llama',
                    'href': '/en/shop/llama',
                  },
                  'pig': {
                    'text': 'Pig',
                    'href': '/en/shop/pig',
                  },
                  'sheep': {
                    'text': 'Sheep',
                    'href': '/en/shop/sheep',
                  }
                }
              },
              'pets': {
                'text': 'Pets',
                'children': {
                  'allPets': {
                    'text': 'All Pets',
                    'href': '/en/shop/pets',
                  },
                  'bird': {
                    'text': 'Bird',
                    'href': '/en/shop/bird',
                  },
                  'cat': {
                    'text': 'Cat',
                    'href': '/en/shop/cat',
                  },
                  'dog': {
                    'text': 'Dog',
                    'href': '/en/shop/dog',
                  },
                  'fish': {
                    'text': 'Fish',
                    'href': '/en/shop/fish',
                  },
                  'hamster': {
                    'text': 'Hamster',
                    'href': '/en/shop/hamster',
                  },
                  'parrot': {
                    'text': 'Parrot',
                    'href': '/en/shop/parrot',
                  },
                  'snake': {
                    'text': 'Snake',
                    'href': '/en/shop/snake',
                  },
                  'spider': {
                    'text': 'Spider',
                    'href': '/en/shop/spider',
                  }
                }
              },
              'ocean': {
                'text': 'Ocean',
                'href': '/en/shop/ocean',
                'children': {
                  'allOcean': {
                    'text': 'All Ocean',
                    'href': '/en/shop/ocean',
                  },
                  'crab': {
                    'text': 'Crab',
                    'href': '/en/shop/crab',
                  },
                  'dolphin': {
                    'text': 'Dolphin',
                    'href': '/en/shop/dolphin',
                  },
                  'fish': {
                    'text': 'Fish',
                    'href': '/en/shop/fish',
                  },
                  'jellyfish': {
                    'text': 'Jellyfish',
                    'href': '/en/shop/jellyfish',
                  },
                  'koi': {
                    'text': 'Koi',
                    'href': '/en/shop/koi',
                  },
                  'lobster': {
                    'text': 'Lobster',
                    'href': '/en/shop/lobster',
                  },
                  'octopus': {
                    'text': 'Octopus',
                    'href': '/en/shop/octopus',
                  },
                  'penguin': {
                    'text': 'Penguin',
                    'href': '/en/shop/penguin',
                  },
                  'seahorse': {
                    'text': 'Seahorse',
                    'href': '/en/shop/seahorse',
                  },
                  'shark': {
                    'text': 'Shark',
                    'href': '/en/shop/shark',
                  },
                  'starfish': {
                    'text': 'Starfish',
                    'href': '/en/shop/starfish',
                  },
                  'whale': {
                    'text': 'Whale',
                    'href': '/en/shop/whale',
                  }
                }
              },
              'insects': {
                'text': 'Insects',
                'children': {
                  'allInsects': {
                    'text': 'All Insects',
                    'href': '/en/shop/insects',
                  },
                  'bee': {
                    'text': 'Bee',
                    'href': '/en/shop/bee',
                  },
                  'beetle': {
                    'text': 'Beetle',
                    'href': '/en/shop/beetle',
                  },
                  'bug': {
                    'text': 'Bug',
                    'href': '/en/shop/bug',
                  },
                  'butterfly': {
                    'text': 'Butterfly',
                    'href': '/en/shop/butterfly',
                  },
                  'caterpillar': {
                    'text': 'Caterpillar',
                    'href': '/en/shop/caterpillar',
                  },
                  'dragonfly': {
                    'text': 'Dragonfly',
                    'href': '/en/shop/dragonfly',
                  },
                  'firefly': {
                    'text': 'Firefly',
                    'href': '/en/shop/firefly',
                  },
                  'spider': {
                    'text': 'Spider',
                    'href': '/en/shop/spider',
                  }
                }
              },
              'birds': {
                'text': 'Birds',
                'href': '/en/shop/birds',
                'children': {
                  'allBirds': {
                    'text': 'All Birds',
                    'href': '/en/shop/birds',
                  },
                  'flamingo': {
                    'text': 'Flamingo',
                    'href': '/en/shop/flamingo',
                  },
                  'hummingbird': {
                    'text': 'Hummingbird',
                    'href': '/en/shop/hummingbird',
                  },
                  'owl': {
                    'text': 'Owl',
                    'href': '/en/shop/owl',
                  },
                  'parrot': {
                    'text': 'Parrot',
                    'href': '/en/shop/parrot',
                  },
                  'peacock': {
                    'text': 'Peacock',
                    'href': '/en/shop/peacock',
                  },
                  'robin': {
                    'text': 'Robin',
                    'href': '/en/shop/robin',
                  },
                  'sparrow': {
                    'text': 'Sparrow',
                    'href': '/en/shop/sparrow',
                  },
                  'toucan': {
                    'text': 'Toucan',
                    'href': '/en/shop/toucan',
                  }
                }
              },
              'zoo': {
                'text': 'Zoo',
                'children': {
                  'allZoo': {
                    'text': 'All Zoo',
                    'href': '/en/shop/zoo',
                  },
                  'bears': {
                    'text': 'Bears',
                    'href': '/en/shop/bears',
                  },
                  'giraffe': {
                    'text': 'Giraffe',
                    'href': '/en/shop/giraffe',
                  },
                  'hippo': {
                    'text': 'Hippo',
                    'href': '/en/shop/hippo',
                  },
                  'llama': {
                    'text': 'Llama',
                    'href': '/en/shop/llama',
                  },
                  'lions': {
                    'text': 'Lions',
                    'href': '/en/shop/lions',
                  },
                  'monkey': {
                    'text': 'Monkey',
                    'href': '/en/shop/monkey',
                  },
                  'panda': {
                    'text': 'Panda',
                    'href': '/en/shop/panda',
                  },
                  'rhino': {
                    'text': 'Rhino',
                    'href': '/en/shop/rhino',
                  },
                  'tigers': {
                    'text': 'Tigers',
                    'href': '/en/shop/tigers',
                  },
                  'zebra': {
                    'text': 'Zebra',
                    'href': '/en/shop/zebra',
                  }
                }
              },
              'safari': {
                'text': 'Safari',
                'children': {
                  'allSafari': {
                    'text': 'All Safari',
                    'href': '/en/shop/safari',
                  },
                  'cheetah': {
                    'text': 'Cheetah',
                    'href': '/en/shop/cheetah',
                  },
                  'elephant': {
                    'text': 'Elephant',
                    'href': '/en/shop/elephant',
                  },
                  'giraffe': {
                    'text': 'Giraffe',
                    'href': '/en/shop/giraffe',
                  },
                  'hippo': {
                    'text': 'Hippo',
                    'href': '/en/shop/hippo',
                  },
                  'leopard': {
                    'text': 'Leopard',
                    'href': '/en/shop/leopard',
                  },
                  'lions': {
                    'text': 'Lions',
                    'href': '/en/shop/lions',
                  },
                  'monkey': {
                    'text': 'Monkey',
                    'href': '/en/shop/monkey',
                  },
                  'rhino': {
                    'text': 'Rhino',
                    'href': '/en/shop/rhino',
                  },
                  'sloth': {
                    'text': 'Sloth',
                    'href': '/en/shop/sloth',
                  },
                  'tigers': {
                    'text': 'Tigers',
                    'href': '/en/shop/tigers',
                  },
                  'zebra': {
                    'text': 'Zebra',
                    'href': '/en/shop/zebra',
                  }
                }
              },
              'dogBreeds': {
                'text': 'Dog Breeds',
                'children': {
                  'allDogBreeds': {
                    'text': 'All Dog Breeds',
                    'href': '/en/shop/dog-breeds',
                  },
                  'bulldog': {
                    'text': 'Bulldog',
                    'href': '/en/shop/bulldog',
                  },
                  'corgi': {
                    'text': 'Corgi',
                    'href': '/en/shop/corgi',
                  },
                  'dachshund': {
                    'text': 'Dachshund',
                    'href': '/en/shop/dachshund',
                  },
                  'dalmatian': {
                    'text': 'Dalmatian',
                    'href': '/en/shop/dalmatian',
                  },
                  'goldenRetriever': {
                    'text': 'Golden Retriever',
                    'href': '/en/shop/golden-retriever',
                  },
                  'greyhound': {
                    'text': 'Greyhound',
                    'href': '/en/shop/greyhound',
                  },
                  'poodle': {
                    'text': 'Poodle',
                    'href': '/en/shop/poodle',
                  },
                  'pug': {
                    'text': 'Pug',
                    'href': '/en/shop/pug',
                  },
                  'yorkie': {
                    'text': 'Yorkie',
                    'href': '/en/shop/yorkie',
                  }
                }
              }
            }
          },
          'nature': {
            'text': 'Nature',
            'children': {
              'allNature': {
                'text': 'All Nature',
                'href': '/en/shop/nature',
              },
              'botanical': {
                'text': 'Botanical',
                'children': {
                  'allBotanical': {
                    'text': 'All Botanical',
                    'href': '/en/shop/botanical',
                  },
                  'cactus': {
                    'text': 'Cactus',
                    'href': '/en/shop/cactus',
                  },
                  'dandelion': {
                    'text': 'Dandelion',
                    'href': '/en/shop/dandelion',
                  },
                  'fauna': {
                    'text': 'Fauna',
                    'href': '/en/shop/fauna',
                  },
                  'feathers': {
                    'text': 'Feathers',
                    'href': '/en/shop/feathers',
                  },
                  'flora': {
                    'text': 'Flora',
                    'href': '/en/shop/flora',
                  },
                  'flowers': {
                    'text': 'Flowers',
                    'href': '/en/shop/flowers',
                  },
                  'fruit': {
                    'text': 'Fruit',
                    'href': '/en/shop/fruit',
                  },
                  'leaves': {
                    'text': 'Leaves',
                    'href': '/en/shop/leaves',
                  },
                  'mushroom': {
                    'text': 'Mushroom',
                    'href': '/en/shop/mushroom',
                  },
                  'palm': {
                    'text': 'Palm',
                    'href': '/en/shop/palm',
                  },
                  'peony': {
                    'text': 'Peony',
                    'href': '/en/shop/peony',
                  },
                  'poppy': {
                    'text': 'Poppy',
                    'href': '/en/shop/poppy',
                  },
                  'rose': {
                    'text': 'Rose',
                    'href': '/en/shop/rose',
                  },
                  'seeds': {
                    'text': 'Seeds',
                    'href': '/en/shop/seeds',
                  },
                  'succulents': {
                    'text': 'Succulents',
                    'href': '/en/shop/succulents',
                  },
                  'sunflower': {
                    'text': 'Sunflower',
                    'href': '/en/shop/sunflower',
                  },
                  'thistles': {
                    'text': 'Thistles',
                    'href': '/en/shop/thistles',
                  },
                  'trees': {
                    'text': 'Trees',
                    'href': '/en/shop/trees',
                  }
                }
              },
              'space': {
                'text': 'Space',
                'href': '/en/shop/space',
                'children': {
                  'allSpace': {
                    'text': 'All Space',
                    'href': '/en/shop/space',
                  },
                  'asteroids': {
                    'text': 'Asteroids',
                    'href': '/en/shop/asteroids',
                  },
                  'astronaut': {
                    'text': 'Astronaut',
                    'href': '/en/shop/astronaut',
                  },
                  'constellations': {
                    'text': 'Constellations',
                    'href': '/en/shop/constellations',
                  },
                  'galaxy': {
                    'text': 'Galaxy',
                    'href': '/en/shop/galaxy',
                  },
                  'moon': {
                    'text': 'Moon',
                    'href': '/en/shop/moon',
                  },
                  'nebula': {
                    'text': 'Nebula',
                    'href': '/en/shop/nebula',
                  },
                  'planets': {
                    'text': 'Planets',
                    'href': '/en/shop/planets',
                  },
                  'sky': {
                    'text': 'Sky',
                    'href': '/en/shop/sky',
                  },
                  'stars': {
                    'text': 'Stars',
                    'href': '/en/shop/stars',
                  },
                  'sun': {
                    'text': 'Sun',
                    'href': '/en/shop/sun',
                  },
                  'zodiac': {
                    'text': 'Zodiac',
                    'href': '/en/shop/zodiac',
                  }
                }
              },
              'weather': {
                'text': 'Weather',
                'children': {
                  'allWeather': {
                    'text': 'All Weather',
                    'href': '/en/shop/weather',
                  },
                  'clouds': {
                    'text': 'Clouds',
                    'href': '/en/shop/clouds',
                  },
                  'ice': {
                    'text': 'Ice',
                    'href': '/en/shop/ice',
                  },
                  'lightning': {
                    'text': 'Lightning',
                    'href': '/en/shop/lightning',
                  },
                  'rain': {
                    'text': 'Rain',
                    'href': '/en/shop/rain',
                  },
                  'rainbow': {
                    'text': 'Rainbow',
                    'href': '/en/shop/rainbow',
                  },
                  'snow': {
                    'text': 'Snow',
                    'href': '/en/shop/snow',
                  },
                  'storm': {
                    'text': 'Storm',
                    'href': '/en/shop/storm',
                  },
                  'sun': {
                    'text': 'Sun',
                    'href': '/en/shop/sun',
                  },
                  'tropical': {
                    'text': 'Tropical',
                    'href': '/en/shop/tropical',
                  },
                  'umbrella': {
                    'text': 'Umbrella',
                    'href': '/en/shop/umbrella',
                  }
                }
              },
              'birds': {
                'text': 'Birds',
                'href': '/en/shop/birds',
                'children': {
                  'allBirds': {
                    'text': 'All Birds',
                    'href': '/en/shop/birds',
                  },
                  'feathers': {
                    'text': 'Feathers',
                    'href': '/en/shop/feathers',
                  },
                  'flamingo': {
                    'text': 'Flamingo',
                    'href': '/en/shop/flamingo',
                  },
                  'hummingbird': {
                    'text': 'Hummingbird',
                    'href': '/en/shop/hummingbird',
                  },
                  'nest': {
                    'text': 'Nest',
                    'href': '/en/shop/nest',
                  },
                  'parrot': {
                    'text': 'Parrot',
                    'href': '/en/shop/parrot',
                  },
                  'robin': {
                    'text': 'Robin',
                    'href': '/en/shop/robin',
                  },
                  'sparrow': {
                    'text': 'Sparrow',
                    'href': '/en/shop/sparrow',
                  },
                  'toucan': {
                    'text': 'Toucan',
                    'href': '/en/shop/toucan',
                  }
                }
              },
              'woodlandNature': {
                'text': 'Woodland Nature',
                'children': {
                  'allWoodlandNature': {
                    'text': 'All Woodland Nature',
                    'href': '/en/shop/woodland',
                  },
                  'antler': {
                    'text': 'Antler',
                    'href': '/en/shop/antler',
                  },
                  'bat': {
                    'text': 'Bat',
                    'href': '/en/shop/bat',
                  },
                  'bear': {
                    'text': 'Bear',
                    'href': '/en/shop/bear',
                  },
                  'birchBark': {
                    'text': 'Birch Bark',
                    'href': '/en/shop/birch-bark',
                  },
                  'deer': {
                    'text': 'Deer',
                    'href': '/en/shop/deer',
                  },
                  'fox': {
                    'text': 'Fox',
                    'href': '/en/shop/fox',
                  },
                  'hedgehog': {
                    'text': 'Hedgehog',
                    'href': '/en/shop/hedgehog',
                  },
                  'moose': {
                    'text': 'Moose',
                    'href': '/en/shop/moose',
                  },
                  'mushroom': {
                    'text': 'Mushroom',
                    'href': '/en/shop/mushroom',
                  },
                  'owl': {
                    'text': 'Owl',
                    'href': '/en/shop/owl',
                  },
                  'rabbit': {
                    'text': 'Rabbit',
                    'href': '/en/shop/rabbit',
                  },
                  'squirrel': {
                    'text': 'Squirrel',
                    'href': '/en/shop/squirrel',
                  },
                  'stag': {
                    'text': 'Stag',
                    'href': '/en/shop/stag',
                  },
                  'trees': {
                    'text': 'Trees',
                    'href': '/en/shop/trees',
                  },
                  'wolf': {
                    'text': 'Wolf',
                    'href': '/en/shop/wolf',
                  }
                }
              },
              'sea': {
                'text': 'Sea',
                'children': {
                  'allSea': {
                    'text': 'All Sea',
                    'href': '/en/shop/sea',
                  },
                  'coral': {
                    'text': 'Coral',
                    'href': '/en/shop/coral',
                  },
                  'island': {
                    'text': 'Island',
                    'href': '/en/shop/island',
                  },
                  'ocean': {
                    'text': 'Ocean',
                    'href': '/en/shop/ocean',
                  },
                  'sand': {
                    'text': 'Sand',
                    'href': '/en/shop/sand',
                  },
                  'seaweed': {
                    'text': 'Seaweed',
                    'href': '/en/shop/seaweed',
                  },
                  'starfish': {
                    'text': 'Starfish',
                    'href': '/en/shop/starfish',
                  },
                  'surf': {
                    'text': 'Surf',
                    'href': '/en/shop/surf',
                  },
                  'waves': {
                    'text': 'Waves',
                    'href': '/en/shop/waves',
                  }
                }
              },
              'outdoors': {
                'text': 'Outdoors',
                'children': {
                  'allOutdoors': {
                    'text': 'All Outdoors',
                    'href': '/en/shop/outdoors',
                  },
                  'campfire': {
                    'text': 'Campfire',
                    'href': '/en/shop/campfire',
                  },
                  'desert': {
                    'text': 'Desert',
                    'href': '/en/shop/desert',
                  },
                  'jungle': {
                    'text': 'Jungle',
                    'href': '/en/shop/jungle',
                  },
                  'mountain': {
                    'text': 'Mountain',
                    'href': '/en/shop/mountain',
                  },
                  'teepee': {
                    'text': 'Teepee',
                    'href': '/en/shop/teepee',
                  },
                  'trees': {
                    'text': 'Trees',
                    'href': '/en/shop/trees',
                  },
                  'birchBark': {
                    'text': 'Birch Bark',
                    'href': '/en/shop/birch-bark',
                  },
                  'camping': {
                    'text': 'Camping',
                    'href': '/en/shop/camping',
                  },
                  'forest': {
                    'text': 'Forest',
                    'href': '/en/shop/forest',
                  },
                  'lake': {
                    'text': 'Lake',
                    'href': '/en/shop/lake',
                  },
                  'smores': {
                    'text': 'Smores',
                    'href': '/en/shop/smores',
                  },
                  'tents': {
                    'text': 'Tents',
                    'href': '/en/shop/tents',
                  }
                }
              }
            }
          },
          'novelty': {
            'text': 'Novelty',
            'children': {
              'allNovelty': {
                'text': 'All Novelty',
                'href': '/en/shop/novelty',
              },
              'foodAndBeverage': {
                'text': 'Food & Beverage',
                'children': {
                  'allFood': {
                    'text': 'All Food',
                    'href': '/en/shop/food',
                  },
                  'banana': {
                    'text': 'Banana',
                    'href': '/en/shop/banana',
                  },
                  'candy': {
                    'text': 'Candy',
                    'href': '/en/shop/candy',
                  },
                  'coffee': {
                    'text': 'Coffee',
                    'href': '/en/shop/coffee',
                  },
                  'cookies': {
                    'text': 'Cookies',
                    'href': '/en/shop/cookies',
                  },
                  'donuts': {
                    'text': 'Donuts',
                    'href': '/en/shop/donuts',
                  },
                  'fruit': {
                    'text': 'Fruit',
                    'href': '/en/shop/fruit',
                  },
                  'ice': {
                    'text': 'Ice',
                    'href': '/en/shop/ice',
                  },
                  'lemon': {
                    'text': 'Lemon',
                    'href': '/en/shop/lemon',
                  },
                  'pineapple': {
                    'text': 'Pineapple',
                    'href': '/en/shop/pineapple',
                  },
                  'pizza': {
                    'text': 'Pizza',
                    'href': '/en/shop/pizza',
                  },
                  'popcorn': {
                    'text': 'Popcorn',
                    'href': '/en/shop/popcorn',
                  },
                  'popsicle': {
                    'text': 'Popsicle',
                    'href': '/en/shop/popsicle',
                  },
                  'sprinkles': {
                    'text': 'Sprinkles',
                    'href': '/en/shop/sprinkles',
                  },
                  'strawberry': {
                    'text': 'Strawberry',
                    'href': '/en/shop/strawberry',
                  },
                  'sushi': {
                    'text': 'Sushi',
                    'href': '/en/shop/sushi',
                  },
                  'watermelon': {
                    'text': 'Watermelon',
                    'href': '/en/shop/watermelon',
                  }
                }
              },
              'music': {
                'text': 'Music',
                'children': {
                  'allMusic': {
                    'text': 'All Music',
                    'href': '/en/shop/music',
                  },
                  'drums': {
                    'text': 'Drums',
                    'href': '/en/shop/drums',
                  },
                  'guitar': {
                    'text': 'Guitar',
                    'href': '/en/shop/guitar',
                  },
                  'jazz': {
                    'text': 'Jazz',
                    'href': '/en/shop/jazz',
                  },
                  'musicNotes': {
                    'text': 'Music Notes',
                    'href': '/en/shop/music-notes',
                  },
                  'piano': {
                    'text': 'Piano',
                    'href': '/en/shop/piano',
                  },
                  'radio': {
                    'text': 'Radio',
                    'href': '/en/shop/radio',
                  },
                  'rockAndRoll': {
                    'text': 'Rock and Roll',
                    'href': '/en/shop/rock-and-roll',
                  },
                  'symphony': {
                    'text': 'Symphony',
                    'href': '/en/shop/symphony',
                  },
                  'trumpets': {
                    'text': 'Trumpets',
                    'href': '/en/shop/trumpets',
                  },
                  'violin': {
                    'text': 'Violin',
                    'href': '/en/shop/violin',
                  }
                }
              },
              'academia': {
                'text': 'Academia',
                'children': {
                  'allAcademia': {
                    'text': 'All Academia',
                    'href': '/en/shop/academia',
                  },
                  'atomic': {
                    'text': 'Atomic',
                    'href': '/en/shop/atomic',
                  },
                  'book': {
                    'text': 'Book',
                    'href': '/en/shop/book',
                  },
                  'geek': {
                    'text': 'Geek',
                    'href': '/en/shop/geek',
                  },
                  'math': {
                    'text': 'Math',
                    'href': '/en/shop/math',
                  },
                  'school': {
                    'text': 'School',
                    'href': '/en/shop/school',
                  },
                  'science': {
                    'text': 'Science',
                    'href': '/en/shop/science',
                  },
                  'teacher': {
                    'text': 'Teacher',
                    'href': '/en/shop/teacher',
                  }
                }
              },
              'sewingNotions': {
                'text': 'Sewing Notions',
                'children': {
                  'allSewingNotions': {
                    'text': 'All Sewing Notions',
                    'href': '/en/shop/sewing-notions',
                  },
                  'knitting': {
                    'text': 'Knitting',
                    'href': '/en/shop/knitting',
                  },
                  'sewingMachines': {
                    'text': 'Sewing Machines',
                    'href': '/en/shop/sewing-machines',
                  },
                  'scissors': {
                    'text': 'Scissors',
                    'href': '/en/shop/scissors',
                  },
                  'tapeMeasure': {
                    'text': 'Tape Measure',
                    'href': '/en/shop/tape-measure',
                  },
                  'thimbles': {
                    'text': 'Thimbles',
                    'href': '/en/shop/thimbles',
                  },
                  'thread': {
                    'text': 'Thread',
                    'href': '/en/shop/thread',
                  }
                }
              },
              'sports': {
                'text': 'Sports',
                'children': {
                  'allSports': {
                    'text': 'All Sports',
                    'href': '/en/shop/sports',
                  },
                  'ballet': {
                    'text': 'Ballet',
                    'href': '/en/shop/ballet',
                  },
                  'baseball': {
                    'text': 'Baseball',
                    'href': '/en/shop/baseball',
                  },
                  'basketball': {
                    'text': 'Basketball',
                    'href': '/en/shop/basketball',
                  },
                  'bicycle': {
                    'text': 'Bicycle',
                    'href': '/en/shop/bicycle',
                  },
                  'equestrian': {
                    'text': 'Equestrian',
                    'href': '/en/shop/equestrian',
                  },
                  'fishing': {
                    'text': 'Fishing',
                    'href': '/en/shop/fishing',
                  },
                  'football': {
                    'text': 'Football',
                    'href': '/en/shop/football',
                  },
                  'golf': {
                    'text': 'Golf',
                    'href': '/en/shop/golf',
                  },
                  'gymnastics': {
                    'text': 'Gymnastics',
                    'href': '/en/shop/gymnastics',
                  },
                  'soccer': {
                    'text': 'Soccer',
                    'href': '/en/shop/soccer',
                  },
                  'surfing': {
                    'text': 'Surfing',
                    'href': '/en/shop/surfing',
                  },
                  'tennis': {
                    'text': 'Tennis',
                    'href': '/en/shop/tennis',
                  }
                }
              },
              'scary': {
                'text': 'Scary',
                'children': {
                  'allScary': {
                    'text': 'All Scary',
                    'href': '/en/shop/scary',
                  },
                  'bat': {
                    'text': 'Bat',
                    'href': '/en/shop/bat',
                  },
                  'ghost': {
                    'text': 'Ghost',
                    'href': '/en/shop/ghost',
                  },
                  'gothic': {
                    'text': 'Gothic',
                    'href': '/en/shop/gothic',
                  },
                  'monster': {
                    'text': 'Monster',
                    'href': '/en/shop/monster',
                  },
                  'skull': {
                    'text': 'Skull',
                    'href': '/en/shop/skull',
                  },
                  'steampunk': {
                    'text': 'Steampunk',
                    'href': '/en/shop/steampunk',
                  },
                  'wolf': {
                    'text': 'Wolf',
                    'href': '/en/shop/wolf',
                  },
                  'zombie': {
                    'text': 'Zombie',
                    'href': '/en/shop/zombie',
                  }
                }
              },
              'transportation': {
                'text': 'Transportation',
                'children': {
                  'allTransportation': {
                    'text': 'All Transportation',
                    'href': '/en/shop/transportation',
                  },
                  'airplanes': {
                    'text': 'Airplanes',
                    'href': '/en/shop/airplanes',
                  },
                  'bicycles': {
                    'text': 'Bicycles',
                    'href': '/en/shop/bicycles',
                  },
                  'boats': {
                    'text': 'Boats',
                    'href': '/en/shop/boats',
                  },
                  'cars': {
                    'text': 'Cars',
                    'href': '/en/shop/cars',
                  },
                  'construction': {
                    'text': 'Construction',
                    'href': '/en/shop/construction',
                  },
                  'helicopters': {
                    'text': 'Helicopters',
                    'href': '/en/shop/helicopters',
                  },
                  'hotAirBalloon': {
                    'text': 'Hot Air Balloon',
                    'href': '/en/shop/hot-air-balloon',
                  },
                  'motorcycles': {
                    'text': 'Motorcycles',
                    'href': '/en/shop/motorcycles',
                  },
                  'retro': {
                    'text': 'Retro',
                    'href': '/en/shop/retro',
                  },
                  'rocketShip': {
                    'text': 'Rocket Ship',
                    'href': '/en/shop/rocket-ship',
                  },
                  'space': {
                    'text': 'Space',
                    'href': '/en/shop/space',
                  },
                  'ships': {
                    'text': 'Ships',
                    'href': '/en/shop/ships',
                  },
                  'tractors': {
                    'text': 'Tractors',
                    'href': '/en/shop/tractors',
                  },
                  'trucks': {
                    'text': 'Trucks',
                    'href': '/en/shop/trucks',
                  }
                }
              }
            }
          },
          'occasion': {
            'text': 'Occasion',
            'children': {
              'babyAndKids': {
                'text': 'Baby & Kids',
                'children': {
                  'allChildren': {
                    'text': 'All Children',
                    'href': '/en/shop/children',
                  },
                  'alphabet': {
                    'text': 'Alphabet',
                    'href': '/en/shop/alphabet',
                  },
                  'babyBoy': {
                    'text': 'Baby Boy',
                    'href': '/en/shop/baby-boy',
                  },
                  'babyGirl': {
                    'text': 'Baby Girl',
                    'href': '/en/shop/baby-girl',
                  },
                  'ballerina': {
                    'text': 'Ballerina',
                    'href': '/en/shop/ballerina',
                  },
                  'butterfly': {
                    'text': 'Butterfly',
                    'href': '/en/shop/butterfly',
                  },
                  'cars': {
                    'text': 'Cars',
                    'href': '/en/shop/cars',
                  },
                  'circus': {
                    'text': 'Circus',
                    'href': '/en/shop/circus',
                  },
                  'dinosaur': {
                    'text': 'Dinosaur',
                    'href': '/en/shop/dinosaur',
                  },
                  'doll': {
                    'text': 'Doll',
                    'href': '/en/shop/doll',
                  },
                  'dragon': {
                    'text': 'Dragon',
                    'href': '/en/shop/dragon',
                  },
                  'fairy': {
                    'text': 'Fairy',
                    'href': '/en/shop/fairy',
                  },
                  'nursery': {
                    'text': 'Nursery',
                    'href': '/en/shop/nursery',
                  },
                  'pirate': {
                    'text': 'Pirate',
                    'href': '/en/shop/pirate',
                  },
                  'princess': {
                    'text': 'Princess',
                    'href': '/en/shop/princess',
                  },
                  'robot': {
                    'text': 'Robot',
                    'href': '/en/shop/robot',
                  },
                  'tractors': {
                    'text': 'Tractors',
                    'href': '/en/shop/tractors',
                  },
                  'trucks': {
                    'text': 'Trucks',
                    'href': '/en/shop/trucks',
                  },
                  'unicorn': {
                    'text': 'Unicorn',
                    'href': '/en/shop/unicorn',
                  }
                }
              },
              'birthday': {
                'text': 'Birthday',
                'children': {
                  'allBirthday': {
                    'text': 'All Birthday',
                    'href': '/en/shop/birthday',
                  },
                  'balloons': {
                    'text': 'Balloons',
                    'href': '/en/shop/balloons',
                  },
                  'cake': {
                    'text': 'Cake',
                    'href': '/en/shop/cake',
                  },
                  'confetti': {
                    'text': 'Confetti',
                    'href': '/en/shop/confetti',
                  },
                  'cupcake': {
                    'text': 'Cupcake',
                    'href': '/en/shop/cupcake',
                  },
                  'gifts': {
                    'text': 'Gifts',
                    'href': '/en/shop/gifts',
                  },
                  'presents': {
                    'text': 'Presents',
                    'href': '/en/shop/presents',
                  },
                  'picnic': {
                    'text': 'Picnic',
                    'href': '/en/shop/picnic',
                  },
                  'wedding': {
                    'text': 'Wedding',
                    'href': '/en/shop/wedding',
                  },
                  'graduation': {
                    'text': 'Graduation',
                    'href': '/en/shop/graduation',
                  }
                }
              },
              'picnic': {
                'text': 'Picnic',
                'href': '/en/shop/picnic',
              },
              'wedding': {
                'text': 'Wedding',
                'href': '/en/shop/wedding',
              },
              'graduation': {
                'text': 'Graduation',
                'href': '/en/shop/graduation',
              }
            }
          },
          'seasonal': {
            'text': 'Seasonal',
            'children': {
              'allSeasonal': {
                'text': 'All Seasonal',
                'href': '/en/shop/seasonal',
              },
              'winter': {
                'text': 'Winter',
                'children': {
                  'allWinter': {
                    'text': 'All Winter',
                    'href': '/en/shop/winter',
                  },
                  'antler': {
                    'text': 'Antler',
                    'href': '/en/shop/antler',
                  },
                  'chanukah': {
                    'text': 'Chanukah',
                    'href': '/en/shop/chanukah',
                  },
                  'christmas': {
                    'text': 'Christmas',
                    'href': '/en/shop/christmas',
                  },
                  'holiday': {
                    'text': 'Holiday',
                    'href': '/en/shop/holiday',
                  },
                  'holly': {
                    'text': 'Holly',
                    'href': '/en/shop/holly',
                  },
                  'ice': {
                    'text': 'Ice',
                    'href': '/en/shop/ice',
                  },
                  'penguins': {
                    'text': 'Penguins',
                    'href': '/en/shop/penguins',
                  },
                  'presents': {
                    'text': 'Presents',
                    'href': '/en/shop/presents',
                  },
                  'bears': {
                    'text': 'Bears',
                    'href': '/en/shop/bears',
                  },
                  'santa': {
                    'text': 'Santa',
                    'href': '/en/shop/santa',
                  },
                  'skiing': {
                    'text': 'Skiing',
                    'href': '/en/shop/skiiing',
                  },
                  'snow': {
                    'text': 'Snow',
                    'href': '/en/shop/snow',
                  },
                  'stockings': {
                    'text': 'Stockings',
                    'href': '/en/shop/stockings',
                  }
                }
              },
              'spring': {
                'text': 'Spring',
                'children': {
                  'allSpring': {
                    'text': 'All Spring',
                    'href': '/en/shop/spring',
                  },
                  'birds': {
                    'text': 'Birds',
                    'href': '/en/shop/birds',
                  },
                  'bunny': {
                    'text': 'Bunny',
                    'href': '/en/shop/bunny',
                  },
                  'crocus': {
                    'text': 'Crocus',
                    'href': '/en/shop/crocus',
                  },
                  'daffodil': {
                    'text': 'Daffodil',
                    'href': '/en/shop/daffodil',
                  },
                  'floral': {
                    'text': 'Floral',
                    'href': '/en/shop/floral',
                  },
                  'flowers': {
                    'text': 'Flowers',
                    'href': '/en/shop/flowers',
                  },
                  'rain': {
                    'text': 'Rain',
                    'href': '/en/shop/rain',
                  },
                  'tulips': {
                    'text': 'Tulips',
                    'href': '/en/shop/tulips',
                  },
                  'umbrella': {
                    'text': 'Umbrella',
                    'href': '/en/shop/umbrella',
                  }
                }
              },
              'summer': {
                'text': 'Summer',
                'children': {
                  'allSummer': {
                    'text': 'All Summer',
                    'href': '/en/shop/summer',
                  },
                  'beach': {
                    'text': 'Beach',
                    'href': '/en/shop/beach',
                  },
                  'camping': {
                    'text': 'Camping',
                    'href': '/en/shop/camping',
                  },
                  'floral': {
                    'text': 'Floral',
                    'href': '/en/shop/floral',
                  },
                  'july4th': {
                    'text': 'July 4th',
                    'href': '/en/shop/july-4th',
                  },
                  'ice': {
                    'text': 'Ice',
                    'href': '/en/shop/ice',
                  },
                  'lemonade': {
                    'text': 'Lemonade',
                    'href': '/en/shop/lemonade',
                  },
                  'mermaid': {
                    'text': 'Mermaid',
                    'href': '/en/shop/mermaid',
                  },
                  'popsicle': {
                    'text': 'Popsicle',
                    'href': '/en/shop/popsicle',
                  },
                  'tiki': {
                    'text': 'Tiki',
                    'href': '/en/shop/tiki',
                  },
                  'tropical': {
                    'text': 'Tropical',
                    'href': '/en/shop/tropical',
                  }
                }
              },
              'fall': {
                'text': 'Fall',
                'children': {
                  'allFall': {
                    'text': 'All Fall',
                    'href': '/en/shop/fall',
                  },
                  'apple': {
                    'text': 'Apple',
                    'href': '/en/shop/apple',
                  },
                  'autumn': {
                    'text': 'Autumn',
                    'href': '/en/shop/autumn',
                  },
                  'backpack': {
                    'text': 'Backpack',
                    'href': '/en/shop/backpack',
                  },
                  'camping': {
                    'text': 'Camping',
                    'href': '/en/shop/camping',
                  },
                  'cider': {
                    'text': 'Cider',
                    'href': '/en/shop/cider',
                  },
                  'halloween': {
                    'text': 'Halloween',
                    'href': '/en/shop/halloween',
                  },
                  'leaves': {
                    'text': 'Leaves',
                    'href': '/en/shop/leaves',
                  },
                  'pumpkins': {
                    'text': 'Pumpkins',
                    'href': '/en/shop/pumpkins',
                  },
                  'school': {
                    'text': 'School',
                    'href': '/en/shop/school',
                  },
                  'skull': {
                    'text': 'Skull',
                    'href': '/en/shop/skull',
                  },
                  'teacher': {
                    'text': 'Teacher',
                    'href': '/en/shop/teacher',
                  },
                  'trees': {
                    'text': 'Trees',
                    'href': '/en/shop/trees',
                  },
                  'woodlandFall': {
                    'text': 'Woodland Fall',
                    'href': '/en/shop/woodland',
                  }
                }
              },
              'holidays': {
                'text': 'Holidays',
                'children': {
                  'allHolidays': {
                    'text': 'All Holidays',
                    'href': '/en/shop/holidays',
                  },
                  'hearts': {
                    'text': 'Hearts',
                    'href': '/en/shop/hearts',
                  },
                  'menorah': {
                    'text': 'Menorah',
                    'href': '/en/shop/menorah',
                  },
                  'pumpkins': {
                    'text': 'Pumpkins',
                    'href': '/en/shop/pumpkins',
                  },
                  'santa': {
                    'text': 'Santa',
                    'href': '/en/shop/santa',
                  },
                  'skull': {
                    'text': 'Skull',
                    'href': '/en/shop/skull',
                  },
                  'spider': {
                    'text': 'Spider',
                    'href': '/en/shop/spider',
                  },
                  'stars': {
                    'text': 'Stars',
                    'href': '/en/shop/stars',
                  },
                  'supernatural': {
                    'text': 'Supernatural',
                    'href': '/en/shop/supernatural',
                  },
                  'witches': {
                    'text': 'Witches',
                    'href': '/en/shop/witches',
                  },
                  'zombies': {
                    'text': 'Zombies',
                    'href': '/en/shop/zombies',
                  }
                }
              }
            }
          },
          'holidays': {
            'text': 'Holidays',
            'children': {
              'allHolidays': {
                'text': 'All Holidays',
                'href': '/en/shop/holidays',
              },
              'christmas': {
                'text': 'Christmas',
                'href': '/en/shop/christmas',
                'children': {
                  'allHolidays': {
                    'text': 'All Holidays',
                    'href': '/en/shop/holidays',
                  },
                  'christmas': {
                    'text': 'Christmas',
                    'href': '/en/shop/christmas',
                  },
                  'holly': {
                    'text': 'Holly',
                    'href': '/en/shop/holly',
                  },
                  'pinecones': {
                    'text': 'Pinecones',
                    'href': '/en/shop/pinecones',
                  },
                  'reindeer': {
                    'text': 'Reindeer',
                    'href': '/en/shop/reindeer',
                  },
                  'retro': {
                    'text': 'Retro',
                    'href': '/en/shop/retro',
                  },
                  'santa': {
                    'text': 'Santa',
                    'href': '/en/shop/santa',
                  },
                  'sled': {
                    'text': 'Sled',
                    'href': '/en/shop/sled',
                  },
                  'sleigh': {
                    'text': 'Sleigh',
                    'href': '/en/shop/sleigh',
                  },
                  'snow': {
                    'text': 'Snow',
                    'href': '/en/shop/snow',
                  },
                  'snowmen': {
                    'text': 'Snowmen',
                    'href': '/en/shop/snowmen',
                  },
                  'stocking': {
                    'text': 'Stocking',
                    'href': '/en/shop/stocking',
                  }
                }
              },
              'july4th': {
                'text': 'July 4th',
                'href': '/en/shop/july-4th',
                'children': {
                  'allJuly4th': {
                    'text': 'All July 4th',
                    'href': '/en/shop/july-4th',
                  },
                  'americanFlag': {
                    'text': 'American Flag',
                    'href': '/en/shop/american-flag',
                  },
                  'cookout': {
                    'text': 'Cookout',
                    'href': '/en/shop/cookout',
                  },
                  'fireworks': {
                    'text': 'Fireworks',
                    'href': '/en/shop/fireworks',
                  },
                  'hamburgers': {
                    'text': 'Hamburgers',
                    'href': '/en/shop/hamburgers',
                  },
                  'hotdogs': {
                    'text': 'Hotdogs',
                    'href': '/en/shop/hotdogs',
                  },
                  'independenceDay': {
                    'text': 'Independence Day',
                    'href': '/en/shop/independence-day',
                  },
                  'patriotic': {
                    'text': 'Patriotic',
                    'href': '/en/shop/patriotic',
                  },
                  'picnic': {
                    'text': 'Picnic',
                    'href': '/en/shop/picnic',
                  },
                  'redWhiteAndBlue': {
                    'text': 'Red, White, & Blue',
                    'href': '/en/shop/red-white-and-blue',
                  },
                  'stars': {
                    'text': 'Stars',
                    'href': '/en/shop/stars',
                  },
                  'stripes': {
                    'text': 'Stripes',
                    'href': '/en/shop/stripes',
                  }
                }
              },
              'valentinesDay': {
                'text': 'Valentine’s Day',
                'children': {
                  'allValentinesDay': {
                    'text': 'All Valentine’s Day',
                    'href': '/en/shop/valentines-day',
                  },
                  'arrows': {
                    'text': 'Arrows',
                    'href': '/en/shop/arrows',
                  },
                  'candy': {
                    'text': 'Candy',
                    'href': '/en/shop/candy',
                  },
                  'cupid': {
                    'text': 'Cupid',
                    'href': '/en/shop/cupid',
                  },
                  'hearts': {
                    'text': 'Hearts',
                    'href': '/en/shop/hearts',
                  },
                  'love': {
                    'text': 'Love',
                    'href': '/en/shop/love',
                  }
                }
              },
              'halloween': {
                'text': 'Halloween',
                'href': '/en/shop/halloween',
                'children': {
                  'allHalloween': {
                    'text': 'All Halloween',
                    'href': '/en/shop/halloween',
                  },
                  'bat': {
                    'text': 'Bat',
                    'href': '/en/shop/bat',
                  },
                  'blackCats': {
                    'text': 'Black Cats',
                    'href': '/en/shop/black-cats',
                  },
                  'candy': {
                    'text': 'Candy',
                    'href': '/en/shop/candy',
                  },
                  'ghost': {
                    'text': 'Ghost',
                    'href': '/en/shop/ghost',
                  },
                  'jack-o-lantern': {
                    'text': 'Jack-o-lantern',
                    'href': '/en/shop/jack-o-lantern',
                  },
                  'pumpkin': {
                    'text': 'Pumpkin',
                    'href': '/en/shop/pumpkin',
                  },
                  'scarecrow': {
                    'text': 'Scarecrow',
                    'href': '/en/shop/scarecrow',
                  },
                  'skull': {
                    'text': 'Skull',
                    'href': '/en/shop/skull',
                  },
                  'spider': {
                    'text': 'Spider',
                    'href': '/en/shop/spider',
                  },
                  'sugarSkulls': {
                    'text': 'Sugar Skulls',
                    'href': '/en/shop/sugar-skulls',
                  },
                  'vampire': {
                    'text': 'Vampire',
                    'href': '/en/shop/vampire',
                  },
                  'witch': {
                    'text': 'Witch',
                    'href': '/en/shop/witch',
                  },
                  'zombie': {
                    'text': 'Zombie',
                    'href': '/en/shop/zombie',
                  }
                }
              }
            }
          },
          'projects': {
            'text': 'Projects',
            'children': {
              'allProjects': {
                'text': 'All Projects',
                'href': '/en/shop/projects',
              },
              'bags': {
                'text': 'Bags',
                'href': '/en/shop/bags',
              },
              'border': {
                'text': 'Border',
                'href': '/en/shop/border',
              },
              'bunting': {
                'text': 'Bunting',
                'href': '/en/shop/bunting',
              },
              'calendarTeaTowels': {
                'text': 'Calendar Tea Towels',
                'href': '/en/shop/calendar-tea-towels',
              },
              'cheaterQuilts': {
                'text': 'Cheater Quilts',
                'href': '/en/shop/cheater-quilts',
              },
              'cutAndSew': {
                'text': 'Cut and Sew',
                'href': '/en/shop/cut-and-sew',
              },
              'pencilPouch': {
                'text': 'Pencil Pouch',
                'href': '/en/shop/pencil-pouch',
              },
              'plushies': {
                'text': 'Plushies',
                'href': '/en/shop/plushies',
              },
              'quiltLabels': {
                'text': 'Quilt Labels',
                'href': '/en/shop/quilt-labels',
              },
              'stockings': {
                'text': 'Stockings',
                'href': '/en/shop/stockings',
              },
              'teaTowels': {
                'text': 'Tea Towels',
                'href': '/en/shop/tea-towels',
              },
              'zipperPouch': {
                'text': 'Zipper Pouch',
                'href': '/en/shop/zipper-pouch',
              }
            }
          }
        }
      },
      'byColors': {
        'text': 'By Color',
        'children': {
          'white': {
            'text': 'White',
            'href': '/en/shop/white',
            'children': {
              'white': {
                'text': 'White',
                'href': '/en/shop/white',
              },
              'beige': {
                'text': 'Beige',
                'href': '/en/shop/beige',
              },
              'cornsilk': {
                'text': 'Cornsilk',
                'href': '/en/shop/cornsilk',
              },
              'ecru': {
                'text': 'Ecru',
                'href': '/en/shop/ecru',
              },
              'eggshell': {
                'text': 'Eggshell',
                'href': '/en/shop/eggshell',
              },
              'flax': {
                'text': 'Flax',
                'href': '/en/shop/flax',
              },
              'ivory': {
                'text': 'Ivory',
                'href': '/en/shop/ivory',
              },
              'linen': {
                'text': 'Linen',
                'href': '/en/shop/linen',
              },
              'vanilla': {
                'text': 'Vanilla',
                'href': '/en/shop/vanilla',
              },
              'whiteSmoke': {
                'text': 'White Smoke',
                'href': '/en/shop/white-smoke',
              }
            }
          },
          'grey': {
            'text': 'Grey',
            'href': '/en/shop/grey',
            'children': {
              'gray': {
                'text': 'Gray',
                'href': '/en/shop/gray',
              },
              'grey': {
                'text': 'Grey',
                'href': '/en/shop/grey',
              },
              'ashGray': {
                'text': 'Ash Gray',
                'href': '/en/shop/ash-gray',
              },
              'battleshipGray': {
                'text': 'Battleship Gray',
                'href': '/en/shop/battleship-gray',
              },
              'blueGray': {
                'text': 'Blue-Gray',
                'href': '/en/shop/blue-gray',
              },
              'charcoal': {
                'text': 'Charcoal',
                'href': '/en/shop/charcoal',
              },
              'gunmetal': {
                'text': 'Gunmetal',
                'href': '/en/shop/gunmetal',
              },
              'platinum': {
                'text': 'Platinum',
                'href': '/en/shop/platinum',
              },
              'silver': {
                'text': 'Silver',
                'href': '/en/shop/silver',
              },
              'slate': {
                'text': 'Slate',
                'href': '/en/shop/slate',
              },
              'taupe': {
                'text': 'Taupe',
                'href': '/en/shop/taupe',
              }
            }
          },
          'pink': {
            'text': 'Pink',
            'href': '/en/shop/pink',
            'children': {
              'pink': {
                'text': 'Pink',
                'href': '/en/shop/pink',
              },
              'carnation': {
                'text': 'Carnation',
                'href': '/en/shop/carnation',
              },
              'cerise': {
                'text': 'Cerise',
                'href': '/en/shop/cerise',
              },
              'coral': {
                'text': 'Coral',
                'href': '/en/shop/coral',
              },
              'cyclamen': {
                'text': 'Cyclamen',
                'href': '/en/shop/cyclamen',
              },
              'fandango': {
                'text': 'Fandango',
                'href': '/en/shop/fandango',
              },
              'fuschsia': {
                'text': 'Fuschsia',
                'href': '/en/shop/fuchsia',
              },
              'hotPink': {
                'text': 'Hot Pink',
                'href': '/en/shop/hot-pink',
              },
              'magenta': {
                'text': 'Magenta',
                'href': '/en/shop/magenta',
              },
              'mauve': {
                'text': 'Mauve',
                'href': '/en/shop/mauve',
              },
              'puce': {
                'text': 'Puce',
                'href': '/en/shop/puce',
              },
              'raspberry': {
                'text': 'Raspberry',
                'href': '/en/shop/raspberry',
              },
              'rose': {
                'text': 'Rose',
                'href': '/en/shop/rose',
              },
              'roseQuartz': {
                'text': 'Rose Quartz',
                'href': '/en/shop/rose-quartz',
              },
              'ruby': {
                'text': 'Ruby',
                'href': '/en/shop/ruby',
              },
              'salmon': {
                'text': 'Salmon',
                'href': '/en/shop/salmon',
              },
              'shockingPink': {
                'text': 'Shocking Pink',
                'href': '/en/shop/shocking-pink',
              }
            }
          },
          'red': {
            'text': 'Red',
            'href': '/en/shop/red',
            'children': {
              'red': {
                'text': 'Red',
                'href': '/en/shop/red',
              },
              'auburn': {
                'text': 'Auburn',
                'href': '/en/shop/auburn',
              },
              'burgundy': {
                'text': 'Burgundy',
                'href': '/en/shop/burgundy',
              },
              'cardinal': {
                'text': 'Cardinal',
                'href': '/en/shop/cardinal',
              },
              'claret': {
                'text': 'Claret',
                'href': '/en/shop/claret',
              },
              'crimson': {
                'text': 'Crimson',
                'href': '/en/shop/crimson',
              },
              'mahogany': {
                'text': 'Mahogany',
                'href': '/en/shop/mahogany',
              },
              'maroon': {
                'text': 'Maroon',
                'href': '/en/shop/maroon',
              },
              'marsala': {
                'text': 'Marsala',
                'href': '/en/shop/marsala',
              },
              'oxblood': {
                'text': 'Oxblood',
                'href': '/en/shop/oxblood',
              },
              'raspberry': {
                'text': 'Raspberry',
                'href': '/en/shop/raspberry',
              },
              'redwood': {
                'text': 'Redwood',
                'href': '/en/shop/redwood',
              },
              'rosewood': {
                'text': 'Rosewood',
                'href': '/en/shop/rosewood',
              },
              'ruby': {
                'text': 'Ruby',
                'href': '/en/shop/ruby',
              },
              'rust': {
                'text': 'Rust',
                'href': '/en/shop/rust',
              },
              'scarlet': {
                'text': 'Scarlet',
                'href': '/en/shop/scarlet',
              },
              'terraCotta': {
                'text': 'Terra Cotta',
                'href': '/en/shop/terra-cotta',
              },
              'vermilion': {
                'text': 'Vermilion',
                'href': '/en/shop/vermilion',
              }
            }
          },
          'brown': {
            'text': 'Brown',
            'href': '/en/shop/brown',
            'children': {
              'brown': {
                'text': 'Brown',
                'href': '/en/shop/brown',
              },
              'beige': {
                'text': 'Beige',
                'href': '/en/shop/beige',
              },
              'bronze': {
                'text': 'Bronze',
                'href': '/en/shop/bronze',
              },
              'buff': {
                'text': 'Buff',
                'href': '/en/shop/buff',
              },
              'burntUmber': {
                'text': 'Burnt umber',
                'href': '/en/shop/burnt-umber',
              },
              'copper': {
                'text': 'Copper',
                'href': '/en/shop/copper',
              },
              'desert': {
                'text': 'Desert',
                'href': '/en/shop/desert',
              },
              'ecru': {
                'text': 'Ecru',
                'href': '/en/shop/ecru',
              },
              'khaki': {
                'text': 'Khaki',
                'href': '/en/shop/khaki',
              },
              'mahogany': {
                'text': 'Mahogany',
                'href': '/en/shop/mahogany',
              },
              'ochre': {
                'text': 'Ochre',
                'href': '/en/shop/ochre',
              },
              'russet': {
                'text': 'Russet',
                'href': '/en/shop/russet',
              },
              'rust': {
                'text': 'Rust',
                'href': '/en/shop/rust',
              },
              'sand': {
                'text': 'Sand',
                'href': '/en/shop/sand',
              },
              'sepia': {
                'text': 'Sepia',
                'href': '/en/shop/sepia',
              },
              'sienna': {
                'text': 'Sienna',
                'href': '/en/shop/sienna',
              },
              'tan': {
                'text': 'Tan',
                'href': '/en/shop/tan',
              },
              'taupe': {
                'text': 'Taupe',
                'href': '/en/shop/taupe',
              },
              'wheat': {
                'text': 'Wheat',
                'href': '/en/shop/wheat',
              }
            }
          },
          'orange': {
            'text': 'Orange',
            'href': '/en/shop/orange',
            'children': {
              'orange': {
                'text': 'Orange',
                'href': '/en/shop/orange',
              },
              'amber': {
                'text': 'Amber',
                'href': '/en/shop/amber',
              },
              'apricot': {
                'text': 'Apricot',
                'href': '/en/shop/apricot',
              },
              'coral': {
                'text': 'Coral',
                'href': '/en/shop/coral',
              },
              'marigold': {
                'text': 'Marigold',
                'href': '/en/shop/marigold',
              },
              'red': {
                'text': 'Red',
                'href': '/en/shop/red',
              },
              'peach': {
                'text': 'Peach',
                'href': '/en/shop/peach',
              },
              'salmon': {
                'text': 'Salmon',
                'href': '/en/shop/salmon',
              },
              'tangerine': {
                'text': 'Tangerine',
                'href': '/en/shop/tangerine',
              }
            }
          },
          'yellow': {
            'text': 'Yellow',
            'href': '/en/shop/yellow',
            'children': {
              'yellow': {
                'text': 'Yellow',
                'href': '/en/shop/yellow',
              },
              'citrine': {
                'text': 'Citrine',
                'href': '/en/shop/citrine',
              },
              'gold': {
                'text': 'Gold',
                'href': '/en/shop/gold',
              },
              'goldenrod': {
                'text': 'Goldenrod',
                'href': '/en/shop/goldenrod',
              },
              'honey': {
                'text': 'Honey',
                'href': '/en/shop/honey',
              },
              'jonquil': {
                'text': 'Jonquil',
                'href': '/en/shop/jonquil',
              },
              'mustard': {
                'text': 'Mustard',
                'href': '/en/shop/mustard',
              },
              'saffron': {
                'text': 'Saffron',
                'href': '/en/shop/saffron',
              }
            }
          },
          'green': {
            'text': 'Green',
            'href': '/en/shop/green',
            'children': {
              'green': {
                'text': 'Green',
                'href': '/en/shop/green',
              },
              'artichoke': {
                'text': 'Artichoke',
                'href': '/en/shop/artichoke',
              },
              'celadon': {
                'text': 'Celadon',
                'href': '/en/shop/celadon',
              },
              'chartreuse': {
                'text': 'Chartreuse',
                'href': '/en/shop/chartreuse',
              },
              'emerald': {
                'text': 'Emerald',
                'href': '/en/shop/emerald',
              },
              'jade': {
                'text': 'Jade',
                'href': '/en/shop/jade',
              },
              'kelly': {
                'text': 'Kelly',
                'href': '/en/shop/kelly',
              },
              'mint': {
                'text': 'Mint',
                'href': '/en/shop/mint',
              }
            }
          },
          'cyan': {
            'text': 'Cyan',
            'href': '/en/shop/cyan',
            'children': {
              'cyan': {
                'text': 'Cyan',
                'href': '/en/shop/cyan',
              },
              'aqua': {
                'text': 'Aqua',
                'href': '/en/shop/aqua',
              },
              'aquamarine': {
                'text': 'Aquamarine',
                'href': '/en/shop/aquamarine',
              },
              'cerulean': {
                'text': 'Cerulean',
                'href': '/en/shop/cerulean',
              },
              'teal': {
                'text': 'Teal',
                'href': '/en/shop/teal',
              },
              'turquoise': {
                'text': 'Turquoise',
                'href': '/en/shop/turquoise',
              },
              'verdigris': {
                'text': 'Verdigris',
                'href': '/en/shop/verdigris',
              }
            }
          },
          'blue': {
            'text': 'Blue',
            'href': '/en/shop/blue',
            'children': {
              'blue': {
                'text': 'Blue',
                'href': '/en/shop/blue',
              },
              'azure': {
                'text': 'Azure',
                'href': '/en/shop/azure',
              },
              'babyBoy': {
                'text': 'Baby Boy',
                'href': '/en/shop/baby-boy',
              },
              'cobalt': {
                'text': 'Cobalt',
                'href': '/en/shop/cobalt',
              },
              'cornflower': {
                'text': 'Cornflower',
                'href': '/en/shop/cornflower',
              },
              'denim': {
                'text': 'Denim',
                'href': '/en/shop/denim',
              },
              'indigo': {
                'text': 'Indigo',
                'href': '/en/shop/indigo',
              },
              'midnight': {
                'text': 'Midnight',
                'href': '/en/shop/midnight',
              },
              'navy': {
                'text': 'Navy',
                'href': '/en/shop/navy',
              },
              'periwinkle': {
                'text': 'Periwinkle',
                'href': '/en/shop/periwinkle',
              },
              'sapphire': {
                'text': 'Sapphire',
                'href': '/en/shop/sapphire',
              },
              'serenity': {
                'text': 'Serenity',
                'href': '/en/shop/serenity',
              },
              'ultramarine': {
                'text': 'Ultramarine',
                'href': '/en/shop/ultramarine',
              }
            }
          },
          'violet': {
            'text': 'Violet',
            'href': '/en/shop/violet',
            'children': {
              'violet': {
                'text': 'Violet',
                'href': '/en/shop/violet',
              },
              'amethyst': {
                'text': 'Amethyst',
                'href': '/en/shop/amethyst',
              },
              'aubergine': {
                'text': 'Aubergine',
                'href': '/en/shop/aubergine',
              },
              'cerise': {
                'text': 'Cerise',
                'href': '/en/shop/cerise',
              },
              'eggplant': {
                'text': 'Eggplant',
                'href': '/en/shop/eggplant',
              },
              'fuchsia': {
                'text': 'Fuchsia',
                'href': '/en/shop/fuchsia',
              },
              'indigo': {
                'text': 'Indigo',
                'href': '/en/shop/indigo',
              },
              'lavender': {
                'text': 'Lavender',
                'href': '/en/shop/lavender',
              },
              'lilac': {
                'text': 'Lilac',
                'href': '/en/shop/lilac',
              },
              'magenta': {
                'text': 'Magenta',
                'href': '/en/shop/magenta',
              },
              'orchid': {
                'text': 'Orchid',
                'href': '/en/shop/orchid',
              },
              'periwinkle': {
                'text': 'Periwinkle',
                'href': '/en/shop/periwinkle',
              },
              'plum': {
                'text': 'Plum',
                'href': '/en/shop/plum',
              },
              'purple': {
                'text': 'Purple',
                'href': '/en/shop/purple',
              },
              'radiantOrchid': {
                'text': 'Radiant Orchid',
                'href': '/en/shop/radiant-orchid',
              }
            }
          },
          'black': {
            'text': 'Black',
            'href': '/en/shop/black',
            'children': {
              'black': {
                'text': 'Black',
                'href': '/en/shop/black',
              },
              'charcoal': {
                'text': 'Charcoal',
                'href': '/en/shop/charcoal',
              },
              'ebony': {
                'text': 'Ebony',
                'href': '/en/shop/ebony',
              },
              'midnight': {
                'text': 'Midnight',
                'href': '/en/shop/midnight',
              },
              'noir': {
                'text': 'Noir',
                'href': '/en/shop/noir',
              },
              'onyx': {
                'text': 'Onyx',
                'href': '/en/shop/onyx',
              }
            }
          }
        }
      },
      'curatedThemes': {
        'text': 'Shop by Theme',
        'href': '/themes',
      },
      'giftIdeas': {
        'text': 'Gift Ideas',
        'href': '/en/gift-ideas',
      },
      'blog': {
        'text': 'Blog',
        'href': 'https://blog.spoonflower.com/',
      }
    }
  },
  'fabric-menu': {
    'text': 'Fabric',
    'href': '/en/fabric',
    'children': {
      'allFabric': {
        'text': 'Explore Fabric',
        'href': '/en/fabric',
      },
      'shopFabricDesigns': {
        'text': 'Shop All Fabric Designs',
        'href': '/en/shop?on=fabric',
      },
      'fabricTypes': {
        'text': 'Fabric Types',
        'href': '/en/fabric-shop',
      },
      'samplePack': {
        'text': 'Sample Pack',
        'href': '/en/sample-pack',
      },
      'fillAYard': {
        'text': 'Fill-A-Yard ®',
        'href': '/fill-a-yard',
      }
    }
  },
  'wallpaper-menu': {
    'text': 'Wallpaper',
    'href': '/en/wallpaper',
    'children': {
      'allWallpaper': {
        'text': 'Explore Wallpaper',
        'href': '/en/wallpaper',
      },
      'shopWallpaperDesigns': {
        'text': 'Shop All Wallpaper Designs',
        'href': '/en/shop?on=wallpaper',
      },
      'peelAndStick': {
        'text': 'Peel and Stick Wallpaper',
        'href': '/en/peel-and-stick-removable-woven-wallpaper',
      },
      'prepastedWallpaper': {
        'text': 'Pre-Pasted Wallpaper',
        'href': '/en/prepasted-removable-wallpaper',
      },
      'nonPastedTraditionalPebble': {
        'text': 'Non-Pasted Wallpaper',
        'href': '/en/non-pasted-traditional-wallpaper',
      },
      'grasscloth': {
        'text': 'Grasscloth Wallpaper',
        'href': '/en/grasscloth-wallpaper',
      },
      'metallic': {
        'text': 'Metallic Wallpaper',
        'href': '/en/metallic-wallpaper',
      }
    }
  },
  'livingDecor-menu': {
    'text': 'Living & Decor',
    'href': '/en/living-decor',
    'children': {
      'allLiving': {
        'text': 'All Living & Decor',
        'href': '/en/living-decor',
      },
      'squareThrowPillows': {
        'text': 'Square Throw Pillows',
        'href': '/en/home-decor/living-decor/square-throw-pillow',
      },
      'lumbarThrowPillows': {
        'text': 'Lumbar Throw Pillows',
        'href': '/en/home-decor/living-decor/lumbar-throw-pillow',
      },
      'throwBlankets': {
        'text': 'Throw Blankets',
        'href': '/en/home-decor/living-decor/throw-blanket',
      },
      'curtains': {
        'text': 'Curtains',
        'href': '/en/home-decor/living-decor/curtains',
      },
      'wallHanging': {
        'text': 'Wall Hangings',
        'href': '/en/home-decor/living-decor/wall-hangings',
      }
    }
  },
  'dining-menu': {
    'text': 'Dining',
    'href': '/en/dining',
    'children': {
      'allDining': {
        'text': 'All Dining',
        'href': '/en/dining',
      },
      'cocktailNapkins': {
        'text': 'Cocktail Napkins',
        'href': '/en/home-decor/dining/cocktail-napkins',
      },
      'dinnerNapkins': {
        'text': 'Dinner Napkins',
        'href': '/en/home-decor/dining/dinner-napkins',
      },
      'placemats': {
        'text': 'Placemats',
        'href': '/en/home-decor/dining/placemats',
      },
      'teaTowelsLink': {
        'text': 'Tea Towels',
        'href': '/en/home-decor/dining/tea-towel',
      },
      'rectangularTablecloths': {
        'text': 'Rectangular Tablecloths',
        'href': '/en/home-decor/dining/rectangular-tablecloth',
      },
      'roundTablecloths': {
        'text': 'Round Tablecloths',
        'href': '/en/home-decor/dining/round-tablecloth',
      },
      'tableRunners': {
        'text': 'Table Runners',
        'href': '/en/home-decor/dining/table-runner',
      }
    }
  },
  'bedding-menu': {
    'text': 'Bedding',
    'href': '/en/bedding',
    'children': {
      'allBedding': {
        'text': 'All Bedding',
        'href': '/en/bedding',
      },
      'duvetCovers': {
        'text': 'Duvet Covers',
        'href': '/en/home-decor/bedding/duvet-cover',
      },
      'sheetSets': {
        'text': 'Sheet Sets',
        'href': '/en/home-decor/bedding/sheet-set',
      },
      'standardPillowShams': {
        'text': 'Standard Pillow Shams',
        'href': '/en/home-decor/bedding/standard-pillow-sham',
      },
      'euroPillowShams': {
        'text': 'Euro Pillow Shams',
        'href': '/en/home-decor/bedding/euro-pillow-sham',
      },
      'longLumbarThrowPillow': {
        'text': 'Extra Long Lumbar Throw Pillow',
        'href': '/en/home-decor/bedding/extra-long-lumbar-throw-pillow',
      }
    }
  },
  'trade-menu': {
    'text': 'Trade Program',
    'href': '/en/trade',
    'children': {
      'tradeProgram': {
        'text': 'Trade Program',
        'href': '/en/trade',
      }
    }
  },
  'artist-menu': {
    'text': 'Artist Corner',
    'href': '/designs/new',
    'children': {
      'uploadYourDesign': {
        'text': 'Upload a Design',
        'href': '/designs/new',
      },
      'designChallenge': {
        'text': 'Design Challenges',
        'href': '/design-challenge',
      },
      'sellingAndCommissions': {
        'text': 'Selling & Commissions',
        'href': '/en/sell',
      },
      'sellerAgreement': {
        'text': 'Seller Agreement',
        'href': '/seller_agreement',
      },
      'designFAQs': {
        'text': 'Designing FAQs',
        'href': 'https://support.spoonflower.com/hc/en-us/sections/200793490-Creating',
      },
      'sellingFAQs': {
        'text': 'Selling FAQs',
        'href': 'https://support.spoonflower.com/hc/en-us/sections/200793480-Selling',
      },
      'artist': {
        'text': 'Artist Tutorials',
        'href': 'https://blog.spoonflower.com/category/tutorials/design-tutorials/',
      }
    }
  },
  'about-menu': {
    'text': 'About',
    'href': '/en/about',
    'children': {
      'aboutUs': {
        'text': 'About Us',
        'href': '/en/about',
      },
      'howItWorks': {
        'text': 'How It Works',
        'href': '/en/how-it-works',
      },
      'purposeImpact': {
        'text': 'Purpose & Impact',
        'href': 'https://grow.spoonflower.com/purpose-and-impact',
      }
    }
  },
  ...(recentlyViewedUrl && recentlyViewedUrl.includes('stage') && {
    'recently-viewed': {
      'text': 'Recently Viewed',
      'href': recentlyViewedUrl,
    }
  })
}
);
