import update from 'immutability-helper';
import {AnyAction} from 'redux';

import * as types from '../constants/ActionTypes';
import {POSITIVE_FAVORITE_COUNT} from '../constants/Design';


function userFavoriteDesigns(state = {}, action: AnyAction) {
  switch (action.type) {
    case types.USER_FAVORITE_DESIGNS_FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetching: true
          }
        }
      );

    case types.USER_FAVORITE_DESIGNS_RECEIVED: {
      const stateFavoriteDesigns = state.favoriteDesigns || {};
      const favoriteDesigns = action.payload.data.user_favorites || {};

      return update(
        state,
        {
          $merge: {
            favoriteDesigns: {
              ...stateFavoriteDesigns,
              ...favoriteDesigns,
            },
            isFetching: false
          }
        }
      );
    }

    case types.USER_FAVORITE_DESIGNS_ERROR:
      return update(
        state,
        {
          $merge: {
            isFetching: false
          }
        }
      );

    case types.DESIGN_FAVORITED + types.SUCCESS: {
      const stateFavoriteDesigns = state.favoriteDesigns || {};
      const designId = Number(action.designId);
      const isFavorite = action.favoriteCountChange === POSITIVE_FAVORITE_COUNT;

      return update(
        state,
        {
          $merge: {
            favoriteDesigns: {
              ...stateFavoriteDesigns,
              [designId]: isFavorite
            },
            isFetching: false
          }
        }
      );
    }

    default:
      return state;
  }
}

export default userFavoriteDesigns;
