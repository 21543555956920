export const HOMEPAGE = 'home';
export const INTL_HOMEPAGES = {
  en: 'home',
  fr: 'home-fr',
  es: 'home-es',
  de: 'home-de'
};
export const HOMEPAGE_PATHS = ['/', '/de', '/fr', '/es', '/en'];

export const JQUERY_URL = 'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js';

export const CMS_BODY = 'cms-body';

export const CMS_TITLE = 'title';
