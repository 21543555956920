/* eslint-disable no-console */
if (process.env.REACT_APP_IS_SERVER) {
  module.exports = require('../server/serverLogger');
} else {
  module.exports = {
    trace: console.log,
    debug: console.log,
    info: console.log,
    warn: console.warn,
    error: console.error
  };
}
