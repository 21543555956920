module.exports = {
	"metaTitle": "Small Business Grant | Spoonflower",
	"metaDescription": "Our Small Business Grant supports business owners worldwide. Recipients receive credit to digitally print fabric and wallpaper for their creative pursuits.",
	"metaKeywords": "textile grant, small business grants, interior design, interior design grant, small business",
	"title": "Small Business Grant",
	"info": {
		"introP": "The Spoonflower Small Business Grant supports handmade business owners worldwide and at all stages of development. Whether you want to prepare for your next craft fair, experiment with a new product, or just get your business off the ground, we encourage you to apply. Recipients will receive an award of Spoonflower credit to digitally print custom fabric, wallpaper, or home decor items for their creative pursuits.",
		"appHeader": "Application",
		"appP1": "Anyone who has a creative small business is eligible to apply. Grant funds up to $1,000 Spoonflower credit will be awarded to each winner.",
		"appP2start": "The online application form will ask for documentation of product samples and a resumé with relevant creative and business experience. The applicant must also answer narrative questions regarding their business and the project proposed. Please see our ",
		"appP2linkText": "blog post",
		"appP2href": "https://blog.spoonflower.com/2019/06/everything-you-need-to-know-about-applying-for-the-spoonflower-small-business-grant/",
		"appP2end": " for more application tips.",
		"appP3": "To view/complete the application, ",
		"appP3linkText": "click here",
		"appP3href": "https://spoonflower.typeform.com/to/ESYIGjz4",
		"deadlineHeader": "Deadlines",
		"deadlineP1": "",
		"deadlineCycle1": "CYCLE 1",
		"deadlineDate1": {
			"applicationLabel": "Application Deadline",
			"applicationDate": "August 15",
			"notificationLabel": "Notifications",
			"notificationDate": "September 15",
			"applicationsGoLiveLabel": "Applications Go Live",
			"applicationsGoLiveDate": "July 15"
		},
		"deadlineCycle2": "CYCLE 2",
		"deadlineDate2": {
			"applicationLabel": "Application Deadline",
			"applicationDate": "August 15",
			"notificationLabel": "Notifications",
			"notificationDate": "September 15",
			"applicationsGoLiveLabel": "Applications Go Live",
			"applicationsGoLiveDate": "July 15"
		}
	},
	"faqs": {
		"title": "Frequently Asked Questions",
		"q0": "I’m from (insert country here). Am I eligible to apply?",
		"a0": "This Grant Program is open to residents of any country, with the exception of residents of Cuba, Iran, North Korea, Sudan, Myanmar/Burma, Syria, Zimbabwe and/or any other country with U.S. sanctions in place, and is void where prohibited by law.",
		"q1": "Any tips for choosing images of my product samples?",
		"a1": {
			"start": "Make those images count! While you are able to include an optional link to your website, the product images are the panel’s first impression of your work. Choose images that are photographed well and show overall artistic skill and a commitment to craft. Please see our ",
			"linkText": "blog post",
			"appP2href": "https://blog.spoonflower.com/2019/06/everything-you-need-to-know-about-applying-for-the-spoonflower-small-business-grant/",
			"end": " for more tips."
		},
		"q2": "How do I figure out the amount of my grant request?",
		"a2": {
			"start": "Grant requests can be up to $1000 USD (or your currency equivalent) credit to Spoonflower. Based on your project proposal, please make an estimate of your project costs (Example: I need 10 yards of denim to complete my project. Denim = $30/yard. I need $300 Spoondollars). While we don’t require a line-by-line budget for your proposed expenses, we want to see that you recognize your funding needs. It is helpful to have an estimate of how many yards or meters you will use and what type(s) of ",
			"linkText1": "products (or fabric and wallpaper)",
			"middle": " you will be using in your project. Don’t forget to include ",
			"linkText2": "swatches",
			"middle2": " and any ",
			"linkText3": "design tools",
			"end": " you may need in your total."
		},
		"q3": "Can I use other elements along with Spoonflower fabrics for my products?",
		"a3": "Yes, fabric purchased using Spoonflower grant funds may be used in combination with other fabrics and/or elements in your products.",
		"q4": "How will I be updated about my application status?",
		"a4": "We will contact you by email whether or not you are chosen as a grant recipient on or before April 1.",
		"q5": "Are there resources that can help me to use your site?",
		"a5": {
			"start": "We recommend reviewing the following pages to begin:",
			"li1": "How does Spoonflower work?",
			"li2": "How to create a seamless repeat from a drawing",
			"li3": "How do I search the Spoonflower site?",
			"li4": "Spoonflower Help Center",
			"end": "Additionally, design tools like our color map, color guide and sample pack will help you choose the right fabrics for your project and make sure your colors print the way you want. ",
			"linkText": "Check out our helpful design tools"
		},
		"q6": "Do I have to order a swatch before I order my fabric?",
		"a6": "In order to achieve expected colors, we recommend ordering a swatch of your design before printing large amounts to ensure you are happy with colors, repeat, scale, etc.",
		"q7": "Do I have to sell my designs on Spoonflower? Can they be private?",
		"a7": {
			"start": "You retain ownership of your designs and are welcome to ",
			"linkText": "keep them private or sell them through Spoonflower"
		},
		"q8": "Do I have to use my own designs or can I use designs in the Spoonflower Marketplace?",
		"a8": "You are free to use designs you upload yourself or choose from over 1.5 million designs in our marketplace to make your products.",
		"q9": "If I’m awarded the grant, what does Spoonflower expect from me?",
		"a9": {
			"start": "We’d love to highlight your process and final products ",
			"linkText": "on the Spoonflower blog",
			"end": "! Grant recipients will work directly with Spoonflower to coordinate sharing their work. Please feel free to send us photos or links as you work, and don’t forget to tag us with the hashtag '#' Spoonflower on any social media posts."
		},
		"postQuestion": "Still have questions?",
		"postAnswer": "Contact us ",
		"postLinkText": "here"
	},
	"makers": {
		"title": "Spoonflower Makers",
		"maker1": {
			"makerProductAlt": "Collars made using Spoonflower fabric",
			"makerTestimonial": "I’ve been using Spoonflower for five years and love the vibrant and consistent colors and the range of fabric options."
		},
		"maker2": {
			"makerProductAlt": "Puppet made using Spoonflower fabric",
			"makerTestimonial": "The fabric that I design is the foundation of my business and having it printed in the U.S. with eco-friendly inks/fabric is the only way I would do what I do, and thanks to Spoonflower this is possible."
		},
		"maker3": {
			"makerProductAlt": "Swimwear made using Spoonflower fabric",
			"makerTestimonial": "It’s so helpful that Spoonflower does not have yardage minimums, which is sustainable to my business when printing samples and fulfilling smaller orders."
		},
		"maker4": {
			"makerProductAlt": "Handbag made using Spoonflower fabric",
			"makerTestimonial": "Because my bags are unique, I always need to find new patterns and the infinity of designers present on Spoonflower help me in this task."
		},
		"makerOf": "of",
		"moreLinkText": "Meet more makers >>"
	},
	"splitHeroImage": {
		"callToActionText": "Meet past grant recipients >",
		"callToActionClick": "https://blog.spoonflower.com/tag/small-business-grant/",
		"imageAlternateText": "Various past grant recipients",
		"line1": "Supporting Small Businesses"
	},
	"heroImage": {
		"callToActionText": "Learn More",
		"callToActionClick": "https://www.spoonflower.com/wholesale",
		"imageAlternateText": "Various items on a desktop",
		"line1": "Your Business and Spoonflower",
		"line2": "Whether you need a small run of custom fabric for your handmade business, or want to take advantage of our wholesale discounts for your large orders, Spoonflower can take your business to the next level. The only limit is your creativity."
	}
};