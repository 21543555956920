export const JWTExpiredCode = 'JWTExpired';
export const cookiePartialDomain = '.spoonflower.com';
export const authCookieName = 'auth_token';
export const JWTInvalidCode = 'JWTInvalid';

const env = process.env.REACT_APP_IS_SERVER ?
  process.env.ENVIRONMENT :
  window.spoonflower?.serverData?.ENV;

export const baseApiUrl = env === 'Production' ? 'https://api-gateway.spoonflower.com' : 'https://staging-api-gateway.spoonflower.com';
export const jwtCookieName = env === 'Production' ? 'jwt_access' : 'jwt_access_staging';

export const searchTraceIdHeaderName = 'x-bae-trace-id';
