import {Middleware} from 'redux';

import getDebugger from './getDebbuger';


const debug = getDebugger('redux');
const reduxLogger: Middleware = () => (next) => (action) => {
  debug('action %o', action);

  return next(action);
};

export default reduxLogger;
