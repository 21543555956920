import {createIntl, createIntlCache} from 'react-intl';
import Translate from 'i18n-react';
import {TranslateWrapper} from '../services/index';
import {isNotValidPrice} from './validation';
import {convertMillimeterToCentimeters} from './conversion';
import {METRIC} from '../constants/Measurements';
import {currencyCodeWithSign, USD} from '../constants/Payment';
import {COLOR_MAP_ID, SWATCH_IMPERIAL_MEASURES, SWATCH_METRIC_MEASURES} from '../constants/Products';
import {FABRIC_PERENNIAL_SATEEN_GRAND, FABRIC_LONGLEAF_SATEEN_GRAND} from '../constants/Codes';


export function getLanguage(fabricLocale) {
  const cache = createIntlCache();

  return createIntl({
    locale: fabricLocale,
    messages: {}
  }, cache);
}

export function defaultBasePrice(price, setCurrency) {
  const intl = getLanguage(TranslateWrapper.langCode);

  if (isNotValidPrice(price)) {
    return;
  }

  let displayedPrice = intl.formatNumber(price, {
    currency: setCurrency, style: 'currency', currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2
  });

  if (setCurrency in currencyCodeWithSign) {
    if (setCurrency === USD) {
      displayedPrice = intl.formatNumber(price, {
        currency: setCurrency, style: 'currency', currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2
      });
    } else {
      displayedPrice = intl.formatNumber(price, {
        currency: setCurrency, style: 'currency', currencyDisplay: 'code', minimumFractionDigits: 2, maximumFractionDigits: 2
      }).replace(setCurrency, currencyCodeWithSign[setCurrency]);
    }
  }

  return displayedPrice;
}

export function getMeasurement(fabric, unit, property, fabricLocale) {
  const intl = getLanguage(fabricLocale);
  const fabricMeasurement = Translate.translate(`fabricMeasurements.${fabric}.${property}.${unit}`);

  return intl.formatNumber(fabricMeasurement).toString();
}

export function formattedMeasurementIsNaN(value) {
  return value === 'NaN';
}

export function getFabricMeasures(fabricSizeSizingMap, fabricSize, measurementSystem) {
  const selectedMeasurement = measurementSystem === METRIC ? 'millimeters' : 'inches';
  let fabricWidth = measurementSystem === METRIC ? SWATCH_METRIC_MEASURES : SWATCH_IMPERIAL_MEASURES;
  let fabricHeight = measurementSystem === METRIC ? SWATCH_METRIC_MEASURES : SWATCH_IMPERIAL_MEASURES;

  if (fabricSizeSizingMap) {
    const fabricSizeMeasurements = fabricSizeSizingMap[fabricSize];

    if (fabricSizeMeasurements) {
      fabricWidth = fabricSizeMeasurements.width[selectedMeasurement];
      fabricHeight = fabricSizeMeasurements.height[selectedMeasurement];
    }

    if (measurementSystem === METRIC) {
      // convert to centimeter and round down fabric width
      fabricWidth = convertMillimeterToCentimeters(fabricWidth);
      fabricHeight = convertMillimeterToCentimeters(fabricHeight);
    }
  }

  return {
    fabricWidth,
    fabricHeight
  };
}

export const isGrandFabric = (fabric) => fabric === FABRIC_PERENNIAL_SATEEN_GRAND || fabric === FABRIC_LONGLEAF_SATEEN_GRAND;
export const isColorMap = (designId) => designId === Number(COLOR_MAP_ID);
