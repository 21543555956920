import isEqual from 'lodash/isEqual';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from '@redux-devtools/extension';
import browserHistory from 'react-router/lib/browserHistory';
import reducers from '../reducers';
import {updateSearchParams} from '../actions/searchParams';
import {isUndefined, isNotUndefined} from '../utils/validation';
import {adjustCurrencyParams} from '../utils/preferenceHelpers';
import {getProductTypeFilteringUpdates} from '../utils/productTypeFiltering';
import {
  COUNTRY,
  CURRENCY,
  MEASUREMENT_SYSTEM,
  SORT_PARAM,
  ON_PARAM,
  SUBSTRATE_PARAM,
  URL_QUERY_PARAM_SUBSTRATE_FABRIC,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_DEFAULT,
} from '../constants/Parameters';
import {URL_QUERY_TO_API_PARAM_SUBSTRATE_WALLPAPER_MAP} from '../services/Search';
import {CURRENCIES} from '../constants/Payment';
import {MEASUREMENTS} from '../constants/Measurements';
import selectCountries from '../entities/pageSetup/countries/selectors/selectCountries';
import reduxLogger from '../redux/logger/reduxLogger';
import resetRenderNotFound from '../actions/resetRenderNotFound';
import {searchResultsReceived} from '../actions/searchResults';

// On a change to URL or page load, detect any params that have invalid values, such as unsupported countries, etc. Remove
// these params before their values propagate through the application and cause errors.
const removeInvalidQueryParams = (store) => (next) => (action) => {
  /** Pass sort params so they are added to the query */
  const {searchParams: {[SORT_PARAM]: sort}} = store.getState();
  const countries = selectCountries(store.getState());

  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }
  const {query, pathname} = action.payload;
  const modifiableQuery = Object.assign({}, query, {
    [SORT_PARAM]: sort,
  });
  const country = modifiableQuery[COUNTRY];
  const currency = modifiableQuery[CURRENCY];
  const measurementSystem = modifiableQuery[MEASUREMENT_SYSTEM];

  if (pathname !== '/en/shop') {
    delete modifiableQuery[SORT_PARAM];
  }
  if (isNotUndefined(country) && isUndefined(countries[country.toUpperCase()])) {
    delete modifiableQuery[COUNTRY];
  }
  if (isNotUndefined(currency) && !CURRENCIES.includes(currency.toUpperCase())) {
    delete modifiableQuery[CURRENCY];
  }
  if (isNotUndefined(measurementSystem) && !MEASUREMENTS.includes(measurementSystem.toUpperCase())) {
    delete modifiableQuery[MEASUREMENT_SYSTEM];
  }
  if (Object.keys(modifiableQuery).length !== Object.keys(query).length) {
    browserHistory.replace({
      pathname: window.location.pathname, query: modifiableQuery
    });

    return;
  }

  return next(action);
};

// On a change to URL or page load, detect any params that will cause errors in combination, and replace the current
// URL with the corrected parameters.
const fixIncompatibleQueryParams = (store) => (next) => (action) => {
  const countries = selectCountries(store.getState());

  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }
  const query = action.payload.query;
  const adjustedQuery = {};
  const country = query[COUNTRY];
  let currency = query[CURRENCY];

  if (isNotUndefined(country) && isNotUndefined(currency)) {
    Object.assign(adjustedQuery, adjustCurrencyParams(countries, country, currency));
  }

  // If no country exists in original params or in adjusted params, define placeholder currency outside of params if country exists
  // so that the measurement system can be accurately adjusted if needed.
  currency =
    adjustedQuery[CURRENCY] ||
    currency ||
    (isNotUndefined(country) && isNotUndefined(countries[country.toUpperCase()]) ?
      countries[country.toUpperCase()].currency :
      null);

  if (Object.keys(adjustedQuery).length) {
    browserHistory.replace({
      pathname: window.location.pathname, query: Object.assign({}, query, adjustedQuery)
    });

    return;
  }

  return next(action);
};

const productTypeFilteringBySearch = (store) => (next) => (action) => {
  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }

  const query = action.payload.query;
  const {[ON_PARAM]: queryOn, autoApply} = query;
  const {
    searchResults,
    searchResults: {
      product_filter: {
        product,
        terms,
        substrate,
      }
    },
  } = store.getState();

  if (
    (action.payload.pathname !== '/en/shop' && action.payload.pathname !== '/') ||
    !autoApply || !product
  ) {
    return next(action);
  }

  const newQuery = {
    ...query,
    ...getProductTypeFilteringUpdates(queryOn, product, terms, substrate),
  };
  const newSearchResults = {
    ...searchResults,
    product_filter: {
      product: '',
      terms: '',
      substrate: '',
    },
  };

  store.dispatch(searchResultsReceived(newSearchResults));
  browserHistory.replace({
    pathname: window.location.pathname,
    query: newQuery,
  });
};

const autoCorrectSubstratesBySearch = () => (next) => (action) => {
  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }

  if (action.payload.pathname !== '/en/shop') {
    return next(action);
  }
  const {
    query: {
      [SUBSTRATE_PARAM]: substrate,
      ...restOfQuery
    },
    query: {[ON_PARAM]: product}
  } = action.payload;
  const isWallpaper = product === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER;
  const isFabric = product === URL_QUERY_PARAM_SUBSTRATE_FABRIC;
  const existedWallpaperSubstrate = URL_QUERY_TO_API_PARAM_SUBSTRATE_WALLPAPER_MAP[substrate];
  const skipConditions = [
    !isWallpaper && !substrate,
    isWallpaper && existedWallpaperSubstrate,
    isFabric && substrate && !existedWallpaperSubstrate,
  ].map(Boolean);

  if (skipConditions.includes(true)) {
    return next(action);
  }

  const conditionalDefaultSubstrate = isWallpaper ? {
    [SUBSTRATE_PARAM]: URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_DEFAULT
  } : {};

  const newQuery = {
    ...conditionalDefaultSubstrate,
    ...restOfQuery,
  };

  browserHistory.replace({
    pathname: window.location.pathname,
    query: newQuery,
  });
};

const matchSearchParamsWithQuery = (store) => (next) => (action) => {
  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }

  next(action);

  const query = action.payload.query;
  const state = store.getState();
  const {searchParams: searchParamsInState} = state;
  const updatedSearchParams = {
    ...searchParamsInState,
    ...query
  };

  if (!isEqual(searchParamsInState, updatedSearchParams)) {
    store.dispatch(updateSearchParams(updatedSearchParams));
  }
};

const reset404Flag = (store) => (next) => (action) => {
  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }
  next(action);
  const {dispatch, getState} = store;
  const {shouldRenderErrorPage} = getState();

  if (shouldRenderErrorPage.shouldRender404) {
    dispatch(resetRenderNotFound());
  }
};


const composeEnhancers = process.env.NODE_ENV === 'development' || process.env.REACT_APP_BUILD_FOR_ENV === 'local' ? composeWithDevTools({}) : compose();

const middleware = [
  thunk,
  removeInvalidQueryParams,
  fixIncompatibleQueryParams,
  productTypeFilteringBySearch,
  autoCorrectSubstratesBySearch,
  matchSearchParamsWithQuery,
  reset404Flag,
  reduxLogger
];

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(reducers, initialState);
}
