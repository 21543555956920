import {Action} from 'redux';

import * as types from '../constants/ActionTypes';
import {ColorData, ImagesData, PricingData} from '../shapes/solids';
import {ActionCreator, PayloadAction, PayloadActionCreator} from '../types/redux';

// This demonstrates a new pattern for action creators given our current Redux
// use.  The advantage is that the Action Creator, the Action, and the Action
// Type are now all tied together by the type system. This means that if you
// call the Action Creator with the wrong payload, if the Action Creator uses
// the wrong Action Type, etc., you'll get a type error. It also means that the
// reducers can be type safe, because their `switch` statements can perform type
// narrowing and exhaustiveness checking.
//
// In the future, we should consider Redux Toolkit. But this is a low-impact and
// gradual way to bring some type safety into new files.
//
// This is the actual string 'SOLID_IMAGES_GET_SUCCESS', which is typed as its
// own string literal type.
export const SolidImagesReceivedActionType = `${types.SOLID_IMAGES_GET}${types.SUCCESS}` as const;
// This is the TYPE for the `ActionType`, i.e. the TYPE of the exact string
// 'SOLID_IMAGES_GET_SUCCESS'
export type TSolidImagesReceivedActionType = typeof SolidImagesReceivedActionType;
// This is the TYPE of the Action, i.e. the object actually dispatched.
export type SolidImagesReceivedAction = PayloadAction<TSolidImagesReceivedActionType, ImagesData>;
// This is the Action Creator, i.e. the function that builds what is dispatched.
// (This used to be the only thing we defined in these files.)
export const solidImagesReceived: PayloadActionCreator<SolidImagesReceivedAction> = (payload) => ({
  type: SolidImagesReceivedActionType,
  payload
});

export const SolidImagesFetchingActionType = `${types.SOLID_IMAGES_GET}${types.FETCHING}` as const;
export type TSolidImagesFetchingActionType = typeof SolidImagesFetchingActionType;
export type SolidImagesFetchingAction = Action<TSolidImagesFetchingActionType>;
export const solidImagesFetching: ActionCreator<SolidImagesFetchingAction> = () => ({
  type: SolidImagesFetchingActionType
});

export const SolidImagesFailActionType = `${types.SOLID_IMAGES_GET}${types.FAIL}` as const;
export type TSolidImagesFailActionType = typeof SolidImagesFailActionType;
export type SolidImagesFailAction = Action<TSolidImagesFailActionType>;
export const solidImagesFail: ActionCreator<SolidImagesFailAction> = () => ({
  type: SolidImagesFailActionType
});

export const SolidPricingReceivedActionType = `${types.SOLID_PRICING_GET}${types.SUCCESS}` as const;
export type TSolidPricingReceivedActionType = typeof SolidPricingReceivedActionType;
export type SolidPricingReceivedAction = PayloadAction<TSolidPricingReceivedActionType, PricingData>;
export const solidPricingReceived: PayloadActionCreator<SolidPricingReceivedAction> = (payload) => ({
  type: SolidPricingReceivedActionType,
  payload
});

export const SolidPricingFetchingActionType = `${types.SOLID_PRICING_GET}${types.FETCHING}` as const;
export type TSolidPricingFetchingActionType = typeof SolidPricingFetchingActionType;
export type SolidPricingFetchingAction = Action<TSolidPricingFetchingActionType>;
export const solidPricingFetching: ActionCreator<SolidPricingFetchingAction> = () => ({
  type: SolidPricingFetchingActionType
});

export const SolidPricingFailActionType = `${types.SOLID_PRICING_GET}${types.FAIL}` as const;
export type TSolidPricingFailActionType = typeof SolidPricingFailActionType;
export type SolidPricingFailAction = Action<TSolidPricingFailActionType>;
export const solidPricingFail: ActionCreator<SolidPricingFailAction> = () => ({
  type: SolidPricingFailActionType
});

export const SolidColorsReceivedActionType = `${types.SOLID_COLORS_GET}${types.SUCCESS}` as const;
export type TSolidColorsReceivedActionType = typeof SolidColorsReceivedActionType;
export type SolidColorsReceivedAction = PayloadAction<TSolidColorsReceivedActionType, ColorData[]>;
export const solidColorsReceived: PayloadActionCreator<SolidColorsReceivedAction> = (payload) => ({
  type: SolidColorsReceivedActionType,
  payload
});

export const SolidColorsFetchingActionType = `${types.SOLID_COLORS_GET}${types.FETCHING}` as const;
export type TSolidColorsFetchingActionType = typeof SolidColorsFetchingActionType;
export type SolidColorsFetchingAction = Action<TSolidColorsFetchingActionType>;
export const solidColorsFetching: ActionCreator<SolidColorsFetchingAction> = () => ({
  type: SolidColorsFetchingActionType
});

export const SolidColorsFailActionType= `${types.SOLID_COLORS_GET}${types.FAIL}` as const;
export type TSolidColorsFailActionType =typeof SolidColorsFailActionType;
export type SolidColorsFailAction = Action<TSolidColorsFailActionType>;
export const solidColorsFail: ActionCreator<SolidColorsFailAction> = () => ({
  type: SolidColorsFailActionType
});

export const SolidColorDataReceivedActionType = `${types.SOLID_COLOR_DATA_GET}${types.SUCCESS}` as const;
export type TSolidColorDataReceivedActionType = typeof SolidColorDataReceivedActionType;
export type SolidColorDataReceivedAction = PayloadAction<TSolidColorDataReceivedActionType, ColorData>;
export const solidColorDataReceived: PayloadActionCreator<SolidColorDataReceivedAction> = (payload) => ({
  type: SolidColorDataReceivedActionType,
  payload
});

export const SolidColorDataFetchingActionType = `${types.SOLID_COLOR_DATA_GET}${types.FETCHING}` as const;
export type TSolidColorDataFetchingActionType = typeof SolidColorDataFetchingActionType;
export type SolidColorDataFetchingAction = Action<TSolidColorDataFetchingActionType>;
export const solidColorDataFetching: ActionCreator<SolidColorDataFetchingAction> = () => ({
  type: SolidColorDataFetchingActionType
});

export const SolidColorDataFailActionType= `${types.SOLID_COLOR_DATA_GET}${types.FAIL}` as const;
export type TSolidColorDataFailActionType =typeof SolidColorDataFailActionType;
export type SolidColorDataFailAction = Action<TSolidColorDataFailActionType>;
export const solidColorDataFail: ActionCreator<SolidColorDataFailAction> = () => ({
  type: SolidColorDataFailActionType
});

export const SolidResetType = types.SOLID_RESET;
export type TSolidReset = typeof SolidResetType;
export type SolidResetAction = Action<TSolidReset>;
export const solidReset: ActionCreator<SolidResetAction> = () => ({
  type: types.SOLID_RESET
});


export type SolidsAction =
  | SolidColorsFailAction
  | SolidColorsFetchingAction
  | SolidColorsReceivedAction
  | SolidColorDataFailAction
  | SolidColorDataFetchingAction
  | SolidColorDataReceivedAction
  | SolidImagesFailAction
  | SolidImagesFetchingAction
  | SolidImagesReceivedAction
  | SolidPricingFailAction
  | SolidPricingFetchingAction
  | SolidPricingReceivedAction
  | SolidResetAction;
