const breakpoints = {
  mobile: 496,
  tablet: 768,
  desktop: 769,
  'large-desktop': 1025,
  'extra-large-desktop': 1808,
};

const scssString = `
  $break-extra-large-desktop: ${breakpoints['extra-large-desktop']};
  $break-large-desktop-min: ${breakpoints['large-desktop']};
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: ${breakpoints.desktop};
  $break-tablet-max: ${breakpoints.tablet};
  $break-tablet-min: 497;
  $break-mobile-max: ${breakpoints.mobile};
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;
`;

module.exports = {
  breakpoints,
  scssString,
};
