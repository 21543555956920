import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Translate from 'i18n-react';
import classNames from 'classnames';
import './_menu-items.scss';
import {translate} from '../../../services';
import {isEmpty} from '../../../utils/validation';
import {handleOpenChat} from '../../../utils/zendeskHelpers';
import {GUEST, REGISTERED, registeredUser, UNAUTHENTICATED, unAuthOrGuestUser} from '../../../constants/userTypes';
import Icon from '../Icon/Icon';
import LinkWrapper from '../LinkWrapper/LinkWrapper';
import LogInJoin from '../../Chrome/HeaderMain/LogInJoin/LogInJoin';

const MenuItems = ({items, userType, userName}) => {
  const [stack, setStack] = useState([]);
  const getMenuKeys = (key) => {
    const newStack = [...stack];

    newStack.push(key);

    setStack(newStack);
  };

  const onBackClick = () => {
    const newStack = [...stack];

    newStack.pop();

    setStack(newStack);
  };

  const renderListItem = (parent, key, displayText, onClickOrHref, isEmphasized, hasSeperator) => {
    const listElementClasses = classNames('list-element', {
      'x-parent-element': parent,
      'x-emphasized': isEmphasized,
      'x-seperator-line': hasSeperator
    });
    const studioHomeKey = 'studioHome';
    const uploadMenuKey = 'uploadMenu';
    const helpChatKey = 'helpChat';

    const myDashboardLink = () => (
      <React.Fragment>
        <span className='user-name'>{userName}</span>
        <span className='my-dashboard'>{displayText}</span>
      </React.Fragment>
    );

    const uploadButtonContent = <React.Fragment>
      <Icon iconName='add-circle'/>
      {displayText}
    </React.Fragment>;

    if (parent) {
      return (
        <li className={listElementClasses} key={key}>
          <button
            className='nav-link'
            type='button'
            onClick={onClickOrHref}
            aria-label={translate('common.forms.open')}>
            <span className='nav-link-text'>{displayText}</span>
            <Icon iconName='chevron-right'/>
          </button>
        </li>
      );
    }

    if (key) {
      if (key === studioHomeKey) {
        return (
          <li className={'my-dashboard-element list-element'} key={key}>
            <LinkWrapper
              hrefValue={onClickOrHref}
              className='nav-link child-link'
              contents={myDashboardLink()}
              target='_self'
            />
          </li>);
      }
      if (key === uploadMenuKey) {
        return (
          <li className='list-element upload-button' key={key}>
            <LinkWrapper
              hrefValue={onClickOrHref}
              className='btn blue-button'
              contents={uploadButtonContent}
              target='_self'
            />
          </li>
        );
      }
      if (key === helpChatKey) {
        return (
          <li key={helpChatKey} className='list-element'>
            <button
              type='button'
              className='zendesk-live-chat nav-link child-link'
              aria-label={translate('navigation.helpChat.text')}
              title={translate('navigation.helpChat.text')}
              onClick={handleOpenChat}>
              {translate('navigation.helpChat.text')}
            </button>
          </li>
        );
      }
    }

    return (
      <li className={listElementClasses} key={key}>
        <LinkWrapper
          hrefValue={onClickOrHref}
          className='nav-link child-link'
          contents={displayText}
          target='_self'
        />
      </li>);
  };

  const createListElements = (items, listElementsToRender) => {
    for (const key of Object.keys(items)) {
      const parent = Object.prototype.hasOwnProperty.call(items[key], 'children');
      const displayText = items[key].text;
      const onClickOrHref = parent ? () => getMenuKeys(key) : items[key].href;
      const isEmphasized = items[key].isEmphasized;
      const hasSeperator = items[key].hasSeperator;

      listElementsToRender.push(renderListItem(parent, key, displayText, onClickOrHref, isEmphasized, hasSeperator));
    }
  };
  const listElementsToRender = [];
  let parent;
  let itemsToIterate = items;
  const iterator = stack.values();

  for (const menuKey of iterator) {
    parent = itemsToIterate[menuKey];
    itemsToIterate = parent.children;
  }

  createListElements(itemsToIterate, listElementsToRender);
  const showBackButton = !isEmpty(stack) && stack.length >= 1;

  return (
    <ul className='b-menu-items'>
      {!showBackButton &&
      <React.Fragment>
        {registeredUser(userType) &&
        <React.Fragment>
          <li className='list-element x-headline-element'>
            {userName}
          </li>
          <li className='list-element x-user-element'>
            <LinkWrapper
              className='link-button x-logout'
              hrefValue={Translate.translate('xurls.logout')}
              contents={translate('navigation.logOut')} />
          </li>
        </React.Fragment>}
        {unAuthOrGuestUser(userType) &&
        <React.Fragment>
          <li className='list-element x-headline-element'>
            {translate('navigation.welcome')}
          </li>
          <li className='list-element x-guest-element'>
            <LogInJoin
              extensionClasses='x-menu'
              buttonClasses='link-button'/>
          </li>
        </React.Fragment>}
      </React.Fragment>}
      {showBackButton &&
      <React.Fragment>
        <li className='list-element x-parent-back-element'>
          <button
            className='nav-link'
            type='button'
            onClick={onBackClick}
            aria-label={translate('checkout.shipping.form.back')}>
            <Icon iconName='chevron-left' />
            <span className='icon-text'>{translate('checkout.shipping.form.back')}</span>
          </button>
        </li>
        <li className='list-element x-parent-headline-element'>
          {parent.text}
        </li>
      </React.Fragment>}
      {listElementsToRender}
    </ul>
  );
};

MenuItems.propTypes = {
  items: PropTypes.objectOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    slug: PropTypes.string,
    isEmphasized: PropTypes.bool,
    hasSeperator: PropTypes.bool,
    children: PropTypes.object
  })).isRequired,
  userType: PropTypes.oneOf([UNAUTHENTICATED, GUEST, REGISTERED]),
  userName: PropTypes.string
};

export default MenuItems;
