module.exports = {
	"head": {
		"title": "Shop {content} | Spoonflower",
		"productTitle": {
			"fabric": "Discover Unique Fabric Designs | Spoonflower"
		},
		"substrateMetaDescription": "Shop a wide range of {content} at Spoonflower. Choose from a variety of high-quality materials, patterns and designs to bring your projects to life."
	},
	"fabricSearchPlaceholder": "Quick-search fabrics",
	"fabricSearchPlaceholderQuick": "Quick-search {searchCategory}",
	"queryPlaceholder": "Search",
	"querySitewide": "Site Search",
	"searchButtonTitle": "Search",
	"substrateOptions": {
		"preposition": {
			"fabric": "in",
			"wallpaper": "in",
			"designers": "in",
			"collections": "in",
			"bedding": "in",
			"living&decor": "in",
			"dining": "in"
		},
		"fabric": "Fabric",
		"wallpaper": "Wallpaper",
		"designers": "Artists",
		"collections": "Collections",
		"bedding": "Bedding",
		"living&decor": "Living & Decor",
		"dining": "Dining"
	},
	"options": {
		"not-for-sale": "Not For Sale",
		"sort-by": "Sort by",
		"alphabetical": "Alphabetical",
		"best-selling": "Best Selling",
		"newest": "Newest",
		"most-favorited": "Most Favorited",
		"trending": "Trending",
		"fresh-picks": "Fresh Picks",
		"all-results": "In All Results",
		"my-connections": "In My Connections",
		"my-favorites": "In My Favorites"
	},
	"filters": "Filters",
	"browseByCategory": "Browse to a Category",
	"browseByColor": "Browse to a Color",
	"applyFilters": "Apply Filters",
	"noResults": "Oops, there are no results for '{searchQuery}'.",
	"noSearchResultsHeader": "Yikes!",
	"noSearchResultsContent": "You ran out of search results. Update your filters and try again.",
	"noResultsCategory": "Oops, there are no results for '{searchQuery}' in {category}.",
	"noConnectionResults": "You don’t have any designer connections.\nVisit designer profiles to follow designers and make connections.\n",
	"continueShopping": "Continue Shopping",
	"clearYourSearch": "Clear your search",
	"andTryAgain": "and try again!",
	"productCount": {
		"showing": "Showing",
		"of": "of",
		"products": "products"
	},
	"headerBrowseTo": "Browse to",
	"headerShow": "Show",
	"filterButton": "Filter",
	"hideFilterButton": "Hide Filters",
	"showFilterButton": "Show Filters",
	"clearFilterButton": "Clear All",
	"removeColorSelection": "Remove {color} color selection",
	"removeFilterSelection": "Remove filter selection",
	"colorSelection": "Select {color}",
	"results": "Results",
	"titlePrefix": "Shop",
	"meta": {
		"substrateName": {
			"fabric": "Fabric",
			"wallpaper": "Wallpaper"
		}
	}
};