import * as types from '../constants/ActionTypes';
import {ORDER_STATUS_PENDING, ORDER_STATUS_SAVED} from '../constants/Database';
import {combineReducers} from 'redux';
import {isUndefined, isEmpty} from '../utils/validation';

const updateOrderItems = (newOrderItemState, state, status, options = {}) => {
  const modifiedOrderItems = modifyOneOrderItem(newOrderItemState, state.order_items, status, options);

  if (isEmpty(modifiedOrderItems)) {
    return state;
  }

  return Object.assign({}, state, {
    order_items: modifiedOrderItems
  });
};

function modifyOneOrderItem(newOrderItemState, orderItems, status, options = {}) {
  if (isUndefined(orderItems) && !options.upsert) {
    return;
  }

  // Allow filtering by 'pending' and 'saved'. Modify this boolean to extend functionality to orders with other statuses.
  const isCorrectOrder = (status === ORDER_STATUS_SAVED) === newOrderItemState.saved;
  let modifiableOrderItems = isUndefined(orderItems) ? [] : Object.assign([], orderItems);
  let orderItemFound = false;

  modifiableOrderItems = modifiableOrderItems.map((orderItem) => {
    if (orderItem.id === newOrderItemState.id) {
      orderItem = Object.assign({}, orderItem, newOrderItemState);
      orderItemFound = true;
    }

    return orderItem;
  });

  if (!orderItemFound && options.upsert && isCorrectOrder) {
    modifiableOrderItems.push(newOrderItemState);
  }

  return modifiableOrderItems;
}

const deleteOrderItem = (id, orderState) => {
  const orderItems = orderState.order_items;

  if (isUndefined(orderItems)) {
    return orderState;
  }

  const modifiableOrderItems = Object.assign([], orderItems);
  const index = modifiableOrderItems.findIndex((item) => (item.id === id));

  if (index < 0) {
    return orderState;
  }
  modifiableOrderItems.splice(index, 1);

  return Object.assign({}, orderState, {
    order_items: modifiableOrderItems
  });
};

function deliveryOptions(state = {}, action) {
  switch (action.type) {
    case types.ORDER_DELIVERY_OPTIONS_GET + types.SUCCESS:
      return Object.assign({}, state, action.payload.data);
    default:
      return state;
  }
}

function genericCart(state = {}, action, status) {
  switch (action.type) {
    case types.ORDER_ITEM_REQUEST + types.FETCHING:
      return updateOrderItems(
        {
          id: action.id,
          isFetching: true
        },
        state,
        status
      );
    case types.ORDER_ITEM_REQUEST + types.FAIL:
      return updateOrderItems(
        {
          id: action.id,
          isFetching: false
        },
        state,
        status
      );
    case types.ORDER_ITEM_REQUEST + types.SUCCESS:
      return updateOrderItems(
        {
          ...action.payload.data,
          isFetching: false
        },
        state,
        status,
        {
          upsert: true
        }
      );
    case types.ORDER_ITEM_DELETE:
      return deleteOrderItem(action.id, state);
    default:
      return state;
  }
}

function pending(state = {}, action) {
  switch (action.type) {
    case types.PENDING + types.ORDER_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });

    case types.PENDING + types.ORDER_GET + types.SUCCESS:
      return Object.assign({}, action.payload.data, {
        pendingCartFetched: true,
        isFetching: false
      });

    case types.PENDING + types.ORDER_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false
      });

    case types.IDME + types.SUCCESS:
      return Object.assign({}, action.payload.data, {
        pendingCartFetched: true,
      });

    default:
      return genericCart(state, action, ORDER_STATUS_PENDING);
  }
}

function saved(state = {}, action) {
  switch (action.type) {
    case types.SAVED + types.ORDER_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.SAVED + types.ORDER_GET + types.SUCCESS:
      return Object.assign({}, action.payload.data, {
        isFetching: false
      });

    default:
      return genericCart(state, action, ORDER_STATUS_SAVED);
  }
}

function lastPaid(state = {}, action) {
  switch (action.type) {
    case types.LAST_PAID + types.ORDER_GET + types.SUCCESS:
      return action.payload.data;

    default:
      return state;
  }
}

function payment(state = {}, action) {
  switch (action.type) {
    case types.PAYMENT_TOKEN_RECEIVED:
      return Object.assign({}, state, action.payload);
    case types.SAVED_CARD_SELECTED:
      return Object.assign({}, state, action.payload);
    case types.CLEAR_PAYMENT_DETAILS:
      return {};
    default:
      return state;
  }
}

function paypal(state = {}, action) {
  switch (action.type) {
    case types.PAYPAL_INITIALIZATION_DATA:
      return Object.assign({}, state, action.payload);
    case types.BRAINTREE_PUBLIC_CLIENT_TOKEN_GET + types.SUCCESS:
      return Object.assign({}, state, {
        braintreePublicClientToken: action.payload.data.token
      });
    default:
      return state;
  }
}

function afterpay(state = {}, action) {
  switch (action.type) {
    case types.AFTERPAY_PUBLIC_CLIENT_TOKEN_GET + types.SUCCESS:
      return {
        ...state, ...action.payload.data
      };
    default:
      return state;
  }
}

function stripe(state = {}, action) {
  switch (action.type) {
    case types.STRIPE_SAVE_NEW_PAYMENT_INFO:
      return Object.assign({}, state, {
        saveNewPaymentInfo: action.payload.saveNewPaymentInfo
      });
    case types.STRIPE_CUSTOMER_GET + types.SUCCESS:
      return Object.assign({}, state, {
        customerData: action.payload.data
      });
    default:
      return state;
  }
}

function count(state = {}, action) {
  switch (action.type) {
    case types.COUNT_GET + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        isCartItemsCountFetching: false
      });
    case types.COUNT_GET + types.FAIL:
      return Object.assign({}, state, {
        isCartItemsCountFetching: false
      });
    case types.COUNT_GET + types.FETCHING:
      return Object.assign({}, state, {
        isCartItemsCountFetching: true
      });

    default: return state;
  }
}

const carts = combineReducers({
  pending,
  saved,
  deliveryOptions,
  lastPaid,
  payment,
  paypal,
  stripe,
  afterpay,
  count
});

export default carts;
