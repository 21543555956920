import {Request} from 'express';

import {EN, supportedLanguages} from '../../../../constants/SupportedLanguages';
import {AppLocalities} from '../../../../entities/pageSetup/countries/types';


export const getLanguageFromUrl = (path: Request['path']): AppLocalities => {
  const urlChunks = path.split('/').filter((p) => !!p);
  const langChunk = urlChunks[0];

  if (supportedLanguages.includes(langChunk)) {
    return langChunk as AppLocalities;
  }

  return EN as AppLocalities;
};
