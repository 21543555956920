// Codes, meaning left and right part are the same
export const ORDER_ITEM_TYPE_FABRIC_ITEM = 'ORDER_ITEM_TYPE_FABRIC_ITEM';
export const ORDER_ITEM_TYPE_WALLPAPER_ITEM = 'ORDER_ITEM_TYPE_WALLPAPER_ITEM';
export const ORDER_ITEM_TYPE_HOME_GOOD_ITEM = 'ORDER_ITEM_TYPE_HOME_GOOD_ITEM';
export const ORDER_ITEM_TYPE_KIT_ITEM = 'ORDER_ITEM_TYPE_KIT_ITEM';
export const ORDER_ITEM_TYPE_STOCK_PRODUCT = 'ORDER_ITEM_TYPE_STOCK_PRODUCT';
export const ORDER_ITEM_TYPE_SERVICE_ITEM = 'ORDER_ITEM_TYPE_SERVICE_ITEM';
export const ORDER_ITEM_TYPE_UNPRINTED_ITEM = 'ORDER_ITEM_TYPE_UNPRINTED';
export const ORDER_ITEM_TYPE_SOLID_ITEM = 'ORDER_ITEM_TYPE_SOLID_ITEM';

// TODO: SP-4079 these should be removed, along with all references
export const ORDER_ITEM_TYPE_PRODUCT = 'ORDER_ITEM_TYPE_PRODUCT';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_SAMPLE_PACK = 'PRODUCT_SUB_TYPE_SPOONFLOWER_SAMPLE_PACK';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_COLOR_GUIDE = 'PRODUCT_SUB_TYPE_SPOONFLOWER_COLOR_GUIDE';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_HANDBOOK = 'PRODUCT_SUB_TYPE_SPOONFLOWER_HANDBOOK';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_SEW_BOOK = 'PRODUCT_SUB_TYPE_SPOONFLOWER_SEW_BOOK';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_BOOK_BUNDLE = 'PRODUCT_SUB_TYPE_SPOONFLOWER_BOOK_BUNDLE';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_S = 'PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_S';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_M = 'PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_M';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_L = 'PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_L';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XL = 'PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XL';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XXL = 'PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XXL';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_GRAB_BAG = 'PRODUCT_SUB_TYPE_SPOONFLOWER_GRAB_BAG';
export const PRODUCT_SUB_TYPE_SPOONFLOWER_GRAND_SATEEN_COLOR_MAP = 'PRODUCT_SUB_TYPE_SPOONFLOWER_GRAND_SATEEN_COLOR_MAP';

export const STOCK_ITEM = 'STOCK_ITEM';
export const STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK = 'STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK';
export const STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK = 'STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK';
export const STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK = 'STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK';
export const STOCK_ITEM_SPOONFLOWER_COLOR_GUIDE = 'STOCK_ITEM_SPOONFLOWER_COLOR_GUIDE';
export const STOCK_ITEM_SPOONFLOWER_HANDBOOK = 'STOCK_ITEM_SPOONFLOWER_HANDBOOK';
export const STOCK_ITEM_WELCOME_PACK_MAKERS = 'STOCK_ITEM_WELCOME_PACK_MAKERS';
export const STOCK_ITEM_WELCOME_PACK_ARTISTS = 'STOCK_ITEM_WELCOME_PACK_ARTISTS';
export const STOCK_ITEM_SPOONFLOWER_SEW_BOOK = 'STOCK_ITEM_SPOONFLOWER_SEW_BOOK';
export const STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE = 'STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE';
export const STOCK_ITEM_SPOONFLOWER_WELCOME_PACK = 'STOCK_ITEM_SPOONFLOWER_WELCOME_PACK';
export const STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_S = 'STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_S';
export const STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_M = 'STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_M';
export const STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_L = 'STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_L';
export const STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XL = 'STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XL';
export const STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XXL = 'STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XXL';
export const STOCK_ITEM_SPOONFLOWER_GRAB_BAG = 'STOCK_ITEM_SPOONFLOWER_GRAB_BAG';
export const STOCK_ITEM_SPOONFLOWER_PERENNIAL_SATEEN_GRAND_COLOR_MAP = 'STOCK_ITEM_SPOONFLOWER_PERENNIAL_SATEEN_GRAND_COLOR_MAP';
export const STOCK_ITEM_SPOONFLOWER_LONGLEAF_SATEEN_GRAND_COLOR_MAP = 'STOCK_ITEM_SPOONFLOWER_LONGLEAF_SATEEN_GRAND_COLOR_MAP';

export const SERVICE_ITEM_TITLE_PURCHASING_PRO_RENEWAL = 'SERVICE_ITEM_TITLE_PURCHASING_PRO_RENEWAL';
export const SERVICE_ITEM_TITLE_PURCHASING_PRO = 'SERVICE_ITEM_TITLE_PURCHASING_PRO';
export const SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO = 'SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO';
export const SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO_RENEWAL = 'SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO_RENEWAL';

export const KIT_SUB_TYPE_FAT_QUARTER_BUNDLE = 'KIT_SUB_TYPE_FAT_QUARTER_BUNDLE';
export const KIT_SUB_TYPE_SMALL_SAMPLER = 'KIT_SUB_TYPE_SMALL_SAMPLER';

export const UNPRINTED_ITEM_SUB_TYPE_BLANK = 'UNPRINTED_ITEM_SUB_TYPE_BLANK';

export const SERVICE_ITEM_TYPE_CHUNKED_SPLIT_ITEM = 'SERVICE_ITEM_TYPE_CHUNKED_SPLIT_ITEM';
export const SERVICE_ITEM_TYPE_COLOR_GUIDE = 'SERVICE_ITEM_TYPE_COLOR_GUIDE';
export const SERVICE_ITEM_TYPE_COLOR_MAP = 'SERVICE_ITEM_TYPE_COLOR_MAP';
export const SERVICE_ITEM_TYPE_GIFT_CERTIFICATE = 'SERVICE_ITEM_TYPE_GIFT_CERTIFICATE';
export const SERVICE_ITEM_TYPE_OTHER = 'SERVICE_ITEM_TYPE_OTHER';
export const SERVICE_ITEM_TYPE_PRO_PURCHASE = 'SERVICE_ITEM_TYPE_PRO_PURCHASE';
export const SERVICE_ITEM_TYPE_SPOONDOLLAR_CREDIT = 'SERVICE_ITEM_TYPE_SPOONDOLLAR_CREDIT';
export const SERVICE_ITEM_TYPE_SPOONDOLLAR_DEBIT = 'SERVICE_ITEM_TYPE_SPOONDOLLAR_DEBIT';
export const SERVICE_ITEM_TYPE_SPOONDOLLAR_PAYOUT = 'SERVICE_ITEM_TYPE_SPOONDOLLAR_PAYOUT';
export const SERVICE_ITEM_TYPE_SWATCH_BOOK = 'SERVICE_ITEM_TYPE_SWATCH_BOOK';
export const SERVICE_ITEM_TYPE_WELCOME_PACK = 'SERVICE_ITEM_TYPE_WELCOME_PACK';

export const TEST_SWATCH_METER = 'TEST_SWATCH_METER';
export const TEST_SWATCH_YARD = 'TEST_SWATCH_YARD';
export const SWATCH = 'SWATCH';
export const TEST_SWATCH = 'TEST_SWATCH';
export const FAT_QUARTER = 'FAT_QUARTER';
export const FAT_QUARTER_METER = 'FAT_QUARTER_METER';
export const FAT_QUARTER_YARD = 'FAT_QUARTER_YARD';
export const YARD_METER = 'YARD_METER';

export const FABRIC = 'FABRIC';
export const FABRIC_PERENNIAL_SATEEN_GRAND = 'FABRIC_PERENNIAL_SATEEN_GRAND';
export const FABRIC_LONGLEAF_SATEEN_GRAND = 'FABRIC_LONGLEAF_SATEEN_GRAND';
export const FABRIC_CHIFFON = 'FABRIC_CHIFFON';
export const FABRIC_SILKY_FAILLE = 'FABRIC_SILKY_FAILLE';
export const FABRIC_ECO_CANVAS = 'FABRIC_ECO_CANVAS';
export const FABRIC_CELOSIA_VELVET = 'FABRIC_CELOSIA_VELVET';
export const FABRIC_PETAL_SIGNATURE_COTTON = 'FABRIC_PETAL_SIGNATURE_COTTON';
export const FABRIC_CYPRESS_COTTON_BRAVA = 'FABRIC_CYPRESS_COTTON_BRAVA';
export const FABRIC_MODERN_JERSEY = 'FABRIC_MODERN_JERSEY';
export const FABRIC_LINEN_COTTON_CANVAS_BRAVA = 'FABRIC_LINEN_COTTON_CANVAS_BRAVA';
export const FABRIC_ORGANIC_COTTON_SATEEN_BRAVA = 'FABRIC_ORGANIC_COTTON_SATEEN_BRAVA';
export const FABRIC_PERFORMANCE_VELVET = 'FABRIC_PERFORMANCE_VELVET';
export const FABRIC_COTTON_LAWN_APPAREL = 'FABRIC_COTTON_LAWN_APPAREL';
export const FABRIC_PERFORMANCE_LINEN = 'FABRIC_PERFORMANCE_LINEN';
export const FABRIC_COTTON_SILK = 'FABRIC_COTTON_SILK';
export const FABRIC_ESSEX_LINEN = 'FABRIC_ESSEX_LINEN';
export const FABRIC_SEERSUCKER = 'FABRIC_SEERSUCKER';
export const FABRIC_SPORT_STRETCH_WOVEN = 'FABRIC_SPORT_STRETCH_WOVEN';

export const FABRIC_BLANK = 'FABRIC_BLANK';
export const FABRIC_BELGIAN_LINEN = 'FABRIC_BELGIAN_LINEN';
export const FABRIC_MINKY = 'FABRIC_MINKY';
export const FABRIC_ORGANIC_COTTON_KNIT_BRAVA = 'FABRIC_ORGANIC_COTTON_KNIT_BRAVA';
export const FABRIC_LINEN_COTTON_CANVAS = 'FABRIC_LINEN_COTTON_CANVAS';
export const FABRIC_DOGWOOD_DENIM = 'FABRIC_DOGWOOD_DENIM';
export const FABRIC_LIGHTWEIGHT_COTTON_TWILL = 'FABRIC_LIGHTWEIGHT_COTTON_TWILL';
export const FABRIC_SPORT_LYCRA = 'FABRIC_SPORT_LYCRA';
export const FABRIC_RECYCLED_CANVAS = 'FABRIC_RECYCLED_CANVAS';
export const FABRIC_ORGANIC_SWEET_PEA_GAUZE = 'FABRIC_ORGANIC_SWEET_PEA_GAUZE';
export const FABRIC_PERFORMANCE_PIQUE = 'FABRIC_PERFORMANCE_PIQUE';
export const FABRIC_ORGANIC_COTTON_SATEEN = 'FABRIC_ORGANIC_COTTON_SATEEN';
export const FABRIC_COTTON_POPLIN_BRAVA = 'FABRIC_COTTON_POPLIN_BRAVA';
export const FABRIC_SATIN = 'FABRIC_SATIN';
export const FABRIC_COTTON_SPANDEX_JERSEY_BRAVA = 'FABRIC_COTTON_SPANDEX_JERSEY_BRAVA';
export const FABRIC_POLY_CREPE_DE_CHINE = 'FABRIC_POLY_CREPE_DE_CHINE';
export const FABRIC_FLEECE = 'FABRIC_FLEECE';
export const FABRIC_POLARTEC_FLEECE = 'FABRIC_POLARTEC_FLEECE';
export const DEFAULT_FABRIC = FABRIC_PETAL_SIGNATURE_COTTON;

export const WALLPAPER = 'WALLPAPER';
export const WALLPAPER_SMOOTH = 'WALLPAPER_SMOOTH';
export const WALLPAPER_WOVEN = 'WALLPAPER_WOVEN';
export const WALLPAPER_ISOBAR = 'WALLPAPER_ISOBAR';
export const WALLPAPER_GRASSCLOTH = 'WALLPAPER_GRASSCLOTH';
export const WALLPAPER_PEEL_AND_STICK = 'WALLPAPER_PEEL_AND_STICK';
export const WALLPAPER_SILVER_METALLIC = 'WALLPAPER_SILVER_METALLIC';
export const WALLPAPER_GOLD_METALLIC = 'WALLPAPER_GOLD_METALLIC';
export const WALLPAPER_ARRAY = [WALLPAPER_SMOOTH, WALLPAPER_ISOBAR, WALLPAPER_GRASSCLOTH, WALLPAPER_PEEL_AND_STICK, WALLPAPER_SILVER_METALLIC, WALLPAPER_GOLD_METALLIC];
export const WALLPAPER_SWATCH = 'WALLPAPER_SWATCH';

export const HOME_GOOD = 'HOME_GOOD';
export const HOME_GOOD_RECTANGULAR_TABLE_CLOTH = 'HOME_GOOD_RECTANGULAR_TABLE_CLOTH';
export const HOME_GOOD_ROUND_TABLE_CLOTH = 'HOME_GOOD_ROUND_TABLE_CLOTH';
export const HOME_GOOD_TABLE_RUNNER = 'HOME_GOOD_TABLE_RUNNER';
export const HOME_GOOD_PLACEMAT = 'HOME_GOOD_PLACEMAT';
export const HOME_GOOD_COCKTAIL_NAPKIN = 'HOME_GOOD_COCKTAIL_NAPKIN';
export const HOME_GOOD_DINNER_NAPKIN = 'HOME_GOOD_DINNER_NAPKIN';
export const HOME_GOOD_TEA_TOWEL = 'HOME_GOOD_TEA_TOWEL';
export const HOME_GOOD_DUVET_COVER = 'HOME_GOOD_DUVET_COVER';
export const HOME_GOOD_SHEET_SET = 'HOME_GOOD_SHEET_SET';
export const HOME_GOOD_STANDARD_PILLOW_SHAM = 'HOME_GOOD_STANDARD_PILLOW_SHAM';
export const HOME_GOOD_EURO_PILLOW_SHAM = 'HOME_GOOD_EURO_PILLOW_SHAM';
export const HOME_GOOD_LONG_LUMBAR_THROW_PILLOW = 'HOME_GOOD_LONG_LUMBAR_THROW_PILLOW';
export const HOME_GOOD_SQUARE_THROW_PILLOW = 'HOME_GOOD_SQUARE_THROW_PILLOW';
export const HOME_GOOD_KNIFE_EDGED_SQUARE_THROW_PILLOW = 'HOME_GOOD_KNIFE_EDGED_SQUARE_THROW_PILLOW';
export const HOME_GOOD_FLANGED_LUMBAR_THROW_PILLOW = 'HOME_GOOD_FLANGED_LUMBAR_THROW_PILLOW';
export const HOME_GOOD_KNIFE_EDGED_LUMBAR_THROW_PILLOW = 'HOME_GOOD_KNIFE_EDGED_LUMBAR_THROW_PILLOW';
export const HOME_GOOD_LONG_LUMBAR_THROW_PILLOW_SIZE_STANDARD_STYLE_KNIFE_EDGED = 'HOME_GOOD_LONG_LUMBAR_THROW_PILLOW_SIZE_STANDARD_STYLE_KNIFE_EDGED';
export const HOME_GOOD_LUMBAR_THROW_PILLOW = 'HOME_GOOD_LUMBAR_THROW_PILLOW';
export const HOME_GOOD_THROW_BLANKET = 'HOME_GOOD_THROW_BLANKET';
export const HOME_GOOD_CURTAIN_PANEL = 'HOME_GOOD_CURTAIN_PANEL';
export const HOME_GOOD_WALL_HANGING = 'HOME_GOOD_WALL_HANGING';

// Solids
export const SOLID = 'SOLID';
export const SOLID_PETAL_WHITE = 'SOLID_PETAL_WHITE';
export const SOLID_PETAL_NATURAL = 'SOLID_PETAL_NATURAL';
export const SOLID_PETAL_BLACK = 'SOLID_PETAL_BLACK';
export const SOLID_PETAL_CINNAMON = 'SOLID_PETAL_CINNAMON';
export const SOLID_PETAL_NAVY = 'SOLID_PETAL_NAVY';
export const SOLID_FABRIC_PETAL = 'SOLID_FABRIC_PETAL';
export const SOLID_COLOR_WHITE = 'SOLID_COLOR_WHITE';
export const SOLID_COLOR_NATURAL = 'SOLID_COLOR_NATURAL';
export const SOLID_COLOR_BLACK = 'SOLID_COLOR_BLACK';
export const SOLID_COLOR_CINNAMON = 'SOLID_COLOR_CINNAMON';
export const SOLID_COLOR_NAVY = 'SOLID_COLOR_NAVY';

// HomeGoodTypeGeneric
export const CURTAIN = 'CURTAIN';
export const DUVETCOVER = 'DUVET_COVER';
export const FLANGED = 'FLANGED';
export const KNIFE_EDGED = 'KNIFE_EDGED';
export const NAPKIN = 'NAPKIN';
export const PILLOW = 'PILLOW';
export const INSERT = 'INSERT';
export const PLACEMAT = 'PLACEMAT';
export const SHEETSET = 'SHEET_SET';
export const TABLECLOTH = 'TABLE_CLOTH';
export const TABLERUNNER = 'TABLE_RUNNER';
export const TEATOWEL = 'TEA_TOWEL';
export const BLANKET = 'BLANKET';
export const WALLHANGING = 'WALL_HANGING';

export const SIZE_STANDARD = 'SIZE_STANDARD';
export const SIZE_KING = 'SIZE_KING';
export const SIZE_18 = 'SIZE_18';
export const SIZE_20 = 'SIZE_20';
export const SIZE_16_BY_72 = 'SIZE_16_BY_72';
export const SIZE_50_BY_84 = 'SIZE_50_BY_84';
export const SIZE_70_ROUND = 'SIZE_70_ROUND';
export const SIZE_70_SQUARE = 'SIZE_70_SQUARE';
export const SIZE_16_BY_24 = 'SIZE_16_BY_24';
export const STYLE_STANDARD = 'STYLE_STANDARD';
export const STYLE_KNIFE_EDGED = 'STYLE_KNIFE_EDGED';
export const SHEET_PRODUCT_SUFFIX = '_SHEET';
export const DUVET_PRODUCT_SUFFIX = '_DUVET';

export const CART_CONTENT_CHANGED = 'CART_CONTENT_CHANGED';
export const BUTTON_CONTENT_DEFAULT = 'BUTTON_CONTENT_DEFAULT';

export const RETURN_HTML = 'RETURN_HTML';
export const RETURN_JSX = 'RETURN_JSX';

export const UNSET_FLAG = 'UNSET_FLAG';

export const UNPRINTED_FABRIC = 'UNPRINTED_FABRIC';

export const NEW_FABRICS = [FABRIC_COTTON_SILK, FABRIC_ESSEX_LINEN, FABRIC_SEERSUCKER, FABRIC_SPORT_STRETCH_WOVEN, FABRIC_LONGLEAF_SATEEN_GRAND, FABRIC_PERENNIAL_SATEEN_GRAND];
