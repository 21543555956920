// import { isNotUndefined } from './validation';
import pick from 'lodash/pick';

// import { State } from '../store/initialState';
import {RequestError, commonErrorProps} from './errors';

// todo refactor error handling logic
//  this is a typescript version of RequestErrorTS
// Wrap errors caught in axios requests to contain useful data.
// export class RequestErrorTS extends Error {
//   status?: number;
//
//   constructor(fetchError: any, url: string | undefined | null, fetchOptions: any, state: State) {
//     const body: any = fetchOptions.body || fetchOptions.data;
//
//     const { fetchResult, status, body: fetchErrorBody, message, response } = fetchError;
//     const { user } = state;
//     const extendedData = {
//       request: {
//         method: fetchOptions.method,
//         // Prioritize exact URL used in fetch middleware. Fallback to url provided in call. If URL is modified in middleware, we want that reported.
//         url: isNotUndefined(fetchResult) && isNotUndefined(fetchResult.url) ? fetchResult.url : url,
//         headers: fetchOptions.headers,
//         body: isNotUndefined(body) ? (typeof body === 'string' ? JSON.parse(body) : body) : undefined
//       },
//       response: {
//         status: status || response.status,
//         body: fetchErrorBody
//       },
//       user: {
//         id: user.id,
//         // @ts-ignore
//         order_id_pending: user.order_id_pending
//       }
//     };
//     super(`${message} : ${JSON.stringify(extendedData)}`);
//     let errorToCopy = fetchError;
//
//     if (fetchError.isAxiosError) {
//       errorToCopy = pick(fetchError, commonErrorProps);
//       this.message = `${this.message}\n${this.stack?.split('\n').splice(1).join('\n')}`;
//       this.stack = fetchError.stack;
//     }
//     Object.keys(errorToCopy).forEach((key) => {
//       // @ts-ignore
//       this[key] = errorToCopy[key];
//     });
//     // So that error is not reported simply as the Error constructor. Cannot use this.constructor.name due to minification
//     // causing error types to be reported as simply 'i' for example.
//     this.name = 'RequestError';
//     this.status = status || response.status;
//   }
// }

export class RequireRedirectError extends Error {
  isRequireRedirectError: boolean;
  name: string;
  redirectUrl: string;
  redirectionSrc: string;

  constructor(e: RequestError, redirectUrl: string, redirectionSrc: string) {
    super();
    const errorToCopy = pick(e, [...commonErrorProps, 'status', 'stack', 'message']);

    Object.keys(errorToCopy).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[key] = errorToCopy[key];
    });

    this.isRequireRedirectError = true;
    this.name = 'RequireRedirectError';
    this.redirectUrl = redirectUrl;
    this.redirectionSrc = `Redirected from - ${redirectionSrc}`;
  }
}

