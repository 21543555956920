module.exports = {
	"fabricShopPage": {
		"title": "All Fabric Types | Spoonflower",
		"titleCategory": "{category} | Spoonflower"
	},
	"fabricPage": {
		"title": "{designTitle} | Spoonflower"
	},
	"fabricPDP": {
		"title": "{designTitle} Fabric | Spoonflower"
	},
	"wallpaperPDP": {
		"title": "{designTitle} Wallpaper | Spoonflower"
	},
	"homeGoodPage": {
		"title": "{designTitle} | Spoonflower"
	},
	"solidPage": {
		"title": "Solids {color} | Spoonflower"
	},
	"solidsLandingPage": {
		"title": "{fabric} Solids | Spoonflower"
	},
	"wallpaperCalculatorLandingPage": {
		"title": "Wallpaper Calculator | Spoonflower"
	},
	"productsOverview": {
		"title": "{designTitle} | Spoonflower"
	},
	"cart": {
		"title": "Cart | Spoonflower"
	},
	"shippingAddress": {
		"title": "Shipping | Spoonflower"
	},
	"deliveryOptions": {
		"title": "Delivery | Spoonflower"
	},
	"payment": {
		"title": "Payment | Spoonflower"
	},
	"reviewOrder": {
		"title": "Review Order | Spoonflower"
	},
	"confirmation": {
		"title": "Confirmation | Spoonflower"
	}
};