import React, {useState} from 'react';

import './_filter.scss';
import {isDesktop} from '../../../constants/Viewports';
import {ViewportType} from '../../../shapes/viewport';
import {isEmpty} from '../../../utils/validation';

import FilterItems from './FilterItems/FilterItems';


export interface FilterProps {
  viewport: ViewportType;
  menuItems: Record<string, FilterCategory>;
  columnExtensionClass: string;
  useLinkComponent: boolean;
  searchQuery?: string;
  filtersAreExpanded: boolean;
  filterFixedExpanded: boolean;
  subMenuHeader: string;
  currentCategory?: string;
}

export interface FilterMenuItem {
  text: string;
  href?: string;
  breadcrumbEnabled?: boolean;
  selectedText?: string;
  slug?: string;
  apiValue?: string;
}

export interface FilterCategory extends FilterMenuItem {
  children: Record<string, FilterCategory | FilterMenuItem>;
}

const Filter = ({
  viewport, menuItems, columnExtensionClass, useLinkComponent, searchQuery, filtersAreExpanded,
  subMenuHeader, currentCategory, filterFixedExpanded
}: FilterProps): JSX.Element => {
  const viewportIsDesktop = isDesktop(viewport);

  const getMenuKeys = (initialStack: string[], key: string, breadcrumbEnabled = false) => {
    const newStack: string[] = [...initialStack];

    !isEmpty(newStack) && newStack.includes(key) && newStack.splice(newStack.indexOf(key));
    newStack.push(key);


    return newStack;
  };

  const constructInitialFilters = (filtersAreExpanded: boolean, items: Record<string, FilterCategory>) => {
    if (!filtersAreExpanded) return [];
    let result: string[] = [];

    for (const key of Object.keys(items)) {
      result = getMenuKeys(result, key);
    }

    return result;
  };

  const [stack, setStack] = useState<string[]>(constructInitialFilters(filtersAreExpanded, menuItems));

  const setMenuKeys = (key: string, breadcrumbEnabled = false) => {
    setStack(getMenuKeys(stack, key, breadcrumbEnabled));
  };

  const onBackClick = () => {
    setStack(stack.slice(0, -1));
  };

  return (
    <div className='b-filter'>
      <FilterItems
        items={menuItems}
        stack={stack}
        viewportIsDesktop={viewportIsDesktop}
        getMenuKeys={setMenuKeys}
        onBackClick={onBackClick}
        columnExtensionClass={columnExtensionClass}
        useLinkComponent={useLinkComponent}
        searchQuery={searchQuery}
        filterFixedExpanded={filterFixedExpanded}
        subMenuHeader={subMenuHeader}
        currentCategory={currentCategory}
      />
    </div>
  );
};


export default Filter;
