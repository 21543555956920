
import {
  RoutingDataActions, RoutingDataSetActionType,
} from '../actions/routingData';
import {State} from '../store/initialState';


type RoutingDataState = State['routingData']

const initialState: RoutingDataState = {
  routesProps: {},
  routesParams: {}
};

function routingData(state: RoutingDataState = initialState, action: RoutingDataActions): RoutingDataState {
  switch (action.type) {
    case RoutingDataSetActionType:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
}

export default routingData;
