import Translate from 'i18n-react';

import {MultiLocaleRouter} from '../services';


export const COLLECTION_RESULT_LIMIT_PER_PAGE = 20;
export const COLLECTIONS = 'collections';
export const thumbnailDimensions = '174';

export const isCollectionsSearch = (params: Record<string, string>): boolean => params.on === COLLECTIONS;

export const emptyFavoriteCollections = {
  collection_favorites: [],
  isFetchingFavorites: false
};

export const collectionUrlCreator = (urlPath: string, internationalUrl = false, productType?: string): string => {
  const internationalizedURL = productType ?
    `${MultiLocaleRouter.localePathname('collections')}/${urlPath}?productType=${productType}` :
    `${MultiLocaleRouter.localePathname('collections')}/${urlPath}`;

  return internationalUrl ? internationalizedURL :
    Translate.translate('xurls.collection', {
      collectionId: urlPath
    })?.toString() || ''; // TODO: SP-8362 Refactor or extend translate utility.
};

export const collectionIdFromSlug = (slug: string): string => slug.substring(0, slug.indexOf('-'));
