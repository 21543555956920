import {AnyAction} from 'redux';

import {SHOULD_RESET_RENDER_NOT_FOUND} from '../constants/ActionTypes';


function resetRenderNotFound(): AnyAction {
  return {
    type: SHOULD_RESET_RENDER_NOT_FOUND,
  };
}

export default resetRenderNotFound;
