/* global ApiResponse */
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {State} from '../../../store/initialState';
import selectCTHost from '../../pageSetup/apiHosts/selectors/selectCTHost';


interface MergeResponse {
  mergeStatus: string;
}

export const mergeCart =
  (guestCartID: string, userCustomerID: string, userCurrency: string): ThunkAction<Promise<void> | void, State, unknown, AnyAction> => (dispatch: Dispatch, getState) => {
    const state = getState();
    const ctCartUrl = selectCTHost(state);


    return fetch(`${ctCartUrl}/api/commercetools/merge-carts`, {
      body: JSON.stringify({
        guestCartID,
        userCustomerID,
        userCurrency
      }),
      method: 'post',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      credentials: 'include'
    })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

