import {TreeItemContentProps, useTreeItem} from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';

import {translate} from '../../../services';
import Icon from '../../Reusable/Icon/Icon';
import {findLeafByPath} from '../SearchFilters/utils';

import {constructLeafRenderId, TreeBranch} from './SearchTree';


export const CollapsedContent = React.forwardRef((
  props: TreeItemContentProps & { nodes: TreeBranch; selectedLeafs: string[] },
  ref,
) => {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    nodes,
    selectedLeafs
  } = props;

  const treeProps = useTreeItem(nodeId);
  const {
    disabled,
    expanded,
    selected,
    focused,
    handleSelection,
    handleExpansion
  } = treeProps;

  const icon = iconProp || expansionIcon || displayIcon;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      }, 'custom-collapsed-content')}
      onClick={handleExpansion}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div className='collapsed-tag-wrapper'>
        {!expanded && selectedLeafs.map((item) => {
          if (item.includes(nodeId)) {
            const selectedNode = findLeafByPath(nodes, item);

            if (!selectedNode) return null;

            const nodeId = constructLeafRenderId(selectedNode);


            return (
              <div className='collapsed-tag' id={nodeId} onClick={handleSelection}>
                {selectedNode?.name || item}
                <Icon id={`${nodeId || item}-close-btn`}
                  iconName='close'
                  iconTitle={translate('search.removeFilterSelection')}
                  extensionClass='cross-icon'
                />
              </div>
            );
          } else {
            return null;
          }
        })
        }
      </div>

      <div className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        component='div'
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});

CollapsedContent.displayName = 'CollapsedContent';
