import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import renderNotFound from '../../../actions/shouldRenderErrorPage';
import {State} from '../../../store/initialState';


export type CmsQueryParams = {
  name: State['pageSetup']['pageName'];
  lang: State['pageSetup']['pageLang'];
  purge?: number;
};


/* The CMS is handled by another application, so we no longer need to request CMS content.
This request is tightly bound to the legacy code and to keep it untouched, this request simply throws an exception to show 404 **/
export default function fetchCmsContent(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    dispatch(renderNotFound());
  };
}
