/* global Collection */
import type {CountriesCollection, Currency} from '../entities/pageSetup/countries/types';
import {UserDefaults} from '../entities/user/types';
import {isNotUndefined} from '../utils/validation';

import {COUNTRY_CODE_US} from './Countries';
import {IMPERIAL, MeasurementSystem, METRIC} from './Measurements';
import {ADULT_CONTENT_OFF, ADULT_CONTENT_ON} from './Parameters';
import {USD} from './Payment';


export const USER_PREFERENCES_FORM_NAME = 'userPreferences';
export const DO_NOT_SELL_OR_SHARE = 'do-not-sell-or-share';
export const USER_PREFERENCES = 'user-preferences';

export const getDefaultCountry = (userDefaults: UserDefaults): string =>
  (isNotUndefined(userDefaults) && userDefaults.country.length ? userDefaults.country : COUNTRY_CODE_US)
;

export const getDefaultCurrency = (countries: CountriesCollection, defaultCountry: string): Currency => countries[defaultCountry].currency || USD;

export function getDefaultMeasurementSystem(defaultCountry: string): MeasurementSystem {
  const countriesUsingImperial = ['US', 'LR', 'MM'];

  return countriesUsingImperial.includes(defaultCountry) ? IMPERIAL : METRIC;
}

export function toggleMatureContentFilter(
  adultContent: string,
  updateSearchMatureContentFilter: (param: boolean, currentOrigin: string) => void,
  dispatchMatureContentFilterToggle: (params: { preferences: Collection<string, string> }) => void,
  currentOrigin: string
): void {
  if (adultContent === ADULT_CONTENT_OFF) {
    updateSearchMatureContentFilter(true, currentOrigin);
    dispatchMatureContentFilterToggle({
      preferences: {
        adult_content: ADULT_CONTENT_ON
      }
    });
  } else {
    updateSearchMatureContentFilter(false, currentOrigin);
    dispatchMatureContentFilterToggle({
      preferences: {
        adult_content: ADULT_CONTENT_OFF
      }
    });
  }
}

export const currenciesAvailableForCountry = (countries: CountriesCollection, country: string): Array<Currency> =>
  countries[country].available_currencies;
