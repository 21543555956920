import {createSelector} from 'reselect';

import {State} from '../../../store/initialState';


export const selectPageSetup = (state: State): State['pageSetup'] => state.pageSetup;

export const selectPageName = createSelector([selectPageSetup], (pageSetup) => pageSetup.pageName);
export const selectPageLang = createSelector([selectPageSetup], (pageSetup) => pageSetup.pageLang);
export const selectViewport = createSelector([selectPageSetup], (pageSetup) => pageSetup.viewport);
export const selectPageKey = createSelector([selectPageSetup], (pageSetup) => pageSetup.pageKey);
