import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {orderPendingFetching, orderPendingReceived} from '../../../actions/orders';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {PaymentOptions, Pricing} from '../../../shapes/invoice';
import {OrderItemObject} from '../../../shapes/orderitems';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import selectAlpenroseHost from '../../pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectUserCartId} from '../../user/selectors';


interface ShippingAddress {
  required: boolean;
  ship_to: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
}

interface PromoDetails {
  order_promo: {
    name_human_readable: string;
    removable: boolean;
    multiple: boolean;
  };
}

interface FetchCartOrdersResponse {
 data: {
  id: number;
  user_id: number;
  status: 'string';
  shipping_address: ShippingAddress;
  order_items: OrderItemObject[];
  pricing: Pricing;
  hide_prices: boolean;
  delivery_option: string;
  factory: string;
  promo_details: PromoDetails;
  payment_options: PaymentOptions;
 };
}


export function fetchCartOrders(): ThunkAction<Promise<void | FetchCartOrdersResponse>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const cartId = selectUserCartId(state);
    const now = new Date().toISOString();
    const eventString = `"fetchCartOrders" for "${cartId}" on path "${state?.routing?.locationBeforeTransitions?.pathname}" started at "${now}"`;

    // eslint-disable-next-line no-console
    console.log(`${eventString} STARTED`);

    if (!cartId) return Promise.resolve();

    dispatch(orderPendingFetching());

    return request(state, {
      url: `${selectAlpenroseHost(state)}/order/${cartId}`,
    })
      .then((response) => {
        dispatch(orderPendingReceived(response.data));

        // eslint-disable-next-line no-console
        console.log(`${eventString} SUCCEEDED`);

        return response.data as FetchCartOrdersResponse;
      })
      .catch((error: RequestError) => {
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(error));

          // eslint-disable-next-line no-console
          console.log(`${eventString} FAILED`);
        }
      })
      .finally(() => {
        // eslint-disable-next-line no-console
        console.log(`${eventString} ENDED`);
      });
  };
}
