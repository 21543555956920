module.exports = {
	"metaTitle": "Spoonflower | Account Data Deletion",
	"description1": "Based upon the information you provided to us in your request, Spoonflower will delete personal information we have located regarding you and direct our Service Providers to do the same, except personal information that is subject to an exception. No further action is needed from you.",
	"ulTitle": "Personal information related to you that Spoonflower has retained is subject to an exception under applicable law, because that information is required to:",
	"li1": "Complete the transaction for which the personal information was collected, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between us and you.",
	"li2": "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity.",
	"li3": "Debug to identify and repair errors that impair existing intended functionality.",
	"li4": "Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided by law.",
	"li5": "To enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us.",
	"li6": "Comply with a legal obligation.",
	"li7": "Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.",
	"description2": "We will not re-identify or link any anonymous information that may relate to you for the purpose of deleting it in response to your request. Additionally, we are not able to delete information about you that another user may have uploaded to their own account (for example, if another user has included your information in their address book). We may have also retained information about you that we have received in our capacity as a Service Provider for a different business. If you have reason to believe that Spoonflower has any such information, we recommend contacting that business directly for instructions on how to submit a deletion request. If any information about you is stored in an archival database or other backup system, we will retain that information until it is purged pursuant to our ordinary schedule. We also will retain a record of your request, as required by law.",
	"footer1": "Feel free to contact the Spoonflower Privacy Office at",
	"footer2": "if you have any further questions.",
	"link": "please complete the form here",
	"confirmedInProgress": {
		"title": "Your account deletion request has been confirmed, and it is in progress",
		"text": "Thank you for confirming your request to delete your account and any personal information that Spoonflower has collected about you.",
		"text2": "We aim to fulfill all requests within 45 days, but if we are not able to complete your request in that timeframe, we will notify you of our need for an extension."
	},
	"confirmed": {
		"title": "Your account deletion request has been confirmed",
		"text1": "Thank you for confirming your request to delete your account and any personal information that Spoonflower has collected about you.",
		"text2": "Based upon the information you provided to us in your request, Spoonflower does not have personal information regarding you in our databases that we would be required to delete under applicable law. Please note that we will maintain a record of your request as required by law."
	},
	"confirmedInfo": {
		"title": "Your information access request has been confirmed",
		"text1": "Thank you for confirming your request to know the personal information that Spoonflower has collected about you.",
		"text2": "Based upon the information you provided to us in your request, Spoonflower is generating a document for you which you will receive via email when it is complete."
	},
	"expired": {
		"title": "Your request has expired",
		"text": "Thank you for reaching out regarding submitting a request to delete your account and any personal information that Spoonflower has collected about you in the past twelve months.",
		"footer1": "Your request has expired and we will not be fulfilling your account and personal information deletion request. If you would like to submit a new request to delete your account and the personal information that Spoonflower has collected about you,",
		"footer2": "or email us at",
		"footer3": "again."
	},
	"expiredInfo": {
		"title": "Your request has expired",
		"text": "Thank you for reaching out regarding submitting an information access request to see any personal information that Spoonflower has collected about you in the past twelve months.",
		"footer1": "Your request has expired and we will not be fulfilling it. If you would like to submit a new request to access the personal information that Spoonflower has collected about you,",
		"footer2": "or email us at",
		"footer3": "again."
	},
	"cancelled": {
		"title": "Your request has been cancelled and will not be processed",
		"text": "Thank you for reaching out regarding submitting a request to delete your account and any personal information that Spoonflower has collected about you in the past twelve months.",
		"footer1": "Because you confirmed you made this request in error, we will not be fulfilling your request. If you would still like to submit a request to delete your account and the personal information that Spoonflower has collected about you,",
		"footer2": "or email us at",
		"footer3": "again."
	},
	"cancelledInfo": {
		"title": "Your request has been cancelled and will not be processed",
		"text": "Thank you for reaching out regarding submitting an information access request to see any personal information that Spoonflower has collected about you in the past twelve months.",
		"footer1": "Because you confirmed you made this request in error, we will not be fulfilling your request. If you would still like to submit a request to access the personal information that Spoonflower has collected about you,",
		"footer2": "or email us at",
		"footer3": "again."
	}
};