import * as types from '../constants/ActionTypes';

function allProducts(state = {}, action) {
  switch (action.type) {
    case types.ALL_PRODUCTS_GET + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        isFetching: false
      });
    case types.ALL_PRODUCTS_GET + types.FETCHING:
      return Object.assign({}, state, action.data, {
        isFetching: true
      });
    case types.ALL_PRODUCTS_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false
      });

    default:
      return state;
  }
}

export default allProducts;
