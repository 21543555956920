/* global ApiResponse */
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {UNPRINTED_FABRIC} from '../../../constants/Codes';
import {YARD} from '../../../constants/Measurements';
import {State} from '../../../store/initialState';
import CartOperationsSingleton from '../../../utils/cartOperations';
import {addToCTCartEvent} from '../../../utils/dataLayer';
import selectCTHost from '../../pageSetup/apiHosts/selectors/selectCTHost';

import {fetchCartCount} from './fetchCartCount';


export interface AddToCartRequestBody {
  fabric?: string;
  fabric_size?: string;
  design_id?: number;
  quantity?: number;
  home_good_code?: string;
  stock_product_code?: string;
  status?: string;
  home_good_addition_codes?: string[];
  saved?: boolean;
  chunk_size?: number;
  yards?: string;
}

export const addToCTCartDispatch = (productTypeAbbreviated: string, requestBody: AddToCartRequestBody, userId: string, isGuest: boolean):
  ThunkAction<Promise<void> | void, State, unknown, AnyAction> => (dispatch: Dispatch, getState) => {
  const state = getState();
  const ctCartUrl = selectCTHost(state);

  const fetchUrl = `${ctCartUrl}/api/spoonflower/add-to-cart`;
  const fetchBody = productTypeAbbreviated === UNPRINTED_FABRIC ?
    {
      fabric: requestBody.fabric, fabric_size: YARD, quantity: parseInt(requestBody.yards || '1', 10), status: 'pending'
    } : requestBody;
  const fetchOptions = {
    method: 'POST',
    body: fetchBody,
    withCredentials: true
  };


  return CartOperationsSingleton.dispatchAddToCart(fetchOptions, fetchUrl, isGuest).then((data: any) => {
    const newOrderItem = data.data;

    if (newOrderItem?.addToCart?.order_id) {
      dispatch(fetchCartCount(true) as unknown as AnyAction);
    }

    window.dataLayer.push(addToCTCartEvent(fetchBody, userId));

    return newOrderItem || data;
  });
};

