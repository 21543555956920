import {camelCase} from 'lodash';
import isUndefined from 'lodash/isUndefined';

import {COLOR_PARAM, ON_PARAM, STYLE_PARAM, SUBSTRATE_PARAM, TOPIC_PARAM, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER} from '../../../constants/Parameters';
import {REGEX_REMOVE_ALL_SPACES} from '../../../constants/Validation';
import {SearchParamType} from '../../../shapes/searchParams';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import {TreeBranch} from '../SearchTree/SearchTree';

import {Tree} from './SearchFilters';


export const searchLeafInTree = (treeBranch: TreeBranch, value: string): string | undefined | TreeBranch => {
  const {name, apiValue} = treeBranch;
  const nameWithoutSpaces = name.replace(REGEX_REMOVE_ALL_SPACES, '');

  const parentId = treeBranch.id;
  const nodeValue = (parentId.includes('productMenu') && apiValue ? apiValue : nameWithoutSpaces).toLowerCase();
  const searchableValue = value.replace(REGEX_REMOVE_ALL_SPACES, '').toLowerCase();
  let nodeId;
  const nodeHasChildren = isNotUndefined(treeBranch.children);

  if (nodeValue === searchableValue) {
    nodeId = treeBranch.id + (nodeHasChildren ? '' : `+${isNotUndefined(apiValue) && parentId.includes('productMenu') ? apiValue : name}`);

    return nodeId;
  } else if (isNotUndefined(treeBranch.children)) {
    for (let i = 0; i < treeBranch.children.length; i++) {
      const result = searchLeafInTree(treeBranch.children[i], value);

      if (!isUndefined(result)) {
        nodeId = result;

        return nodeId;
      }
    }
  }
};

type SearchParams = SearchParamType;
/*
 * The function is used to traverse a tree
 * to find value and concatenate it to the id.
 * It is later used as an indicator to tell if
 * the node is a leaf or a branch.
 * The way of traversing: `Depth-first search`
 * */
export const leafsIdsFromQueryParams = (searchParams: SearchParams, searchFiltersTree: Tree): string[] | undefined => {
  const keysToProcess = [COLOR_PARAM, ON_PARAM, TOPIC_PARAM, STYLE_PARAM];
  const cache = new Map<string, string[]>();
  const stringifiedSearchParams = JSON.stringify(searchParams);

  if (cache.has(stringifiedSearchParams)) return cache.get(stringifiedSearchParams);

  const ids = Object.entries(searchParams).reduce((prev, current) => {
    const [key, value] = current;

    if (!keysToProcess.includes(key) || isEmpty(value)) return prev;

    let treeBranch: TreeBranch;

    if (key === ON_PARAM) {
      treeBranch = searchFiltersTree['productMenu'];
    } else {
      const paramKey = `${key}Menu`;

      treeBranch = searchFiltersTree[paramKey];
    }

    const values = value.split(',');

    values.forEach((item) => {
      const substrate = key === ON_PARAM && value === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER && camelCase(searchParams[SUBSTRATE_PARAM]);
      const leafValue = searchLeafInTree(treeBranch, substrate || item);

      typeof leafValue === 'string' && prev.push(leafValue);
    });

    return prev;
  }, [] as string[]);

  cache.set(stringifiedSearchParams, ids);

  return cache.get(stringifiedSearchParams);
};

export const treeInitialBranchesAndLeafs = (
  searchParams: SearchParams,
  searchFiltersTree: Tree
): {
  branchesIdsToExpandInitially: string[];
  leafsIdsToSelectInitially: string[];
} => {
  const leafsIds = leafsIdsFromQueryParams(searchParams, searchFiltersTree);

  const branchesIdsToExpandInitially: string[] = [];
  const leafsIdsToSelectInitially: string[] = [];

  isNotUndefined(leafsIds) &&
  leafsIds.forEach((leaf) => {
    const leafPathIds = leaf.split('.');

    leafsIdsToSelectInitially.push(leaf);

    leafPathIds.pop();

    branchesIdsToExpandInitially.push(...convertIdToPathIds(leafPathIds.join('.')));
  });

  return {
    branchesIdsToExpandInitially, leafsIdsToSelectInitially
  };
};

export const nodeIsLeaf = (node: string): boolean => node.includes('+');

/*
* Receives `id` in following format: outerId.innerId.innerId2
* Returns [outerId, outerId.innerId, outerId.innerId.innerId2]
* */
export const convertIdToPathIds = (id: string): string[] => {
  const path: string[] = id.split('.');
  const pathIds: string[] = [];
  const cache = new Map<string, string[]>();

  if (cache.has(id)) return cache.get(id) as string[];

  let currentId = '';

  for (let i = 0; i < path.length; i++) {
    currentId += i === 0 ? path[i] : `.${path[i]}`;
    pathIds.push(currentId);
  }

  cache.set(id, pathIds);

  return cache.get(id) as string[];
};

export const findLeafByPath = (branch: TreeBranch, path: string): TreeBranch | undefined => {
  if (!branch.children || !branch.children.length || !nodeIsLeaf(path) || !path.includes(branch.id)) return;

  const leafPathIds = path.split('+')[0].split(branch.id)[1].split('.');

  leafPathIds.shift();

  let curNode: TreeBranch | undefined = branch;

  leafPathIds.forEach((key: string, index: number, arr: string[]) => {
    const curKey = `${branch.id}.${arr.slice(0, index + 1).join('.')}`;

    curNode = curNode?.children?.find((child) => child.id === curKey);
  });

  return curNode;
};

export const recursiveBranchPicker = (children: readonly TreeBranch[], acc: string[] = []): string[] => {
  children.forEach((child) => {
    if (child.children) {
      acc.push(child.id);
      recursiveBranchPicker(child.children, acc);
    }
  });

  return acc;
};


