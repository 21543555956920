import * as types from '../constants/ActionTypes';

export function wallpaperRecommendationFetching() {
  return {
    type: types.WALLPAPER_RECOMMENDATION_POST + types.FETCHING
  };
}

export function wallpaperRecommendationFail() {
  return {
    type: types.WALLPAPER_RECOMMENDATION_POST + types.FAIL
  };
}

export function wallpaperRecommendationPosted(payload) {
  return {
    type: types.WALLPAPER_RECOMMENDATION_POST + types.SUCCESS,
    payload
  };
}

export function wallpaperLandingRecommendationFetching() {
  return {
    type: types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.FETCHING
  };
}

export function wallpaperLandingRecommendationFail() {
  return {
    type: types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.FAIL
  };
}

export function wallpaperLandingRecommendationPosted(payload) {
  return {
    type: types.WALLPAPER_LANDING_RECOMMENDATION_POST + types.SUCCESS,
    payload
  };
}
