import {AnyAction} from 'redux';

import * as types from '../constants/ActionTypes';
import {State} from '../store/initialState';


function transitionMessage(state = {}, action: AnyAction): State['transitionMessage'] {
  switch (action.type) {
    case types.TRANSITION_MESSAGE_SET_MESSAGE:
      return Object.assign({}, state, action.payload);

    case types.TRANSITION_MESSAGE_HIDE_MESSAGE:
      return Object.assign({}, state, {
        headerMessage: '',
        infoMessage: '',
        showMessage: false
      });

    default:
      return state;
  }
}

export default transitionMessage;
