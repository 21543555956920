import {useMemo} from 'react';

import {TreeBranch} from '../SearchTree/SearchTree';

import {recursiveBranchPicker} from './utils';


interface SsrExpandedBranchesProps {
  branchesToExpand: string[];
  selectedFilters: TreeBranch[];
}
export const useSsrExpandedBranches = ({branchesToExpand, selectedFilters}: SsrExpandedBranchesProps): string[] => {
  const isServer = Boolean(process.env.REACT_APP_IS_SERVER);

  return useMemo(() => {
    if (!isServer) {
      return branchesToExpand;
    }

    return recursiveBranchPicker(selectedFilters);
  }, [branchesToExpand, selectedFilters, isServer]);
};
