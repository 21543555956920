import React from 'react';

import {refProps} from '../shapes/refScroll';

import {objectIsNotEmpty} from './validation';


export const onClickPreventPropagation = (event: React.MouseEvent<HTMLElement>): void => (
  event.stopPropagation()
);

export const onClickPreventDefault = (event: React.MouseEvent<HTMLElement>): void => (
  event.preventDefault()
);

export const onClickFormPreventDefault = (event: {preventDefault: () => void}): void => (
  event.preventDefault()
);

export const handleAnchorScroll = (ref: refProps): void => {
  objectIsNotEmpty(ref) && ref.current && ref.current.scrollIntoView({
    behavior: 'smooth'
  });
};
