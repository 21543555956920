import Cookies from 'js-cookie';
import keys from 'lodash/keys';

export const setSecureCookie = (name, value, secure = true) => {
  let isSecure = secure;

  // don't set secure in test env since cookie setting won't work
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  if (process.env.NODE_ENV === 'test') {
    isSecure = false;
  }

  Cookies.set(name, value, {
    secure: isSecure
  });
};

export const getCookieNameByPrefix = (cookies, namePrefix) =>
  keys(cookies).find((cookie) => cookie.startsWith(namePrefix));
