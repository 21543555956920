export const SPOONFLOWER = 'Spoonflower';
export const TRADEMARK = 'TM';
export const REGISTERED_TRADEMARK = '®';
export const USED_DESIGNER_NAME = 'I Got Stripes Studio';

export const KEY_EVENT_ENTER = 'Enter';
export const KEY_EVENT_DOWN = 'ArrowDown';
export const KEY_EVENT_UP = 'ArrowUp';
export const CODE_EVENT_SPACE = 'Space'; // This value is found at event.code

export const copyrightWithYear = (copyrightTerminalYear: string): string => (
  `© 2008-${copyrightTerminalYear} ${SPOONFLOWER} Inc.`
);
