module.exports = {
	"subscribe": {
		"header": "Join Our Creative Community",
		"info": "When you sign up for our email or text list, you’ll be the first to hear about deals, exciting new products and much more!",
		"email": "Email address (required)",
		"email_error": "Please correct the errors below.",
		"email_error_txt": "Oops! Email address is required.",
		"phone": "Phone number for text/SMS (required)",
		"phone_error": "Oops! Phone number for text/SMS is required.",
		"sms_text": "I also want to be on the text/SMS list",
		"language": "Which language?",
		"country_optional": "Country",
		"country_error": "Oops! Country is required",
		"select_country": "Select one",
		"language_en": "English",
		"language_de": "German",
		"options_headline1": "Anything else we should know?",
		"options_headline2": "What would you like to hear about?",
		"options_headline3": "What brings you to Spoonflower today?",
		"marketing_deals": "Yes, I’d like to receive marketing messages with updates and exclusive deals.",
		"design_challenges": "I want to hear about Weekly Design Challenges",
		"maker": "I’m a maker",
		"artist": "I’m an artist",
		"designer": "I’m an interior designer",
		"creator": "I’m creating my own designs",
		"business_owner": "I’m a maker/small business owner",
		"shopping": "I’m shopping for myself or gifts",
		"wallpaper": "I’m interested in wallpaper",
		"home_decor": "I’m interested in home decor",
		"fabric": "I’m interested in fabric",
		"exclusive_deals": "Yes, I’d like to receive marketing messages with updates and exclusive deals.",
		"sms_info_terms": "By signing up via text, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Spoonflower at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View",
		"sms_info": "By checking the SMS messaging confirmation box, entering your mobile number and clicking \\\"Subscribe\\\" you are agreeing to receive up to 10 automated texts and other marketing messages per month from Spoonflower to your phone number. Messages and data rates may apply. You may change preferences or opt out at any time",
		"sms_info_part_1": "By checking the SMS messaging confirmation box, entering your mobile number and clicking \"Subscribe\" you are agreeing to receive automated texts and other marketing messages from Spoonflower to your phone number. Message frequency varies and data rates may apply. You may change preferences or opt out at any time. Visit our",
		"sms_info_policy_link": "privacy policy",
		"sms_info_part_2": "for more information.",
		"submit_txt": "Subscribe",
		"terms": "Terms",
		"privacy": "Privacy"
	},
	"subscribe_ok": {
		"header": "Thanks for Joining",
		"info": "We’re thrilled you’re here. Stay tuned for creative inspiration and great offers. At any point, you can",
		"url_text": "manage your messaging preferences",
		"for": "for"
	},
	"preferences": {
		"header": "Manage My Messaging Preferences",
		"info": "Manage your preferences below or",
		"login_header": "Log in to get more specific",
		"login_info": "If you would like to update how you receive messages about sales, favorites and more,",
		"login_info_link": "log in",
		"login_info_part2": "to your Spoonflower Account and go to Account Settings under your user icon.",
		"submit_txt": "Save"
	},
	"preferences_ok": {
		"header": "Your Preferences are Saved",
		"info": "Thanks for letting us know more about you. At any point, you can",
		"url_text": "review your message preferences",
		"for": "for"
	},
	"unsubscribe": {
		"header": "Manage Your Subscription Preferences",
		"info": "We hate spam as much as you and promise to never rent, share, or abuse your email address and contact information in any way.",
		"options_headline": "Your subscriptions:",
		"email": "Email",
		"sms": "SMS",
		"all": "Unsubscribe from all",
		"unsubscribe": "Unsubscribe successful",
		"submit_txt": "Save preferences",
		"preUnsubscribeMessage": "I don't want to receive special product/service offers any more",
		"afterUnsubscribeMessage": "You have been removed from our mailing list.",
		"unsubscribeError": "Unsubscribe failed",
		"unsubscribeText": "Please try again later."
	}
};