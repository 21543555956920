import update from 'immutability-helper';
import * as types from '../constants/ActionTypes';

function fabrics(state = {}, action) {
  switch (action.type) {
    case types.FABRICS_GET + types.SUCCESS:
      return update(
        action.payload.data,
        {
          $merge: {
            isFetching: false
          }
        }
      );
    case types.FABRICS_GET + types.FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetching: true
          }
        }
      );
    case types.FABRICS_GET + types.FAIL:
      return update(
        state,
        {
          $merge: {
            isFetching: false,
            failed: true
          }
        }
      );
    case types.SET_FABRIC_SHOP_SEARCH: {
      const {fabricShopSearch} = action.payload;

      return {
        ...state,
        fabricShopSearch,
      };
    }
    default:
      return state;
  }
}

export default fabrics;
