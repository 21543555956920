import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import parse from 'html-react-parser';
import get from 'lodash/get';
import {JQUERY_URL, CMS_BODY, CMS_TITLE} from '../../constants/CmsPages';
import {SPOONFLOWER} from '../../constants/Common';
import isPageRenderedOnServer from '../../application/ssr/isPageRenderedOnServer';

const isSSR = isPageRenderedOnServer();
const isServer = process.env.REACT_APP_IS_SERVER;
const shouldLoad = (isSSR && isServer) || !isSSR;

export default class Cms extends Component {
  componentDidUpdate() {
    const target = document.getElementById(CMS_BODY);
    const observer = new MutationObserver((mutation) => this.callScript(mutation, observer));
    const config = {
      childList: true,
      subtree: true
    };

    if (!target) {
      return;
    }

    observer.observe(target, config);
  }

  callScript(mutations, observer) {
    let counter = 0;

    mutations.forEach(function() {
      counter = ++counter;
      if (counter === mutations.length) {
        setTimeout(() => window.readyFunction(), 0);
        observer.disconnect();
      }
    });
  }

  render() {
    const {body, stylesheets, metadata, scripts, webflowPageId, webflowSiteId} = this.props;
    const cssLinks = [];
    const metaLinks = [];
    const scriptsLinks = [];
    const html = {
      'data-wf-page': webflowPageId,
      'data-wf-site': webflowSiteId
    };

    if (scripts && shouldLoad) {
      scriptsLinks.push({
        type: 'text/javascript',
        src: JQUERY_URL,
        crossorigin: 'anonymous',
      });
      for (const value of scripts) {
        scriptsLinks.push({
          type: 'text/javascript',
          src: value,
        });
      }
    }

    if (stylesheets && shouldLoad) {
      for (const value of stylesheets) {
        cssLinks.push({
          rel: 'stylesheet',
          href: value,
          media: 'print',
          onLoad: "this.media='all'",
        });
      }
    }

    if (metadata) {
      Object.keys(metadata).forEach(function(key) {
        if (metadata[key]) {
          if (key.startsWith('og:')) {
            metaLinks.push({
              property: key,
              content: metadata[key],
            });
          } else if (key !== CMS_TITLE) {
            metaLinks.push({
              name: key,
              content: metadata[key],
            });
          }
        }
      });
    }

    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={html}
          link={cssLinks}
          meta={metaLinks}
          script={scriptsLinks}
          title={get(metadata, 'title', SPOONFLOWER) || SPOONFLOWER}
        >
          {/* This CSS selector does not conform to the styleguide as it is overwriting a style that is defined in the external CMS. It is used here to prevent FOUC.*/}
          {/* We need to render it only on server if server render is successful and client should respect it if it was rendered meaning it should not remove it */}
          { isPageRenderedOnServer() ? (
            <style type='text/css'>
              {`
                .wf-body {
                  opacity: 0;
                  visibility: hidden;
                  min-height: 600px;
                }
              `}
            </style>
          ) : null }
        </Helmet>
        {parse(body)}
      </React.Fragment>
    );
  }
}

Cms.propTypes = {
  body: PropTypes.string,
  stylesheets: PropTypes.array,
  metadata: PropTypes.object,
  scripts: PropTypes.array,
  webflowPageId: PropTypes.string,
  webflowSiteId: PropTypes.string
};
