import {createSelector} from 'reselect';

import {UserState} from '../../../shapes/user';
import {State} from '../../../store/initialState';


export const selectUser = (state: State): State['user'] => state.user;

export const selectUserId = createSelector([selectUser], (user: UserState) => {
  if ('id' in user) {
    return user.id;
  }
});
export const selectUserCartId = createSelector([selectUser], (user: UserState) => {
  if ('order_id_pending' in user) {
    return user.order_id_pending;
  }
});
export const selectUserHasJwt = createSelector([selectUser], (user) => user.userHasJwt);
export const selectUserCookies = createSelector([selectUser], (user) => user.cookies);
export const selectUserGroup = createSelector([selectUser], (user) => user.userGroup);
export const selectUserDefaults = createSelector([selectUser], (user) => user.userDefaults);
