import * as types from '../constants/ActionTypes';
import {combineReducers} from 'redux';
import convertArrayToObject from '../utils/convertArrayToObject';

function imageTemplateReducer(state = {}, action) {
  switch (action.type) {
    case types.FAY_IMAGE_TEMPLATE_RECEIVED:
      return {
        ...action.payload.data
      };
    default:
      return state;
  }
}

function masterTemplatesReducer(state = {}, action) {
  let masterTemplates = {};

  switch (action.type) {
    case types.FAY_MASTER_TEMPLATES_RECEIVED:
      masterTemplates = action.payload.data;
      masterTemplates.forEach((masterTemplate) => {
        masterTemplate.fabrics = convertArrayToObject(masterTemplate.fabrics, 'id');
      });

      return convertArrayToObject(masterTemplates, 'id');
    default:
      return state;
  }
}

function collectionReducer(state = {}, action) {
  switch (action.type) {
    case types.FAY_COLLECTION_RECEIVED:
      return {
        ...action.payload.data
      };
    default:
      return state;
  }
}

function selectionsReducer(state = {}, action) {
  switch (action.type) {
    case types.FAY_MASTER_TEMPLATE_SELECTED: {
      const selectedMasterTemplateId = action.payload;

      return Object.assign({}, state, {
        masterTemplateId: selectedMasterTemplateId
      });
    }

    case types.FAY_FABRIC_SELECTED: {
      const selectedFabricId = action.payload;

      return Object.assign({}, state, {
        fabricId: selectedFabricId
      });
    }

    default:
      return state;
  }
}

const fillayard = combineReducers({
  imageTemplate: imageTemplateReducer,
  masterTemplates: masterTemplatesReducer,
  collection: collectionReducer,
  selections: selectionsReducer
});

export default fillayard;
