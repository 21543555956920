import * as types from '../constants/ActionTypes';
import initialState from '../store/initialState';

function newsletter(state = initialState.newsletter, action) {
  switch (action.type) {
    case types.USER_SUBSCRIPTION_INFO_POST + types.SUCCESS:
      return Object.assign({}, action.payload, {
        isFetching: false,
        isError: false,
        isLoaded: true,
      });
    case types.USER_SUBSCRIPTION_INFO_GET + types.FAIL:
      return Object.assign({}, state,{
        isFetching: false,
        isError: true,
        isLoaded: true,
      });
    case types.USER_SUBSCRIPTION_INFO_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        isLoaded: false,
      });
    default:
      return state;
  }
}

export default newsletter;
