module.exports = {
	"solids": {
		"headline": "How are Cotton Solids different from other fabrics on Spoonflower?",
		"textList": {
			"availableOnText": "Available on Petal Signature Cotton® only",
			"dyedText": "Dyed vs digitally printed",
			"partPriceText": {
				"text": "Half the price of "
			},
			"partPriceLink": {
				"linkText": "printed Petal Signature Cotton",
				"link": "https://www.spoonflower.com/en/shop?on=fabric&fabric=PETAL_SIGNATURE_COTTON"
			},
			"colorText": "Color on both sides"
		},
		"buttonText": "View Product Details"
	}
};