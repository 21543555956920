import {сmInOneInch, mmInOneCM} from '../constants/Measurements';


export function convertMillimeterToCentimeters(amountInMillimeter: number): number {
  return Math.floor(amountInMillimeter/10);
}

export const convertCmToInches = (amountInCm: number[] | number): number[] | number => {
  if (Array.isArray(amountInCm)) {
    return amountInCm.map((amountInCmElement: number) => Math.floor(amountInCmElement / mmInOneCM /сmInOneInch));
  }

  return Math.floor(amountInCm / mmInOneCM /сmInOneInch);
};
