import browserHistoryWrapper from '../components/Reusable/LinkWrapper/browserHistoryWrapper';
import Translate from 'i18n-react';
import * as types from '../constants/ActionTypes';
import {upsertUrlQuery} from '../utils/url';
import {MultiLocaleRouter, TranslateWrapper} from '../services';
import {REDIRECT_AUTO_COMPLETE_CATEGORIES} from '../services/Search';
import {SOLID} from '../constants/Codes';
import {addAutoApply} from '../utils/productTypeFiltering';

export function updateSearchParamsAndSubmit(payload, typeOfAction, currentOrigin, forcePush) {
  return function(dispatch, getState) {
    const {searchParams: {on: prevProduct}} = getState();

    switch (typeOfAction) {
      case types.SEARCH_PARAMS_RESET:
        dispatch(resetSearchParams(payload));
        break;
      case types.SEARCH_QUERY_CHANGED:
        dispatch(updateSearchParamsQueryChanged(payload));
        break;
      default :
        dispatch(updateSearchParams(payload));
    }

    const state = getState();

    if (REDIRECT_AUTO_COMPLETE_CATEGORIES.includes(state.searchParams.on)) {
      window.location.assign(upsertUrlQuery(Translate.translate('xurls.shop.spelunksPage'), {
        type: state.searchParams.on,
        s: state.searchParams.q
      }, currentOrigin));
    } else {
      // If the user is currently on a Solid PDP page, then don't include the color param when searching,
      // because the color param is used to set the current color of the solid PDP, and so it shouldn't
      // affect the search functionality on this page.
      //
      // A better solution would be to use a different query params for filtering search results versus
      // setting the current color of a solid PDP. However, this may have implications for URLs used by
      // marketing, so we're avoiding making that change for now.
      const isSolidPdp = state.routingData.routesProps.productType === SOLID;
      const searchParams = isSolidPdp ?
        {
          ...state.searchParams,
          color: undefined,
        } :
        state.searchParams;

      // All non-English based languages are handled within React and so can be redirected without a full page redirect
      browserHistoryWrapper.push(
        upsertUrlQuery(
          MultiLocaleRouter.localePathname('shop.home', TranslateWrapper.langCode),
          addAutoApply(searchParams, prevProduct),
          currentOrigin
        ),
        forcePush
      );
    }
  };
}

export function updateSearchParams(payload) {
  return {
    type: types.SEARCH_PARAMS_UPDATED,
    payload
  };
}

export function updateSearchParamsQueryChanged(payload) {
  return {
    type: types.SEARCH_QUERY_CHANGED,
    payload
  };
}

export function resetSearchParams(payload) {
  return {
    type: types.SEARCH_PARAMS_RESET,
    payload
  };
}
