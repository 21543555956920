import {SUBSTRATE_PARAM, URL_QUERY_PARAM_SUBSTRATE_FABRIC, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_DEFAULT} from '../constants/Parameters';
import {SearchParamType} from '../shapes/searchParams';


export const getProductTypeFilteringUpdates =
  (oldProduct: string, product: string | null | undefined, terms?: string, substrate?: string): {
    on: string;
    q?: string;
    [SUBSTRATE_PARAM]?: string;
  } => (product ?
    {
      on: String(product),
      q: terms,
      [SUBSTRATE_PARAM]: substrate ||
        (product === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER ? URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_DEFAULT : ''),
    } :
    {
      on: oldProduct || URL_QUERY_PARAM_SUBSTRATE_FABRIC,
    });

export const addAutoApply = (searchParams: SearchParamType, prevProduct: string): SearchParamType => {
  const res: SearchParamType = {
    ...searchParams,
    on: searchParams.on || prevProduct,
    autoApply: !searchParams.on,
  };
  const {autoApply, ...rest} = res;

  return autoApply ? res : rest;
};
