import classNames from 'classnames';
import React from 'react';

import {COLOR_API_PARAM_TO_COLOR_KEY_MAP_OLD_COLOR_UI} from '../../../constants/Parameters';
import './_dot.scss';


interface DotProps {
  colorName: string;
}

type ColorKey = keyof typeof COLOR_API_PARAM_TO_COLOR_KEY_MAP_OLD_COLOR_UI;

const Dot = ({colorName}: DotProps): JSX.Element => {
  const parsedColorName = colorName.split('+')[1];
  const colorNameMapped = COLOR_API_PARAM_TO_COLOR_KEY_MAP_OLD_COLOR_UI[parsedColorName.toLowerCase() as ColorKey];
  const colorDotClasses = classNames('b-dot', `x-${colorNameMapped}`);

  return (
    <span
      aria-label={parsedColorName}
      title={parsedColorName}
      className={colorDotClasses}>
      {colorName}
    </span>
  );
};

export default Dot;
