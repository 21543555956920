import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {orderCountFailed, orderCountFetching, orderCountReceived} from '../../../actions/orders';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import selectAlpenroseHost from '../../pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectUserCartId, selectUserId} from '../../user/selectors';


interface CartCount {
  cartCount: number;
}

interface CartCountResponse {
  cartCount: CartCount;
  success: boolean;
}

export const fetchCartCount =
  (newCart = false): ThunkAction<Promise<void> | void, State, unknown, AnyAction> => (dispatch: Dispatch, getState) => {
    const state = getState();
    const stayLegacy = state.user.stayLegacyUser;

    if (!stayLegacy) {
      const userId = selectUserId(state);

      if (!userId) return Promise.resolve();
      dispatch(orderCountFetching());
      const url = `${state.pageSetup.apiHosts.cartApi}/api/spoonflower/fetch-cart-count`;

      return request<CartCountResponse>(state, {
        url,
        withCredentials: true,
        method: 'post',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          query: `query Query {
            cartCount {
              cartCount
            }
          }`,
          variables: {}
        }
      })
        .then((data) => {
          dispatch(orderCountReceived({
            data: {
              count: data.data.cartCount.cartCount
            }
          }));
        })
        .catch((error: RequestError) => {
          console.error(error);
          if (process.env.REACT_APP_IS_SERVER) {
            dispatch(orderCountFailed());
            dispatch(serverError(error));
          }
        });
    } else {
      const cartId = selectUserCartId(state);

      if (!cartId) return Promise.resolve();
      dispatch(orderCountFetching());

      return request(state, {
        url: `${selectAlpenroseHost(state)}/order/${cartId}/cart_items_count`,
      })
        .then((data) => {
          console.error(data.data);
          dispatch(orderCountReceived(data.data));
        })
        .catch((error: RequestError) => {
          console.error(error);
          if (process.env.REACT_APP_IS_SERVER) {
            dispatch(orderCountFailed());
            dispatch(serverError(error));
          }
        });
    }
  };

