// An array of regular expressions that match routes which should be handled client-side
export const clientSideRoutes = [
  /(\/(en)\/(fabric-shop)|\/(de)\/(stoffe-shop)|\/(es)\/(tienda-de-telas)|\/(fr)\/(magasin-tissu))\w*\S*/,
  /^\/en\/fabric\/.+$/,
  /^\/en\/wallpaper\/.+$/,
  /^\/en\/solid-fabric$/,
  /^\/en\/home-decor\/.+\/.+\/.+$/,
  /^\/de\/stoffe\/.+$/,
  /^\/de\/tapete\/.+$/,
  /^\/de\/uni-stoffe$/,
  /^\/de\/heimtextilien\/.+\/.+\/.+$/,
  /^\/es\/telas\/.+$/,
  /^\/es\/papel-tapiz\/.+$/,
  /^\/es\/tela-lisa$/,
  /^\/es\/decoracion-hogar\/.+\/.+\/.+$/,
  /^\/fr\/tissus\/.+$/,
  /^\/fr\/papier-peint\/.+$/,
  /^\/fr\/tissus-unis$/,
  /^\/fr\/maison\/.+\/.+\/.+$/,
  /^\/en\/subscribe\/success$/,
  /^\/en\/preferences/,
  /^\/en\/preferences\/success$/,
];
