import cheerio from 'cheerio';
import {AnyAction} from 'redux';

import * as types from '../../constants/ActionTypes';
import logger from '../../logger';

import getArrayWithLinkTags from './getArrayWithLinkTags';
import getPictureTagChildrenThatHasWordInAttribute from './getPictureTagChildrenThatHasWordInAttribute';


type preloadStateType = {
  images: string[];
  scripts: string[];
  styles: string[];
};

const initialState: preloadStateType = {
  images: [],
  scripts: [],
  styles: [],
};

function preloadReducer(state = initialState, action: AnyAction): preloadStateType {
  if (process.env.REACT_APP_IS_SERVER) {
    switch (action.type) {
      case types.CMS_CONTENT_GET + types.SUCCESS: {
        const startTime = Date.now();
        const result: cheerio.Element[] = getPictureTagChildrenThatHasWordInAttribute(action.payload.contents.body, 'hero');

        logger.debug('preloadReducer cheerio took', Date.now() - startTime);

        if (result && result.length > 0) {
          const imageLinks = getArrayWithLinkTags(result);

          return {
            ...state, images: imageLinks
          };
        } else return state;
      }

      default:
        return state;
    }
  } else return state;
}

export default preloadReducer;
