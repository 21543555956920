import classNames from 'classnames';
import React from 'react';


export interface IconProps {
  iconName: string;
  iconTitle?: string;
  extensionClass?: string;
  id?: string;
}

const Icon = ({iconName, iconTitle, extensionClass, id}: IconProps): JSX.Element => {
  const iconClasses = classNames('ico', `ico-${iconName}`, extensionClass);

  // if we have a title assume that the icon is not only decorative, therefore it's not hidden for screen readers
  return (
    <span
      className={iconClasses}
      aria-hidden={!iconTitle}
      aria-label={iconTitle}
      title={iconTitle}
      {
        ...(id && {
          id
        })
      }
    />
  );
};

export default React.memo(Icon);
