import {AnyAction} from 'redux';

import * as types from '../../../../../constants/ActionTypes';
import initialState, {State} from '../../../../../store/initialState';
import {SETUP_SERVER_CACHE_TTL} from '../../../../cache/actionTypes';


function server(state: State['server'] = initialState.server, action: AnyAction): State['server'] {
  if (!process.env.REACT_APP_IS_SERVER) {
    return state;
  }

  switch (action.type) {
    case types.SERVER_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    case SETUP_SERVER_CACHE_TTL:
      return {
        ...state,
        cacheTtl: action.payload,
      };
    case types.CLEAR_SERVER_CONTEXT:
      return {
        ...initialState.server
      };
    default:
      return state;
  }
}

export default server;
