import * as types from '../constants/ActionTypes';

export const orderItemFetching = (id) => (
  {
    type: types.ORDER_ITEM_REQUEST + types.FETCHING,
    id
  }
);

export const orderItemReceived = (payload) => (
  {
    type: types.ORDER_ITEM_REQUEST + types.SUCCESS,
    payload
  }
);

export const orderItemFetchFail = (id) => (
  {
    type: types.ORDER_ITEM_REQUEST + types.FAIL,
    id
  }
);

export const orderItemDelete = (id) => ({
  type: types.ORDER_ITEM_DELETE,
  id
}
);
