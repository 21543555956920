module.exports = {
	"fabricPDP": {
		"description": "{designTitle} custom fabric by {designer} for sale on Spoonflower"
	},
	"solidPDP": {
		"description": "Check out our Solid Fabric in {color} for a brilliant cotton solid fabric that can stand alone or pair perfectly with a Spoonflower print."
	},
	"wallpaperPDP": {
		"description": "{designTitle} custom wallpaper by {designer} for sale on Spoonflower"
	},
	"homeGoodPDP": {
		"description": "{designTitle} custom {homeGoodType} by {designer} for sale on Spoonflower"
	},
	"productsOverview": {
		"description": "Buy {designTitle} custom fabric, wallpaper and home accessories by {designer} on Spoonflower"
	},
	"wallpaperCalculatorLanding": {
		"description": "Looking for an estimate of how many rolls you’ll need for your project? The Spoonflower wallpaper calculator has you covered. (No pun intended)"
	}
};