import * as types from '../constants/ActionTypes';

export function setSubstrate(payload) {
  return {
    type: types.SUBSTRATE_CHOSEN,
    payload
  };
}

export function setOrderItemSubstrateCodeSet(payload) {
  return {
    type: types.CHANGE_ORDER_ITEM_SUBSTRATE_TYPE,
    payload
  };
}

export function setDesign(payload) {
  return {
    type: types.DESIGN_CHOSEN,
    payload
  };
}

export function setQuantity(payload) {
  return {
    type: types.QUANTITY_CHOSEN,
    payload
  };
}

export function setAddition(payload) {
  return {
    type: types.HOME_GOOD_ADDITION_CHOSEN,
    payload
  };
}

export function setProductSize(payload) {
  return {
    type: types.PRODUCT_SIZE_CHOSEN,
    payload
  };
}

export function addToCartFetching() {
  return {
    type: types.ADD_TO_CART_POST + types.FETCHING
  };
}

export function addToCartFail() {
  return {
    type: types.ADD_TO_CART_POST + types.FAIL
  };
}

export function addToCartReceived(payload) {
  return {
    type: types.ADD_TO_CART_POST + types.SUCCESS,
    payload
  };
}

export function fabricPricingFetching() {
  return {
    type: types.FABRIC_PRICING_GET + types.FETCHING
  };
}

export function fabricPricingFail() {
  return {
    type: types.FABRIC_PRICING_GET + types.FAIL
  };
}

export function fabricPricingReceived(payload) {
  return {
    type: types.FABRIC_PRICING_GET + types.SUCCESS,
    payload
  };
}

export function calculatorModalErrorReset() {
  return {
    type: types.CALCULATOR_MODAL_RESET
  };
}

export function cleanAddToCartState() {
  return {
    type: types.CLEAR_ADD_TO_CART_STATE,
  };
}

