import * as types from '../constants/ActionTypes';
import {ParseRoutePropsParams} from '../shapes/routes';
import {PayloadAction, PayloadActionCreator} from '../types/redux';


export const RoutingDataSetActionType = `${types.SET_ROUTING_DATA}` as const;
export type RoutingDataActionType = typeof RoutingDataSetActionType;
export type RoutingDataAction = PayloadAction<RoutingDataActionType, ParseRoutePropsParams>;
export const routingDataSet: PayloadActionCreator<RoutingDataAction> = (payload) => ({
  type: RoutingDataSetActionType,
  payload
});

export type RoutingDataActions = RoutingDataAction

