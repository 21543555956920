module.exports = {
	"FABRIC_PERENNIAL_SATEEN_GRAND": {
		"name": "Perennial Sateen Grand",
		"description": "100% cotton sateen imported from Italy with a delicate drape and subtle sheen",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>"
	},
	"FABRIC_LONGLEAF_SATEEN_GRAND": {
		"name": "Longleaf Sateen Grand™",
		"description": "100% natural cotton fabric with a low-luster face and beautiful drape",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for tablecloths, table runners, drapery, napkins and throw pillows.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for tablecloths, table runners, drapery, napkins and throw pillows.</li>"
	},
	"FABRIC_CYPRESS_COTTON_BRAVA": {
		"name": "Cypress Cotton Canvas",
		"description": "Sophisticated basketweave texture offers lived-in luxury for home decor",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 5-7% in length and 0-1% in width</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-7% in length and 0-1% in width</li>"
	},
	"FABRIC_BELGIAN_LINEN": {
		"name": "Belgian Linen",
		"description": "Unmatched elegance",
		"details": "<li>Natural White</li> <li>Printable width: 54\" in (137 cm)</li> <li>9.88 oz per square yard (335 gsm)</li>",
		"pdpDetails": "<li>Printable width: 54\" in (137 cm)</li> <li>9.88 oz per square yard (335 gsm)</li>"
	},
	"FABRIC_LINEN_COTTON_CANVAS": {
		"name": "Linen Cotton Canvas",
		"description": "Durability of linen with the softness of cotton",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 3-6% in length and 0-1% in width.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-6% in length and 0-1% in width.</li>"
	},
	"FABRIC_MINKY": {
		"name": "Minky",
		"description": "Ultra cuddly with a soft napped finish",
		"details": "<li>White</li> <li>Estimated shrinkage: 1-2% in length and 0-1% in width</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 0-1% in width</li>"
	},
	"FABRIC_RECYCLED_CANVAS": {
		"name": "Recycled Canvas",
		"description": "A woven canvas featuring REPREVE® recycled polyester suitable for seasonal outdoor use.",
		"details": "<li>White</li> <li>Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.</li> <li>REPREVE® is a registered trademark of Unifi, Inc. in the US and other regions.</li>",
		"pdpDetails": "<li>Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.</li> <li>REPREVE® is a registered trademark of Unifi, Inc. in the US and other regions.</li>"
	},
	"FABRIC_PERFORMANCE_VELVET": {
		"name": "Performance Velvet",
		"description": "Super-soft and durable matte velvet",
		"details": "<li>White</li> <li>Estimated shrinkage: 1-3.5% in length and 0-1.5% in width</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-3.5% in length and 0-1.5% in width</li>"
	}
};