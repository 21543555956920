import get from 'lodash/get';
import {isNotUndefined} from './validation';
import {orderItemProducts, getOrderItemVariant, getOrderItemCategory, getOrderItemDepartment} from './orderItemData';
import {USD} from '../constants/Payment';
import {SPOONFLOWER} from '../constants/Common';
import {DATA_HOME_GOOD} from '../constants/DataLayer';


// DEPRECATED. use productImpressionsEvent from dataLayerEvents.js instead
export const productImpressionsEvent = (currency, designs, userId) => (
  {
    ecommerce: {
      currencyCode: currency,
      userId,
      actionField: {
        totalQuantity: designs?.length
      },
      impressions: designs
    }
  }
);

export const colorwaysImpressionsEvent = (currency, designs, userId, list) => (
  {
    ecommerce: {
      currencyCode: currency,
      userId,
      item_list_name: list,
      actionField: {
        totalQuantity: designs?.length
      },
      impressions: designs
    }
  }
);

export const colorwayClickEvent = (design, currency, userId, list) => ({
  event: 'productClick',
  ecommerce: {
    currencyCode: currency,
    userId,
    click: {
      actionField: {
        list
      },
      products: [{
        ...design
      }]
    }
  }
});

export const productClickEvent = (eventData) => {
  const {
    brand,
    category,
    currency,
    department,
    id,
    list,
    name,
    position,
    price,
    product_link,
    search_term,
    variant,
    userId
  } = eventData;

  const item_categories = category === DATA_HOME_GOOD ? {
    item_category: category,
    item_category2: department,
    item_category3: name,
  } : {
    item_category: category
  };

  return {
    event: 'select_item',
    ecommerce: {
      click: {
        currency,
        item_list_name: list,
        items: [{
          currency,
          department,
          design_id: id,
          item_brand: brand,
          item_list_name: list,
          ...item_categories,
          item_name: name,
          index: position,
          price,
          product_link,
          variant,
        }],
        search_term,
        userId
      }
    }
  };
};

export const addToCartEvent = (orderItem, userId) => (
  {
    event: 'add_to_cart',
    ecommerce: {
      currencyCode: USD,
      userId,
      add: {
        items: [
          orderItemProducts(orderItem)
        ]
      }
    }
  }
);

export const addToCTCartEvent = (orderItem, userId) => (
  {
    event: 'add_to_cart',
    ecommerce: {
      userId,
      add: {
        items: [
          orderItem
        ]
      }
    }
  }
);

export const removeFromCartEvent = (orderItem, order) => (
  {
    event: 'removeFromCart',
    userId: order.user_id,
    ecommerce: {
      currencyCode: USD,
      remove: {
        products: [
          orderItemProducts(orderItem)
        ]
      }
    }
  }
);

export const checkoutImpressionEvent = (step, checkoutTitle, orderItems, order) => {
  const products = [];
  let totalQuantity = 0;

  for (const orderItem of orderItems) {
    products.push(orderItemProducts(orderItem));
    totalQuantity += orderItem.quantity.value;
  }

  return {
    event: 'checkout',
    ecommerce: {
      currencyCode: USD,
      userId: order.user_id,
      cartId: order.id,
      checkout: {
        actionField: {
          step,
          option: checkoutTitle,
          revenue: order.pricing.raw_total_usd,
          localRevenue: order.pricing.raw_total,
          localRevenueWithoutShipping: order.pricing.raw_subtotal_without_shipping,
          localCurrencyCode: order.pricing.currency,
          totalQuantity
        },
        products
      }
    }
  };
};

export const purchaseEvent = (paidOrderItems, userId, paidOrder) => {
  const products = [];
  let totalQuantity = 0;

  for (const orderItem of paidOrderItems) {
    const orderItemAnalytics = orderItem.analytics;

    orderItemAnalytics['price'] = parseFloat(`${get(orderItem, 'pricing.raw_price_usd') / orderItemAnalytics.quantity}`).toFixed(2);
    orderItemAnalytics['variant'] = getOrderItemVariant(orderItem);
    orderItemAnalytics['category'] = getOrderItemCategory(orderItem);
    orderItemAnalytics['department'] = getOrderItemDepartment(orderItem);
    orderItemAnalytics['productSku'] = orderItemAnalytics.sku;
    orderItemAnalytics['parentSku'] = orderItemAnalytics.parent_sku;
    orderItemAnalytics['localPrice'] = parseFloat(`${get(orderItem, 'pricing.raw_price') / orderItemAnalytics.quantity}`).toFixed(2);
    orderItemAnalytics['localPriceUndiscounted'] = parseFloat(`${get(orderItem, 'pricing.raw_price_undiscounted') / orderItemAnalytics.quantity}`).toFixed(2);
    orderItemAnalytics['localCurrencyCode'] = orderItem.pricing.currency;
    orderItemAnalytics['itemPromoCode'] = get(orderItem.promo, 'name');
    orderItemAnalytics['itemIdPromoCode'] = get(orderItem.promo, 'id');
    orderItemAnalytics['itemDiscount'] = get(orderItem.promo, 'raw_amount_saved');
    orderItemAnalytics['itemDiscountUsd'] = get(orderItem.promo, 'raw_amount_saved_usd');

    products.push(orderItemAnalytics);
    totalQuantity += orderItem.quantity.value;
  }

  return {
    event: 'trackTransaction',
    user_id: userId,
    ecommerce: {
      currencyCode: USD,
      purchase: {
        actionField: {
          id: paidOrder.id,
          affiliation: SPOONFLOWER,
          revenue: paidOrder.pricing.raw_total_usd,
          localRevenue: paidOrder.pricing.raw_total,
          localRevenueWithoutShipping: paidOrder.pricing.raw_subtotal_without_shipping,
          localCurrencyCode: paidOrder.pricing.currency,
          totalQuantity,
          orderDiscount: paidOrder.pricing.raw_amount_saved,
          orderDiscountUsd: paidOrder.pricing.raw_amount_saved_usd,
          orderPromoCode: get(paidOrder.promo_details, 'order_promo.name_human_readable'),
          orderIdPromoCode: paidOrder.analytics.order_promo_id,
          shipping: `${paidOrder.analytics.raw_shipping_usd}`,
          coupon: paidOrder.analytics.order_promo_id,
          option: paidOrder.analytics.freight_class_name
        },
        products
      }
    }
  };
};

export const abTestEvent = (experimentName, experimentGroup) => (
  {
    event: experimentName,
    [experimentName]: experimentGroup
  }
);

export const ecommValueUpdatedEvent = (productSku, parentSku, ecommValue, currency, quantity = 1, department, userId, productData) => {
  const {
    addition,
    brand,
    category,
    color,
    id,
    name,
    price,
    variant,
  } = productData;

  const item_categories = category === DATA_HOME_GOOD ? {
    item_category: category,
    item_category2: department,
    item_category3: name,
  } : {
    item_category: category
  };

  return {
    currency,
    department,
    ecommerce: {
      currency,
      userId,
      item: [{
        ...item_categories,
        color,
        currency,
        department,
        design_id: id,
        item_addition: addition,
        item_brand: brand,
        item_id: productSku,
        item_name: itemNameMask(name),
        item_parent_id: parentSku,
        item_variant: variant,
        price,
        quantity,
      }]
    },
    ecommValue,
    event: 'view_item',
    pageCategory: 'product',
    parentSku,
    productSku,
    quantity,
    userId,
  };
};

export const cartContentEvent = (specificPageType, order) => {
  const products = [];
  let totalQuantity = 0;

  if (isNotUndefined(order.order_items)) {
    for (const orderItem of order.order_items) {
      products.push(orderItemProducts(orderItem));
      totalQuantity += orderItem.quantity.value;
    }

    return {
      event: 'cartContent',
      ecommerce: {
        currencyCode: USD,
        pageType: specificPageType,
        userId: order.user_id,
        cart: {
          actionField: {
            revenue: order.pricing.raw_total_usd,
            localRevenue: order.pricing.raw_total,
            localRevenueWithoutShipping: order.pricing.raw_subtotal_without_shipping,
            localCurrencyCode: order.pricing.currency,
            totalQuantity
          },
          products
        }
      }
    };
  } else {
    return {
      event: 'cartContent',
      ecommerce: {
        cartIsEmpty: true,
        pageType: specificPageType,
        userId: order.user_id,
        cart: {
          products: []
        }
      }
    };
  }
};

export const searchSubmitEvent = ({q, on}) => ({
  event: 'searchSubmit',
  q,
  on,
});

export const itemNameMask = (item_name) => item_name?.replace('®', '');

// TODO: let's move events to ./dataLayerEvents.js
