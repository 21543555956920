module.exports = {
	"UNAUTHORIZED": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"SERVER_ERROR": "Yikes! Our website is having trouble. Please try again later.",
	"EXPIRED_SESSION": "Your session has expired, please log in again.",
	"INVALID_API_KEY": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"REQUEST_PARAMETERS_BLANK": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"CURRENCY_INVALID": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"FIELD_CANNOT_BE_BLANK": "Please enter a value for this field.",
	"FIELD_VALUE_INVALID": "Please check the value for this field.",
	"FIELD_NAME_INVALID_OR_INEDITABLE": "Oops! I'm sorry, this is an invalid field or it might not be editable.",
	"ORDER_CLEANUP_REVIEW_REQUIRED": "Oh no! Something in your cart changed. Please review your cart details and try again.",
	"ORDER_CLEANUP_REASON_PROMO_APPLIED": "Promo was applied",
	"ORDER_CLEANUP_REASON_PROMO_ENDED": "Promo has ended",
	"ORDER_CLEANUP_REASON_PROMO_NOT_APPLICABLE": "Promo is not applicable",
	"ORDER_CLEANUP_REASON_NOT_AVAILABLE_FABRIC": "Fabric is not available",
	"ORDER_CLEANUP_REASON_NOT_AVAILABLE_DESIGN": "Design is not available",
	"ORDER_CLEANUP_REASON_NOT_AVAILABLE_ITEM": "Item is not available",
	"ORDER_CONTAINS_NO_ITEMS": "It looks like your order is empty.",
	"ORDER_NOT_FOUND": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_NOT_PENDING": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_NOT_EDITABLE": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_HAS_NO_COUNTRY": "Please select a shipping country.",
	"ORDER_CANNOT_BE_INVOICED": "Unfortunately we weren’t able to automatically make an invoice for this order. Please contact help@spoonflower.com if you require an invoice.",
	"ORDER_ITEMS_FOR_ORDER_AT_LIMIT": "You’ve reached your order item limit.",
	"ORDER_PAYABLE_MISSING_REQUIRED_DELIVERY_OPTION": "Please select a delivery option.",
	"ORDER_PAYABLE_MISSING_REQUIRED_SHIPPING_DATA": "Please fill out your shipping address.",
	"ORDER_PAYABLE_USER_HAS_INSUFFICIENT_SPOONDOLLARS": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_PAYABLE_CANNOT_PATCH_MORE_SPOONDOLLARS_THAN_TOTAL": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_PAYABLE_CANNOT_PATCH_NEGATIVE_SPOONDOLLARS": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_ITEM_NOT_FOUND": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_ITEM_NOT_IN_PENDING_ORDER": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_ITEM_NOT_IN_SAVED_ORDER": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"ORDER_ITEM_CANNOT_EDIT_QUANTITY": "I'm sorry that item quantity is not editable.",
	"ORDER_SAVED_TIMEOUT_ERROR": "Your cart seems to be loading slowly.\nHint: Try removing some items you’ve \"saved for later\" and favorite them instead.",
	"EXCHANGE_RATE_CHANGED": "Oh no! Since you've been shopping, the exchange rate for your chosen currency has been updated. Please review your order total and re-enter your payment details.",
	"VAT_RATE_CHANGED": "Oh no! Since you've been shopping, the Tax rate for your shipping address has been updated. Please review your order total and re-enter your payment details.",
	"PAYMENT_DETAILS_MISSING_ERROR": "Please provide payment details.",
	"STRIPE_CUSTOMER_TECHNICAL_FAILURE": "Oh no! We're having trouble processing your credit card payment, and we're looking into it. In the meantime you can use PayPal.",
	"PAYMENT_PAYPAL_CONNECTION_FAILED": "Oh no! We're having trouble connecting to PayPal, and we're looking into it. Please use a credit card.",
	"PAYMENT_AFTERPAY_CONNECTION_FAILED": "Oh no! We're having trouble connecting to Afterpay, and we're looking into it. Please use a credit card.",
	"PAYMENT_FAILED": "Oops! Your payment was declined.",
	"PAYMENT_TECHNICAL_FAILURE": "Oh no! We were unable to process your payment. Please try again.",
	"PROMO_CODE_INVALID": "I'm sorry, there is no promo with that code. Note: some promos may require you to be logged in.",
	"PROMO_ORDER_DOES_NOT_QUALIFY": "Your order does not qualify for this promotion. Please try another code.",
	"USER_NOT_FOUND": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"USER_GUEST_MUST_SUPPLY_EMAIL_ADDRESS": "Please enter your email address to check out as a guest.",
	"OLD_BROWSER_WARNING": "Oh no! It looks like your browser is out of date. Please switch browsers or upgrade and try again.",
	"STRIPE_CUSTOMER_REQUEST_BY_GUEST_USER_NOT_ALLOWED": "Oh no! We were unable to process your payment. Please try again.",
	"CANNOT_APPLY_SPOONDOLLARS_CONTAINS_SUBSCRIPTION_ITEM": "Oh no! You can't pay for your order without saving a credit card, because your cart contains a subscription item. Please pay with only a credit card.",
	"CANNOT_PAY_WITH_PAYPAL_CONTAINS_SUBSCRIPTION_ITEM": "Oh no! You can't pay for your order without saving a credit card, because your cart contains a subscription item. Please pay with only a credit card.",
	"CANNOT_PAY_WITH_UNSAVED_CREDIT_CARD_CONTAINS_SUBSCRIPTION_ITEM": "Oh no! You can't pay for your order without saving a credit card, because your cart contains a subscription item. Please pay with only a credit card.",
	"DESIGN_FUNNEL_WRONG_STEP_DESIGN_FIRST": "Oh no! You can't pick a fabric now, please pick a design first.",
	"DESIGN_FUNNEL_PICK_FABRIC": "Oh no! Please pick a fabric.",
	"CURRENCY_AND_MEASUREMENT_SYSTEM_INCOMPATIBLE": "Oops! This combination of measurement and currency is not supported",
	"COUNTRY_AND_CURRENCY_INCOMPATIBLE": "Oops! This combination of country and currency is not supported",
	"STRIPE_CUSTOMER_ACCOUNT_ALREADY_EXISTS": "Oops! Your customer account exists already.",
	"STRIPE_TOTAL_AMOUNT_TOO_LOW": "Oops! Your order total must be at least {amount} to pay with a credit card! Please use PayPal or add something to your cart.",
	"INVALID_ZIPCODE": "Oops! This zipcode doesn't appear to exist.",
	"NO_IMAGE_AVAILABLE": "Design ({designName}) is not available",
	"ERROR_BOUNDARY_TRIGGER": "Oops, this content is not currently loading. We're working on it.",
	"ID_ME_VERIFICATION_FAILED": "Oh no! Verification failed. Please try again.",
	"ERROR_IN_UNSUBSCRIBING": "Oops! I'm sorry, something unexpected happened. Please refresh and try again.",
	"NOT_UNIQUE_PHONE_NUMBER": "Entered mobile number already exists.",
	"NOT_UNIQUE_EMAIL_ADDRESS": "Email address already exists with another number.",
	"stripe": {
		"CARD_DECLINED": "Oops! Your card was declined.",
		"PROCESSING_ERROR": "Oops! An error occurred while processing your card.",
		"EXPIRED_CARD": "Oops! Your card was declined because it has expired.",
		"DELETE_CARD_TECHNICAL_FAILURE": "Oh no! We're having trouble deleting your card at the moment. Please try again later.",
		"fields": {
			"incomplete_number": "Oops! Your card was declined because the card number was invalid.",
			"invalid_number": "Oops! Your card was declined because the card number was invalid.",
			"incomplete_expiry": "Oops! Your card was declined because the expiration date was invalid.",
			"invalid_expiry_year_past": "Oops! Your card was declined because the expiration date was invalid.",
			"incomplete_cvc": "Oops! Your card was declined because the card's security code was incorrect.",
			"INCORRECT_NUMBER": "Oops! Your card was declined because the card number was incorrect.",
			"INSUFFICIENT_FUNDS": "Oops! Your card was declined because the card has insufficient funds to complete the purchase."
		}
	},
	"inline": {
		"validTaxType": "Please provide a valid {taxType}",
		"validCardNumber": "Please provide a valid card number.",
		"validCVC": "Please provide a valid CVC for your card.",
		"validExpirationYear": "Please provide the card expiration year.",
		"validExpirationDate": "Please provide a valid expiration date.",
		"selectCountry": "Please select a country.",
		"provideAddress": "Please provide an address.",
		"provideCity": "Please provide a city.",
		"provideNameCard": "Please provide the name on the card.",
		"provideExpirationMonth": "Please provide the card expiration month.",
		"provideZipCode": "Please provide a zipcode.",
		"provideName": "Please provide a name.",
		"providePhone": "Please provide a phone number.",
		"usZipcodeFormat": "For US zipcodes, use the format 12345 or 12345-6789.",
		"caZipcodeFormat": "For Canadian postal codes, use the format A1A 1X1 or A1A1X1.",
		"usSelectState": "Please select a state for US addresses.",
		"caSelectState": "Please select a province for Canadian addresses.",
		"provideEmail": "Please provide a valid email address in the format of \"exampleemail@example.com\" without any spaces.",
		"provideEmailConfirmation": "Please retype email address.",
		"retypeEmailDoesNotMatch": "Email addresses do not match. Please check them again, making sure they match.",
		"required": "Required.",
		"atLeastMinCharacters": "Must be at least {min} characters",
		"noMoreMaxCharacters": "Must be no more than {max} characters",
		"provideInteger": "Must be an integer",
		"provideOneOf": "Must be one of: {enumeration}",
		"doNotMatch": "Do not match",
		"comment": "Your comment could not be posted",
		"userAlreadyVoted": "Oops! It seems you’ve already voted."
	},
	"errorPages": {
		"403": "You need different permissions to access this page (403 error).",
		"404": "This page may have moved or the address may be incorrect (404 error).",
		"500": "We’re experiencing a server problem and are working quickly on it (500 error). Sorry about that. In the meantime:",
		"503": "Our site is temporarily down for maintenance (our expected down time is until {date}). Sorry about that. In the meantime:",
		"goHome": "Go to the homepage",
		"shopElsewhere": "Shop Spoonflower on Etsy",
		"artwork": "Artwork by"
	},
	"transitionMessage": {
		"CHANGES_SAVED": "Changes saved",
		"THANKS_FOR_SUBSCRIBING": "Thanks for subscribing!",
		"WE_ARE_SORRY_TO_SEE_YOU_GO": "We are sorry to see you go!"
	}
};