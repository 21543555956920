import browserHistory from 'react-router/lib/browserHistory';

import {isClientSideRoute} from '../../../utils/url';


const browserHistoryWrapper = {
  push: (pathname: string, forcePush?: boolean): void => {
    if (forcePush || isClientSideRoute(pathname, window.location.hostname)) {
      browserHistory.push(pathname);
    } else {
      window.location.href = pathname;
    }
  },
};

export default browserHistoryWrapper;
