import * as types from '../constants/ActionTypes';

function collection(state = {}, action) {
  switch (action.type) {
    case types.COLLECTION_RECEIVED:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
}

export default collection;
