/* eslint-disable @typescript-eslint/ban-ts-comment */
import '@babel/polyfill';
import {loadableReady} from '@loadable/component';
import {getUserAnalytics, pushPageViewToDataLayer} from '@spoonflower/user-analytics';
import isEqual from 'lodash/isEqual';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import {syncHistoryWithStore} from 'react-router-redux';
import browserHistory from 'react-router/lib/browserHistory';
import {Store} from 'redux';

// import reportWebVitals from './reportWebVitals';
import {clientBootstrap} from './actions/clientBootstrap';
import {routingDataSet} from './actions/routingData';
import {userStatsPageTypeUpdated} from './actions/userStats';
import {PAGE_TYPE_UNDEFINED} from './constants/pageTypes';
import routes from './constants/Routes';
import App from './containers/App';
import apiHosts from './entities/pageSetup/apiHosts';
import {countries} from './entities/pageSetup/countries/countriesSlice';
import {selectPageLang} from './entities/pageSetup/selectors';
import multiLocaleRouterConfigSlice from './entities/routing/multiLocaleRouter/multiLocaleRouterConfigSlice';
import {canadaStates, usStates} from './entities/states/loadStates';
import getRuntimeEnvFromHostname, {RuntimeEnv} from './environment/runtimeEnv';
import './index.scss';
import {initializeLocalization} from './initializers';
import {MultiLocaleRouter, TranslateWrapper} from './services';
import configureStore from './store/configureStore';
import initialState, {State} from './store/initialState';
import './stylesheets/basics.scss';
import CartOperations from './utils/cartOperations';
import {fetchMiddleware} from './utils/fetch';
import parseRoutePropsParams from './utils/parseRoutePropsParams';


// Client should be able to work without server (npm start) - which means that some values will be always default values since server didn't override them
// In case if we fall back to CSR in production, there's still `preloadedState`, because server has generated it
const preloadedState = process.env.REACT_APP_IS_DEVSERVER ?
  initialState :
  window.spoonflower?.preloadedState;

preloadedState.pageSetup.hostname = window.location.hostname || preloadedState.pageSetup.hostname;
preloadedState.pageSetup.origin = window.location.origin || preloadedState.pageSetup.origin;
preloadedState.pageSetup.pageLang = process.env.REACT_APP_IS_DEVSERVER ?
  window.location.pathname.substring(1, 3) || preloadedState.pageSetup.pageLang :
  preloadedState.pageSetup.pageLang;
preloadedState.pageSetup.countries = process.env.REACT_APP_IS_DEVSERVER ?
  countries :
  preloadedState.pageSetup.countries;
preloadedState.pageSetup.usStates = process.env.REACT_APP_IS_DEVSERVER ?
  usStates :
  preloadedState.pageSetup.usStates;
preloadedState.pageSetup.canadaStates = process.env.REACT_APP_IS_DEVSERVER ?
  canadaStates :
  preloadedState.pageSetup.canadaStates;
preloadedState.pageSetup.runtimeEnv =
  preloadedState.pageSetup.runtimeEnv ||
  getRuntimeEnvFromHostname(preloadedState.pageSetup.hostname);
const useApiHostsFromEnv =
  window.spoonflower?.serverData?.useApiHostsFromEnv ??
  /* could be any other based on cookie value */ preloadedState.pageSetup.runtimeEnv;

preloadedState.pageSetup.apiHosts = {
  ...apiHosts[useApiHostsFromEnv as RuntimeEnv].client
};

preloadedState.routingData = process.env.REACT_APP_IS_DEVSERVER ? parseRoutePropsParams(routes, window.location.href) : preloadedState.routingData;

const store: Store = configureStore(preloadedState);

const state = store.getState() as unknown as State;
const pageLang = selectPageLang(state);

CartOperations.setDispatch(store.dispatch);
fetchMiddleware.setStore(store);

const renderMethod = window.spoonflower?.serverData?.ssr ? ReactDOM.hydrate : ReactDOM.render;
const history = syncHistoryWithStore(browserHistory, store);

store.dispatch(userStatsPageTypeUpdated(state.routingData.routesProps.pageType ?? PAGE_TYPE_UNDEFINED));

history.listen((location) => {
  // It's necessary to set state.routingData here, rather than in Redux middleware, to guarantee that it gets updated
  // before React component lifecycle methods run, because those methods depend on this state being up-to-date.
  // If we set state.routingData in Redux middleware, then it doesn't get updated in time when browser back and forward buttons are used.
  // We should consider refactoring to remove state.routingData and instead derive its values on-demand from window.location.
  // This would allow us to avoid having an extra source of truth for routing information.
  const {dispatch, getState} = store;
  const {routingData} = getState();

  const newRoutingData = parseRoutePropsParams(routes, location);

  // Update the pageType in the GTM dataLayer.
  window.dataLayer.push({
    pageType: newRoutingData.routesProps.pageType,
  });

  getUserAnalytics({
    isProd: window.spoonflower?.serverData?.ENV === 'Production'
  }).then((data) => {
    if (data) {
      pushPageViewToDataLayer(
        {
          pageType: newRoutingData.routesProps.pageType,
          userStats: data.data_layer,
          cartId: data.cart?.cart_id,
          guestCartId: data.cart?.guest_cart_id
        }
      );
    }
  });

  if (!isEqual(newRoutingData, routingData)) {
    dispatch(routingDataSet(newRoutingData));
  }

  // Disable scroll restoration during client-side navigation, because it can be inaccurate for PDPs
  // where the page's content is temporarily replaced by a loading indicator.
  window.history.scrollRestoration = 'manual';
});

initializeLocalization(pageLang);
const multiLocaleRouterConfig =
  process.env.NODE_ENV === 'development' ?
    multiLocaleRouterConfigSlice :
    window.spoonflower?.serverData?.multiLocaleRouterConfig;

MultiLocaleRouter.initRoutesTranslated(multiLocaleRouterConfig);
TranslateWrapper.setLangCode(pageLang);

clientBootstrap(store);

const env = process.env.REACT_APP_IS_SERVER ?
  process.env.ENVIRONMENT :
  window.spoonflower?.serverData?.ENV;

const renderApp = (store: Store) => {
  loadableReady(() => {
    renderMethod(
      <React.StrictMode>
        <Provider store={store} key='provider'>
          <IntlProvider locale={pageLang}>
            <App history={history} />
          </IntlProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('react-root-0')
    );
  });
};

// make sure intl polyfill is loaded before the app is rendered
Promise.all([
  new Promise((resolve) => {
    if (!window.IntersectionObserver) {
      // @ts-ignore
      import('intersection-observer').then(resolve);
    } else {
      resolve(null);
    }
  }),
  new Promise((resolve) => {
    if (!window.Intl) {
      Promise.all([
        import('intl'),
        // @ts-ignore
        import('intl/locale-data/jsonp/en.js'),
        // @ts-ignore
        import('intl/locale-data/jsonp/de.js'),
        // @ts-ignore
        import('intl/locale-data/jsonp/fr.js'),
        // @ts-ignore
        import('intl/locale-data/jsonp/es.js'),
        import('@formatjs/intl-pluralrules/polyfill'),
        import('@formatjs/intl-pluralrules/locale-data/de'),
        import('@formatjs/intl-pluralrules/locale-data/en'),
        import('@formatjs/intl-pluralrules/locale-data/es'),
        import('@formatjs/intl-pluralrules/locale-data/fr'),
        import('@formatjs/intl-relativetimeformat/polyfill'),
        import('@formatjs/intl-relativetimeformat/locale-data/de'),
        import('@formatjs/intl-relativetimeformat/locale-data/en'),
        import('@formatjs/intl-relativetimeformat/locale-data/es'),
        import('@formatjs/intl-relativetimeformat/locale-data/fr'),
      ]).then(resolve);
    } else {
      resolve(null);
    }
  }),
  // loadableReady,
]).then(async() => renderApp(store));

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
if (!String.prototype.startsWith) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(String.prototype, 'startsWith', {
    // @ts-ignore
    value(search, pos) {
      pos = !pos || pos < 0 ? 0 : +pos;

      return this.substring(pos, pos + search.length) === search;
    },
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
