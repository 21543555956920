module.exports = {
	"addedToCart": {
		"confirmation": "Added to Your Cart",
		"close": "Close window",
		"relatedProducts": "Customers who bought this also bought:",
		"viewCart": "View Cart",
		"continueShopping": "Continue shopping"
	},
	"shippingCostFlashMessage": {
		"notFreeShipping": "Add {context} to qualify for FREE ground shipping in the US!",
		"freeShipping": "Your order qualifies for FREE ground shipping in the US!"
	},
	"cart": {
		"securePayment": "Secure Payment",
		"loadingCart": "Loading your cart …",
		"shoppingCart": "Cart",
		"saved": "Saved",
		"save": "Save",
		"savings": "Savings",
		"emptyCart": "Oh no - your shopping cart is empty!",
		"emptySaveForLater": "You haven’t saved any items for later.",
		"startShopping": "Start Shopping",
		"addedItem": "Item moved to cart",
		"shipping": "Shipping",
		"change": "change",
		"by": "by",
		"editItem": "Edit Item",
		"removeItem": "Remove item from cart",
		"moveToCart": "Move to Cart",
		"saveForLater": "Save for Later",
		"favoriteItem": "Favorite Item",
		"moreDetails": "More Details",
		"print": "Print:",
		"size": "Total Size:",
		"style": "Style:",
		"fabric": "Fabric:",
		"chunking": "Cut:",
		"productInsert": "Insert:",
		"wide": "wide",
		"item": "item",
		"TEST_SWATCH_YARD": "Swatch",
		"TEST_SWATCH_METER": "Swatch",
		"FAT_QUARTER_YARD": "Fat Quarter",
		"FAT_QUARTER_METER": "Fat Quarter",
		"YARD": "Yard",
		"METER": "Meter",
		"ROLL": "Roll",
		"PANEL": "Panel",
		"dpiAmount": "{designDpi} DPI",
		"payIn": "Pay in",
		"selectCurrency": "Select a currency",
		"goToShop": "Go to the Shop page",
		"proceedCheckout": "Proceed to Checkout",
		"goToCheckout": "Go to the checkout",
		"checkout": "Check Out",
		"cartNotice": "Please Note: All prices in the cart and during checkout are shown including tax. Prices shown on other pages may not include tax.",
		"subTotal": "Subtotal",
		"total": "Total",
		"estimatedTotal": "Estimated Total",
		"tax": "Tax",
		"spoondollars": "Spoondollars",
		"helperTaxTitle": "Why tax?",
		"helperTaxDescription": "Depending on your US state or country of residence, Spoonflower is required by governments to charge you tax.",
		"WALLPAPER_METRIC_TEST_SWATCH_61_x_30": "Swatch (61 cm x 30 cm)",
		"WALLPAPER_METRIC_ROLL_61_x_91": "61 cm x 91 cm",
		"WALLPAPER_METRIC_ROLL_61_x_183": "61 cm x 183 cm",
		"WALLPAPER_METRIC_ROLL_61_x_274": "61 cm x 274 cm",
		"WALLPAPER_METRIC_ROLL_61_x_366": "61 cm x 366 cm",
		"WALLPAPER_METRIC_ROLL_61_x_823": "61 cm x 823 cm",
		"WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1": "Swatch (2' x 1')",
		"WALLPAPER_IMPERIAL_ROLL_2_x_3": "2' x 3'",
		"WALLPAPER_IMPERIAL_ROLL_2_x_6": "2' x 6'",
		"WALLPAPER_IMPERIAL_ROLL_2_x_9": "2' x 9'",
		"WALLPAPER_IMPERIAL_ROLL_2_x_12": "2' x 12'",
		"WALLPAPER_IMPERIAL_ROLL_2_x_27": "2' x 27'"
	},
	"promo": {
		"general": "{amountSavedByPromo} saved with '{promoName}' promotion.",
		"heading": "Promo Code",
		"placeholder": "Enter a promo code",
		"buttonTitle": "Apply promotion",
		"buttonText": "Apply",
		"promoAppliedCorrect": "Promo applied correctly",
		"promoApplied": "{promoName} applied",
		"removePromo": "Remove {promoName} promotion",
		"discount": "Discount:"
	},
	"idMe": {
		"linkText": "What is Id.me?",
		"buttonTitle": "Verify with ID.me"
	}
};