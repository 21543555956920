import {isEmpty} from './validation';
import {ON_PARAM, ALLCOLORS, MENU_SUFFIX, COLOR_PARAM, SUBSTRATE_PARAM} from '../constants/Parameters';
import {DEFAULT_PRODUCT} from '../constants/SearchFilters';
import {snakeCase} from 'lodash';
import {upsertUrlQuery} from './url';
import {MultiLocaleRouter, TranslateWrapper} from '../services';

export function onRemoveFilter(searchParams, filter, value, onSubmitQuery, currentOrigin) {
  const isSubstrate = filter === SUBSTRATE_PARAM;
  const isProduct = filter === ON_PARAM;
  const isColor = filter === COLOR_PARAM;
  const valueToCheck = isSubstrate ? snakeCase(value) : value;
  const filterValues = searchParams[filter]
    .split(',')
    .filter((formerFilter) => valueToCheck !== formerFilter)
    .join(',');
  const customPayload = (isEmpty(filterValues) && (isSubstrate || isProduct)) || (value === ALLCOLORS && isColor);

  if (customPayload) {
    onSubmitQuery({
      [ON_PARAM]: isSubstrate ? DEFAULT_PRODUCT : '',
      [SUBSTRATE_PARAM]: '',
      [filter]: isProduct ? DEFAULT_PRODUCT : '',
    }, currentOrigin);

    return;
  }

  onSubmitQuery({
    [filter]: filterValues
  }, currentOrigin);
}

export function getLeafValue(nodeValue) {
  return nodeValue.replace(MENU_SUFFIX, '');
}

export function searchInStack(target, tree, path = [], isProduct = false) {
  const results = [];

  function recursiveSearch(target, tree, path, results) {
    if (!isEmpty(tree.children)) {
      const treeChildren = Object.keys(tree.children).reduce((menuTree, menuElement) => {
        if (!isProduct) {
          menuTree[tree.children[menuElement].text] = menuElement;
        } else {
          menuTree[tree.children[menuElement].apiValue] = menuElement;
        }

        return menuTree;
      }, {});

      if (Object.keys(treeChildren).includes(target)) {
        const keyFromTarget = treeChildren[target];

        results.push([...path, keyFromTarget]);
      } else {
        Object.values(treeChildren).forEach((childName) => {
          recursiveSearch(target, tree.children[childName], [...path, childName], results);
        });
      }
    }
  }
  recursiveSearch(target, tree, path, results);

  return results[0];
}

export function stackHasLeafNode(stack) {
  return !stack[stack.length - 1].includes(MENU_SUFFIX);
}

export const generateNoMatureLink = (text, searchParams, currentOrigin) => {
  const params = {
    ...searchParams,
    showMatureContent: false,
  };
  const href = currentOrigin ?
    upsertUrlQuery(
      MultiLocaleRouter.localePathname('shop.home', TranslateWrapper.langCode),
      params,
      currentOrigin
    ) : '';

  return {
    href,
    text,
  };
};

