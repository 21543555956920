import * as types from '../constants/ActionTypes';
import {isNotUndefined} from '../utils/validation';

function searchResults(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case types.SEARCH_RESULTS_RECEIVED:
      return {
        ...state, ...action.payload
      };

    case types.DESIGN_FAVORITED + types.SUCCESS:
      if (isNotUndefined(newState.results)) {
        newState.results.forEach((value, key) => {
          if (action.designId === value.id) {
            if (action.favoriteCountChange > 0) {
              newState.results[key].num_favorites++;
            } else {
              newState.results[key].num_favorites--;
            }
          }
        });
      }

      return newState;

    case types.TAG_RECEIVED:
      return {
        ...state, tag: action.payload
      };

    case types.SEARCH_RESULTS_LOADING:
      return {
        ...state, resultsLoading: action.payload
      };

    default:
      return state;
  }
}

export default searchResults;
