module.exports = {
	"optionsTitle": "Fill A Yard Options",
	"canvasTitle": "Fill A Yard Canvas",
	"headerText": "Fill-A-Yard",
	"loadingMessage": {
		"collection": "Loading your collection…",
		"templateSelector": "Loading templates…"
	},
	"templateSelector": {
		"title": "Choose a Template",
		"iconAltText": "{templateItem.name} icon",
		"iconTitle": "selected",
		"maxDesignCountText": "Up to {maxDesignCount} designs",
		"iconAltText_8": "Horizontal Split Yard icon",
		"iconAltText_6": "Vertical Split Yard icon",
		"iconAltText_4": "1 Yard Cheater Quilt icon",
		"iconAltText_5": "2 Yard Cheater Quilt icon",
		"iconAltText_1": "Infinity Scarf icon",
		"iconAltText_10": "Dinner Napkins icon",
		"name": {
			"templateItemId_8": "Horizontal Split (1 Yard)",
			"templateItemId_6": "Vertical Split (1 Yard)",
			"templateItemId_4": "Cheater Quilt (1 Yard)",
			"templateItemId_5": "Cheater Quilt (2 Yards)",
			"templateItemId_1": "Infinity Scarf (2 Yards)",
			"templateItemId_10": "Dinner Napkins"
		},
		"description": {
			"templateItemId_8": "",
			"templateItemId_6": "",
			"templateItemId_4": "6” x 6” squares",
			"templateItemId_5": "9” x 9” squares",
			"templateItemId_1": "2 scarves",
			"templateItemId_10": "16” x 16” squares"
		}
	},
	"fabricSelector": {
		"headerText": "Choose a Fabric",
		"suggestion": {
			"templateItemId_8": "",
			"templateItemId_6": "",
			"templateItemId_4": "Also, remember to order backing fabric separately!",
			"templateItemId_5": "Also, remember to order backing fabric separately!",
			"templateItemId_1": "",
			"templateItemId_10": ""
		}
	},
	"applyDesignsButtonText": "Next"
};