import {SHOULD_RENDER_NOT_FOUND} from '../constants/ActionTypes';


type RenderNotFound = {
  type: string;
}

function renderNotFound(): RenderNotFound {
  return {
    type: SHOULD_RENDER_NOT_FOUND,
  };
}

export default renderNotFound;
