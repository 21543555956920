module.exports = {
	"fabricSize": {
		"TEST_SWATCH_YARD": {
			"1": "{context} Swatch",
			"2..99": "{context} Swatches"
		},
		"TEST_SWATCH_METER": {
			"1": "{context} Swatch",
			"2..99": "{context} Swatches"
		},
		"FAT_QUARTER_YARD": {
			"1": "{context} Fat Quarter",
			"2..99": "{context} Fat Quarters"
		},
		"FAT_QUARTER_METER": {
			"1": "{context} Fat Quarter",
			"2..99": "{context} Fat Quarters"
		},
		"YARD": {
			"1": "{context} Yard",
			"2..99": "{context} Yards"
		},
		"METER": {
			"1": "{context} Meter",
			"2..99": "{context} Meters"
		},
		"ROLL": {
			"1": "{context} Roll",
			"2..99": "{context} Rolls"
		},
		"FEET": {
			"1": "{context} Foot",
			"2..99": "{context} Feet"
		},
		"ITEMS": {
			"0": "{context} Items",
			"1": "{context} Item",
			"2..99": "{context} Items"
		},
		"WALLPAPER_METRIC_TEST_SWATCH_61_x_30": {
			"1": "{context} Swatch - 61 cm x 30 cm",
			"2..99": "{context} Swatches - 61 cm x 30 cm"
		},
		"WALLPAPER_METRIC_ROLL_61_x_91": {
			"1": "{context} Roll - 61 cm x 91 cm",
			"2..99": "{context} Rolls - 61 cm x 91 cm"
		},
		"WALLPAPER_METRIC_ROLL_61_x_183": {
			"1": "{context} Roll - 61 cm x 183 cm",
			"2..99": "{context} Rolls - 61 cm x 183 cm"
		},
		"WALLPAPER_METRIC_ROLL_61_x_274": {
			"1": "{context} Roll - 61 cm x 274 cm",
			"2..99": "{context} Rolls - 61 cm x 274 cm"
		},
		"WALLPAPER_METRIC_ROLL_61_x_366": {
			"1": "{context} Roll - 61 cm x 366 cm",
			"2..99": "{context} Rolls - 61 cm x 366 cm"
		},
		"WALLPAPER_METRIC_ROLL_61_x_823": {
			"1": "{context} Roll - 61 cm x 823 cm",
			"2..99": "{context} Rolls - 61 cm x 823 cm"
		},
		"WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1": {
			"1": "{context} Swatch - 2' x 1'",
			"2..99": "{context} Swatches - 2' x 1'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_3": {
			"1": "{context} Roll - 2' x 3'",
			"2..99": "{context} Rolls - 2' x 3'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_6": {
			"1": "{context} Roll - 2' x 6'",
			"2..99": "{context} Rolls - 2' x 6'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_9": {
			"1": "{context} Roll - 2' x 9'",
			"2..99": "{context} Rolls - 2' x 9'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_12": {
			"1": "{context} Roll - 2' x 12'",
			"2..99": "{context} Rolls - 2' x 12'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_27": {
			"1": "{context} Roll - 2' x 27'",
			"2..99": "{context} Rolls - 2' x 27'"
		}
	},
	"forms": {
		"update": "Update",
		"cancel": "Cancel",
		"close": "Close",
		"open": "Open",
		"selected": "Selected"
	},
	"loading": "Loading …",
	"or": "or",
	"turnaroundPreceding": "Made to order in ",
	"turnaroundFollowing": " if you order now",
	"login": "Log in ",
	"createAccount": " create an account "
};