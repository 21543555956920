// WARNING: For performance and architectural reasons, this file and others associated with the cross-platform
//          MultiLocaleRouter functionality may be duplicated or include duplicate logic in multiple Spoonflower
//          repositories / apps, including Alpenrose Rails, Baerlauch Rails / React, and classic Spoonflower Rails. Be
//          mindful that any changes you make here may also require corresponding changes in those places.
//
// To understand why, and for more info about the MultiLocaleRouter, see:
// https://github.com/Spoonflower/baerlauch/wiki/The-Spoonflower-MultiLocaleRouter

/* global newrelic */
import {ROUTING_PATH_FABRIC} from '../constants/Products';

class MultiLocaleRouter {
  constructor(multiLocaleRouteData, {throwExceptions = false, verboseWarnings = false}) {
    this.throwExceptions = throwExceptions;
    this.verboseWarnings = verboseWarnings;
    this.fallbackLocale = multiLocaleRouteData.fallback_locale;
    this.routesUntranslated = multiLocaleRouteData.routes_untranslated;
  }

  setRoutesTranslated(routesTranslated) {
    this.routesTranslated = routesTranslated;
  }

  initRoutesTranslated(routesTranslated) {
    this.setRoutesTranslated(routesTranslated);
    this._basicConfigurationValidation();
    this._initializeAlternateLocaleMaps();
  }

  _basicConfigurationValidation() {
    if (typeof this.fallbackLocale !== 'string') {
      throw new Error('fallbackLocale must be string');
    }
    if (typeof this.routesUntranslated !== 'object') {
      throw new Error('routesUntranslated must be object');
    }
    if (typeof this.routesTranslated !== 'object') {
      throw new Error('routesTranslated must be object');
    }
  }

  /** ALTERNATE LOCALE RESOLUTION */

  // ! Duplicate: Spoonflower/baerlauch:lib/multi_locale_router.rb | MultiLocaleRouter#alternate_locales
  // ! Duplicate: Spoonflower/spoonflower:lib/multi_locale_router.rb | MultiLocaleRouter#alternate_locales
  alternateLocales(pathName) {
    const fabricPagePaths = this.routesTranslated[ROUTING_PATH_FABRIC];
    let pathNameisProductPage = false;

    for (const lang in fabricPagePaths) {
      if (pathName.startsWith(`${fabricPagePaths[lang]}/`)) {
        pathNameisProductPage = true;
        break;
      }
    }

    if (pathNameisProductPage) {
      pathName = pathName.split(/[\d?#]/)[0]; // remove url parameter and designId
    }

    const routeName = this.routeNameForPathName(pathName);

    if (typeof routeName === 'undefined') {
      return {};
    }

    return this.routesTranslated[routeName];
  }

  // ! Duplicate: Spoonflower/baerlauch:lib/multi_locale_router.rb | MultiLocaleRouter#route_name_for_pathname
  // ! Duplicate: Spoonflower/spoonflower:lib/multi_locale_router.rb | MultiLocaleRouter#route_name_for_pathname
  routeNameForPathName(pathName) {
    pathName = MultiLocaleRouter._standardizePathName(pathName);

    return this.routeNameFromPath[pathName];
  }

  // ! Duplicate: Spoonflower/baerlauch:lib/multi_locale_router.rb | MultiLocaleRouter#locale_for_pathname
  // ! Duplicate: Spoonflower/spoonflower:lib/multi_locale_router.rb | MultiLocaleRouter#locale_for_pathname
  localeForPathName(pathName) {
    pathName = MultiLocaleRouter._standardizePathName(pathName);

    return this.localeFromPath[pathName];
  }

  _initializeAlternateLocaleMaps() {
    this.routeNameFromPath = {};
    this.localeFromPath = {};

    let pathName;

    for (const routeName of Object.keys(this.routesTranslated)) {
      for (const locale of Object.keys(this.routesTranslated[routeName])) {
        pathName = this.routesTranslated[routeName][locale];
        this.localeFromPath[pathName] = locale;
        this.routeNameFromPath[pathName] = routeName;
      }
    }
  }

  // ! Duplicate: Spoonflower/baerlauch:lib/multi_locale_router.rb | MultiLocaleRouter#standardize_pathname
  // ! Duplicate: Spoonflower/spoonflower:lib/multi_locale_router.rb | MultiLocaleRouter#standardize_pathname
  static _standardizePathName(pathName) {
    if (pathName === '/') {
      return pathName;
    }

    return pathName.replace(/\/$/, '');
  }

  /** LINK GENERATION / TARGET URL RESOLUTION */

  setPreferredLocale(preferredLocale) {
    this.preferredLocale = preferredLocale;
  }

  localePathname(identifier, locale = undefined) {
    locale = locale || this.preferredLocale;

    if (typeof locale === 'undefined') {
      throw new Error('MultiLocaleRouter: No locale specified');
    }

    const hasTranslatedRoute = Object.keys(this.routesTranslated).includes(identifier);
    const hasUntranslatedRoute = Object.keys(this.routesUntranslated).includes(identifier);

    // Descriptive / debuggable fallback for production, if the preferred locale and the fallback locale look-ups both fail
    let pathname = `${locale}-${identifier}`;

    if (!this._enforceIdentifierPresenceAndUniqueness(identifier, hasTranslatedRoute, hasUntranslatedRoute)) {
      return pathname;
    }

    if (hasTranslatedRoute) {
      pathname = this._localePathnameFromTranslatedRoute(identifier, locale);
    } else {
      // hasUntranslatedRoute
      pathname = this._localePathnameFromUntranslatedRoute(identifier, locale);
    }

    return pathname;
  }

  /**
   * If production, simply newrelic.noticeError, so the application doesn't crash on account of one link.
   * Otherwise throw an exception, so that mistakes surface during development.
   * @param message
   */
  _noticeBrokenRouteIdentifier(message) {
    if (this.throwExceptions) {
      throw new Error(`MultiLocaleRouter: ${message}`);
    }
    if (typeof newrelic !== 'undefined' && newrelic !== null) {
      newrelic.noticeError(new Error(message));
    }
  }

  _localePathnameFromTranslatedRoute(identifier, locale) {
    if (Object.prototype.hasOwnProperty.call(this.routesTranslated[identifier], locale)) {
      return this.routesTranslated[identifier][locale];
    }
    if (this.verboseWarnings) {
      console.warn(`Preferred locale (${locale}) undefined for translated route: ${identifier}`);
    }
    if (Object.prototype.hasOwnProperty.call(this.routesTranslated[identifier], this.fallbackLocale)) {
      return this.routesTranslated[identifier][this.fallbackLocale];
    }
    this._noticeBrokenRouteIdentifier(`No fallback locale (${this.fallbackLocale}) defined for translated route: ${identifier}`);

    return false;
  }

  _localePathnameFromUntranslatedRoute(identifierPathTail, locale) {
    let matchingLocale = this.fallbackLocale;

    if (this.routesUntranslated[identifierPathTail].includes(locale)) {
      matchingLocale = locale;
    }
    if (this.verboseWarnings) {
      console.warn(`Preferred locale (${locale}) undefined for untranslated route: ${identifierPathTail}`);
    }

    return `/${matchingLocale}/${identifierPathTail}`;
  }

  _enforceIdentifierPresenceAndUniqueness(identifier, hasTranslatedRoute, hasUntranslatedRoute) {
    if (hasTranslatedRoute && hasUntranslatedRoute) {
      this._noticeBrokenRouteIdentifier(`Identifier is defined twice: ${identifier}`);

      return false;
    } else if (!hasTranslatedRoute && !hasUntranslatedRoute) {
      this._noticeBrokenRouteIdentifier(`Identifier is undefined: ${identifier}`);

      return false;
    }

    return true;
  }
}

export default MultiLocaleRouter;
