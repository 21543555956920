module.exports = {
	"companies": {
		"buzzfeed": {
			"title": "BuzzFeed",
			"alt": "BuzzFeed Logo"
		},
		"houzz": {
			"title": "Houzz",
			"alt": "Houzz Logo"
		},
		"burdastyle": {
			"title": "BurdaStyle",
			"alt": "BurdaStyle Logo"
		},
		"molliemakes": {
			"title": "MollieMakes",
			"alt": "MollieMakes Logo"
		},
		"huffpo": {
			"title": "The Huffington Post",
			"alt": "The Huffington Post Logo"
		}
	}
};