import {AxiosResponse} from 'axios';
import {Dispatch} from 'redux';

import {userStatsReceived} from '../../../actions/userStats';
import request from '../../../application/api/request';
import {State} from '../../../store/initialState';
import selectAlpenroseHost from '../../pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectUserId} from '../selectors';


export const fetchUserStats = (dispatch: Dispatch, state: State): Promise<State['user'] | AxiosResponse<unknown>> => {
  const userId = selectUserId(state);
  let url = `user/${userId}/user_stats`;

  // Seems like magic 🤔
  const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)s\s*\=\s*([^;]*).*$)|^.*$/, '$1'); // eslint-disable-line

  if (cookieValue) url = `${url}/${cookieValue}`;

  return request(state, {
    url: `${selectAlpenroseHost(state)}/${url}`,
  })
    .then((data) => {
      dispatch(userStatsReceived(data.data));

      return data;
    });
};
