import {translate} from './index';
import {searchInStack, getLeafValue} from '../utils/search';
import {upsertUrlQuery} from '../utils/url';
import {navigationByStyle, navigationByTopic} from '../utils/navigation/navigation';
import {isUndefined, isNotUndefined, isEmpty} from '../utils/validation';
import {COLLECTIONS} from '../constants/Collections';
import {
  URL_QUERY_PARAM_SUBSTRATE_FABRIC, URL_QUERY_PARAM_PRODUCT_CURTAIN_PANEL, URL_QUERY_PARAM_PRODUCT_THROW_BLANKET,
  URL_QUERY_PARAM_PRODUCT_LUMBAR_THROW_PILLOW, URL_QUERY_PARAM_PRODUCT_DINNER_NAPKIN, URL_QUERY_PARAM_PRODUCT_PLACEMAT,
  URL_QUERY_PARAM_PRODUCT_TEA_TOWEL, URL_QUERY_PARAM_PRODUCT_RECTANGULAR_TABLE_CLOTH, URL_QUERY_PARAM_PRODUCT_ROUND_TABLE_CLOTH,
  URL_QUERY_PARAM_PRODUCT_TABLE_RUNNER, URL_QUERY_PARAM_PRODUCT_DUVET_COVER, URL_QUERY_PARAM_PRODUCT_SHEET_SET,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER, URL_QUERY_PARAM_PRODUCT_COCKTAIL_NAPKIN, URL_QUERY_PARAM_PRODUCT_SQUARE_THROW_PILLOW,
  URL_QUERY_PARAM_SORT_ALPHABETICAL, URL_QUERY_PARAM_PRODUCT_STANDARD_PILLOW_SHAM, URL_QUERY_PARAM_PRODUCT_EURO_PILLOW_SHAM,
  URL_QUERY_PARAM_SORT_MOSTFAVORITED, URL_QUERY_PARAM_SORT_TRENDING, URL_QUERY_PARAM_SORT_NEW,
  URL_QUERY_PARAM_SORT_BESTSELLERS, URL_QUERY_PARAM_SUBSTRATE_ALL, URL_QUERY_PARAM_CATEGORY_BEDDING,
  URL_QUERY_PARAM_CATEGORY_DINING, URL_QUERY_PARAM_CATEGORY_LIVING,URL_QUERY_PARAM_SORT_MOSTRELEVANT,
  URL_QUERY_PARAM_PRODUCT_LONG_LUMBAR_THROW_PILLOW, URL_QUERY_PARAM_PRODUCT_WALL_HANGING,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SMOOTH,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GRASSCLOTH,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_ISOBAR,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SILVER_METALLIC,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GOLD_METALLIC,
} from '../constants/Parameters';
import {REGEX_ALL_PARAM} from '../constants/Validation';

// Sort
const API_PARAM_SORT_BESTSELLERS = 'bestSelling';
const API_PARAM_SORT_NEW = 'newest';
// const API_PARAM_SORT_TRENDING = 'trending';
const API_PARAM_SORT_MOSTFAVORITED = 'mostFavorited';
const API_PARAM_SORT_MOSTRELEVANT = 'mostRelevant';
const API_PARAM_SORT_ALPHABETICAL = 'alphabetical';

const URL_QUERY_TO_API_PARAM_SORT_MAP = {
  [URL_QUERY_PARAM_SORT_BESTSELLERS]: API_PARAM_SORT_BESTSELLERS,
  [URL_QUERY_PARAM_SORT_NEW]: API_PARAM_SORT_NEW,
//  [URL_QUERY_PARAM_SORT_TRENDING]: API_PARAM_SORT_TRENDING,
  [URL_QUERY_PARAM_SORT_MOSTFAVORITED]: API_PARAM_SORT_MOSTFAVORITED,
  [URL_QUERY_PARAM_SORT_ALPHABETICAL]: API_PARAM_SORT_ALPHABETICAL,
  [URL_QUERY_PARAM_SORT_MOSTRELEVANT]: API_PARAM_SORT_MOSTRELEVANT
};
// End Sort

// Product
const API_PARAM_PRODUCT_FABRIC = 'Fabric';
const API_PARAM_PRODUCT_WALLPAPER = 'Wallpaper';
const API_PARAM_PRODUCT_SQUARE_THROW_PILLOW = 'SquareThrowPillow';
const API_PARAM_PRODUCT_LUMBAR_THROW_PILLOW = 'LumbarThrowPillow';
const API_PARAM_PRODUCT_THROW_BLANKET = 'ThrowBlanket';
const API_PARAM_PRODUCT_CURTAIN_PANEL = 'CurtainPanel';
const API_PARAM_PRODUCT_COCKTAIL_NAPKIN = 'CocktailNapkin';
const API_PARAM_PRODUCT_DINNER_NAPKIN = 'DinnerNapkin';
const API_PARAM_PRODUCT_PLACEMAT = 'Placemat';
const API_PARAM_PRODUCT_TEA_TOWEL = 'TeaTowel';
const API_PARAM_PRODUCT_WALL_HANGING = 'WallHanging';
const API_PARAM_PRODUCT_RECTANGULAR_TABLE_CLOTH = 'RectangularTable_cloth';
const API_PARAM_PRODUCT_ROUND_TABLE_CLOTH = 'RoundTable_cloth';
const API_PARAM_PRODUCT_TABLE_RUNNER = 'TableRunner';
const API_PARAM_PRODUCT_DUVET_COVER = 'DuvetCover';
const API_PARAM_PRODUCT_SHEET_SET = 'SheetSet';
const API_PARAM_PRODUCT_STANDARD_PILLOW_SHAM = 'StandardPillowSham';
const API_PARAM_PRODUCT_EURO_PILLOW_SHAM = 'EuroPillowSham';
const API_PARAM_CATEGORY_BEDDING = 'Bedding';
const API_PARAM_CATEGORY_DINING = 'Dining';
const API_PARAM_CATEGORY_LIVING = 'Living_Decor';
const API_PARAM_SUBSTRATE_ALL = 'Fabric,Wallpaper,Bedding,LongLumbarThrowPillow,Dining,Living_Decor';
const API_PARAM_PRODUCT_LONG_LUMBAR_THROW_PILLOW = 'LongLumbarThrowPillow';

// wallpaper substrates
const API_PARAM_SUBSTRATE_WALLPAPER_SMOOTH = 'wallpaper_smooth';
const API_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK = 'wallpaper_peel_and_stick';
const API_PARAM_SUBSTRATE_WALLPAPER_GRASSCLOTH = 'wallpaper_grasscloth';
const API_PARAM_SUBSTRATE_WALLPAPER_ISOBAR = 'wallpaper_isobar';
const API_PARAM_SUBSTRATE_WALLPAPER_SILVER_METALLIC = 'wallpaper_silver_metallic';
const API_PARAM_SUBSTRATE_WALLPAPER_GOLD_METALLIC = 'wallpaper_gold_metallic';

export const URL_QUERY_TO_API_PARAM_PRODUCT_MAP = {
  [URL_QUERY_PARAM_SUBSTRATE_ALL]: API_PARAM_SUBSTRATE_ALL,
  [URL_QUERY_PARAM_SUBSTRATE_FABRIC]: API_PARAM_PRODUCT_FABRIC,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER]: API_PARAM_PRODUCT_WALLPAPER,
  [URL_QUERY_PARAM_PRODUCT_SQUARE_THROW_PILLOW]: API_PARAM_PRODUCT_SQUARE_THROW_PILLOW,
  [URL_QUERY_PARAM_PRODUCT_LUMBAR_THROW_PILLOW]: API_PARAM_PRODUCT_LUMBAR_THROW_PILLOW,
  [URL_QUERY_PARAM_PRODUCT_THROW_BLANKET]: API_PARAM_PRODUCT_THROW_BLANKET,
  [URL_QUERY_PARAM_PRODUCT_CURTAIN_PANEL]: API_PARAM_PRODUCT_CURTAIN_PANEL,
  [URL_QUERY_PARAM_PRODUCT_COCKTAIL_NAPKIN]: API_PARAM_PRODUCT_COCKTAIL_NAPKIN,
  [URL_QUERY_PARAM_PRODUCT_DINNER_NAPKIN]: API_PARAM_PRODUCT_DINNER_NAPKIN,
  [URL_QUERY_PARAM_PRODUCT_PLACEMAT]: API_PARAM_PRODUCT_PLACEMAT,
  [URL_QUERY_PARAM_PRODUCT_TEA_TOWEL]: API_PARAM_PRODUCT_TEA_TOWEL,
  [URL_QUERY_PARAM_PRODUCT_WALL_HANGING]: API_PARAM_PRODUCT_WALL_HANGING,
  [URL_QUERY_PARAM_PRODUCT_RECTANGULAR_TABLE_CLOTH]: API_PARAM_PRODUCT_RECTANGULAR_TABLE_CLOTH,
  [URL_QUERY_PARAM_PRODUCT_ROUND_TABLE_CLOTH]: API_PARAM_PRODUCT_ROUND_TABLE_CLOTH,
  [URL_QUERY_PARAM_PRODUCT_TABLE_RUNNER]: API_PARAM_PRODUCT_TABLE_RUNNER,
  [URL_QUERY_PARAM_PRODUCT_DUVET_COVER]: API_PARAM_PRODUCT_DUVET_COVER,
  [URL_QUERY_PARAM_PRODUCT_SHEET_SET]: API_PARAM_PRODUCT_SHEET_SET,
  [URL_QUERY_PARAM_PRODUCT_STANDARD_PILLOW_SHAM]: API_PARAM_PRODUCT_STANDARD_PILLOW_SHAM,
  [URL_QUERY_PARAM_PRODUCT_EURO_PILLOW_SHAM]: API_PARAM_PRODUCT_EURO_PILLOW_SHAM,
  [URL_QUERY_PARAM_CATEGORY_BEDDING]: API_PARAM_CATEGORY_BEDDING,
  [URL_QUERY_PARAM_CATEGORY_DINING]: API_PARAM_CATEGORY_DINING,
  [URL_QUERY_PARAM_CATEGORY_LIVING]: API_PARAM_CATEGORY_LIVING,
  [URL_QUERY_PARAM_PRODUCT_LONG_LUMBAR_THROW_PILLOW]: API_PARAM_PRODUCT_LONG_LUMBAR_THROW_PILLOW,
};

export const URL_QUERY_TO_API_PARAM_SUBSTRATE_WALLPAPER_MAP = {
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SMOOTH]: API_PARAM_SUBSTRATE_WALLPAPER_SMOOTH,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK]: API_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GRASSCLOTH]: API_PARAM_SUBSTRATE_WALLPAPER_GRASSCLOTH,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_ISOBAR]: API_PARAM_SUBSTRATE_WALLPAPER_ISOBAR,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SILVER_METALLIC]: API_PARAM_SUBSTRATE_WALLPAPER_SILVER_METALLIC,
  [URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GOLD_METALLIC]: API_PARAM_SUBSTRATE_WALLPAPER_GOLD_METALLIC,
};

const SORT_PARAM = 'sort';
const QUERY_PARAM = 'q';
const DESIGNER_PARAM = 'designer';
const STYLE_PARAM = 'style';
const COLOR_PARAM = 'color';
const TOPIC_PARAM = 'topic';
const PRODUCT_PARAM = 'on';
const OFFSET_PARAM = 'offset';
const LANG_PARAM = 'lang';
const MATURE_CONTENT_PARAM = 'showMatureContent';
const SEARCH_TEST_VARIANT = 'testVariant';
const IS_TAGS_PAGE = 'isTagsPage';
const AUTO_APPLY = 'autoApply';
const SUBSTRATE_PARAM = 'fabric';
const AUTO_APPLY_MATURE_CONTENT = 'autoApplyMatureContent';
// End Product

export const URL_QUERY_PARAM_DESIGNER_SEARCH = 'designers';
export const URL_QUERY_PARAM_COLLECTION_SEARCH = COLLECTIONS;

export const SEARCH_AUTO_COMPLETE_CATEGORIES = [
  URL_QUERY_PARAM_SUBSTRATE_FABRIC,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER,
  URL_QUERY_PARAM_CATEGORY_BEDDING,
  URL_QUERY_PARAM_CATEGORY_DINING,
  URL_QUERY_PARAM_CATEGORY_LIVING,
  URL_QUERY_PARAM_DESIGNER_SEARCH,
  URL_QUERY_PARAM_COLLECTION_SEARCH
];

export const REDIRECT_AUTO_COMPLETE_CATEGORIES = [
  URL_QUERY_PARAM_DESIGNER_SEARCH
];

export default class Search {
  static substrateUrlQueryParamIsValid(value) {
    return isNotUndefined(URL_QUERY_TO_API_PARAM_PRODUCT_MAP[value]);
  }

  static substrateParamIsValid(value) {
    return isNotUndefined(URL_QUERY_TO_API_PARAM_SUBSTRATE_WALLPAPER_MAP[value]);
  }

  static sortUrlQueryParamIsValid(value) {
    return isNotUndefined(URL_QUERY_TO_API_PARAM_SORT_MAP[value]);
  }

  static validateUrlQueryParams({
    lang,
    sort = URL_QUERY_PARAM_SORT_BESTSELLERS
    // q='', offset=0, limit=30 are other possible query params
  }) {
    if (isUndefined(lang) || lang === '') {
      throw new Error('lang param is required');
    }
    if (!Search.sortUrlQueryParamIsValid(sort)) {
      throw new Error('invalid sort value');
    }
  }

  static englishParam(selectedParam, paramList) {
    const paramPath = searchInStack(selectedParam, paramList);
    let param;

    if (!isEmpty(paramPath)) {
      param = translate(`navigation.${getLeafValue(paramPath.pop())}.text`, {
        'langCode': 'en'
      }).replace(REGEX_ALL_PARAM, '');
    } else {
      param = selectedParam;
    }

    return param;
  }

  static autoApplyParam({lang, query, autoApply}) {
    const result = lang === 'en' && Boolean(query) &&
      Boolean(process.env.REACT_APP_IS_SERVER) && autoApply;

    return result ? {
      autoApply: result
    } : {};
  }

  static formatApiParams(params) {
    const sort = params[SORT_PARAM];
    const query = params[QUERY_PARAM];
    const designer = params[DESIGNER_PARAM];
    const styleList = params[STYLE_PARAM];
    const colorsList = params[COLOR_PARAM];
    const topicList = params[TOPIC_PARAM];
    const product = params[PRODUCT_PARAM];
    const page_offset = params[OFFSET_PARAM];
    const showMatureContent = params[MATURE_CONTENT_PARAM];
    const lang = params[LANG_PARAM];
    const searchParams = {
      lang,
    };
    const testVariant = params[SEARCH_TEST_VARIANT];
    const isTagsPage = params[IS_TAGS_PAGE];
    const autoApply = params[AUTO_APPLY];
    const substrate = params[SUBSTRATE_PARAM];
    const autoApplyMatureContent = params[AUTO_APPLY_MATURE_CONTENT];

    // terms params
    if (isNotUndefined(query)) {
      Object.assign(searchParams, {
        terms: query
      });
    }
    //  screenName param
    if (isNotUndefined(designer)) {
      Object.assign(searchParams, {
        screenName: designer
      });
    }
    // style params
    if (isNotUndefined(styleList)) {
      Object.assign(searchParams, {
        style: Search.englishParam(styleList, navigationByStyle())
      });
    }
    // color params
    if (isNotUndefined(colorsList)) {
      Object.assign(searchParams, {
        color: colorsList
      });
    }
    // topic params
    if (isNotUndefined(topicList)) {
      Object.assign(searchParams, {
        topic: Search.englishParam(topicList, navigationByTopic())
      });
    }
    // sort param
    if (isNotUndefined(sort)) {
      Object.assign(searchParams, {
        sort: URL_QUERY_TO_API_PARAM_SORT_MAP[sort]
      });
    }
    // product param
    if (isNotUndefined(product)) {
      Object.assign(searchParams, {
        product: URL_QUERY_TO_API_PARAM_PRODUCT_MAP[product.toLowerCase()]
      });
    }
    // page offset param
    if (isNotUndefined(page_offset)) {
      Object.assign(searchParams, {
        page_offset
      });
    }
    // showMatureContent params
    if (isNotUndefined(showMatureContent)) {
      Object.assign(searchParams, {
        showMatureContent
      });
    }

    if (isNotUndefined(testVariant)) {
      searchParams.testVariant = testVariant;
    }

    // isTagsPage param
    if (isNotUndefined(isTagsPage)) {
      Object.assign(searchParams, {
        isTagsPage
      });
    }

    // autoApply param for productTypeFiltering
    if (isNotUndefined(product)) {
      Object.assign(searchParams, Search.autoApplyParam({
        lang,
        query,
        autoApply,
      }));
    }

    // substrate param
    if (isNotUndefined(substrate)) {
      Object.assign(searchParams, {
        [SUBSTRATE_PARAM]: substrate,
      });
    }

    // autoApplyMatureContent param
    if (isNotUndefined(autoApplyMatureContent)) {
      Object.assign(searchParams, {
        [AUTO_APPLY_MATURE_CONTENT]: autoApplyMatureContent,
      });
    }

    return searchParams;
  }

  static makeUrl(pythiasHost, {
    lang = '',
    page_offset = '3',
    terms = '',
    screenName = '',
    style = '',
    color = '',
    eigColors = '',
    topic = '',
    sort = '',
    product = '',
    showMatureContent = '',
    testVariant = '',
    isTagsPage = '',
    autoApply = '',
    [SUBSTRATE_PARAM]: substrateParam = '',
    [AUTO_APPLY_MATURE_CONTENT]: autoApplyMatureContent = '',
  }, endpoint, currentOrigin) {
    return upsertUrlQuery(endpoint, {
      lang,
      page_offset,
      terms,
      screenName,
      style,
      color,
      eigColors,
      topic,
      sort,
      product,
      showMatureContent,
      testVariant,
      isTagsPage,
      autoApply,
      [SUBSTRATE_PARAM]: substrateParam,
      [AUTO_APPLY_MATURE_CONTENT]: autoApplyMatureContent,
    }, currentOrigin, pythiasHost);
  }
}
