/* eslint-disable complexity */
import isObject from 'lodash/isObject';
import * as types from '../constants/ActionTypes';
import initialState from '../store/initialState';
import omit from 'lodash/omit';

function addToCart(state = {}, action) {
  let itemQuantity = state.quantity;

  switch (action.type) {
    case types.SUBSTRATE_CHOSEN:
      return Object.assign({}, state, {
        selectedSubstrate: action.payload
      });
    case types.DESIGN_CHOSEN:
      return Object.assign({}, state, {
        designId: action.payload
      });
    case types.QUANTITY_CHOSEN:
      return Object.assign({}, state, {
        quantity: action.payload
      });
    case types.PRODUCT_SIZE_CHOSEN:
      return Object.assign({}, state, {
        productSize: action.payload
      });
    case types.HOME_GOOD_ADDITION_CHOSEN:
      return Object.assign({}, state, {
        addition: isObject(action.payload) ? action.payload.target.value : action.payload
      });
    case types.ADD_TO_CART_POST + types.SUCCESS:
      return Object.assign({}, state, action.data, {
        isFetching: false
      });
    case types.FABRIC_PRICING_GET + types.SUCCESS:
    case types.HOME_GOOD_PRICING_GET + types.SUCCESS:
    case types.WALLPAPER_PRICING_GET + types.SUCCESS:
    case types.STOCK_ITEM_PRICING_GET + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        isFetching: false
      });
    case types.ADD_TO_CART_POST + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false
      });
    case types.FABRIC_PRICING_GET + types.FAIL:
    case types.HOME_GOOD_PRICING_GET + types.FAIL:
    case types.WALLPAPER_PRICING_GET + types.FAIL:
    case types.STOCK_ITEM_PRICING_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        failed: true
      });
    case types.ADD_TO_CART_POST + types.FETCHING:
    case types.FABRIC_PRICING_GET + types.FETCHING:
    case types.HOME_GOOD_PRICING_GET + types.FETCHING:
    case types.WALLPAPER_PRICING_GET + types.FETCHING:
    case types.STOCK_ITEM_PRICING_GET + types.FETCHING:
      return Object.assign({}, state,{
        isFetching: true
      });
    case types.PRODUCT_PAGE_RESET:
      return initialState.addToCart;

    case types.WALLPAPER_RECOMMENDATION_POST + types.FAIL:
      return Object.assign({}, state,{
        measurementsPosted: true,
        isPostingMeasurements: false
      });
    case types.WALLPAPER_RECOMMENDATION_POST + types.FETCHING:
      return Object.assign({}, state,{
        isPostingMeasurements: true,
        measurementsPosted: false,
      });
    case types.WALLPAPER_RECOMMENDATION_POST + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        measurementsPosted: true,
        isPostingMeasurements: false,
        productSize: action.payload.data.default_size
      });
    case types.CHANGE_ORDER_ITEM_SUBSTRATE_TYPE:
      return Object.assign({}, state, {
        orderItemSubstrateCodeSet: action.payload.data
      });
    case types.CALCULATOR_MODAL_RESET:
      if (itemQuantity > 99) {
        itemQuantity = 1;
      }

      return Object.assign({}, state,{
        error: '',
        quantity: itemQuantity
      });
    case types.CLEAR_ADD_TO_CART_STATE:

      return Object.assign(
        {},
        omit(state, [
          'pricing',
          'price_per',
          'promo',
          'analytics',
          'turnarounds',
          'afterpay',
          'productSize',
          'selectedSubstrate',
          'fabric_code',
          'default_size',
          'addition',
        ]),
        {
          isFetching: false,
          isPostingMeasurements: false,
          measurementsPosted: false,
          availability: true,
          orderItemSubstrateCodeSet: false,
        }
      );

    default:
      return state;
  }
}

export default addToCart;
