import * as types from '../constants/ActionTypes';

function getAnnouncements(state = {}, action) {
  switch (action.type) {
    case types.ANNOUNCEMENTS_RECEIVED:
      return action.payload;

    default:
      return state;
  }
}

export default getAnnouncements;
