// TODO SP-6816: Refine types throughout this file as TS expands in codebase
import {ProductFormProps} from '../components/Product/ProductForm/ProductForm';
import {MEASUREMENTS, MeasurementSystem} from '../constants/Measurements';
import {CURRENCIES} from '../constants/Payment';
import {REGEX_EMAIL, REGEX_US_ZIP_CODE, REGEX_CA_ZIP_CODE, REGEX_REMOVE_ALL_SPACES, REGEX_VAT} from '../constants/Validation';
import type {CountriesCollection} from '../entities/pageSetup/countries/types';
import {translate} from '../services';


export const objectIsEmpty = (object: Record<string, unknown> | undefined): boolean => !object || Object.keys(object).length === 0;

export const objectIsNotEmpty = <K extends string | number | symbol, V>(maybeObject: Record<K, V> | undefined): maybeObject is Record<K, V> => (
  Boolean(maybeObject && Object.keys(maybeObject).length)
);

export const isEmpty = (value: unknown): value is undefined | null | '' | [] | -1 =>
  typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0) || value === -1;

export const isArray = Array.isArray;

const join = (rules: ((...args: unknown[]) => unknown)[]) => (value: unknown, data: unknown) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0 /* first error */];

/**
 * Accepts 12345 or 12345-6789
 */
export function usZipCode(value: string): string | undefined {
  if (!REGEX_US_ZIP_CODE.exec(value)) {
    return translate('errors.inline.usZipcodeFormat');
  }
}

/**
 * Accepts A1A1X1 or A1A 1X1 or a1a1x1 or a1a 1x1
 */
export function caZipCode(value: string): string | undefined {
  if (!REGEX_CA_ZIP_CODE.exec(value)) {
    return translate('errors.inline.caZipcodeFormat');
  }
}

/**
 * Accepts 2 letter codes for every US state
 */
export function usStates(value: string | number, statesObject: Record<string, unknown>): string | undefined {
  if (statesObject[value] === undefined) {
    return translate('errors.inline.usSelectState');
  }
}

/**
 * Accepts 2 letter codes for every Canada province/state
 */
export function canadaStates(value: string | number, statesObject: Record<string, unknown>): string | undefined {
  if (statesObject[value] === undefined) {
    return translate('errors.inline.caSelectState');
  }
}

/**
 * Validates that the input includes @ and dot.
 * @param value
 */
export function email(value: string): string | undefined {
  if (!REGEX_EMAIL.exec(value)) {
    return translate('errors.inline.provideEmail');
  }
}

export const emailsMustMatch = <T>(value: T, allValues: {email: T}): string | undefined =>
  (value !== allValues.email ? translate('errors.inline.retypeEmailDoesNotMatch') : undefined);

export function required(value: Record<string, string>, message?: string): string | undefined {
  if (isEmpty(value)) {
    return message || translate('errors.inline.required');
  }
}

export function requiredCountrySelect(value: Record<string, string>): string | undefined {
  if (isEmpty(value)) {
    return translate('subscribeNewsletter.subscribe.country_error');
  }
}

export function requiredRecaptcha(value: Record<string, string>): string | undefined {
  if (isEmpty(value)) {
    return 'Recaptcha Incorrect!';
  }
}

export function isTrue(value: unknown, message?: string): string | undefined {
  if (value !== true) {
    return message || translate('errors.inline.required');
  }
}

export function minLength(min: number) {
  return (value: unknown[]): string | undefined => {
    if (!isEmpty(value) && value.length < min) {
      return translate('errors.inline.atLeastMinCharacters', {
        min
      });
    }
  };
}

export function maxLength(max: number) {
  return (value: unknown[]): string | undefined => {
    if (!isEmpty(value) && value.length > max) {
      return translate('errors.inline.noMoreMaxCharacters', {
        max
      });
    }
  };
}

export function integer(value: unknown): string | undefined {
  if (!Number.isInteger(Number(value))) {
    return translate('errors.inline.provideInteger');
  }
}

export function oneOf(enumeration: unknown[]) {
  return (value: unknown): string | undefined => {
    if (!enumeration.includes(value)) {
      return translate('errors.inline.provideOneOf', {
        enumeration: enumeration.join(', ')
      });
    }
  };
}

export function match(field: string | number) {
  return <T>(value: T, data: Record<string | number, T>): string | undefined => {
    if (data) {
      if (value !== data[field]) {
        return translate('errors.inline.doNotMatch');
      }
    }
  };
}

export function createValidator(mapValuesAndPropsToRules: unknown | ((...args: unknown[]) => unknown)) {
  return (values: string, props: unknown): ReturnType<typeof validate> => {
    let rules;

    if (isFunction(mapValuesAndPropsToRules)) {
      rules = mapValuesAndPropsToRules(values, props);
    } else {
      rules = mapValuesAndPropsToRules;
    }

    return validate(rules, values);
  };
}

function validate<T extends string>(
  rules: {[k in keyof T]: ((...args: unknown[]) => unknown)[]},
  data: {[k in keyof T]: unknown}
): Partial<{[k in keyof T]: unknown}> {
  const errors: Partial<{[k in keyof typeof rules]: unknown}> = {};

  Object.entries(rules).forEach(([key, value]) => {
    const rule = join([...value]);
    const error = rule((data[key as keyof T]), data);

    if (error) {
      errors[key as keyof T] = error;
    }
  });

  return errors;
}

export function isValidNzbn(nzbn: string): undefined | boolean {
  const valid_regex = /^(\d{13})$/.exec(nzbn);

  if (valid_regex === null) {
    return false;
  }

  const sum = valid_regex[1].split('').slice(0, 12)
    .map((num, i) => {
      if (i % 2 === 0) {
        return parseInt(num, 10);
      }

      return parseInt(num, 10) * 3;
    })
    .reduce((a, b) => a + b);

  return (parseInt(nzbn[12], 10) === ((Math.ceil(sum / 10) * 10) - sum)) && nzbn.length === 13;
}

export function isValidAbn(abn: string): boolean {
  // look here for info about this: https://abr.business.gov.au/Help/AbnFormat
  const abnArray = abn
    .replace(REGEX_REMOVE_ALL_SPACES, '')
    .split('')
    .map((el) => parseInt(el,10));

  if (!isWellFormattedAbn(abnArray)) {
    return false;
  }
  const total = abnArray.reduce(reduceAbn, 0);

  return total % 89 === 0;
}

export const isValidNorthernIrelandVAT = (fullVatNumber: string): boolean => {
  const matcher = REGEX_VAT.find((regex) => fullVatNumber.match(regex));

  if (!matcher) {
    return false;
  }
  const vatNumber = RegExp.$2;

  // Checks the check digits of a UK VAT number.
  const multipliers = [8, 7, 6, 5, 4, 3, 2];

  // Government departments
  if (vatNumber.startsWith('GD')) {
    return Number(vatNumber.substr(2, 3)) < 500;
  }
  // Health authorities
  if (vatNumber.startsWith('HA')) {
    return Number(vatNumber.substr(2, 3)) > 499;
  }
  // Standard and Commercial Group numbers
  let total = 0;

  // A VAT number of all zeros is disallowed
  if (Number(vatNumber.slice(0)) === 0) {
    return false;
  }
  // Check range is OK for modulus 97 calculation
  const no = Number(vatNumber.slice(0, 7));

  // Extract the next digit and multiply by the counter.
  for (let i = 0; i < 7; i++) {
    total += Number(vatNumber.charAt(i)) * multipliers[i];
  }
  // Old numbers use a simple 97 modulus, but new numbers use an adaptation of that (less 55). Our
  // VAT number could use either system, so we check it against both.
  // Establish check digits by subtracting 97 from total until negative.
  let cd = (97 - (total % 97)) % 97;

  // Compare the check digit with the last two characters of the VAT number. If
  // the same, then it is a valid traditional check digit. However, even then
  // the number must fit within certain specified ranges.
  if (
    cd === Number(vatNumber.slice(7, 9)) &&
    no < 9990001 &&
    (no < 100000 || no > 999999) &&
    (no < 9490001 || no > 9700000)
  ) {
    return true;
  }
  // Now try the new method by subtracting 55 from the check digit if we can - else add 42
  cd = cd >= 55 ? cd - 55 : cd + 42;

  return cd === Number(vatNumber.slice(7, 9)) && no > 1000000;
};

function isWellFormattedAbn(nums: number[]): boolean {
  return nums.length === 11 && !nums.includes(NaN);
}

function reduceAbn(acc: number, n: number, i: number) {
  if (i === 0) {
    acc += (n - 1) * 10;
  } else {
    const weight = ((i - 1) * 2) + 1;

    acc += n * weight;
  }

  return acc;
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

export function isNotUndefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}

// Above initially sourced from:
// https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/utils/validation.js
// Via: http://redux-form.com/4.2.0/#/examples/synchronous-validation?_k=09ieia

export const isValidMeasurementSystem = (measurementSystem: unknown): measurementSystem is MeasurementSystem => (
  typeof measurementSystem === 'string' &&
  MEASUREMENTS.includes(measurementSystem.toUpperCase() as MeasurementSystem)
);

export const isValidCurrency = (currency: unknown): currency is string => (
  typeof currency === 'string' &&
  CURRENCIES.includes(currency.toUpperCase())
);

export const isValidCountry = (countries: CountriesCollection, country: unknown): country is string => (
  typeof country === 'string' &&
  Object.keys(countries).includes(country.toUpperCase())
);

export const isValidSubstrateCookie = (cookie: unknown): cookie is string => (
  typeof cookie == 'string' &&
  !isEmpty(cookie) &&
  // "Cookies.get()" can return the string "undefined"
  cookie !== 'undefined'
);

export const validatePhotoFormat = (file: {type: string}): boolean => {
  const allowedFormats = ['image/gif', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/png', 'image/x-png'];

  return allowedFormats.indexOf(file.type) === -1;
};

export const isNotValidPrice = (price: number): boolean => (
  isNaN(price)
);

export const validateProductForm = (values: Record<string, string>, props: ProductFormProps): Record<string, string> => {
  const {sizeStyleMap, productSize, reset} = props;
  let selectedSize;
  let selectedStyle;

  if (productSize && sizeStyleMap?.hasOwnProperty(productSize)) {
    selectedSize = Object.keys(sizeStyleMap[productSize])[0];
    selectedStyle = Object.values(sizeStyleMap[productSize])[0];
  }

  if (selectedSize !== values.size || selectedStyle !== values.style) {
    isFunction(reset) && reset();
  }
  // empty object returned for redux form validate
  // TODO: SP-8571 Revisit the validateProductForm function

  return {};
};
