import classNames from 'classnames';
import React from 'react';

import './_filter-items.scss';
import {translate} from '../../../../services';
import {isNotUndefined,} from '../../../../utils/validation';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';
import {FilterCategory, FilterMenuItem,} from '../Filter';


export interface FilterItemsProps {
  items: Record<string, FilterCategory>;
  stack: string[];
  viewportIsDesktop: boolean;
  getMenuKeys: (key: string, breadcrumbEnabled?: boolean) => void;
  onBackClick: () => void;
  currentCategory?: string;
  columnExtensionClass: string;
  useLinkComponent: boolean;
  searchQuery?: string;
  filterFixedExpanded: boolean;
  subMenuHeader: string;
}

const FilterItems = ({
  columnExtensionClass,
  useLinkComponent,
  searchQuery,
  stack,
  items,
  viewportIsDesktop,
  onBackClick,
  subMenuHeader,
  filterFixedExpanded,
  getMenuKeys,
  currentCategory
}: FilterItemsProps): JSX.Element => {
  const listElementsToRender: JSX.Element[] = [];


  const categoryContainsSearchQuery = (displayText: string) => (isNotUndefined(searchQuery) &&
    searchQuery !== '' &&
    displayText.toLowerCase().includes(searchQuery) ? <mark>{displayText}</mark> : displayText);

  const renderListItem = (key: string, displayText: string, href: string,
    isCurrentSelection: boolean) => {
    const columnClasses = classNames('list-element', columnExtensionClass, {
      'x-columns': !columnExtensionClass
    });
    const childLinkClasses = classNames('nav-link', 'child-link', {
      'x-current-selection': isCurrentSelection
    });

    return (
      <li className={columnClasses} key={key}> {
        useLinkComponent ?
          <LinkWrapper
            hrefValue={href}
            className={childLinkClasses}
            contents={categoryContainsSearchQuery(displayText)} /> :
          <LinkWrapper
            hrefValue={href}
            className='nav-link child-link'
            contents={displayText} />}
      </li>
    );
  };

  const isSearchFilterCategory = (input: FilterCategory | FilterMenuItem): input is FilterCategory => Object.prototype.hasOwnProperty.call(input as FilterCategory, 'children');

  const createListElements = (items: Record<string, FilterCategory | FilterMenuItem>) => {
    for (const key of Object.keys(items)) {
      const item = items[key];
      const displayText = item.text;
      const href = item.href ? item.href : '';

      const getCurrentSelection = () => {
        if (currentCategory && items[key].slug) {
          return item.slug === currentCategory;
        }

        return false;
      };

      listElementsToRender.push(
        renderListItem(key, displayText, href, getCurrentSelection())
      );
    }
  };

  const menuClasses = classNames('b-filter-items', {
    'x-back-button': stack.length > 2
  });

  let parent;
  let itemsToIterate: Record<string, FilterCategory | FilterMenuItem> = items;

  for (const menuKey of stack) {
    parent = itemsToIterate[menuKey];
    if (isSearchFilterCategory(parent)) {
      itemsToIterate = parent.children;
    }
  }

  createListElements(itemsToIterate);

  return (
    <ul className={menuClasses}>
      {((isNotUndefined(subMenuHeader) && !viewportIsDesktop) || filterFixedExpanded) &&
        <li className='list-element x-filter-subheading'>
          <h3>{translate(`navigation.subMenuHeader.${subMenuHeader}`)}</h3>
        </li>}
      {listElementsToRender}
    </ul>
  );
};

export default FilterItems;
