import React from 'react';
import PropTypes from 'prop-types';
import './_flash-messages.scss';
import * as fm from '../../../../constants/FlashMessages';
import FlashMessageBase from './FlashMessageBase';

const FlashMessage = ({message, severity, specialClass}) => (
  <FlashMessageBase
    message={message}
    cssClasses={[specialClass, `x-${severity}`]}
  />
);

FlashMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
  severity: PropTypes.oneOf(fm.MESSAGE_SEVERITIES).isRequired,
  specialClass: PropTypes.string,
};

export default FlashMessage;
