import {UNAUTHENTICATED} from '../constants/userTypes';
import {fetchUserStats} from '../entities/user/api/fetchUserStats';
import {pageUpdate} from './pageSetup';
import {currentUserReceived, fetchUserCollections} from './user';


export function bootstrapState(dispatch, state) {
  return Promise.all([
    fetchUserStats(dispatch, state),
    // dispatch(fetchCartCount()),
    fetchUserCollections(dispatch, state),
  ])
    .then(() => {
      dispatch(pageUpdate({
        finishedInitialCart: true
      }));
    });
}

export function unAuthBootstrapState(dispatch) {
  const unAuthUser = {
    data: {
      userType: UNAUTHENTICATED
    }
  };

  dispatch(currentUserReceived(unAuthUser));
}
