import * as types from '../constants/ActionTypes';

function multiLocaleRouter(state = {}, action) {
  switch (action.type) {
    case types.ALTERNATE_LOCALES_SET:
      return Object.assign({}, state, {
        alternateLocales: action.payload
      });
    default:
      return state;
  }
}

export default multiLocaleRouter;
