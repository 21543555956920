import * as types from '../constants/ActionTypes';

export function crossSellFetching() {
  return {
    type: types.CROSS_SELL_GET + types.FETCHING
  };
}

export function crossSellReceived(payload) {
  return {
    type: types.CROSS_SELL_GET + types.SUCCESS,
    payload
  };
}

export function crossSellFailed(payload) {
  return {
    type: types.CROSS_SELL_GET + types.FAIL,
    payload
  };
}

export function clearCrossSell() {
  return {
    type: types.CROSS_SELL_CLEAR
  };
}

export function colorCrossSellFetching() {
  return {
    type: types.SOLID_CROSS_SELL_GET + types.FETCHING
  };
}

export function colorCrossSellReceived(payload) {
  return {
    type: types.SOLID_CROSS_SELL_GET + types.SUCCESS,
    payload
  };
}

export function colorCrossSellFailed() {
  return {
    type: types.SOLID_CROSS_SELL_GET + types.FAIL,
  };
}
