import {CURRENCY} from '../constants/Parameters';
import {isNotUndefined} from './validation';

export const adjustCurrencyParams = (countries, countryCode, currency) => {
  const countryData = countries[countryCode.toUpperCase()];

  if (isNotUndefined(countryData) && !countryData.available_currencies.includes(currency)) {
    return {
      [CURRENCY]: countryData.currency
    };
  }

  return {};
};

// Convert a locale and country code to an ISO country-specific locale, provided that it is supported.
// i.e. 'en' and 'GB' to 'en_GB'
// https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.10
export const localeToIso = (locale, country, supportedIsos) => {
  const generalIsos = supportedIsos.filter((iso) => iso.split('_')[0] === locale);
  const specificIso = generalIsos.find((iso) => iso.split('_')[1] === country);

  return specificIso || generalIsos[0];
};
