module.exports = {
	"metaTitle": "Student Project Grant | Spoonflower",
	"metaDescription": "Our Student Project Grant supports the artistic pursuits students and provides recipients up to $1,000 in credit to design their own fabrics and wallpaper.",
	"metaKeywords": "textile grant, grant, student grant, fashion grant, art projects, student project grant, undergrad grant, graduate grant, interior design, interior design grant",
	"title": "Student Project Grant",
	"moreLinkText": "See More >>",
	"info": {
		"introP": "The Spoonflower Student Project Grant (formerly the Emerging Designer Grant) supports the artistic pursuits of a select group of full-time undergraduate and graduate students worldwide. Recipients will receive an award of up to $1,000 in Spoonflower credit to design their own digitally printed fabrics and wallpaper. Possible student projects include, but are not limited to, art installations, fashion shows or interior design projects.",
		"appHeader": "Application",
		"appP1": "Any undergraduate or graduate student currently enrolled in an art, textile, fashion or design program is eligible to apply. Grant requests will be awarded to up to 10 applicants per semester.",
		"appP2start": "The online application form will ask for proof of enrollment, PDF with three previous works ",
		"appP2linkText": "(see example)",
		"appP2href": "https://d3u3xhd9tobx8a.cloudfront.net/static/pages/2018-07/PDF_Example.pdf",
		"appP2end": ", and contact information for your project advisor. The applicant must also answer narrative questions regarding their work and the project proposed.",
		"appP3": "To view/complete the application,",
		"appP3linkText": "click here",
		"appP3href": "https://spoonflower.wufoo.com/forms/spoonflower-student-project-grant/",
		"deadlineHeader": "Deadlines",
		"deadlineP1": "We offer one deadline per school year.",
		"deadlineSeason1": "Autumn",
		"deadlineDate1": {
			"applicationLabel": "Application Deadline",
			"applicationDate": "October 1",
			"notificationLabel": "Notifications",
			"notificationDate": "October 22"
		},
		"deadlineSeason2": "Spring",
		"deadlineDate2": {
			"applicationLabel": "Application Deadline",
			"applicationDate": "February 1, 2019",
			"notificationLabel": "Notifications",
			"notificationDate": "February 15, 2019"
		}
	},
	"video": {
		"title": "Art2Wear Fashion Show",
		"subtitle": "North Carolina State University",
		"text": "Art2Wear is the largest student-organized runway show in the Southeast that presents fashion, costume, and wearable sculpture created by students of the College of Design at NC State University. This annual event, organized and hosted by the College of Design’s Art + Design department, displays juried examples of our best student work each Spring through runway and exhibition."
	},
	"recipients": {
		"title": "Past Recipients"
	},
	"faqs": {
		"title": "Frequently Asked Questions",
		"q1": "What can I submit for proof of enrollment documents?",
		"a1": {
			"start": "Please upload one of the following to your application:",
			"li1": "A transcript or class list for the current term (must include the date/term)",
			"li2": "A copy or picture of your student ID (must include an expiration date or term)"
		},
		"q2": "Any tips for choosing images of my previous work?",
		"a2": {
			"start": "You really want to make those three images count! While you are able to include an optional link to your website, the three image PDF slideshow is the panel’s first impression of your work. Choose images that are photographed well and show overall artistic skill and a commitment to craft. You can include assignments, works in progress and sketches, but we highly recommend you show at least one finished work. Lastly, don’t forget to compile the three images into one singular PDF like ",
			"linkText": "this example",
			"end": ". Use Microsoft PowerPoint or Google Slides to easily put this document together."
		},
		"q3": "How do I figure out the amount of my grant request?",
		"a3": {
			"start": "Grant requests can be up to $1,000 credit to Spoonflower. Based on your project proposal, please make an estimate of your project costs. While we don’t require a line-by-line budget for your proposed expenses, we want to see that you recognize your project’s funding needs. It is helpful to have an estimate of how many yards or meters you will use and what type(s) of ",
			"linkText1": "fabric and/or paper",
			"middle": " you will be using in your project. Don’t forget to include ",
			"linkText2": "swatches",
			"middle2": " and any ",
			"linkText3": "design tools",
			"end": " you may need in your total."
		},
		"q4": "Can I use other elements along with Spoonflower fabrics for my garments, installation, etc.?",
		"a4": "Yes, fabric and/or paper purchased using Spoonflower Student Project Grant funds may be used in combination with other fabrics and/or elements in your project.",
		"q5": "How will I be updated about my application status?",
		"a5": "We will contact you by email whether or not you are chosen as a grant recipient on or before October 22.",
		"q6": "Are there resources that can help me to use your site?",
		"a6": {
			"start": "We recommend reviewing the following pages to begin:",
			"li1": "How does Spoonflower work?",
			"li2": "How to create a seamless repeat from a drawing",
			"li3": "Spoonflower Help Center",
			"end": "Additionally, design tools like our color map, color guide and sample pack will help you choose the right fabrics for your project and make sure your colors print the way you want. ",
			"linkText": "Check out our helpful design tools"
		},
		"q7": "Do I have to order a swatch before I order my fabric or wallpaper?",
		"a7": "In order to achieve expected colors we recommend ordering a swatch of your design before printing large amounts to ensure you are happy with colors, repeat, size, etc.",
		"q8": "Do I have to sell my designs on Spoonflower? Can they be private?",
		"a8": {
			"start": "You retain ownership of your designs and are welcome to ",
			"linkText": "keep them private or sell them through Spoonflower"
		},
		"q9": "If I’m awarded the grant, what does Spoonflower expect from me?",
		"a9": {
			"start": "We’d love to highlight your process and final project ",
			"linkText": "on the Spoonflower blog",
			"end": " Student Project Grant winners will work directly with Spoonflower to coordinate sharing of the project. Please feel free to send us photos or links as you work, and don’t forget to tag us with the hashtag '#' Spoonflower on any social media posts."
		},
		"postQuestion": "Still have questions?",
		"postAnswer": "Contact us ",
		"postLinkText": "here"
	}
};