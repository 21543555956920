import * as types from '../constants/ActionTypes';

export function userStatsReceived(payload) {
  return {
    type: types.USER_STATS_GET + types.SUCCESS,
    payload
  };
}

export function userStatsPageTypeUpdated(payload) {
  return {
    type: types.USER_STATS_PAGE_TYPE + types.SUCCESS,
    payload
  };
}

export function userStatsSetInitials(payload) {
  return {
    type: types.USER_STATS_SET_INITIALS,
    payload
  };
}
