import React from 'react';
import PropTypes from 'prop-types';
import './_mobile-navigation-cover.scss';
import {navigationItems} from '../../../utils/navigation/mainNavigation';
import {onClickPreventPropagation} from '../../../utils/events';
import {languagesWithoutCategoriesColors, EN} from '../../../constants/SupportedLanguages';
import {GUEST, REGISTERED, UNAUTHENTICATED} from '../../../constants/userTypes';
import MenuItems from '../../Reusable/MenuItems/MenuItems';
import {useSelector} from 'react-redux';


const MobileNavigation = ({userType, userName, locale, userId, userDesignOwner}) => {
  const {recentlyViewedUrl} = useSelector((state) => ({
    recentlyViewedUrl: state.pageSetup.apiHosts.recentlyViewedApi
  }));
  const hideCategoriesColors = languagesWithoutCategoriesColors.includes(locale);
  const localeIsEnglish = locale === EN;
  const menuItems = navigationItems(hideCategoriesColors, localeIsEnglish, true, userName, userId, userDesignOwner, recentlyViewedUrl);

  return (
    <nav className='b-mobile-navigation' onClick={onClickPreventPropagation}>
      <MenuItems
        items={menuItems}
        userType={userType}
        userName={userName}
        userDesignOwner={userDesignOwner}/>
    </nav>
  );
};


MobileNavigation.propTypes = {
  userName: PropTypes.string,
  locale: PropTypes.string,
  userDesignOwner: PropTypes.bool,
  userId: PropTypes.number,
  userType: PropTypes.oneOf([UNAUTHENTICATED, GUEST, REGISTERED])
};

export default MobileNavigation;
