import {RuntimeEnv} from '../../../environment/runtimeEnv';


export enum ApiHostsEnum {
  pythias = 'pythias',
  apiGateway = 'apiGateway',
  images = 'images',
  assets = 'assets',
  afterpay = 'afterpay',
  s3 = 's3',
  garden = 'garden',
  storeApi = 'storeApi',
  cartApi = 'cartApi',
  recentlyViewedApi = 'recentlyViewedApi'
}

export type ApiHosts = {
  [P in keyof typeof ApiHostsEnum]: string;
};

type ApiServerHosts = {
  [P in keyof ApiHosts]?: ApiHosts[P];
};

interface ApiHostsEnvs {
  client: ApiHosts;
  server: ApiServerHosts;
}

interface ApiHostsConfiguration {
  [RuntimeEnv.Development]: ApiHostsEnvs;
  [RuntimeEnv.Staging]: ApiHostsEnvs;
  [RuntimeEnv.Production]: ApiHostsEnvs;
}

const apiHosts: ApiHostsConfiguration = {
  [RuntimeEnv.Development]: {
    client: {
      pythias: 'https://pythias-staging.spoonflower.com',
      apiGateway: 'https://staging-api-gateway.spoonflower.com',
      images: 'https://cdn.spoonflower.com/baerlauch/development/images',
      assets: 'https://staging.spoonflower.com/assets',
      afterpay: 'https://portal.sandbox.afterpay.com/afterpay.js',
      s3: 'https://s3.amazonaws.com/spoonflower-development',
      garden: 'https://staging-garden.spoonflower.com',
      storeApi: 'https://staging-api-gateway.spoonflower.com/store-api',
      cartApi: 'https://test.spoonflower.com',
      recentlyViewedApi: 'https://recent-stage.spoonflower.com'
    },
    server: {},
  },
  [RuntimeEnv.Staging]: {
    client: {
      pythias: 'https://pythias-staging.spoonflower.com',
      apiGateway: 'https://staging-api-gateway.spoonflower.com',
      images: 'https://cdn.spoonflower.com/baerlauch/development/images',
      assets: 'https://staging.spoonflower.com/assets',
      afterpay: 'https://portal.sandbox.afterpay.com/afterpay.js',
      s3: 'https://s3.amazonaws.com/spoonflower-development',
      garden: 'https://staging-garden.spoonflower.com',
      storeApi: 'https://staging-api-gateway.spoonflower.com/store-api',
      cartApi: 'https://test.spoonflower.com',
      recentlyViewedApi: 'https://recent-stage.spoonflower.com'
    },
    server: {},
  },
  [RuntimeEnv.Production]: {
    client: {
      pythias: 'https://pythias.spoonflower.com',
      apiGateway: 'https://api-gateway.spoonflower.com',
      images: 'https://cdn.spoonflower.com/baerlauch/production/images',
      assets: 'https://s3.amazonaws.com/spoonflower/public',
      afterpay: 'https://portal.afterpay.com/afterpay.js',
      s3: 'https://s3.amazonaws.com/spoonflower',
      garden: 'https://garden.spoonflower.com',
      storeApi: 'https://api-gateway.spoonflower.com/store-api',
      cartApi: 'https://cart.spoonflower.com',
      recentlyViewedApi: 'https://recent.spoonflower.com'
    },
    server: {},
  },
};

export default apiHosts;
