module.exports = {
	"reviews": {
		"summaryHeading": "Customer Reviews Summary ({ratingCount})",
		"moreReviews": "More Reviews",
		"add": "Write a Review",
		"reviewsAmount": {
			"1": "Review",
			"2..99": "Reviews"
		},
		"starsAmount": {
			"1": "{context} Star",
			"2..5": "{context} Stars"
		},
		"materialQuestion": "How would you rate the material?",
		"printQuestion": "How would you rate the print?",
		"materialReceivedQuestion": "What material did you receive?",
		"projectQuestion": "What did you make?",
		"recommendationQuestion": "Would you recommend this item to others?",
		"reviewHelpfulQuestion": "Was this review helpful?",
		"positiveAnswer": "Yes",
		"negativeAnswer": "No",
		"flagAsInappropriate": "Flag as inappropriate",
		"itemPurchased": "Purchased",
		"noReviewsMessage": "There are no reviews for this item.",
		"noReviewsAdd": "Write Review",
		"turnToReference": "Powered by TurnTo",
		"reviewAllowanceInfo": "We're sorry, you can only review designs you've ordered",
		"readIndividualReviews": "Read individual reviews"
	},
	"reviewsForm": {
		"formHeading": "Reviews Form",
		"heading": "Please Review Your Purchase",
		"starsRating": "Overall rating (required):",
		"rating": "Rating",
		"selectRating": "Click to add rating of {context}",
		"materialReceivedQuestion": "What material did you receive? (required):",
		"materialReceivedQuestionPlaceholder": "Select One",
		"printRating": "How would you rate the print?",
		"materialRating": "How would you rate the material?",
		"projectQuestion": "What did you make?",
		"recommendationQuestion": "Would you recommend this item to others?",
		"positiveAnswer": "Yes",
		"negativeAnswer": "No",
		"reviewTitle": "Review title:",
		"review": "Review (required):",
		"attachMedia": "Attach media (add up to five files):*",
		"mediaFormatsAllowed": "*Supported formats: gif, jpeg, jpg, pjpeg, png or x-png",
		"attachPhoto": "Photo",
		"attachVideo": "Video",
		"removePhoto": "Remove Photo",
		"submit": "Submit",
		"upload": "Upload",
		"guidelines": "Review Guidelines",
		"close": "Close",
		"titlePlaceholder": "Headline or summary of your review",
		"textPlaceholder": "Share your thoughts about this item. Your experience may guide others in finding the material best suited to their project.",
		"validateRating": "Please rate the item",
		"validateMaterial": "Please select a material",
		"validateReviewText": "Please tell us how you liked the item",
		"validateCaptions": "Please add a description for each photo you uploaded",
		"validatePhotoFormat": "Please upload a picture with one of the supported formats",
		"reviewSubmitted": "Thank you for reviewing this item. Your review will be published soon.",
		"captionPlaceholder": "Please add a description",
		"choose_photo": "Choose photo"
	},
	"reviewsGuidelines": {
		"section1": "We value your input and invite you to rate and review your purchases. Be sure to explain why you like or dislike the product and focus on the product's features and your own experience using it.",
		"section2": "If you wish to comment about product selection, pricing, ordering, delivery or other issues, please contact our customer support.",
		"section3": "Please refrain from including any of the following in your review:",
		"bulletPoint1": "Obscene or discriminatory language",
		"bulletPoint2": "Critical or inappropriate comments about other reviews and shoppers",
		"bulletPoint3": "Advertising, spam, references to other websites or retailers",
		"bulletPoint4": "Personal information such as email addresses, phone numbers or physical addresses",
		"termsOfUseStatement": "All reviews are subject to our store's Terms of Use."
	},
	"answerTranslations": {
		"Apparel - Adult": "Apparel - Adult",
		"Curtains": "Curtains",
		"Poster/Signage": "Poster/Signage",
		"Apparel - Children’s": "Apparel - Children’s",
		"Fashion Accessories": "Fashion Accessories",
		"Quilt": "Quilt",
		"Baby Accessories": "Baby Accessories",
		"Linens": "Linens",
		"Toys or Plushies": "Toys or Plushies",
		"Baby Blankets": "Baby Blankets",
		"Other crafts": "Other crafts",
		"Upholstery": "Upholstery",
		"Bags": "Bags",
		"Pillows": "Pillows",
		"Wallpaper": "Wallpaper",
		"Fabric - Performance Pique": "Sport Piqué",
		"Fabric - Performance Knit": "Performance Knit",
		"Fabric - Petal": "Petal Signature Cotton",
		"Fabric - Modern Jersey": "Modern Jersey",
		"Fabric - Cotton Spandex Jersey": "Cotton Spandex Jersey",
		"Fabric - Minky": "Minky",
		"Fabric - Organic Cotton Knit Ultra": "Organic Cotton Knit Ultra",
		"Fabric - Fleece": "Fleece",
		"Fabric - Sport Lycra": "Sport Lycra",
		"Fabric - Basic Cotton Ultra": "Basic Cotton Ultra",
		"Fabric - Satin": "Satin",
		"Fabric - Kona Cotton Ultra": "Kona® Cotton Ultra",
		"Fabric - Cotton Poplin Ultra": "Cotton Poplin Ultra",
		"Fabric - Poly Crepe de Chine": "Poly Crepe de Chine",
		"Fabric - Silky Faille": "Silky Faille",
		"Fabric - Lightweight Cotton Twill": "Lightweight Cotton Twill",
		"Fabric - Organic Cotton Sateen Ultra": "Organic Cotton Sateen Ultra",
		"Fabric - Linen Cotton Canvas Ultra": "Linen Cotton Canvas Ultra",
		"Fabric - Eco Canvas": "Eco Canvas",
		"Fabric - Heavy Cotton Twill": "Heavy Cotton Twill",
		"Fabric - Faux Suede": "Faux Suede",
		"Fabric - Silk Crepe de Chine": "Silk Crepe de Chine",
		"Fabric - Organic Sweet Pea Gauze": "Organic Sweet Pea Gauze",
		"Wallpaper - Water Activated Smooth": "Wallpaper - Water Activated Smooth",
		"Wallpaper - Peel and Stick Woven": "Wallpaper - Peel and Stick Woven",
		"Gift Wrap - Satin Finish": "Gift Wrap - Satin Finish",
		"Fabric - Celosia Velvet": "Celosia Velvet",
		"Fabric - Chiffon": "Chiffon",
		"Fabric - Dogwood Denim": "Dogwood Denim",
		"Fabric - Cypress Cotton Canvas": "Cypress Cotton Canvas",
		"Fabric - Longleaf Sateen Grand": "Longleaf Sateen Grand",
		"Fabric - Perennial Sateen Grand": "Perennial Sateen Grand",
		"Yes": "Yes",
		"No": "No"
	}
};