// pages

export const PAGE_TYPE_UNDEFINED = 'Undefined'; // we use this to group pages we might want to add a page type

export const PAGE_TYPE_PRODUCT_DETAIL = 'Product Detail Page';
export const PAGE_TYPE_DESIGN_PRODUCTS = 'Design Products Page';
export const PAGE_TYPE_SEARCH_RESULTS = 'Search Results Page';
export const PAGE_TYPE_CATEGORY = 'Category Page';
export const PAGE_TYPE_FABRIC_DETAIL = 'Fabric Detail Page';
export const PAGE_TYPE_CHECKOUT = 'Checkout Page';
export const PAGE_TYPE_LANDING = 'Landing Page';
export const PAGE_TYPE_INTERNAL = 'Internal Page';
export const PAGE_TYPE_LOGIN = 'Login Page';
export const PAGE_TYPE_COLLECTIONS = 'Collections Page';
export const PAGE_TYPE_STOCK_ITEM_DETAIL = 'Stock Item Detail Page';
