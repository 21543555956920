import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';

import carts from './carts';
import flashMessage from './flashMessage';
import tabSets from './tabSets';
import user from './user';
import userStats from './userStats';
import searchResults from './searchResults';
import searchParams from './searchParams';
import invoice from './invoice';
import multiLocaleRouter from './multiLocaleRouter';
import pageSetup from './pageSetup';
import fabrics from './fabrics';
import design from './design';
import wallpapers from './wallpaper';
import addToCart from './addToCart';
import fillayard from './fillayard';
import announcements from './announcements';
import reviews from './reviews';
import userCollections from './userCollections';
import userFavoriteDesigns from './userFavoriteDesigns';
import crossSell from './crossSell';
import allProducts from './allProducts';
import cmsContent from './cmsContent';
import collection from './collection';
import stockItems from './stockItems';
import afterpay from './afterpay';
import preloadReducer from '../entities/preload/preloadReducer';
import solids from './solids';
import routingData from './routingData';
import accountInfo from './accountInfo';
import colorways from './colorways';
import featureFlags from './featureFlags';

import server from '../server/getServer/middlewares/store/reducers/server';
import shouldRenderErrorPage from './shouldRenderErrorPage';
import newsletter from './newsletter';
import transitionMessage from './transitionMessage';

export default combineReducers({
  carts,
  server,
  apiCalled: (state = []) => state,
  shouldRenderErrorPage,
  preloads: preloadReducer,
  form: formReducer,
  routing: routerReducer,
  multiLocaleRouter,
  pageSetup,
  tabSets,
  user,
  userStats,
  flashMessage,
  searchResults,
  searchParams,
  invoice,
  fabrics,
  addToCart,
  fillayard,
  design,
  announcements,
  reviews,
  collection,
  userCollections,
  userFavoriteDesigns,
  crossSell,
  allProducts,
  cmsContent,
  wallpapers,
  stockItems,
  afterpay,
  solids,
  routingData,
  accountInfo,
  newsletter,
  colorways,
  transitionMessage,
  featureFlags,
});
