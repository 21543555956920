import * as types from '../constants/ActionTypes';

function accountInfo(state = {}, action) {
  switch (action.type) {
    case types.ACCOUNT_INFO_POST + types.SUCCESS:
      return Object.assign({}, action.payload.data, {
        isFetching: false
      });
    case types.ACCOUNT_INFO_GET + types.FAIL:
      return Object.assign({}, state,{
        isFetching: false
      });
    case types.ACCOUNT_INFO_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.DATA_DELETION_FETCHING:
      return Object.assign({}, state, {
        dataDeletionLoading: action.payload
      });
    default:
      return state;
  }
}

export default accountInfo;
