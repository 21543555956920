module.exports = {
	"productInfo": {
		"headline": "Product Details"
	},
	"options": {
		"size": {
			"METRIC": "{width} cm x {length} cm",
			"IMPERIAL": "{width}\" x {length}\""
		}
	},
	"content": {
		"addToCartTitle": "Add {product} to Cart",
		"subDescriptionButtonText": "Learn more.",
		"faq": "Frequently Asked Questions",
		"faqHref": "https://support.spoonflower.com/hc/en-us/articles/360061069892-Petal-Signature-Cotton-Solids-Fabric-FAQ",
		"exploreAllFabricTypes": "Explore All Fabric Types"
	},
	"crossSell": {
		"buySwatch": "Buy a swatch book",
		"swatchLink": "of all solids",
		"buyDesigns": "Shop printed fabric",
		"buyCollections": "Shop design collections",
		"artistsLink": "by our artists"
	},
	"SOLID_FABRIC_PETAL": {
		"name": "Petal Signature Cotton® Solids",
		"title": "{color} Solid Fabric",
		"cartName": "{size} {measurementUnit} of Petal Signature Cotton® Solids in {color}",
		"shortAbout": "Dyed 100% cotton fabric for quilts, apparel and crafts",
		"longAbout": "Our Petal Signature Cotton® Solids are offered in a full spectrum of beautiful colors that complement any sewing project or craft. These 100% cotton fabrics are easy to sew with making them the perfect complement to quilts, clothing, costumes, crafts and more! Color is consistent and dyed through the face and back of fabric. Each available color can be purchased in full yard/meter increments, which are cut directly from a spool.  All orders are cut and shipped as a single piece (continuous yardage) up to 120 yards (110 meters). Purchase a Swatch Book to see all available solid colors and find the perfect Petal Signature Cotton Solid color for your project!",
		"blurbQuestion": "How are Cotton Solids different from other fabrics on Spoonflower?",
		"blurbAnswers": "<li>Available on Petal Signature Cotton Solids only</li> <li>Dyed vs digitally printed</li> <li>Half the price of printed Petal Signature Cotton</li> <li>Color on both sides</li>",
		"description": {
			"preLink": "Our Petal Signature Cotton® Solids are offered in a full spectrum of beautiful colors that complement any sewing project or craft. These 100% cotton fabrics are easy to sew with making them the perfect complement to quilts, clothing, costumes, crafts and more! Color is consistent and dyed through the face and back of fabric. Each available color can be purchased in full yard/meter increments, which are cut directly from a spool. All orders are cut and shipped as a single piece (continuous yardage) up to 120 yards (110 meters). ",
			"linkText": "Purchase a Swatch Book",
			"postLink": " to see all available solid colors and find the perfect Petal Signature Cotton Solid color for your project!",
			"hrefValue": "/en/petal-solids-swatch-book"
		},
		"useCases": "Quilting, craft projects, costuming, toys & accessories",
		"careInstructions": "Machine wash warm or cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "Indonesia",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, plain weave",
		"weight": "4.3 oz/squard yd (145 gsm)",
		"width": "44\" (112 cm) wide",
		"threadCount": 120,
		"needleType": "Universal",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-14 per inch (4-6 per cm)",
		"threadType": "100% cotton - all purpose",
		"fabricTitleTag": "Petal Signature Cotton® Solids | Spoonflower",
		"fabricMetaDescription": "If you love Petal Signature Cotton, you have to try our Cotton Solids! Ideal for projects that call for solid color to coordinate with a Spoonflower design.",
		"colorTitleTag": "Petal Signature Cotton® Solids - {color} | Spoonflower",
		"colorMetaDescription": "Check out our Petal Signature Cotton® Solids in {color} for a brilliant cotton solid fabric that can stand alone or pair perfectly with a Spoonflower print.",
		"landingPage": {
			"name": "Petal Signature Cotton",
			"trademarkSuffix": " Solids",
			"subDescription": "Dyed 100% cotton fabric for quilts, apparel and crafts. "
		}
	}
};