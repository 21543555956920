module.exports = {
	"designItemLinkTitle": "Go to detail page of {designTitle}",
	"favoriteDesignButton": {
		"adding": "Click to favorite design",
		"deleting": "Click to unfavorite design"
	},
	"edit": "Edit design",
	"otherSubstrateTextPrefix": "Also available in",
	"otherSubstrateTextConjunction": "&",
	"otherSubstrateFabric": "Fabric",
	"otherSubstrateWallpaper": "Wallpaper",
	"otherSubstrateHomeDecor": "Home Decor",
	"teaTowels": "Tea Towels",
	"wallHangings": "Wall Hangings",
	"cellTitle": "Cell Title",
	"designerDetails": "Designer Details",
	"availableSubstrates": "Available Substrates",
	"designerSection": "Designer Section",
	"carouselCtaRoostery": "Roostery.com",
	"carouselCtaShop": "Shop",
	"carouselPrevious": "Previous",
	"carouselNext": "Next",
	"carouselGoTo": "Go to slide {number}",
	"designerInfo": {
		"designerHeading": "From {designer}:",
		"designHeading": "About the Design",
		"designerShopTitle": "Shop {designer}",
		"designerFollowText": "Follow {designer}",
		"designerFollowButtonText": "following",
		"designerShopButtonText": "shop",
		"more": "More",
		"less": "Less",
		"designCount": {
			"1": "{context} design",
			"2..99999": "{context} designs"
		},
		"featuredInCollectionHeading": "Featured In",
		"designersCollectionText": "{designer}’s design collection",
		"shopDesignersCollectionTitle": "Shop {designer}’s design collection",
		"moreLikeThisHeading": "More Like This",
		"moreLikeThisText": "Shop similar designs",
		"moreLikeThisTitle": "Shop similar designs"
	},
	"engagementHeader": "Engagement",
	"facebookShareButton": "Share on Facebook",
	"pinterestShareButton": "Pin on Pinterest",
	"twitterShareButton": "Tweet on Twitter",
	"facebookShareIcon": "You can share {designTitle}",
	"pinterestShareIcon": "You can pin {designTitle}",
	"twitterShareIcon": "You can tweet {designTitle}",
	"productsOverview": {
		"allProducts": "All Products for",
		"byDesigner": "by",
		"fabric": "Fabric",
		"wallpaper": "Wallpaper",
		"kitchenDining": "Kitchen & Dining",
		"bedding": "Bedding",
		"decorLiving": "Living & Decor",
		"moreDesignsBy": "More Designs by {designerUserName}",
		"roosteryBySpoonflower": "Roostery, by Spoonflower",
		"goToRoostery": "Go to Roostery product page",
		"goToSpoonflower": "Go to Spoonflower product page",
		"waterActivatedWallpaper": "Pre-Pasted Wallpaper",
		"wovenStickWallpaper": "Peel and Stick Removable Woven Wallpaper",
		"smoothPasteWallpaper": "Non-Pasted Wallpaper",
		"grassclothWallpaper": "Grasscloth Wallpaper",
		"peelStickWallpaper": "Peel and Stick Wallpaper",
		"silverMetallicWallpaper": "Silver Metallic Wallpaper",
		"goldMetallicWallpaper": "Gold Metallic Wallpaper",
		"allFabrics": "All Fabrics",
		"knitFabrics": "Knit Fabrics",
		"organicFabrics": "Organic Fabrics",
		"performanceFabrics": "Performance Fabrics",
		"upholsteryFabrics": "Upholstery Fabrics",
		"wovenFabrics": "Woven Fabrics",
		"seeAllProducts": "See on all products",
		"productNavGoTo": "Go to {headline}"
	},
	"crossSellTags": "Or see more",
	"crossSellHeader": {
		"FABRIC": "Fabric you might like",
		"SOLID": "Fabric you might like",
		"FABRIC_BLANK": "You might like",
		"HOME_GOOD_RECTANGULAR_TABLE_CLOTH": "Rectangular Tablecloths you might like",
		"HOME_GOOD_ROUND_TABLE_CLOTH": "Round Tablecloths you might like",
		"HOME_GOOD_TABLE_RUNNER": "Table Runners you might like",
		"HOME_GOOD_PLACEMAT": "Placemats you might like",
		"HOME_GOOD_COCKTAIL_NAPKIN": "Cocktail Napkins you might like",
		"HOME_GOOD_DINNER_NAPKIN": "Dinner Napkins you might like",
		"HOME_GOOD_TEA_TOWEL": "Tea Towels you might like",
		"HOME_GOOD_DUVET_COVER": "Duvet Covers you might like",
		"HOME_GOOD_SHEET_SET": "Sheet Sets you might like",
		"HOME_GOOD_STANDARD_PILLOW_SHAM": "Standard Pillow Shams you might like",
		"HOME_GOOD_STANDARD_PILLOW_SHAM_FLANGED": "Standard Flanged Pillow Shams you might like",
		"HOME_GOOD_EURO_PILLOW_SHAM": "Euro Pillow Shams you might like",
		"HOME_GOOD_EURO_PILLOW_SHAM_FLANGED": "Euro Flanged Pillow Shams you might like",
		"HOME_GOOD_LONG_LUMBAR_THROW_PILLOW": "Extra Long Lumbar Throw Pillows you might like",
		"HOME_GOOD_SQUARE_THROW_PILLOW": "Square Throw Pillows you might like",
		"HOME_GOOD_LUMBAR_THROW_PILLOW": "Lumbar Throw Pillows you might like",
		"HOME_GOOD_THROW_BLANKET": "Throw Blankets you might like",
		"HOME_GOOD_CURTAIN_PANEL": "Curtains you might like",
		"HOME_GOOD_WALL_HANGING": "Wall hangings you might like",
		"WALLPAPER": "Wallpaper you might like"
	},
	"coordinatingSolidsHeader": "Find Coordinating Solids",
	"coordinatingSolidsShopAll": "Shop All 50 Solids",
	"coordinatingSolidsShopAllImageAlt": "Gradient of rainbow colors",
	"alsoAvailableAs": "Also available as",
	"makeCollection": " to make a collection",
	"addFavorite": " to favorite designs",
	"designerDiscount": "Everyday Designer Discount applied in cart"
};