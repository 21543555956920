module.exports = {
	"logo": "Afterpay logo",
	"messaging": {
		"checkout": "Pay now with {afterpay}",
		"eligibleWithPrice": "Or 4 interest-free installments of {amount} with {afterpay}",
		"paymentEligibleWithPrice": "4 interest-free installments of {amount} with {afterpay}",
		"eligibleWithPriceRange": "Or 4 interest-free installments on orders\n{minPrice} – {maxPrice} with {afterpay}",
		"eligibleWithoutPrice": "Or 4 interest-free installments with {afterpay}",
		"eligibleNoThreshold": "{afterpay} available for orders between {minPrice} – {maxPrice}",
		"notEligible": "This order is not eligible for {afterpay}",
		"installmentsByAfterpay": "Installments by Afterpay",
		"redirectAndBillingNotice": "You will be redirected to the Afterpay website to fill out your payment information. You will be redirected back to our site to complete your order. Afterpay can only be used as a payment method for orders with a shipping and billing address within the US.",
		"redirectAndBillingNoticeAU": "You will be redirected to the Afterpay website to fill out your payment information. You will be redirected back to our site to complete your order. Afterpay can only be used as a payment method for orders with a shipping and billing address within AU."
	},
	"installmentAgreement": "Installment Agreement",
	"installmentPieChart": "Pie chart of installment payment",
	"installmentDetails": {
		"firstInstallment": "First Installment",
		"secondInstallment": "2 weeks later",
		"thirdInstallment": "4 weeks later",
		"fourthInstallment": "6 weeks later"
	},
	"modal": {
		"motto": "Shop now. Pay later. Always interest-free.",
		"process": {
			"stepOne": "Add your favorites to cart",
			"stepTwo": "Select Afterpay at checkout",
			"stepThree": "Log into or create your Afterpay account, with instant approval decision",
			"stepFour": "Your payment will be split into 4 payments payable every 2 weeks"
		},
		"legal": {
			"text": "You must be over 18, a resident of the U.S. and meet additional eligibility criteria to qualify. Late fees may apply. Estimated payment amounts shown on product pages exclude taxes and shipping charges, which are added at checkout. Click the Installment Agreement link below for complete terms. Loans to California residents made or arranged pursuant to a California Finance Lenders Law license. © 2020 Afterpay",
			"linkText": "Installment Agreement"
		},
		"legalAU": {
			"text": "Late fees and additional eligibility criteria apply. The first payment may be due at the time of purchase. For complete terms visit {link}"
		},
		"openDetailModal": "Open afterpay details modal",
		"closeDetailModal": "Close afterpay details modal"
	},
	"landing": {
		"title": "afterpay - Shop now. Pay later. Always interest-free.",
		"notice": "Afterpay is currently only available on Spoonflower.com for ship-to countries of United States and Australia, and Afterpay Frequently Asked Questions are currently only available in English.",
		"preferenceButton": "Change your user preferences accordingly to read on",
		"afterpayLink": "Or visit Afterpay.com",
		"buttonTextFabric": "Fabric",
		"buttonTextWallpaper": "Wallpaper",
		"buttonTextDecor": "Decor",
		"goodtoknow": "Good to Know Up Front",
		"goodtoknowRange": "Afterpay can only be used on orders totaling {minimum} – {maximum}.",
		"goodtoknowTerritory": "Afterpay orders shipping to {country} must be paid for in {currency}.",
		"faq": "Frequently Asked Questions",
		"faqAU": {
			"whatAfterpay": "What is Afterpay?",
			"whatAfterpayAnswer": "Afterpay allows you to purchase from Spoonflower.com online store and pay later in 4 easy interest-free instalments.",
			"howAfterpay": "How does Afterpay work?",
			"howAfterpayAnswer": "To pay with Afterpay:",
			"howAfterpayAnswerDetails": "<li>Add your items to the cart</li> <li>Select Afterpay as the payment option at the checkout</li> <li>Signup or use your existing Afterpay account</li> <li>Once approved, Spoonflower will dispatch your order</li>",
			"interestAmount": "Is there any interest on the amount paid?",
			"interestAmountAnswer": "No, there is no interest payable by you.",
			"limit": "Is there an order limit?",
			"limitAnswer": "Yes, the maximum purchase amount using Afterpay on Spoonflower.com is {maximum}.",
			"terms": "What are other terms and conditions I need to be aware of?",
			"termsAnswer": "Full terms & conditions are available at",
			"termsAnswerLink": "https://www.afterpay.com/en-AU/terms-of-service",
			"termsAnswerLinkText": "www.afterpay.com/en-AU/terms.",
			"repayment": "How often is repayment?",
			"repaymentAnswer": "Afterpay offers your order to be paid in 4 instalments with repayment automatically occuring every 2 weeks.",
			"signUp": "How do I sign up for Afterpay?",
			"signUpAnswer": "Select Afterpay as a payment method (or visit the website).",
			"signUpAnswerNote": "You can register as you complete your purchase.",
			"returns": "How do returns work with Afterpay?",
			"returnsAnswer": "Merchants will be able to initiate all eligible refunds on your behalf. Once completed, your refund will be applied to your order.",
			"interest": "Does Afterpay charge interest?",
			"interestAnswer": "There is no interest payable by you if you place an order using Afterpay.",
			"phoneOrder": "Can I place an Afterpay order with you over the phone?",
			"phoneOrderAnswer": "Afterpay is available as an online payment method and requires login credentials for authorisation. Our team members or merchants are unable to place an order on your behalf.",
			"moreInfo": "Where can I find out more about Afterpay?",
			"moreInfoAnswerSite": "If you would like to know more about Afterpay, visit the Afterpay website",
			"moreInfoAnswerSiteLink": "https://www.afterpay.com",
			"moreInfoAnswerSiteLinkText": "www.afterpay.com",
			"moreInfoAnswerList": "or a comprehensive list of",
			"moreInfoAnswerFAQLinkText": "FAQs",
			"moreInfoAnswerFAQLink": "https://help.afterpay.com/hc/en-au/",
			"moreInfoAnswerAmp": "&",
			"moreInfoAnswerTermsLinkText": "terms.",
			"moreInfoAnswerTermsLink": "https://www.afterpay.com/en-AU/terms-of-service",
			"moreInfoAnswerPolicy": "Afterpay’s Privacy Policy which can be found at",
			"moreInfoAnswerPolicyLinkText": "www.afterpay.com/en-AU/privacy-policy.",
			"moreInfoAnswerPolicyLink": "https://www.afterpay.com/en-AU/privacy-policy"
		},
		"faqUS": {
			"whatInstallments": "What is Installments with Afterpay?",
			"whatInstallmentsAnswer": "Installments with Afterpay is a service that allows you to make purchases now and pay for them in four payments made every 2 weeks without any interest.",
			"howInstallments": "How do I use Installments with Afterpay?",
			"howInstallmentsAnswer": "Simply shop online and add items to your shopping bag and checkout as normal. At the checkout choose Installments with Afterpay as your payment method. First-time customers will need to register with Afterpay and provide payment details, as usual, returning customers simply log in to make their purchase. It’s that easy!",
			"howInstallmentsAnswerNote": "Please note that all items in your shopping bag must be eligible for Installments with Afterpay. A minimum purchase amount may apply and you must meet additional eligibility requirements to qualify.",
			"whereInstallments": "Where can I use Installments with Afterpay?",
			"whereInstallmentsAnswer": "Installments with Afterpay can only be applied to purchases made on Spoonflower.com website.",
			"useInstallments": "Can I use Installments with Afterpay if I’m an international customer?",
			"useInstallmentsAnswer": "Afterpay is only offered to our customers who have a US billing address, US shipping address, a US Visa or Mastercard (credit or debit card), or American Express and a US mobile phone number. Customers with international billing addresses, shipping addresses, and/or phone numbers will not be able to set up an account with Afterpay.",
			"sheduleWork": "How does the payment schedule work?",
			"sheduleWorkAnswer": "All customers are required to make their first payment at the time of purchase, with the remaining three payments deducted every two weeks from your chosen payment method. If you choose to make additional payments before your scheduled pay dates, you may do so through your Afterpay account.",
			"sheduleWorkAnswerNote": "You can log in to your Afterpay account to view your payment schedule and make a payment before the due date if you choose to do so.",
			"faq": "Where can I find common questions about payments?",
			"faqAnswer": "Check out the",
			"faqAnswerLinkText": "Afterpay Payments FAQ.",
			"faqAnswerLink": "https://help.afterpay.com/hc/en-us/sections/203376123-Payments",
			"delivery": "When will my items be delivered if I use Installments with Afterpay?",
			"deliveryAnswer": "Afterpay orders are delivered as per our standard shipping time frame after you complete your order online. Visit our shipping page for more information by clicking",
			"deliveryAnswerLinkText": "here.",
			"deliveryAnswerLink": "https://support.spoonflower.com/hc/en-us/sections/200793500-Orders-Shipping-Returns",
			"limitSingleTransaction": "Is there a limit to how much I can spend on a single transaction?",
			"limitSingleTransactionAnswer": "Yes, transaction value limits apply to purchases made on Spoonflower.com using Installments by Afterpay.",
			"notEligibleProducts": "What products are not eligible for purchase using Installments with Afterpay?",
			"notEligibleProductsAnswer": "Afterpay is not available on purchases of gift cards. If you are purchasing gift cards along with Afterpay eligible products, you will need to place two separate orders.",
			"return": "How do I return or exchange an item purchased using Installments with Afterpay?",
			"returnAnswer": "If you have changed your mind, you can return your Afterpay purchase in-store or by mail for a refund. Please check that your return or exchange meets our Return policy here. Afterpay will be notified of your return and will process the appropriate refunds. Please do not return your purchase to Afterpay.",
			"returnAnswerNote": "If you believe there is fault or concern with your Afterpay purchase payment, please contact Afterpay customer support at info@afterpay.com.",
			"cantPay": "What if I can’t pay an Afterpay installment?",
			"cantPayAnswer": "If funds are not available or you otherwise do not make your payment when it is due, late fees may apply. Click",
			"cantPayAnswerLinkText": "here for complete terms.",
			"cantPayAnswerLink": "https://www.afterpay.com/purchase-payment-agreement",
			"partialRefund": "What happens if I have a partial refund, and how does that affect my payments?",
			"partialRefundAnswer": "Afterpay will be notified once your refund is processed. Your payment plan will be automatically adjusted to reflect the new total order value starting with the last payment and working backward. In the case where a refund amount is larger than 25% of the total order, Afterpay will eliminate the fourth installment payment and apply the remaining amount to your earlier payments.",
			"moreInfo": "Where can I find out more about Afterpay?",
			"moreInfoAnswerSite": "If you would like to know more about Afterpay, visit the Afterpay website",
			"moreInfoAnswerSiteLink": "https://www.afterpay.com",
			"moreInfoAnswerSiteLinkText": "www.afterpay.com",
			"moreInfoAnswerList": "for a comprehensive list of",
			"moreInfoAnswerFAQLinkText": "FAQs,",
			"moreInfoAnswerFAQLink": "https://help.afterpay.com/hc/en-us/",
			"moreInfoAnswerTermsLinkText": "Terms,",
			"moreInfoAnswerTermsLink": "https://www.afterpay.com/terms-of-service",
			"moreInfoAnswerInstallmentAgreementLinkText": "Installment Agreement",
			"moreInfoAnswerInstallmentAgreementLink": "https://www.afterpay.com/purchase-payment-agreement",
			"moreInfoAnswerPolicy": "as well as Afterpay’s Privacy Policy which can be found at",
			"moreInfoAnswerPolicyLinkText": "www.afterpay.com/privacy-policy.",
			"moreInfoAnswerPolicyLink": "https://www.afterpay.com/privacy-policy",
			"moreInfoAnswerAcount": "If you have any questions about your Afterpay account, please contact Afterpay directly via a web form found",
			"moreInfoAnswerAcountLinkText": "here.",
			"moreInfoAnswerAcountLink": "https://help.afterpay.com/hc/en-us/requests/new"
		}
	}
};