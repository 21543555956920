import {isEmpty} from '../utils/validation';
import * as types from '../constants/ActionTypes';
import {
  URL_QUERY_PARAM_SORT_BESTSELLERS, URL_QUERY_PARAM_SORT_MOSTRELEVANT, QUERY_PARAM,
  SORT_PARAM, COLOR_PARAM, STYLE_PARAM, TOPIC_PARAM, OFFSET_PARAM
} from '../constants/Parameters';

function updatedSearchParams(state = {}, action) {
  const newState = {};

  switch (action.type) {
    case types.SEARCH_PARAMS_UPDATED:
      Object.assign(newState, state, action.payload);
      newState[OFFSET_PARAM]='';
      if (isEmpty(action.payload[QUERY_PARAM]) && Object.keys(action.payload).includes(QUERY_PARAM) && state[SORT_PARAM] === URL_QUERY_PARAM_SORT_MOSTRELEVANT) {
        newState[SORT_PARAM] = URL_QUERY_PARAM_SORT_BESTSELLERS;
      }

      return newState;

    case types.SEARCH_QUERY_CHANGED:
      Object.assign(newState, state, action.payload);
      [COLOR_PARAM, STYLE_PARAM, TOPIC_PARAM, OFFSET_PARAM].forEach((param) => newState[param] = '');

      return newState;

    case types.SEARCH_PARAMS_RESET:
      Object.assign(newState, state, action.payload);
      [COLOR_PARAM, STYLE_PARAM, TOPIC_PARAM, OFFSET_PARAM].forEach((param) => newState[param] = '');
      newState[SORT_PARAM] = URL_QUERY_PARAM_SORT_BESTSELLERS;

      return newState;

    default:
      return state;
  }
}

export default updatedSearchParams;
