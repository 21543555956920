import classNames from 'classnames';
import React from 'react';

import {SPOONFLOWER} from '../../../constants/Common';
import {TViewPort, VIEWPORT_DESKTOP, VIEWPORT_TABLET} from '../../../constants/Viewports';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {MultiLocaleRouter, translate} from '../../../services';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_header-secondary.scss';


interface HeaderSecondaryProps {
  message: string;
  viewport: TViewPort;
  isPendingOrderItemsUndefined: boolean;
  headerType: string;
}

const HeaderSecondary = ({message, viewport, isPendingOrderItemsUndefined, headerType}: HeaderSecondaryProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  const checkoutHeader = headerType === 'checkout';
  const cartHeader = headerType === 'cart';
  const headerClasses = classNames('b-header-secondary ', {
    'chrome-width': viewport === VIEWPORT_DESKTOP,
    'x-center-logo': checkoutHeader
  });

  const checkoutButtonClassNames = classNames('btn', 'black-button', 'big-button', 'space-bottom', {
    'x-disabled': isPendingOrderItemsUndefined,
    'hide': viewport === VIEWPORT_DESKTOP,
    'x-non-full-width-mobile-button': viewport === VIEWPORT_TABLET,
  });

  const svgLogo = (width: number, height: number) => (
    <LinkWrapper
      className='logo'
      hrefValue={MultiLocaleRouter.localePathname('welcome')}
      title={`${SPOONFLOWER} ${message && message}`}
      target='_self'
      contents={
        <img
          className='logo-svg'
          src={`${imagesHost}/logo.svg`}
          width={width}
          height={height}
          alt={SPOONFLOWER}
        />
      }
    />
  );

  return (
    <header className={headerClasses} role='banner'>
      {cartHeader ?
        (
          <React.Fragment>
            {svgLogo(180, 43)}
            <LinkWrapper
              hrefValue={MultiLocaleRouter.localePathname('checkout.shipping_address')}
              title={translate('cart.cart.goToCheckout')}
              className={checkoutButtonClassNames}
              contents={translate('cart.cart.checkout')}
            />
          </React.Fragment>
        ) :
        svgLogo(257, 91)
      }
    </header>
  );
};


export default HeaderSecondary;
