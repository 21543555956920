import Translate from 'i18n-react';

import {FOOT, measurementSystemToUnit, METER, YARD} from '../constants/Measurements';
import {MeasurementType} from '../shapes/measurement';

import {getLanguage} from './fabrics';


export const formatAddress = (zipcode: string, city: string, state: string): string => {
  let addressString = '';

  if (zipcode) {
    addressString += `${zipcode} `;
  }

  addressString += city;

  if (state) {
    addressString += `, ${state}`;
  }

  return addressString;
};

export const prettifyDate = (uglyDate: Date): string => (new Date(uglyDate).toLocaleDateString('en-US'));

export const getUnitPricePer = (pricePerUnit: string, pricePerYard: string, pricePerMeter: string, unitType: string): string => (
  // TODO EN-1673: Wallpaper pricePerUnit
  pricePerUnit ?
    measurementSystemToUnit[unitType as MeasurementType] :
    (pricePerYard ? YARD :
      (pricePerMeter ? METER : FOOT)));

export const getSolidSize = (locale: string, width: string, length: number, measurementSystem: string): string => {
  const intl = getLanguage(locale);

  return Translate.translate(`${locale}.solidFabrics.options.size.${measurementSystem}`, {
    width: intl.formatNumber(parseFloat(width)),
    length
  })?.toString() || '';
};

export const filterDataKeys = (obj: Record<string, unknown>): Record<string, unknown> => {
  const filteredEntries = Object.entries(obj).filter(([key]) => String(key).startsWith('data-'));

  return Object.fromEntries(filteredEntries);
};
