// http://emailregex.com
// eslint-disable-next-line no-useless-escape
export const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
export const REGEX_US_ZIP_CODE = /^\d{5}(\-\d{4})?$/; // 5 digits or 5 digits, dash and 4 digits
export const REGEX_CA_ZIP_CODE= /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][\s]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;
// Canada Format = A1A 1A1, where A is a letter and 1 is a digit. Invalid letters [DFIOQU], first position doesnt allow [WZ]. Allows space/nospace, case insensitive
export const REGEX_PROMO_CODE = /^[\w\u00C0-\u017F-]*$/; // alphanumeric, underscore, dash, international characters
export const REGEX_REMOVE_ALL_SPACES = /\s/g; // removes all spaces from a string
export const REGEX_NAVIGATION_CLASS = /([a-z])([A-Z])/g; // converts CamelCase to text-between-dashes
export const REGEX_SNAKE_CASE_TO_CAMEL_CASE = /_\w/g; // detects snake_case
export const REGEX_ROOSTERY_GARDEN_IMAGE_URL = /\bdesigns\b=\+?\d+&\bsize\b=[a-z]+&\bformula\b=/gm;
export const REGEX_HTML_LIST_FORMATTING = /(?:<\/?li>)/g;
export const REGEX_GET_PROPER_PRODUCT_NAME = /HOME_GOOD_/g; // remove HOME_GOOD_ string from product Name
export const REGEX_REMOVE_UNDERSCORE = /_/g; // remove globally underscores characters
export const REGEX_REMOVE_DASH = /-/g; // remove globally dash characters
export const REGEX_CURRENT_SELECTION = /[A-Za-z]+/g; // matches all letter characters in lowercase
export const REGEX_MATCH_CHARS = /[A-Za-z]+/g;
export const REGEX_MATCH_NUMBERS = /^\d+$/;
export const REGEX_MATCH_DOT = /\./;
// eslint-disable-next-line no-useless-escape
export const REGEX_WHITESPACE_AMPERSANDS = /(\s+|\&+)/g;
// eslint-disable-next-line no-useless-escape
export const REGEX_AND_AMPERSANDS = /((&amp;)|\&+)/g;
export const REGEX_ALL_PARAM = /^All\s/g;
export const REGEX_WALLPAPER_NAME = /X/;
export const REGEX_FIRST_NUMBER = /[0-9]/; // match character b/w '0' and '9'
export const REGEX_VAT = [
  /^(XI)?(\d{9})$/i,
  /^(XI)?(\d{12})$/i,
  /^(XI)?(GD\d{3})$/i,
  /^(XI)?(HA\d{3})$/i,
];
