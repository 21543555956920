import * as types from '../constants/ActionTypes';
import initialState from '../store/initialState';

function design(state = {}, action) {
  switch (action.type) {
    case types.DESIGN_GET + types.SUCCESS:
      return Object.assign({}, state, action.payload.data, {
        error: false,
        isFetching: false
      });
    case types.DESIGN_GET + types.FETCHING:
      return Object.assign({}, state, action.data, {
        isFetching: true
      });
    case types.DESIGN_GET + types.FAIL:
      return Object.assign({}, state, {
        error: true, errorNumber: action.payload
      });
    case types.VALID_IMAGE_HEAD + types.SUCCESS:
      return Object.assign({}, state, {
        isFetchingValidImage: false
      });
    case types.VALID_IMAGE_HEAD + types.FETCHING:
      return Object.assign({}, state, {
        isFetchingValidImage: true
      });
    case types.VALID_IMAGE_HEAD + types.FAIL:
      return Object.assign({}, state, {
        isFetchingValidImage: false
      });
    case types.DESIGN_FAVORITED + types.SUCCESS:
      return Object.assign({}, state, {
        user_favorite: !state.user_favorite,
        favorite_count: state.favorite_count + action.favoriteCountChange,
        isFetchingFavoriteDesign: false
      });
    case types.DESIGN_FAVORITED + types.FETCHING:
      return Object.assign({}, state, action.data, {
        isFetchingFavoriteDesign: true
      });
    case types.PRODUCT_PAGE_RESET:
      return initialState.design;
    default:
      return state;
  }
}

export default design;
