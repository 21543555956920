module.exports = {
	"head": {
		"title": "Invoice"
	},
	"content": {
		"loading": "Invoice is loading",
		"customerSupportMessage": "The invoice generated on this page may have incorrect pricing. Please email help@spoonflower.com for an invoice."
	},
	"fabricSize": {
		"TEST_SWATCH_YARD": "Test Swatches",
		"TEST_SWATCH_METER": "Test Swatches",
		"FAT_QUARTER_YARD": "Fat Quarters",
		"FAT_QUARTER_METER": "Fat Quarters",
		"YARD": {
			"1": "{context} Yard",
			"2..99": "{context} Yards"
		},
		"METER": {
			"1": "{context} Meter",
			"2..99": "{context} Meters"
		},
		"ROLL": "Rolls",
		"FEET": {
			"1": "{context} Foot",
			"2..99": "{context} Feet"
		},
		"ITEMS": "Items"
	}
};