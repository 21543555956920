import {getUserAnalytics, pushPageViewToDataLayer} from '@spoonflower/user-analytics';
import qs from 'qs';
import {Store} from 'redux';

import {selectUserHasJwt, selectUserId} from '../entities/user/selectors';
import {selectIsDev} from '../environment/runtimeEnv/selectors';
import {newrelicError} from '../errors/newrelic';
import {State} from '../store/initialState';
import CartOperations from '../utils/cartOperations';

import {getCurrentUser} from './auth';
import {pageUpdate} from './pageSetup';

import {bootstrapState, unAuthBootstrapState} from './index';


const isGTMDisabled = () => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  return queryParams.disabled_gtm;
};

export const clientBootstrap = (store: Store<State>): void => {
  const dispatch = store.dispatch;
  const state = store.getState();

  CartOperations.setDispatch(dispatch);
  CartOperations.setState(state);


  if (!selectIsDev(state)) newrelicError();

  const start = () => {
    if (!isGTMDisabled()) {
      window.startGoogleTagManager();
    }

    const state = store.getState();
    const userAuthenticated = selectUserId(state);

    if (userAuthenticated) {
      bootstrapState(dispatch, state).then(() => {
        window.dataLayer.push(state.userStats.data_layer);
      });
    } else {
      unAuthBootstrapState(dispatch);
      dispatch(
        pageUpdate({
          finishedInitialCart: true,
        })
      );
      window.dataLayer.push(state.userStats.data_layer);
    }

    getUserAnalytics({
      isProd: window.spoonflower?.serverData?.ENV === 'Production'
    }).then((data) => {
      if (data) {
        pushPageViewToDataLayer(
          {
            pageType: state.routingData.routesProps.pageType,
            userStats: data.data_layer,
            cartId: data.cart?.cart_id,
            guestCartId: data.cart?.guest_cart_id
          }
        );
      }
    });
  };

  // TODO: I took out the conditions before this statement because we wanted to
  // get PostHog feature flags for every user. We want the ability to turn off
  // feature flags and have them honored immediately. We could potentially add
  // the conditions back, but wanted them removed for now.
  getCurrentUser(dispatch, state).then(start);
};
