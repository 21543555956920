export const errorsToIgnoreList = ['Extension context invalidated.'];

export const unknownDimensionsErrorCode = 'WALLPAPER_CALCULATOR_ERROR_UNKNOWN_DIMENSIONS';
export const decimalsAreUsedError = 'WALLPAPER_CALCULATOR_ERROR_DECIMAL';
export const panelHeightErrorCode = 'WALLPAPER_CALCULATOR_ERROR_PANEL_HEIGHT';
export const rollHeightErrorCode = 'WALLPAPER_CALCULATOR_ERROR_ROLL_HEIGHT';
export const calculationFailureErrorCode = 'WALLPAPER_CALCULATOR_ERROR_CALCULATION_FAILURE';
export const panelRepeatErrorCode = 'WALLPAPER_CALCULATOR_ERROR_PANEL_REPEAT';
export const rollRepeatErrorCode = 'WALLPAPER_CALCULATOR_ERROR_ROLL_REPEAT';
export const maxQuantityErrorCode = 'WALLPAPER_CALCULATOR_ERROR_MAX_ITEM_QUANTITY';
