import * as types from '../constants/ActionTypes';
import update from 'immutability-helper';
import {objectIsEmpty} from '../utils/validation';
import {emptyFavoriteCollections} from '../constants/Collections';


const updateUserCollections = (userCollectionResponse, state) => {
  const modifiedUserState = update(
    state,
    {
      $merge: {
        collections: modifyOneUserCollection(userCollectionResponse, state),
        isFetching: false
      }
    }
  );

  return modifiedUserState;
};

const modifyOneUserCollection = (userCollectionResponse, state) => {
  const userCollections = state.collections;
  const updatedCollections = userCollectionResponse.designs ? userCollectionResponse.designs.map((entry) => entry.id) : [];

  return userCollections.map((collection) => {
    if (collection.id === userCollectionResponse.id) {
      return update(
        collection,
        {
          $merge: {
            design_ids: updatedCollections
          }
        }
      );
    }

    return collection;
  });
};

const modifyFavoriteCollections = (collection, removeCollection, state) => {
  const userFavoriteCollectionsArray = state.collection_favorites;
  const collectionId = collection.id;

  if (removeCollection) {
    return userFavoriteCollectionsArray.filter((collection) => collection !== collectionId);
  }

  userFavoriteCollectionsArray.push(collectionId);

  return userFavoriteCollectionsArray;
};

function userCollections(state = {}, action) {
  switch (action.type) {
    case types.USER_COLLECTIONS_FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetching: true
          }
        }
      );

    case types.USER_COLLECTIONS_RECEIVED: {
      const collections = action.payload.data.collections || [];

      return update(
        state,
        {
          $merge: {
            ...action.payload.data,
            collections,
            isFetching: false
          }
        }
      );
    }

    case types.USER_COLLECTION_DESIGN_ADDED:
      return updateUserCollections(
        action.payload.data,
        state
      );

    case types.USER_COLLECTION_DESIGN_REMOVED:
      return updateUserCollections(
        action.payload.data,
        state
      );

    case types.USER_COLLECTION_DESIGN_REMOVED_ERROR:
    case types.USER_COLLECTION_DESIGN_ADDED_ERROR:
      return update(
        state,
        {
          $merge: {
            isFetching: false
          }
        }
      );

    case types.USER_FAVORITE_COLLECTIONS + types.FETCHING:
      return update(
        state,
        {
          $merge: {
            isFetchingFavorites: true
          }
        }
      );

    case types.USER_FAVORITE_COLLECTIONS_RECEIVED:
      return update(
        state,
        {
          $merge: objectIsEmpty(action.payload.data) ? emptyFavoriteCollections : {
            collection_favorites: action.payload.data.collection_favorites,
            isFetchingFavorites: false
          },
        }
      );

    case types.USER_FAVORITE_COLLECTIONS + types.FAIL:
      return update(
        state,
        {
          $merge: {
            isFetching: false
          }
        }
      );

    case types.USER_FAVORITE_COLLECTION_ADDED:
      return update(
        state,
        {
          $set: {
            collection_favorites: modifyFavoriteCollections(action.payload.data, false, state)
          }
        }
      );

    case types.USER_FAVORITE_COLLECTION_REMOVED:
      return update(
        state,
        {
          $set: {
            collection_favorites: modifyFavoriteCollections(action.payload.data, true, state)
          }
        }
      );

    default:
      return state;
  }
}

export default userCollections;
