import {LocationDescriptorObject} from 'history';
import {RouteConfig} from 'react-router';
import match from 'react-router/lib/match';

import {RoutesPropsParams, ParseRoutePropsParams} from '../shapes/routes';

/**
 * Takes react route component's props and flattens its properties
 * { prop1: "val1" }, { prop2: "val2" } -> { prop1: "val1", prop2: "val2"}
 * Also gets rid of unnecessary properties like `component` or `childRoutes`
 * @param routes {RouteConfig} bunch of react routes that will be parsed by `match` function
 * @param location {string | LocationDescriptorObject} url (path+search) or location object to make match with
 * @returns {routesProps: RoutesPropsParams, routesParams: RoutesPropsParams}
* */

export default function parseRoutePropsParams(routes: RouteConfig, location: LocationDescriptorObject | string): ParseRoutePropsParams {
  const routesProps: RoutesPropsParams = {};
  const routesParams: RoutesPropsParams = {};

  match({
    routes, location
  }, (error, redirectLocation, renderProps) => {
    for (let i = 0; i < renderProps.routes.length; i++) {
      // eslint-disable-next-line guard-for-in
      for (const prop in renderProps.routes[i]) {
        const excludedProps = ['childRoutes', 'component', 'path'];

        if (!excludedProps.includes(prop)) {
          routesProps[prop] = renderProps.routes[i][prop];
        }
      }
    }

    routesProps['path'] = renderProps.location.pathname;

    // eslint-disable-next-line guard-for-in
    for (const prop in renderProps.params) {
      routesParams[prop] = renderProps.params[prop];
    }
  });

  return {
    routesProps,
    routesParams
  };
}

