/* eslint-disable @typescript-eslint/no-explicit-any */
import Translate from 'i18n-react';

import {EN} from '../constants/SupportedLanguages';

import MLR from './MultiLocaleRouter';
import TranslateLocale from './TranslateLocale';


const multiLocaleRoutes = {
  fallback_locale: 'en',
  routes_untranslated: {},
};

export const MultiLocaleRouter = new MLR(multiLocaleRoutes, {
  // we should not throw an exception in production and test envs but we should do it in dev
  throwExceptions: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'
});

export const TranslateWrapper = new TranslateLocale(EN);

// TODO SP-6816: This can be removed once we move from PropTypes to TS
// interfaces in our component types. Places where typing is inferred from the
// `element` PropType (and are thus `ReactElementLike`) conflict with the
// `React.ReactNode` type.
type TranslateFunction = (...params: Parameters<typeof Translate.translate>) => string;

export const translate: TranslateFunction = (key: any, options?: any) =>
  TranslateWrapper.translate(key, options) as any;
