import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {State} from '../../../store/initialState';
import {setupServerCacheTtl} from '../actions';


export default function setupOneDayCache(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch) => {
    dispatch(setupServerCacheTtl(86400));
  };
}
