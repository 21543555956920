import {AnyAction} from 'redux';

import {SHOULD_RENDER_NOT_FOUND, SHOULD_RESET_RENDER_NOT_FOUND} from '../constants/ActionTypes';
import initialState, {State} from '../store/initialState';


function shouldRenderErrorPage(state = initialState.shouldRenderErrorPage, action: AnyAction): State['shouldRenderErrorPage'] {
  switch (action.type) {
    case SHOULD_RENDER_NOT_FOUND:
      return {
        ...state,
        shouldRender404: true
      };
    case SHOULD_RESET_RENDER_NOT_FOUND:
      return {
        ...state,
        shouldRender404: false
      };
    default:
      return state;
  }
}

export default shouldRenderErrorPage;
