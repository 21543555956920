module.exports = {
	"helpHeadline": "Need help?",
	"navigation": {
		"language": "Language",
		"ourCompany": "Our Company",
		"aboutUs": "About Us",
		"careers": "Careers",
		"plus": "Plus Membership",
		"tradeProgram": "Trade Program",
		"subscribe": "Subscribe to Our Newsletter",
		"contactUs": "Contact Us",
		"contactUsUrl": "/en/contact",
		"privacy": "Privacy Notice",
		"terms": "Terms",
		"sellerAgreement": "Seller Agreement",
		"termsOfService": "Terms of Service",
		"proTermsOfService": "Pro Terms of Service",
		"returnsAndExchanges": "Returns & Exchanges",
		"policies": "Policies",
		"accessibility": {
			"href": "/en/accessibility-statement",
			"text": "Accessibility Statement"
		},
		"cookies": "Manage Cookies",
		"sellOrShare": "Do Not Sell or Share My Personal Information",
		"index": "Index"
	},
	"subscribe": {
		"joinLink": "Join our creative community",
		"joinInfo": "Be the first to hear about deals, exciting new products and much more!",
		"headerText": "Stay Up To Date",
		"placeholderText": "Your email",
		"checkboxLabel": "I have a creative business",
		"submit": "Submit"
	}
};