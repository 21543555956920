import * as types from '../constants/ActionTypes';

const webflowS3Location = 'https://s3.amazonaws.com/spoonflower-cms-public/';
const webflowCdnLocation = 'https://cdn.spoonflower.com/';

function cmsContent(state = {}, action) {
  let cmsContent = {};
  let matchCounter = 0;

  switch (action.type) {
    case types.CMS_CONTENT_GET + types.SUCCESS:
      cmsContent = Object.assign({}, state, {
        body: action.payload.contents.body.replace(/<img /g, (match) => {
          matchCounter++;

          return (matchCounter > 5) ? '<img loading="lazy" ' : match;
        }),
        stylesheets: action.payload.stylesheets.map((stylesheet) => stylesheet.replace(webflowS3Location, webflowCdnLocation)),
        scripts: action.payload.scripts.map((script) => script.replace(webflowS3Location, webflowCdnLocation)),
        metadata: action.payload.contents.metadata,
        title: action.payload.contents.title,
        isContentLoading: false,
        webflowPageId: action.payload.contents.webflowPageId,
        webflowSiteId: action.payload.contents.webflowSiteId
      });

      action.payload.updatedUrl && Object.assign(cmsContent, {
        updatedUrl: action.payload.updatedUrl
      });

      return cmsContent;

    case types.CMS_CONTENT_LOADING:
      return Object.assign({}, state, {
        isContentLoading: action.payload
      });

    default:
      return state;
  }
}

export default cmsContent;
