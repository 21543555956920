import {ColorwaysAction} from '../actions/colorways';
import * as types from '../constants/ActionTypes';
import {ColorwaysState} from '../shapes/colorways';


function colorways(state = {} as ColorwaysState, action: ColorwaysAction): ColorwaysState {
  switch (action.type) {
    case types.COLORWAYS_GET + types.SUCCESS:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      return Object.assign({}, action.payload.data, {
        isFetching: false
      });
    case types.COLORWAYS_GET + types.FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.COLORWAYS_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false
      });
    case types.COLORWAYS_RESET:
      return Object.assign({}, state, {
        isFetching: false,
        colorways: []
      });
    default:
      return state;
  }
}

export default colorways;
