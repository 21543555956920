module.exports = {
	"head": {
		"title": "Wallpaper",
		"buttonText": "Learn More",
		"buttonTextAfter": " about all wallpaper types",
		"chooseWallpaper": "Choose a wallpaper: "
	},
	"content": {
		"productInfoTitle": "Wallpaper Details",
		"buyASwatchGuide": "From any wallpaper page, choose your wallpaper type and then “Swatch” under “Size.”",
		"buttonSeeDesigns": "See Designs on {wallpaper}",
		"ourTypes": "Our wallpaper types",
		"waysToSample": "TWO WAYS TO SAMPLE",
		"buttonTextWallpaper": "Shop Wallpaper",
		"buyATestSwatch": "Order A Swatch"
	},
	"calculator": {
		"howToMeasureTitle": "How to measure your wall",
		"standardWall": "Standard wall",
		"windowsWall": "Standard wall with windows or doors",
		"pitchedWall": "Pitched or vaulted wall",
		"slopedWall": "Sloped wall",
		"title": "Wallpaper Calculator",
		"descriptionP1": "Wallpaper is one of those timeless home decorating elements that can transform a room to fit any style, but how much do you need for your project?",
		"descriptionP2": "A math error can leave your project short of needed material, so always double check the calculations. Better yet, use our wallpaper calculator to get the right number of wallpaper rolls every time!",
		"descriptionP3": "For best results, enter the dimensions of the entire room, without deducting for windows, doors or closets. Trying to reduce the square footage for these features can make it harder to calculate the total amount of wallpaper needed, and makes matching the pattern more difficult.",
		"descriptionP4": "A good practice is to order an extra roll to have on hand, just in case. Purchasing a little extra ensures that every roll will come from the same machine, and will be a perfect color match.",
		"manual": {
			"title": {
				"METRIC": "How to Calculate Square CM of a Wall for Wallpaper: Manual Method",
				"IMPERIAL": "How to Calculate Square Footage of a Wall for Wallpaper: Manual Method"
			},
			"p1": "Figuring out the number of wallpaper rolls that are needed for a room is a simple process that involves the dimensions of the wall being covered, the roll size and the frequency of the pattern repeat. To begin:",
			"bullet1": {
				"METRIC": "First, measure the length of the wall from ceiling to floor. Our Peel and Stick Removable Woven and Pre-Pasted Wallpapers are available in 91 cm, 183 cm, 274 cm or 366 cm long sections, and Non-Pasted is available in 823 cm long sections. Round up to choose the roll length that is larger than needed for the target wall. For example, if the wall is eight feet high, then the nine foot roll is best. Keep in mind that walls are not always completely even, so take a few measurements in different places to make sure the roll will be long enough.",
				"IMPERIAL": "First, measure the length of the wall from ceiling to floor. Our Peel and Stick Removable Woven and Pre-Pasted Wallpapers are available in three, six, nine or twelve foot long sections, and Non-Pasted is available in 27 foot long sections. Round up to choose the roll length that is larger than needed for the target wall. For example, if the wall is eight feet high, then the nine foot roll is best. Keep in mind that walls are not always completely even, so take a few measurements in different places to make sure the roll will be long enough."
			},
			"bullet2": {
				"METRIC": "Once the length is calculated, measure the width of each wall and add them together. Wallpaper rolls come in a convenient 61 cm width, so simply divide the total width by 61 cm to determine the number of rolls needed. Remember to round up.",
				"IMPERIAL": "Once the length is calculated, measure the width of each wall and add them together. Wallpaper rolls come in a convenient two-foot width, so simply divide the total width by 24 inches to determine the number of rolls needed. Remember to round up."
			},
			"p2": "If a wallpaper border is desired, measure the width of the wall or walls it is being applied to. Divide the total by the width of the border roll.",
			"p3": "It's best to do a dry test run with each new wallpaper roll to make sure the pattern matches exactly. Slight differences in wall construction may require a minor adjustment before the paper is applied to the wall."
		}
	},
	"samples": {
		"samplePack": {
			"title": "Sample Pack",
			"description": {
				"METRIC": "Our Sample Pack includes 10 cm x 10 cm swatches of all wallpaper and fabric types. Ships free.",
				"IMPERIAL": "Our Sample Pack includes 4in x 4in swatches of all wallpaper and fabric types. Ships free."
			},
			"button": "Learn More"
		},
		"sampleSwatch": {
			"title": {
				"METRIC": "Swatch 61 cm x 30 cm",
				"IMPERIAL": "Swatch 2' x 1'"
			},
			"description": "From any wallpaper page, choose your wallpaper type and then “Swatch” under “Size.”",
			"button": "Find a Design"
		}
	},
	"productSize": {
		"METRIC": {
			"WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1": {
				"1": "{context} Swatch 61 cm x 30 cm",
				"2..99": "{context} Swatches 61 cm x 30 cm"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_3": {
				"1": "{context} 61 cm x 91 cm",
				"2..99": "{context} 61 cm x 91 cm"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_6": {
				"1": "{context} 61 cm x 183 cm",
				"2..99": "{context} 61 cm x 183 cm"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_9": {
				"1": "{context} 61 cm x 274 cm",
				"2..99": "{context} 61 cm x 274 cm"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_12": {
				"1": "{context} 61 cm x 366 cm",
				"2..99": "{context} 61 cm x 366 cm"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_27": {
				"1": "{context} 61 cm x 823 cm",
				"2..99": "{context} 61 cm x  823 cm"
			}
		},
		"IMPERIAL": {
			"WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1": {
				"1": "{context} Swatch 2' x 1'",
				"2..99": "{context} Swatches 2' x 1'"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_3": {
				"1": "{context} 2' x 3'",
				"2..99": "{context} 2' x 3'"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_6": {
				"1": "{context} 2' x 6'",
				"2..99": "{context} 2' x 6'"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_9": {
				"1": "{context} 2' x 9'",
				"2..99": "{context} 2' x 9'"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_12": {
				"1": "{context} 2' x 12'",
				"2..99": "{context} 2' x 12'"
			},
			"WALLPAPER_IMPERIAL_ROLL_2_x_27": {
				"1": "{context} 2' x 27'",
				"2..99": "{context} 2' x 27'"
			}
		},
		"WALLPAPER_METRIC_TEST_SWATCH_60_96_x_30": {
			"1": "{context} Swatch 60.96 cm x 30 cm",
			"2..99": "{context} Swatches 60.96 cm x 30 cm"
		},
		"WALLPAPER_METRIC_ROLL_60_96_x_100": {
			"1": "{context} 60.96 cm x 100 cm",
			"2..99": "{context} 60.96 cm x 100 cm"
		},
		"WALLPAPER_METRIC_ROLL_60_96_x_260": {
			"1": "{context} 60.96 cm x 260 cm",
			"2..99": "{context} 60.96 cm x 260 cm"
		},
		"WALLPAPER_METRIC_ROLL_60_96_x_310": {
			"1": "{context} 60.96 cm x 310 cm",
			"2..99": "{context} 60.96 cm x 310 cm"
		},
		"WALLPAPER_METRIC_ROLL_60_96_x_400": {
			"1": "{context} 60.96 cm x 400 cm",
			"2..99": "{context} 60.96 cm x 400 cm"
		},
		"WALLPAPER_METRIC_ROLL_10M": {
			"1": "{context} 60.96 cm x 1000 cm",
			"2..99": "{context} 60.96 cm x 1000 cm"
		},
		"WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1": {
			"1": "{context} Swatch 2' x 1'",
			"2..99": "{context} Swatches 2' x 1'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_3": {
			"1": "{context} 2' x 3'",
			"2..99": "{context} 2' x 3'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_6": {
			"1": "{context} 2' x 6'",
			"2..99": "{context} 2' x 6'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_9": {
			"1": "{context} 2' x 9'",
			"2..99": "{context} 2' x 9'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_12": {
			"1": "{context} 2' x 12'",
			"2..99": "{context} 2' x 12'"
		},
		"WALLPAPER_IMPERIAL_ROLL_2_x_27": {
			"1": "{context} 2' x 27'",
			"2..99": "{context} 2' x 27'"
		}
	},
	"outOfStock": {
		"message": "We are temporarily out of this wallpaper type; please select another.",
		"messageShort": "We are temporarily out of this wallpaper type."
	}
};