import * as types from '../constants/ActionTypes';
import {HOMEPAGE, INTL_HOMEPAGES} from '../constants/CmsPages';
// Restore stickyDefaults to their default if PAGE_SETUP doesn't overwrite them / include a value for them
export const stickyDefaults = {
  displayFooter: true,
  displayHeader: true,
  displayNavigation: true,
  displayHeaderSearch: true,
  displayModal: false,
  modal: {
    modalName: '',
    displayModal: false
  },
  showSlideInComponent: false,
  touchDetected: false
};

// Keep stickyProperties at their existing value if PAGE_SETUP doesn't overwrite them / include a value for them
const stickyProperties = [
  'viewport',
  'finishedInitialCart',
  'windowWidth',
  'windowHeight',
  'windowPosition',
  'lastScrollPosition'
];

const getPageName = (pageName, pageLang) => (pageName === HOMEPAGE ? INTL_HOMEPAGES[pageLang] : pageName);

// Examples of global / structural data which aren't suitable for pageSetup:
//
// a) flashMessage; it has special, non-generic reducer logic which requires it's own reducer and namespace in state
// b) alternateLocales; it is non-generic data which is highly specific to each distinct URL, but this data is managed
//    from a generic place (GlobalStructureContainer.) This makes the pageUpdate / pageSetup pattern inappropriate,
//    since neither kind of "stickiness" is appropriate to alternateLocale data, and page containers subsequently blow
//    away the data when they call pageSetup.

function pageSetup(state = {}, action) {
  const {type, payload} = action;

  switch (type) {
    // PAGE_SETUP clears all non-sticky data from the previous use of the action, by design. It's meant to be used
    // when the router changes.
    case types.PAGE_SETUP:
      for (const propertyName of Object.keys(stickyDefaults)) {
        if (!Object.prototype.hasOwnProperty.call(payload, propertyName)) {
          payload[propertyName] = stickyDefaults[propertyName];
        }
      }

      for (const propertyName of stickyProperties) {
        if (!Object.prototype.hasOwnProperty.call(payload, propertyName)) {
          payload[propertyName] = state[propertyName];
        }
      }

      return {
        ...state,
        ...payload,
        pageName: getPageName(state.pageName, state.pageLang),
      };
    case types.PAGE_UPDATE:
      return {
        ...state,
        ...payload,
        pageName: getPageName(payload.pageName || state.pageName, payload.pageLang || state.pageLang),
      };
    case types.MODAL_CLOSE:
      document.body.classList.remove('display-modal');

      return {
        ...state, modal: stickyDefaults.modal
      };
    case types.MODAL_OPEN:
      document.body.classList.add('display-modal');

      return {
        ...state, ...payload
      };
    case types.SAVE_SCROLL_POSITION:
      return {
        ...state, ...payload
      };
    case types.PAGE_KEY_SETUP:
      return {
        ...state, pageKey: payload
      };
    default:
      return state;
  }
}

export default pageSetup;
