/* global ApiResponse */
import {AxiosError} from 'axios';
import {AnyAction, Dispatch} from 'redux';

import request from '../application/api/request';
import * as fm from '../constants/FlashMessages';
import {mergeCart} from '../entities/cart/api/mergeCart';
import selectCTHost from '../entities/pageSetup/apiHosts/selectors/selectCTHost';
import {selectPageLang} from '../entities/pageSetup/selectors';
import type {UserResponse} from '../entities/user/types';
import {translate} from '../services';
import type {State} from '../store/initialState';
import {isNotUndefined} from '../utils/validation';

import {showFlashMessage} from './flashMessage';
import {currentUserFail, currentUserFetching, currentUserReceived} from './user';


export async function whoAmIRequest(dispatch: Dispatch, state: State): Promise<void> {
  const ctCartUrl = selectCTHost(state);

  return request<ApiResponse<{user: UserResponse}>>(state, {
    url: `${ctCartUrl}/api/spoonflower/who-am-i`,
    params: {
      page_locale: selectPageLang(state)
    },
  })
    .then((response) => {
      const user = response.data.data.user;

      dispatch(currentUserReceived({
        data: user
      }));
      if (user) {
        if (!user.guest && user.guest_cart_id) {
          // call rené's endpoint
          dispatch(mergeCart(user.guest_cart_id, user.ctUser.id, user.preferences.currency) as unknown as AnyAction);
        }
      }
    });
}

export const getCurrentUser = (dispatch: Dispatch, state: State): Promise<AnyAction | void> => {
  dispatch(currentUserFetching());


  return whoAmIRequest(dispatch, state)
    .catch(async(error: AxiosError) => {
      dispatch(currentUserFail());

      if (!process.env.REACT_APP_IS_SERVER) {
        if (error.response) {
          if (error.response.status === 400 && isNotUndefined(error.response.data)) {
            dispatch(
              showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate(`errors.${error.code}`))
            );
          }
        } else {
          dispatch(showFlashMessage(fm.MESSAGE_SEVERITY_ERROR, translate('errors.SERVER_ERROR')));
        }
      }
    });
};
