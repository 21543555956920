module.exports = {
	"ownerFormTitle": "For Your Business - Owner Form",
	"starterFormTitle": "For Your Business - Starter Form",
	"confirmTitle": "For Your Business - Thank You",
	"formLanguage": "en",
	"required": "required",
	"ownerForm": {
		"header": "Please tell us a bit about your business",
		"text": "Spoonflower is working to better serve and promote business customers, small and large. Over time, we’ll be sharing essential business content as well as inspiring success stories—perhaps yours!—of makers and designers who use Spoonflower to power their creative ideas.",
		"typeQuestion": "What type of business?",
		"selectType": "Select a type",
		"productBased": "Product-based business",
		"surfaceDesign": "Surface design",
		"both": "Both",
		"other": "Other",
		"yourLink": "Link to your e-commerce shop",
		"linkPlaceholder": "Site, Etsy, etc",
		"stageQuestion": "What stage are you in?",
		"selectStage": "Select a stage",
		"starting": "Just starting out",
		"partTimeOnly": "Part-time, and happy with that",
		"partTimeToFull": "Part-time, hoping to go full-time",
		"fullTime": "Full-time",
		"emailAddress": "Your email address",
		"emailAddressHere": "Email address here",
		"consentStatement": "By submitting this form, you agree to receive marketing communications from Spoonflower. To see our complete privacy policy, ",
		"clickHere": "click here",
		"submit": "Submit"
	},
	"starterForm": {
		"header": "Thinking of starting a business? Great!",
		"text": "Spoonflower wants to support you in following your passion. Let us know a little bit of information below, and we’ll help you ask the right questions and find some helpful answers and inspiration from our community.",
		"typeQuestion": "What type of business are you considering?",
		"selectType": "Select a type",
		"productBased": "Product-based business",
		"surfaceDesign": "Surface design",
		"both": "Both",
		"other": "Other",
		"biggestQuestion": "What’s your biggest question or hesitation?",
		"emailAddress": "Your email address",
		"emailAddressHere": "Email address here",
		"consentStatement": "By submitting this form, you agree to receive marketing communications from Spoonflower. To see our complete privacy policy, ",
		"clickHere": "click here",
		"submit": "Submit"
	},
	"ownerProfileAlt": "Geoffrey Merlet, maker",
	"ownerTestimonial": "“Because my bags are unique, I always need to find new patterns and the infinity of designers present on Spoonflower help me in this task. It also offers me the possibility to print my own patterns to make special collections.”",
	"ownerBusinessAlt": "Bag by Geoffrey Merlet",
	"starterProfileAlt": "Caroline Bowers, maker",
	"starterTestimonial": "“I really wanted to create something different and something SO fun. I wanted dogs to be able to stand out and be the talk of the neighborhood. Spoonflower allows me to search for the exact designs I am looking for.”",
	"starterBusinessAlt": "Bandanas by Caroline Bowers",
	"confirm": {
		"path": "/en/business/confirm",
		"header": "Thank You!",
		"subheader": "We’re Honored",
		"p1": "We’re thrilled that you’re using or considering Spoonflower as a part of your business. We look forward to helping you explore opportunities.",
		"p2": "Have questions or would like to chat? Don’t hesitate to reach out to us at ",
		"p3": "Be sure to check out ",
		"linkText": "benefits for your business",
		"button": "Back to Shopping"
	}
};