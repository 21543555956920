// debounce since lodash makes problems
export function debounce(fn, wait) {
  let timeout;

  return function() {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(function() {
      fn.apply(context, arguments);
    }, wait || 100);
  };
}

export function promiseSleep(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
}
