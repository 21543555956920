import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import * as types from '../../../constants/ActionTypes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectPageLang} from '../../../entities/pageSetup/selectors';
import {State} from '../../../store/initialState';

import {AnnouncementsResponse} from './AnnouncementsResponse';


export default function fetchAnnouncements(): ThunkAction<
  Promise<{ type: string; payload: unknown }>,
  State,
  unknown,
  AnyAction
  > {
  return (dispatch, getState) => {
    const state = getState();

    const pageLang = selectPageLang(state);

    return request<AnnouncementsResponse>(state, {
      url: `${selectAlpenroseHost(state)}/announcements`,
      params: {
        page_locale: pageLang,
      },
    })
      .then(({data}) =>
        dispatch({
          type: types.ANNOUNCEMENTS_RECEIVED,
          payload: data.data,
        })
      )
      .catch((error) => {
        if (process.env.REACT_APP_IS_SERVER) {
          return dispatch(serverError(error));
        }

        return dispatch({
          type: types.ANNOUNCEMENTS_ERROR,
          payload: error,
        });
      });
  };
}
