/* global dataLayer */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import browserHistoryWrapper from '../../components/Reusable/LinkWrapper/browserHistoryWrapper';
import browserHistory from 'react-router/lib/browserHistory';
import {translate} from '../../services';
import {isNotUndefined} from '../../utils/validation';
import fetchCmsContent from './api/fetchCmsContent';
import fetchCmsSlugs from './api/fetchCmsSlugs';
import {cartContentEvent} from '../../utils/dataLayer';
import {DATA_CMS} from '../../constants/DataLayer';
import Cms from '../../components/Cms/Cms';
import Loading from '../../components/Reusable/Loading/Loading';
import {selectPageName} from '../../entities/pageSetup/selectors';
import {upsertUrlQuery} from '../../utils/url';
import setupCMSPage from './initialState/setupCMSPage';
import setupOneDayCache from '../../server/cache/extendInitialState/setupOneDayCache';

class CmsContainer extends Component {
  constructor() {
    super();
    this.cartContentPushedToGA = false;
  }

  componentDidMount() {
    const {pageName, pageLang, updatedUrl, dispatchFetchCmsContent, dispatchPageSetup, body, currentOrigin} = this.props;

    dispatchPageSetup();

    // Sometimes cms could return `updateUrl` field
    // that we need to redirect a user then,
    // let's do that on server by rendering <Redirect> component
    // or something. Logic to catch such redirects on server are also required.
    // Did mount won't be called on server, so we need to define it during the render method
    // and leave it in componentDidMount also for the use-cases when the transtion happens
    // on client via client-side routing between CMS-to-CMS pages
    if (updatedUrl) {
      dispatchFetchCmsContent();
      const carrySlash = updatedUrl.charAt(0) === '/';
      let fixedUpdatedUrl = updatedUrl.replace(/^\//, '');

      // Make sure that the url is prefixed with the language.
      if (fixedUpdatedUrl.indexOf(pageLang) !== 0) {
        fixedUpdatedUrl = `${pageLang}/${fixedUpdatedUrl}`;
      }
      if (carrySlash) {
        fixedUpdatedUrl = `/${fixedUpdatedUrl}`;
      }
      browserHistoryWrapper.push(upsertUrlQuery(fixedUpdatedUrl, {}, currentOrigin));
    }

    if (!body && isNotUndefined(pageName)) {
      dispatchFetchCmsContent();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatchPageSetup,
      updatedUrl,
      dispatchFetchCmsContent,
      pageLang,
      pageName,
      pendingCart,
    } = this.props;

    dispatchPageSetup();

    if (prevProps.pageName !== pageName) {
      dispatchFetchCmsContent();
    }

    // Sometimes cms could return updateUrl field that we need to redirect a user then. Looks like we need to keep it here.
    if (prevProps.updatedUrl !== updatedUrl) {
      dispatchFetchCmsContent();
      browserHistory.replace({
        pathname: `/${pageLang}/${updatedUrl}`,
      });
    }

    if (
      !this.cartContentPushedToGA &&
      pendingCart.pendingCartFetched
    ) {
      this.cartContentPushedToGA = true;
      const cmsPage = `${DATA_CMS} ${pageName}`;

      dataLayer.push(cartContentEvent(cmsPage, pendingCart));
    }
  }

  render() {
    const {
      body,
      stylesheets,
      metadata,
      cmsPageLoading,
      scripts,
      webflowPageId,
      webflowSiteId,
    } = this.props;

    if (cmsPageLoading !== false) {
      return <Loading message={translate('common.loading')} />;
    }

    return (
      <Cms
        body={body}
        stylesheets={stylesheets}
        metadata={metadata}
        scripts={scripts}
        webflowSiteId={webflowSiteId}
        webflowPageId={webflowPageId}
      />
    );
  }
}

CmsContainer.propTypes = {
  dispatchPageSetup: PropTypes.func.isRequired,
  body: PropTypes.string,
  currentOrigin: PropTypes.string,
  stylesheets: PropTypes.array,
  scripts: PropTypes.array,
  metadata: PropTypes.object,
  cmsPageLoading: PropTypes.bool,
  dispatchFetchCmsContent: PropTypes.func,
  pageLang: PropTypes.string,
  pageName: PropTypes.string,
  webflowPageId: PropTypes.string,
  webflowSiteId: PropTypes.string,
  updatedUrl: PropTypes.string,
  pendingCart: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageName: selectPageName(state),
  body: state.cmsContent.body,
  stylesheets: state.cmsContent.stylesheets,
  metadata: state.cmsContent.metadata,
  cmsPageLoading: state.cmsContent.isContentLoading,
  scripts: state.cmsContent.scripts,
  webflowPageId: state.cmsContent.webflowPageId,
  webflowSiteId: state.cmsContent.webflowSiteId,
  updatedUrl: state.cmsContent.updatedUrl,
  pendingCart: state.carts.pending,
  currentOrigin: state.pageSetup.origin,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  dispatchPageSetup() {
    dispatch(setupCMSPage());
  },
  dispatchFetchCmsContent() {
    dispatch(fetchCmsContent());
  },
});

const CmsContainerConnected = connect(mapStateToProps, mapDispatchToProps)(CmsContainer);

CmsContainerConnected.fetchDataOnServer = new Set([fetchCmsContent]);
CmsContainerConnected.extendInitialStateBeforeDataLoad = new Set([setupCMSPage, setupOneDayCache]);
CmsContainerConnected.extendRedirectsConfig = new Set([fetchCmsSlugs]);
CmsContainerConnected.pageKey = 'cmsPage';

export default CmsContainerConnected;
