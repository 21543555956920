export const COLOR_PREFIX = 'color_';
export const keyColorModifier = (key) => (!key.startsWith(COLOR_PREFIX) ? key : key.split(COLOR_PREFIX).pop());

export const MENU_ITEMS_PRECEDED_BY_HORIZONTAL_RULES = [
  'fabricTypes',
  'solids',
  'fillAYard',
  'sellingAndCommissions',
  'discounts',
  'challenge1',
  'proMembership',
  'owner'
];
