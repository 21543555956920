module.exports = {
	"content": {
		"headline": "Gift Ideas",
		"seeDetails": "See Details",
		"forArtists": "For artists",
		"forMakers": "For makers",
		"avatarAltText": "Spoonflower Designer",
		"sampleSectionTitle": "Sample Sections",
		"englishNote": "Note that the book is available in English only.",
		"englishShortNote": "In English only."
	},
	"STOCK_ITEM_SPOONFLOWER_HANDBOOK": {
		"title": "Spoonflower Handbook",
		"short_description": "A DIY Guide to Designing Fabric & Wallpaper",
		"long_description": "The Spoonflower Handbook puts surface design for fabric and wallpaper well within the reach of creative people everywhere. Today anyone with a computer, Internet connection, and idea can upload a file and, at modest cost, order their own fabric or paper, printed one yard at a time. At the forefront of this revolutionary DIY movement is Spoonflower, producing designs for hundreds of thousands of creative people worldwide — printing 24 hours a day/seven days a week to keep up with demand. Written for both new and experienced users of this print-on-demand technology, it covers everything from equipment to software to working with photos, scans, repeats, and vector files, and includes projects for the home and wardrobe as well as special tutorials contributed by members of the Spoonflower community.",
		"author": "By Stephen Fraser, Judi Ketteler, and Becka Rahn, 207 pages",
		"sampleSectionContent": {
			"sectionOne": {
				"sectionTitle": "Design with Found Objects",
				"sectionText": "A collection of objects (like feathers or stamps or recipes) and a scanner can be all you need to get started, without having to worry about about your drawing skills.",
				"sectionImageAlt": "Tea towel on Spoonflower fabric"
			},
			"sectionTwo": {
				"sectionTitle": "Design with Photos",
				"sectionText": "Photos are another easy way to get inspiration for a design—and they are plentiful! Whatever a camera can capture, you can translate into a cool surface design.",
				"sectionImageAlt": "Pillows with photo designs on Spoonflower fabric"
			},
			"sectionThree": {
				"sectionTitle": "Design with Drawings, Paintings & Prints",
				"sectionText": "The drawings your kids create, the doodles and sketches that come from your imagination, the vintage postcard—it’s all teeming with design possibility!",
				"sectionImageAlt": "A tie made with Spoonflower fabric"
			},
			"sectionFour": {
				"sectionTitle": "Design with Words, Text & Layers",
				"sectionText": "In this chapter, we introduce the ideas of working with text and working in layers, which means creating the different parts of a design as separate elements.",
				"sectionImageAlt": "Placemat made using Spoonflower fabric"
			},
			"sectionFive": {
				"sectionTitle": "All-Digital Designs",
				"sectionText": "We head in a slightly different direction in this chapter and teach you how to work with vector-based images—artwork created solely on the computer.",
				"sectionImageAlt": "Table runner made with Spoonflower fabric"
			},
			"sectionSix": {
				"sectionTitle": "Design Cut & Sew and Whole Cloth",
				"sectionText": "Here we introduce a technique called “whole cloth” design or “whole surface” design, because your design takes up the entirety of what you are printing, without repeating.",
				"sectionImageAlt": "Pillows made from Spoonflower fabric"
			}
		},
		"testimonialSectionContent": {
			"testimonialOne": {
				"testimonialInfo": "Verified Purchaser",
				"testimonialInfoUrl": "",
				"testimonialText": "I am so excited to create something wonderful. The book is laid out step by step how to create your own one of a kind projects with your own designed fabrics. Thanks Spoonflower.",
				"testimonialAuthor": "Ellen"
			},
			"testimonialTwo": {
				"testimonialInfo": "Verified Purchaser",
				"testimonialInfoUrl": "",
				"testimonialText": "Good explanation. This book takes you step by step through the fabric designing process for beginners. It also explains in detail what’s involved and Spoonflower’s printing process.",
				"testimonialAuthor": "S. Marshall"
			},
			"testimonialThree": {
				"testimonialInfo": "Verified Purchaser",
				"testimonialInfoUrl": "",
				"testimonialText": "A nice guide to designing your own fabric to be printed by Spoonflower. The sample projects were very interesting and gave me some great ideas for my projects.",
				"testimonialAuthor": "A. Edwards"
			},
			"testimonialFour": {
				"testimonialInfo": "Verified Purchaser",
				"testimonialInfoUrl": "",
				"testimonialText": "Perfect for a design hobbyist. This book really helps to decide how to use the Spoonflower site to design fabric, gift-wrap and wallpaper. It has helpful, basic design tips for the novice…",
				"testimonialAuthor": "A. Teeny"
			}
		}
	},
	"STOCK_ITEM_SPOONFLOWER_SEW_BOOK": {
		"title": "Spoonflower Quick Sew Project Book",
		"short_description": "34 DIYs to Make the Most of Your Fabric Stash",
		"long_description": "Discover all that you can make with just a yard or two! Spoonflower —a create-your-own/print-on-demand fabric company known for its unique, clever, and must-have designs— presents dozens of brand new projects that can be completed in just a few hours. Get inspired and turn your favorite fabric into a lovely garland, stylish tote, children’s tent, and all sorts of other accessories for your home and more. With step-by-step tutorials and projects that span a wide-spectrum skills, this book is perfect for both new and experienced sewists.",
		"author": "By Anda Corrie, Photography by Zoë Noble, 191 pages",
		"authorInfo": {
			"authorName": "Anda Corrie",
			"authorIntro": "Meet the Author, Anda Corrie",
			"authorBio": "To bring this book to life, we turned to our dear friend Anda Corrie, one of the very first designers on Spoonflower. Formerly of Etsy, Anda is not only a whimsical illustrator and artist, but is also a handmade enthusiast that has created many DIY projects for the Spoonflower blog. From saving and using scraps of fabric too gorgeous to throw away to creating one-of-a-kind pieces with your own designs, Anda wrote this book for anyone who loves beautifully designed fabric and wants to turn it into something special—whether that’s you, your mom or your best friend."
		},
		"sampleSectionContent": {
			"sectionOne": {
				"sectionTitle": "Intro",
				"sectionText": "We’ve arranged the projects in this book according to size, smallest to largest, and most can be completed in an afternoon. We run you through what you’ll need to get started.",
				"sectionImageAlt": "Spoonflower fabrics on a work surface"
			},
			"sectionTwo": {
				"sectionTitle": "Notions + Trims",
				"sectionText": "Learning to make your own notions and trims means you will always have what you need on hand when you start a project. These seven projects will have you well on your way.",
				"sectionImageAlt": "Spoonflower fabrics on notions and trims"
			},
			"sectionThree": {
				"sectionTitle": "Swatch Projects",
				"sectionText": "What can you make with an 8-inch square swatch? Quite a lot, it turns out, from herbal sachets to toys to adorable mini purses. We think you’ll swoon over these six projects.",
				"sectionImageAlt": "Pin cushions made with Spoonflower swatches"
			},
			"sectionFour": {
				"sectionTitle": "Fat Quarter Projects",
				"sectionText": "We love sewing for friends. It allows us to empty our houses and craft at the same time—plus, who on earth does not like presents? Here are eight projects that make excellent gifts.",
				"sectionImageAlt": "Pot holders made from fat quarters using Spoonflower fabrics"
			},
			"sectionFive": {
				"sectionTitle": "One-Yard Projects",
				"sectionText": "Step it up a bit with a few simple projects that use a yard of fabric! These eight projects are sure to brighten up your home in fun and clever ways.",
				"sectionImageAlt": "Pillows made from single yards of Spoonflower fabrics"
			},
			"sectionSix": {
				"sectionTitle": "Multi-Yard Projects",
				"sectionText": "We promise, there is no reason to be intimidated by huge swaths of fabric. By the end of this section of five projects, you’ll wonder why you ever worried in the first place.",
				"sectionImageAlt": "Bean bag chair made from multiple yards of Spoonflower fabric"
			}
		},
		"featuredDesigners": {
			"title": "Designers Featured in the Book",
			"designerOne": {
				"designerName": "Holli Zollinger",
				"designerProfile": "/profiles/holli_zollinger"
			},
			"designerTwo": {
				"designerName": "Heleen van den Thillart",
				"designerProfile": "/profiles/heleen_vd_thillart"
			},
			"designerThree": {
				"designerName": "Judy Quintero",
				"designerProfile": "/profiles/shopcabin"
			},
			"designerFour": {
				"designerName": "Jessica Prout",
				"designerProfile": "/profiles/littlearrowdesign"
			},
			"designerFive": {
				"designerName": "Esther Fallon Lau",
				"designerProfile": "/profiles/nouveau_bohemian"
			},
			"designerSix": {
				"designerName": "Amy Steele",
				"designerProfile": "/profiles/willowlanetextiles"
			}
		},
		"testimonialSectionContent": {
			"testimonialOne": {
				"testimonialInfo": "The team at Lia Griffith",
				"testimonialInfoUrl": "https://www.liagriffith.com",
				"testimonialText": " \"Full of on-trend, simple patterns and designs, we found fresh new ways to use our scraps of fabric, as well as larger projects for our home and office that we know will be coveted!\" ",
				"testimonialAuthor": ""
			},
			"testimonialTwo": {
				"testimonialInfo": "Tabitha Sewer",
				"testimonialInfoUrl": "https://www.tabithasewer.com",
				"testimonialText": " \"I am teaching my daughter to sew, and this book came at the most opportune time. The instructions are easy to follow, and the imagery for each project makes you want to try them all.\" ",
				"testimonialAuthor": ""
			},
			"testimonialThree": {
				"testimonialInfo": "Cynthia Frenette",
				"testimonialInfoUrl": "https://cynthiafrenette.com",
				"testimonialText": " \"So many cute and cheerful projects! I’m so excited to make some holiday print Pet Collar Bandanas for our dogs so they’ll be all set for this year’s pics with Santa!\" ",
				"testimonialAuthor": ""
			},
			"testimonialFour": {
				"testimonialInfo": "Love to Sew",
				"testimonialInfoUrl": "https://www.lovetosewpodcast.com",
				"testimonialText": " \"We’re always looking for ways to make the most of our fabric stash, and this scrap busting book is the perfect tool! Time to dive into our stashes and get sewing!\" ",
				"testimonialAuthor": "Helen Wilkinson and Caroline Somos"
			}
		}
	},
	"STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE": {
		"title": "Spoonflower Book Bundle",
		"short_description": "Give both the {bookOne} and the {bookTwo} as a combo gift and save 10% by ordering both together (available in English only)",
		"long_description": "MULTILINE",
		"multi_line_description": {
			"lineOne": "The {book} puts surface design for fabric, wallpaper and home decor within the reach of creative people everywhere.",
			"lineTwo": "In the {book}, sewing fans will find a variety of new and stylish projects designed with their fabric stash in mind."
		},
		"author": ""
	},
	"STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK": {
		"title": "Sample Pack",
		"short_description": "Each sample pack contains a 4” x 4” colorfully printed swatch of each of our fabrics, plus printed samples of wallpaper.*",
		"long_description": "Considering a project? Don't know where to begin? A Spoonflower sample pack is the perfect place to start. Each pack contains a sample of each of our fabrics and papers printed with the same colorful design. Get a feel for which fabric is perfectly soft, stretchy, sturdy, or silky enough for your project. Check out the print quality and color vibrancy to pick the ideal product for you.",
		"exclusion": "*Excludes new products: Gold Metallic, Silver Metallic wallpaper, Cotton Silk, Essex Linen, Seersucker and Sport Stretch Woven fabrics",
		"author": "",
		"metaTitle": "Order A Sample Pack | Spoonflower",
		"metaDescription": "Starting a project? One of our sample packs is the perfect place to begin! Each pack includes samples of our fabrics and wallpaper with the same design."
	},
	"STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK": {
		"title": "Petal Signature Cotton® Solids Swatch Book",
		"short_description": "Each Petal Signature Cotton® Solids Swatch Book contains a 2\" x 2\" (5 cm x 5 cm) swatch of all available color options.",
		"long_description": "Not sure if your computer screen is reading our colors right?  Swatch Books are the perfect place to begin any project. Explore the variety of colors and pick the perfect dyed solid fabric to coordinate with your digitally printed on demand fabric. From modern quilt pieces, to apparel linings and crafted creations; vibrant Petal Signature Cotton Solids offer endless options.|We recommend ordering your digitally printed design first and then using the swatch book to select the perfect coordinating solid.",
		"author": "",
		"metaTitle": "Petal Signature Cotton Solids Swatch Book | Spoonflower",
		"metaDescription": "Want a coordinating solid color fabric for your next project? Use our swatch book to get a feel for one of our 50 solid colors in Petal Signature Cotton®."
	},
	"STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK": {
		"title": "Grasscloth Sample Book",
		"short_description": "Spoonflower's Grasscloth wallpaper is crafted from 100% natural sisal grass for a wallcovering that offers a timeless textured look in any room. Each 2' x 27' rolls offers unique variations and striation of texture for an elevated look that adds warmth and dimension everywhere it is installed.",
		"long_description": "Our Grasscloth Sample Book offers two 5.5 inch x 8.5 inch (13,9 x 20,3 cm) samples and eight 3 inch x 4.75 inch (7,62 x 12 cm) samples of designs chosen by the Spoonflower team from the Marketplace to showcase all the beautiful features of grasscloth wallpaper.",
		"author": "",
		"metaTitle": "Grasscloth Sample Book | Spoonflower",
		"metaDescription": "Spoonflower's Grasscloth Sample Book offers ten sample designs chosen to showcase the natural features of grasscloth wallpaper."
	},
	"STOCK_ITEM_WELCOME_PACK_MAKERS": {
		"title": "Spoonflower Welcome Pack for Makers",
		"short_description": "Share creative inspiration with your favorite maker or crafter and give this kit featuring tools and DIY projects for every fabric lover worldwide.",
		"list": {
			"headline": "The pack includes:",
			"listItems": {
				"itemOne": "The {samplePack} with samples of all the fabrics and materials, including wallpaper, that we offer for printing",
				"itemTwo": "A copy of the {book} in which sewing fans will find a variety of new and stylish projects designed with their fabric stash in mind",
				"itemThree": "A 25% off coupon for a future purchase",
				"itemFour": "Assorted bonus goodies"
			}
		}
	},
	"STOCK_ITEM_WELCOME_PACK_ARTISTS": {
		"title": "Spoonflower Welcome Pack for Artists",
		"short_description": "Want to pick up a new hobby or help an artistic friend express themselves? This set of tools is curated to guide a beginner through designing textiles on Spoonflower for personal use or potential profit!",
		"list": {
			"headline": "The pack includes:",
			"listItems": {
				"itemOne": "The {samplePack} with samples of all the fabrics and materials, including wallpaper, that we offer for printing",
				"itemTwo": "A {colorMap} printed on Petal Signature Cotton™",
				"itemThree": "A copy of the {handbook} which covers everything from equipment to software to working with photos, scans repeats and vector files so that you can quickly get up to speed designing on the Spoonflower platform",
				"itemFour": "A 25% off coupon for a future purchase",
				"itemFive": "Assorted bonus goodies"
			}
		}
	},
	"addToCartItems": {
		"quickSew": {
			"headline": "Spoonflower Quick-Sew Project Book",
			"description": "In the Spoonflower Quick Sew Project Book, sewing fans will find a variety of new and stylish projects designed with their fabric stash in mind. Available in English only."
		},
		"handbook": {
			"headline": "Spoonflower Handbook",
			"description": "Give the gift of textile design! The Spoonflower Handbook puts surface design for fabric, wallpaper and home decor well within the reach of creative people everywhere. In English only."
		},
		"bookBundle": {
			"headline": "Spoonflower Book Bundle",
			"description": "Give both The Spoonflower Quick Sew Project Book and The Spoonflower Handbook as a combo gift and save 10% by ordering both together (available in English only)"
		},
		"welcomePack": {
			"headline": "Spoonflower Welcome Pack",
			"description": "Share creative inspiration with your favorite maker or crafter and give this kit featuring tools and DIY projects for every fabric lover worldwide."
		},
		"samplePack": {
			"headline": "Sample Pack",
			"description": "Each sample pack contains a 4” x 4” colorfully printed swatch of each of our fabrics plus printed samples of wallpaper."
		},
		"solidSamplePack": {
			"headline": "Petal Signature Cotton® Solids Swatch Book",
			"description": "Each Petal Signature Cotton® Solids Swatch  Book contains a 2\" x 2\" (5 cm x 5 cm) swatch of all available color options."
		},
		"grassclothSamplePack": {
			"headline": "Grasscloth Sample Book",
			"description": "Spoonflower's Grasscloth Sample Book offers ten sample designs chosen to showcase the natural features of grasscloth wallpaper."
		},
		"colorMap": {
			"headline": "Spoonflower Color Map",
			"description": "This chart of over 1400 color chips and their hex codes prints on a full yard of any of our fabrics or 4 feet of wallpaper."
		},
		"designBlog": {
			"headline": "Design Resources",
			"description": "Be inspired and learn how to design everything from surface designs to cut and sew patterns with guidance from Spoonflower and our community.",
			"detailsLink": "Browse resources"
		}
	}
};