import {snakeCase} from 'lodash';
import React, {useMemo} from 'react';

import {ON_PARAM, PRODUCT_PARAM, SUBSTRATE_PARAM, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER} from '../../../constants/Parameters';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import useCurrentPath from '../../../entities/pageSetup/path/useCurrentPath';
import {onClickPreventDefault} from '../../../utils/events';
import {getLeafValue} from '../../../utils/search';

import {TreeBranch} from './SearchTree';


export const useSeoLabel = ({nodes}: {nodes: TreeBranch}): JSX.Element => {
  const {name: label, id} = nodes;
  const currentPath = useCurrentPath();
  const origin = useOrigin();

  return useMemo(() => {
    const shouldProcessLeaf = !Array.isArray(nodes.children);

    if (!shouldProcessLeaf || !nodes.apiValue) {
      return <span>{label}</span>;
    }
    const url = new URL(currentPath, origin);
    const searchValue = nodes.apiValue || '';
    const [parentCategoryName, category] = id.split('.');
    const facetParam = getLeafValue(parentCategoryName);
    const searchParam = facetParam === PRODUCT_PARAM ? ON_PARAM : facetParam;

    if (category === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER) {
      url.searchParams.set(ON_PARAM, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER);
      url.searchParams.set(SUBSTRATE_PARAM, snakeCase(searchValue));
    } else {
      url.searchParams.set(searchParam, searchValue);
    }
    const href = url.pathname + url.search;

    return <a className='seo-label' href={href} onClick={onClickPreventDefault}>{label}</a>;
  }, [nodes, origin, currentPath]);
};
