module.exports = {
	"head": {
		"title": "{tag} Fabric, Wallpaper and Home Decor | Spoonflower",
		"matureDescription": "By default, Spoonflower blocks designs marked as “Adult content.” We’ve updated your settings to show them going forward. You can ",
		"matureDescriptionTail": " if you prefer.",
		"matureDescriptionLink": "Block Adult content",
		"meta": {
			"description": "Shop {tag} fabric by the yard, wallpapers and home decor items with hundreds of amazing patterns created by indie makers all over the world. Print and sell your own designs, too!",
			"keywords": "{tag} fabric, {tag} wallpaper, {tag} home accessories, {tag} surface design, digital printed {tag} designs, digital printed {tag} wallpaper, shop digital printed designs, shop indie design, shop indie designers's",
			"og": {
				"title": "{tag} Fabric, Wallpaper and Home Decor | Spoonflower",
				"description": "Shop {tag} fabric by the yard, wallpaper and home decor items with hundreds of amazing patterns created by indie makers all over the world. Print and sell your own designs, too!"
			}
		}
	},
	"header": {
		"title1": "Shop {tag} Designs in Fabric, ",
		"title2": "Wallpaper",
		"title3": " and ",
		"title4": "Home Decor",
		"wallpaperUrl": "/en/shop?on=wallpaper&q={tag}&sort=bestSelling",
		"homeDecorUrl": "/en/shop?on=allProducts&q={tag}&sort=bestSelling",
		"message": "Custom printed fabric and more"
	},
	"navigation": {
		"shop": "Shop",
		"design": "Design",
		"howItWorks": "How it works"
	},
	"main": {
		"sectionTitle": "Tags for {tag}",
		"wallpaperSubTitle": "{tag} Wallpaper",
		"fabricSubTitle": "{tag} Fabric",
		"subDescription": "Shop fabric, wallpaper and home accessories and check out projects with unique {tag} designs.",
		"removeFilter": "remove filter",
		"showMoreFilters": "Show more filters",
		"authorBy": "by",
		"tagsListTerm": "tags",
		"similarDesigns": "Similar Designs",
		"seeSimilarDesigns": "See Similar Designs",
		"authorLinkTitle": "To the profile of {designerName}",
		"tagSlug": "/en/shop/{tagSlug}",
		"chooseDesign": "Choose Design",
		"loading": "Please wait a moment, the designs are loading.",
		"noResults": "Yikes - you ran out of search results! Update your filters and try again."
	},
	"footer": {
		"description": "All of these great {searchQuery} designs are available in fabric by the yard, fabric by the meter, wallpaper and home decor items like curtains, bedding, pillows and dining. Your purchase supports Spoonflower's growing community of artists. Have a design of your own? {url} here to print your custom fabric, wallpaper or home decor!",
		"descriptionLinkText": "Upload it",
		"headline": "About {tag} Designs"
	}
};