import {AxiosResponse} from 'axios';

import request, {HttpMethod} from '../../../application/api/request';
import type {State} from '../../../store/initialState';
import selectAlpenroseHost from '../../pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectPageLang} from '../../pageSetup/selectors';
import type {UserResponse} from '../types';

import {UserPreferencesData} from './updateUser';


// eslint-disable-next-line no-undef
export const updateUserViaAlpAPI = (state: State, data: UserPreferencesData, skipCache = false): Promise<AxiosResponse<ApiResponse<UserResponse>> | void> =>
  // eslint-disable-next-line no-undef
  request<ApiResponse<UserResponse>>(
    state,
    {
      url: `${selectAlpenroseHost(state)}/user/me/preferences`,
      method: HttpMethod.patch,
      data,
      params: {
        page_locale: selectPageLang(state)
      },
    },
    false,
    skipCache
  );
