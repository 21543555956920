export const MAX_TITLE_LENGTH = 40;
export const MAX_DESCRIPTION_TITLE_LENGTH = 80;

export const THUMB_NAIL_SIZE = '45';
export const CAROUSEL_THUMB_NAIL_SIZE = 55;
export const STACKED_THUMB_NAIL_SIZE = '64';
export const FEATURED_THUMB_NAIL_SIZE = '80';
export const COLLECTION_THUMB_NAIL_SIZE = '115';

export const POSITIVE_FAVORITE_COUNT = 1;
export const NEGATIVE_FAVORITE_COUNT = -1;

export const MAX_LENGTH_DESIGNER_DESCRIPTION = 100;
export const SHORT_DESCRIPTION = 'shortDescription';
export const LONG_DESCRIPTION = 'longDescription';

export const SCROLL_POSITION_MOBILE_ADD_TO_CART = 860;

export const MAX_NUMBER_OF_TAGS = 13;

export const CAROUSEL_WRAPPER_WIDTH = 774;
export const CAROUSEL_WRAPPER_HEIGHT = 474;
export const QUICKLOOK_CTA_WIDTH = 440;

export const LAZY_OFFSET = 17;
export const DOUBLE_LAZY_OFFSET = 34;

export const AVATAR_SIZE = 75;

export const NO_MUGSHOT = 'NO_MUGSHOT';
