import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {pageSetup} from '../../../actions/pageSetup';
import {translate} from '../../../services';
import {State} from '../../../store/initialState';
import {isNotUndefined} from '../../../utils/validation';


export default function setupCMSPage(): ThunkAction<void, State & { cmsContent?: { title?: string } }, unknown, AnyAction> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const payload: {
      contentInnerExtensions: string;
      pageTitle?: string;
    } = {
      contentInnerExtensions: 'x-1294',
    };
    let pageTitle;

    if (isNotUndefined(state.cmsContent?.title)) {
      pageTitle = state.cmsContent?.title || translate('welcome.title');
      if (pageTitle) {
        payload.pageTitle = pageTitle;
      }
      dispatch(pageSetup(payload));
    }
  };
}
