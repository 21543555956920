import merge from 'deepmerge';
import * as types from '../constants/ActionTypes';
import {
  SET_ADDITIONAL_USER_DATA,
} from '../entities/user/actions/actionTypes';
import {isNotUndefined, isUndefined} from '../utils/validation';
import {GUEST, REGISTERED, UNAUTHENTICATED} from '../constants/userTypes';

function user(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case types.CURRENT_USER_GET + types.FETCHING:
      return Object.assign({}, state,{
        isFetching: true
      });
    case types.CURRENT_USER_GET + types.FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        userType: UNAUTHENTICATED
      });
    case types.CURRENT_USER_GET + types.SUCCESS:
      if (action.payload.data) {
        if (isUndefined(action.payload.data.guest)) {
          return merge(state, {
            isFetching: false,
            ...action.payload.data
          });
        } else if (isNotUndefined(action.payload.data.guest)) {
          const {...user} = action.payload.data;

          user.userType = (action.payload.data.guest ? GUEST : REGISTERED);

          return merge(state, {
            ...user,
            isFetching: false
          });
        }
      }

      return state;
    case types.SET_ABN_CONSENT + types.SUCCESS:
      return {
        ...state, abnConsent: true
      };
    case types.SET_ABN_CONSENT + types.FAIL:
      return {
        ...state, abnConsent: false
      };
    case types.CLEAR_SERVER_CONTEXT:
      return {
        ...state, cookies: {}
      };

    case SET_ADDITIONAL_USER_DATA:
      return {
        ...state, ...action.payload
      };

    default:
      return state;
  }
}

export default user;
