export const ALTERNATE_LOCALES_SET = 'ALTERNATE_LOCALES_SET';

export const PAGE_SETUP = 'PAGE_SETUP';
export const PAGE_UPDATE = 'PAGE_UPDATE';
export const PAGE_KEY_SETUP = 'PAGE_KEY_SETUP';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const CALCULATOR_MODAL_RESET = 'CALCULATOR_MODAL_RESET';
export const CALCULATOR_LANDING_RESET = 'CALCULATOR_LANDING_RESET';
export const MODAL_OPEN = 'MODAL_OPEN';
export const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION';
export const CHANGE_ORDER_ITEM_SUBSTRATE_TYPE = 'CHANGE_ORDER_ITEM_SUBSTRATE_TYPE';

export const VIEWPORT_UPDATE = 'VIEWPORT_UPDATE';

export const PAYMENT_TOKEN_RECEIVED = 'PAYMENT_TOKEN_RECEIVED';
export const CLEAR_PAYMENT_DETAILS = 'CLEAR_PAYMENT_DETAILS';
export const PAYPAL_INITIALIZATION_DATA = 'PAYPAL_INITIALIZATION_DATA';
export const TAB_SET_ACTIVE_TAB_NAME = 'TAB_SET_ACTIVE_TAB_NAME';

export const FLASH_MESSAGE_SET_MESSAGE = 'FLASH_MESSAGE_SET_MESSAGE';

export const TRANSITION_MESSAGE_SET_MESSAGE = 'TRANSITION_MESSAGE_SET_MESSAGE';
export const TRANSITION_MESSAGE_HIDE_MESSAGE = 'TRANSITION_MESSAGE_HIDE_MESSAGE';

export const SEARCH_RESULTS_RECEIVED = 'SEARCH_RESULTS_RECEIVED';
export const SEARCH_PARAMS_UPDATED = 'SEARCH_PARAMS_UPDATED';
export const SEARCH_PARAMS_RESET = 'SEARCH_PARAMS_RESET';
export const SEARCH_QUERY_CHANGED = 'SEARCH_QUERY_CHANGED';
export const TAG_RECEIVED = 'TAG_RECEIVED';
export const SEARCH_RESULTS_LOADING = 'SEARCH_RESULTS_LOADING';

export const STRIPE_SAVE_NEW_PAYMENT_INFO = 'STRIPE_SAVE_NEW_PAYMENT_INFO';
export const SAVED_CARD_SELECTED = 'SAVED_CARD_SELECTED';

export const INVOICE_TOGGLE_LOADING = 'INVOICE_TOGGLE_LOADING';
export const INVOICE_METADATA_TOGGLE_LOADING = 'INVOICE_METADATA_TOGGLE_LOADING';

export const SUBSTRATE_CHOSEN = 'SUBSTRATE_CHOSEN';
export const DESIGN_CHOSEN = 'DESIGN_CHOSEN';
export const QUANTITY_CHOSEN = 'QUANTITY_CHOSEN';
export const PRODUCT_SIZE_CHOSEN = 'PRODUCT_SIZE_CHOSEN';
export const HOME_GOOD_PRODUCT_SIZE_CHOSEN = 'HOME_GOOD_PRODUCT_SIZE_CHOSEN';
export const HOME_GOOD_ADDITION_CHOSEN = 'HOME_GOOD_ADDITION_CHOSEN';
export const COLOR_CHOSEN = 'COLOR_CHOSEN';

export const ANNOUNCEMENTS_RECEIVED = 'ANNOUNCEMENTS_RECEIVED';
export const ANNOUNCEMENTS_ERROR = 'ANNOUNCEMENTS_ERROR';
export const PRODUCT_PAGE_RESET = 'PRODUCT_PAGE_RESET';

// Fill-A-Yard (FAY)
export const FAY_OPTIONS_PAGE_ERROR = 'FAY_OPTIONS_PAGE_ERROR';
export const FAY_COLLECTION_RECEIVED = 'FAY_COLLECTION_RECEIVED';
export const FAY_MASTER_TEMPLATES_RECEIVED = 'FAY_MASTER_TEMPLATES_RECEIVED';
export const FAY_MASTER_TEMPLATE_SELECTED = 'FAY_MASTER_TEMPLATE_SELECTED';
export const FAY_IMAGE_TEMPLATE_RECEIVED = 'FAY_IMAGE_TEMPLATE_RECEIVED';
export const FAY_FABRICS_RECEIVED = 'FAY_FABRICS_RECEIVED';
export const FAY_FABRIC_SELECTED = 'FAY_FABRIC_SELECTED';

// User Collections
export const USER_COLLECTIONS_FETCHING = 'USER_COLLECTIONS_FETCHING';
export const USER_COLLECTIONS_RECEIVED = 'USER_COLLECTIONS_RECEIVED';
export const USER_COLLECTIONS_ERROR = 'USER_COLLECTIONS_ERROR';
export const USER_COLLECTION_DESIGN_REMOVED = 'USER_COLLECTION_DESIGN_REMOVED';
export const USER_COLLECTION_DESIGN_REMOVED_ERROR = 'USER_COLLECTION_DESIGN_REMOVED_ERROR';
export const USER_COLLECTION_DESIGN_ADDED = 'USER_COLLECTION_DESIGN_ADDED';
export const USER_COLLECTION_DESIGN_ADDED_ERROR = 'USER_COLLECTION_DESIGN_ADDED_ERROR';
export const USER_COLLECTION_CREATED = 'USER_COLLECTION_CREATED';
export const USER_COLLECTION_CREATED_ERROR = 'USER_COLLECTION_CREATED_ERROR';
export const USER_FAVORITE_COLLECTIONS = 'USER_FAVORITE_COLLECTIONS_';
export const USER_FAVORITE_COLLECTIONS_RECEIVED = 'USER_FAVORITE_COLLECTIONS_RECEIVED';
export const USER_FAVORITE_COLLECTION_ADDED = 'USER_FAVORITE_COLLECTION_ADDED';
export const USER_FAVORITE_COLLECTION_REMOVED = 'USER_FAVORITE_COLLECTION_REMOVED';
export const USER_FAVORITE_COLLECTION_ADDED_ERROR = 'USER_FAVORITE_COLLECTION_ADDED_ERROR';
export const USER_FAVORITE_COLLECTION_REMOVED_ERROR = 'USER_FAVORITE_COLLECTION_REMOVED_ERROR';
// Collection data from Pythias endpoint
export const COLLECTION_RECEIVED = 'COLLECTION_RECEIVED';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';

export const USER_FAVORITE_DESIGNS_FETCHING = 'USER_FAVORITE_DESIGNS_FETCHING';
export const USER_FAVORITE_DESIGNS_RECEIVED = 'USER_FAVORITE_DESIGNS_RECEIVED';
export const USER_FAVORITE_DESIGNS_ERROR = 'USER_FAVORITE_DESIGNS_ERROR';

// Account info
export const ACCOUNT_INFO_GET = 'ACCOUNT_INF_GET_';
export const ACCOUNT_INFO_POST = 'ACCOUNT_INF_POST_';

// Subscription to newsletters
export const USER_SUBSCRIPTION_INFO_GET = 'USER_SUBSCRIPTION_INFO_GET_';
export const USER_SUBSCRIPTION_INFO_POST = 'USER_SUBSCRIPTION_INFO_POST_';

// Data deletion
export const DATA_DELETION_FETCHING = 'DATA_DELETION_FETCHING';

// Prefixes
export const PENDING = 'PENDING_'; // ORDER_GET_ action type prefix
export const SAVED = 'SAVED_'; // ...also
export const LAST_PAID = 'LAST_PAID_';
export const INVOICE = 'INVOICE_';
export const ORDER_GET = 'ORDER_GET_';
export const IDME = 'IDME_';
export const COUNT_GET = 'COUNT_GET_';
export const FEATURE_FLAGS = 'FEATURE_FLAGS_';

export const ORDER_DELIVERY_OPTIONS_GET = 'ORDER_DELIVERY_OPTIONS_GET_';
export const CURRENT_USER_GET = 'CURRENT_USER_GET_';
export const ADD_TO_CART_POST = 'ADD_TO_CART_POST_';
export const FABRIC_PRICING_GET = 'FABRIC_PRICING_GET_';
export const AFTERPAY_GET = 'AFTERPAY_GET_';
export const HOME_GOOD_PRICING_GET = 'HOME_GOOD_PRICING_GET_';
export const WALLPAPER_PRICING_GET = 'WALLPAPER_PRICING_GET_';
export const STOCK_ITEM_PRICING_GET = 'STOCK_ITEM_PRICING_GET_';
export const SET_ABN_CONSENT = 'SET_ABN_CONSENT_';
export const ORDER_ITEM_REQUEST = 'ORDER_ITEM_REQUEST_';
export const ORDER_ITEM_DELETE = 'ORDER_ITEM_DELETE';

export const USER_STATS_GET = 'USER_STATS_GET_';
export const USER_STATS_SET_INITIALS = 'USER_STATS_SET_INITIALS';
export const USER_STATS_PAGE_TYPE = 'USER_STATS_PAGE_TYPE_';
export const BRAINTREE_PUBLIC_CLIENT_TOKEN_GET = 'BRAINTREE_PUBLIC_CLIENT_TOKEN_GET_';
export const AFTERPAY_PUBLIC_CLIENT_TOKEN_GET = 'AFTERPAY_PUBLIC_CLIENT_TOKEN_GET_';
export const INVOICE_GATEWAY_METADATA_GET = 'INVOICE_GATEWAY_METADATA_';
export const STRIPE_CUSTOMER_GET = 'STRIPE_CUSTOMER_GET_';
export const FABRICS_GET = 'FABRICS_GET_';
export const SET_FABRIC_SHOP_SEARCH = 'SET_FABRIC_SHOP_SEARCH';
export const WALLPAPERS_GET = 'WALLPAPERS_GET_';
export const COLORWAYS_GET = 'COLORWAYS_GET_';
export const COLORWAYS_RESET = 'COLORWAYS_RESET';
export const DESIGN_GET = 'DESIGN_GET_';
export const ALL_PRODUCTS_GET = 'ALL_PRODUCTS_GET_';
export const STOCK_ITEMS_GET = 'STOCK_ITEMS_GET_';
export const VALID_IMAGE_HEAD = 'VALID_IMAGE_HEAD_';
export const DESIGN_FAVORITED = 'DESIGN_FAVORITED_';
export const CROSS_SELL_GET = 'CROSS_SELL_GET_';
export const CROSS_SELL_CLEAR = 'CROSS_SELL_CLEAR';
export const SOLID_CROSS_SELL_GET = 'SOLID_CROSS_SELL_GET_';
export const SOLID_IMAGES_GET = 'SOLID_IMAGES_GET_';
export const SOLID_PRICING_GET = 'SOLID_PRICING_GET_';
export const SOLID_COLORS_GET = 'SOLID_COLORS_GET_';
export const SOLID_COLOR_DATA_GET = 'SOLID_COLOR_DATA_GET_';
export const SOLID_RESET = 'SOLID_RESET';

export const WALLPAPER_RECOMMENDATION_POST = 'WALLPAPER_RECOMMENDATION_POST_';
export const WALLPAPER_LANDING_RECOMMENDATION_POST = 'WALLPAPER_LANDING_RECOMMENDATION_POST_';

export const COMMENTS_GET = 'COMMENTS_GET_';
export const COMMENTS_POST = 'COMMENTS_POST_';

export const REVIEWS_GET = 'REVIEWS_GET_';
export const REVIEWS_VOTE_PATCH = 'REVIEWS_VOTE_PATCH_';
export const REVIEWS_FLAG_PATCH = 'REVIEWS_FLAG_PATCH_';
export const REVIEWS_POST = 'REVIEWS_POST_';
export const REVIEWS_ELIGIBILITY_GET = 'REVIEWS_ELIGIBILITY_GET_';

export const CMS_CONTENT_GET = 'CMS_CONTENT_GET_';
export const CMS_CONTENT_LOADING = 'CMS_CONTENT_LOADING';

// Server
export const SERVER_ERROR = 'SERVER_ERROR';

export const SET_ROUTING_DATA = 'SET_ROUTING_DATA';

export const CLEAR_SERVER_CONTEXT = 'CLEAR_SERVER_CONTEXT';
export const CLEAR_ADD_TO_CART_STATE = 'CLEAR_ADD_TO_CART_STATE';

export const SHOULD_RENDER_NOT_FOUND = 'SHOULD_RENDER_NOT_FOUND';

export const SHOULD_RESET_RENDER_NOT_FOUND = 'SHOULD_RESET_RENDER_NOT_FOUND';

// Suffixes
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const FETCHING = 'FETCHING';
export const RESET = 'RESET';
