import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_flash-messages.scss';

export const FlashMessageBase = ({message, cssClasses}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const flashMessageClasses = classNames('b-flash-messages', ...cssClasses);

  return (
    <div className={flashMessageClasses}>
      <p className='message' role='alert' aria-live='polite'>
        {message}
      </p>
    </div>
  );
};

FlashMessageBase.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
  cssClasses: PropTypes.arrayOf(PropTypes.string),
};

export default FlashMessageBase;
