/* global Collection */
import {breakpoints} from './breakpoints';


export type TViewPort = 'mobile' | 'tablet' | 'desktop' | 'small-desktop' | 'large-desktop';
type TBreakpointsCollection = Collection<TViewPort, number>;

const getBreakpointsFromHtml = (viewportName: TViewPort) => (breakpoints as TBreakpointsCollection)[viewportName];

export const VIEWPORT_MOBILE: TViewPort = 'mobile';
export const VIEWPORT_TABLET: TViewPort = 'tablet';
export const VIEWPORT_DESKTOP: TViewPort = 'desktop';
export const VIEWPORT_SMALL_DESKTOP: TViewPort = 'small-desktop';
export const VIEWPORT_LARGE_DESKTOP: TViewPort = 'large-desktop';
export const breakSmallDesktopMax = getBreakpointsFromHtml(VIEWPORT_LARGE_DESKTOP) - 1; // 1024
export const breakDesktopMin = getBreakpointsFromHtml(VIEWPORT_DESKTOP); // 769
export const breakTabletMax = getBreakpointsFromHtml(VIEWPORT_TABLET); // 768
export const breakTabletMin = getBreakpointsFromHtml(VIEWPORT_MOBILE) + 1; // 497
export const breakMobileMax = getBreakpointsFromHtml(VIEWPORT_MOBILE); // 496

export const isMobileViewport = (viewport: TViewPort): boolean => viewport === VIEWPORT_MOBILE;
export const isTabletDown = (viewport: TViewPort): boolean => viewport === VIEWPORT_TABLET || viewport === VIEWPORT_MOBILE;
export const isDesktop = (viewport: TViewPort): boolean => viewport === VIEWPORT_DESKTOP || viewport === VIEWPORT_SMALL_DESKTOP;
// TODO SP-5544: refactor to account for viewport of large desktop in getViewportFromScreenSize()
export const isLargeDesktop = (): boolean => window.innerWidth >= getBreakpointsFromHtml(VIEWPORT_LARGE_DESKTOP);

export function getViewportFromScreenSize(): string {
  let VIEWPORT;
  const windowWidth = window.innerWidth;

  if (windowWidth <= getBreakpointsFromHtml(VIEWPORT_MOBILE)) {
    VIEWPORT = VIEWPORT_MOBILE;
  } else if (windowWidth > getBreakpointsFromHtml(VIEWPORT_MOBILE) && windowWidth <= getBreakpointsFromHtml(VIEWPORT_TABLET)) {
    VIEWPORT = VIEWPORT_TABLET;
  } else if (windowWidth >= getBreakpointsFromHtml(VIEWPORT_DESKTOP) && windowWidth < getBreakpointsFromHtml(VIEWPORT_LARGE_DESKTOP)) {
    VIEWPORT = VIEWPORT_SMALL_DESKTOP;
  } else {
    VIEWPORT = VIEWPORT_DESKTOP;
  }

  return VIEWPORT;
}

export function getScreenWidth(): number {
  return window.innerWidth;
}

export function getScreenHeight(): number {
  return window.innerHeight || 0;
}

export function getWindowPosition(): number {
  // browsers have different methods to return the correct window position
  return window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
}
