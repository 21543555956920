export const EN = 'en';
export const DE = 'de';
export const FR = 'fr';
export const ES = 'es';

export const supportedLanguages = [EN, DE, FR, ES];
export const nonEnglishLanguages = [DE, FR, ES];

// TODO EN-1241 english shop needs to use dynamic urls for categories
export const languagesWithoutCategoriesColors = [FR, ES];

// We don't put these in our i18n Yaml files because they will be the same in every language (users will choose their
// language using the native name for that language.)
export const languageNames = Object.freeze({
  [DE]: 'Deutsch',
  [EN]: 'English',
  [ES]: 'Español',
  [FR]: 'Français'
});

export const languageCountryLocales = Object.freeze({
  [DE]: 'de-DE',
  [EN]: 'en-US',
  [ES]: 'es-ES',
  [FR]: 'fr-FR'
});
