import * as types from '../constants/ActionTypes';
import * as fm from '../constants/FlashMessages';

// TODO (SP-6156): Remove the `hops` and `targetComponent` arguments, as the
// creators of flash/error messages should not have to know the number of
// redirects that will occur downstream nor the components that will render the
// result.
//
// Rename this action. It does not actually show a flash message, it creates an
// action (which updates state, which gets mapped to props in a component, which
// displays the message).
//
// Reconsider the key updated on state. It should probably be `errorsForDisplay`
// or similar, not `flashMessage`. *The fact that an error exists* is known by
// code that dispatches the error, but *where and how to render it* is a view
// concern. The `flashMessage` key conflates the semantic meaning of the error
// with the view concern of where to display it.
//
// See further discussion in the SP-6156 ticket.
/**
 * @param {fm.FLASH_MESSAGE_SET_MESSAGE} severity The severity of this error.
 *   Must be in the `fm.MESSAGE_SEVERITIES` array or will throw an error.
 * @param {string} message The message to display in the flash message.
 * @param {number} [hops=0] The amount of redirects this flash message should
 *   survive. Necessary for scenarios where we set the flash message and
 *   immediately redirect the user, e.g. cart cleanup or payment declined.
 * @param targetComponent An optional argument, the component in which to
 *   render this flash message
 */
export function showFlashMessage(severity, message, hops = 0, targetComponent = undefined) {
  if (!fm.MESSAGE_SEVERITIES.includes(severity)) {
    throw new Error(`Invalid flash message severity "${severity}"`);
  }

  return {
    type: types.FLASH_MESSAGE_SET_MESSAGE,
    severity,
    message,
    hops,
    targetComponent,
  };
}

// TODO (SP-6156): Create a new action for clearing the flash message rather
// than reusing the `FLASH_MESSAGE_SET_MESSAGE`. Reducers, not action creators,
// should know how to update the state object. This couples the shape of the
// state object to this action creator, violating the Law of Demeter. See
// further discussion in the SP-6156 ticket.
export function clearFlashMessage() {
  return {
    type: types.FLASH_MESSAGE_SET_MESSAGE,
    severity: undefined,
    message: undefined,
    hops: undefined,
    targetComponent: undefined,
  };
}
